import axios from '../services/axios';

export async function getEquipmentProfile(id) {
  const { data } = await axios.get(`/equipment/${id}`, {});
  return data;
}

export async function addCustomStop(dataJson) {
  const { data } = await axios.post('/equipment/map', dataJson);
  return data;
}

export async function scheduleMaintenance(body) {
  const { data } = await axios.post('equipment/maintenance-schedule', body);
  return data;
}

export async function updateScheduledMaintenance(id, body) {
  const { data } = await axios.put(`equipment/maintenance-schedule/${id}`, body);
  return data;
}

export async function getEquipmentLogs(params, signal) {
  const { data } = await axios.get(`equipment/change-log`, { params, signal });
  return data;
}

export async function getMotionLogs(params) {
  const { data } = await axios.get(`equipment/gps-motion-logs`, { params });
  return data;
}

export async function getDrivers(params) {
  const { data } = await axios.get('/drivers/list', { params: { page: 1, itemsPerPage: 1000, ...params } });
  return data;
}

export async function getEquipmentNotes({ equipment_id, page, itemsPerPage, sort }) {
  const params = { page, itemsPerPage, sort };
  const { data } = await axios.get(`/notes/equipment/${equipment_id}`, { params });
  return data;
}

export async function addEquipmentNote(body) {
  const { data } = await axios.post('/notes/equipment', body);
  return data;
}

export async function deleteEquipmentNote(id) {
  const { data } = await axios.delete(`/notes/equipment/${id}`);
  return data;
}

export async function quickAddEquipment(formData) {
  const { data } = await axios.post('/equipment', formData);
  return data;
}

export async function getAssignedDrivers(params, signal) {
  const { data } = await axios.get('/driver/assigned-list', { params, signal });
  return data;
}
