import axios from 'axios';
import axiosConfigured from 'services/axios';
import axiosInstance from '../services/axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export async function Staff(filter) {
  const params = {
    ...filter,
  };
  const { data } = await axios.get(`${API_URL}/user/staff`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function StaffGeneral({ id }) {
  const url = `${API_URL}/user/staff/basic/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function staffCompansationData({ id }) {
  const url = `${API_URL}/user/staff/compensation/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function StaffConnectedService(staff_id) {
  const url = `${API_URL}/user/staff/get-connected-service/${staff_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function RestrictionAndShift(staff_id) {
  const url = `${API_URL}/user/staff/restrictions-shift/${staff_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function staffDocumentData(staff_id, params) {
  const url = `${API_URL}/user/staff/documents/${staff_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function StaffUpdateDocumentData(updateDocumentsData, staffId) {
  const url = `${API_URL}/user/staff/documents/${staffId}`;
  const { data } = await axios.put(
    url,
    {
      ...updateDocumentsData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function DriverRemoveDocumentData(staffId) {
  const url = `${API_URL}/user/staff/document/${staffId}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function hourOfServiceAll() {
  const url = `${API_URL}/staff/hours-of-service`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function DepartmentsEmail() {
  const url = `${API_URL}/customer/departments`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function staffRemovePreviData(EmployID) {
  const url = `${API_URL}/previous-employment/staff/${EmployID}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function staffPreviousEmployment({
  page,
  sortField = 'sort[][id]',
  sortBy = 'desc',
  itemsPerPage,
  staff_id,
}) {
  const url = `${API_URL}/previous-employment/staff/${staff_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      [sortField]: sortBy,
      itemsPerPage,
    },
  });
  return data;
}

export async function getStaffLoginLog(params) {
  const { data } = await axiosInstance.get('securitylog', { params });
  return data;
}

export async function getStaffChangeLog(params, signal) {
  const { data } = await axiosInstance.get('user/staff/change-log', { params, signal });
  return data;
}

export async function staffAbsenceData({ year, page, query, sort = 'desc', staff_id, itemsPerPage }) {
  const url = `${API_URL}/staff/absences`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      'sort[][id]': sort,
      staff_id,
      itemsPerPage,
      year,
      query,
    },
  });
  return data;
}

export async function staffCreateAbsenceData(absenceData) {
  const url = `${API_URL}/absence`;
  const { data } = await axios.post(
    url,
    {
      ...absenceData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function staffUpdateAbsenceData(absenceData, absenceId) {
  const url = `${API_URL}/absence/${absenceId}`;
  const { data } = await axios.put(
    url,
    {
      ...absenceData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function staffRemoveAbsenceData(absenceID) {
  const url = `${API_URL}/absence/staff/${absenceID}`;
  const { data } = await axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function staffRequestData({ page, query, sort = 'desc', itemsPerPage, from_date, to_date, staff_id }) {
  const url = `${API_URL}/staff/requests`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      staff_id,
      'sort[][id]': sort,
      page,
      itemsPerPage,
      from_date,
      to_date,
      query,
    },
  });
  return data;
}

export async function AddStaffRequest(request) {
  const url = `${API_URL}/request`;
  const { data } = await axios.post(
    url,
    {
      ...request,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateStaffRequest(driverReuest, id) {
  const url = `${API_URL}/request/${id}`;
  const { data } = await axios.put(
    url,
    {
      ...driverReuest,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function driverTimeTrackingData(params) {
  const url = `${API_URL}/staff/time-tracking`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function getDriverShipments({ id }) {
  const url = `${API_URL}/customer/driver/${id}/shipments?page=1&itemsPerPage=5`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function AddStaffBasicDetail(staff) {
  const url = `${API_URL}/user/staff/basic`;
  const { data } = await axios.post(
    url,
    {
      ...staff,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateStaffBasicDetail(staff, staffId) {
  const url = `${API_URL}/user/staff/basic/${staffId}`;
  const { data } = await axios.put(
    url,
    {
      ...staff,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateStatus(driverStatus, id) {
  const url = `${API_URL}/driver/status/${id}`;
  const { data } = await axios.put(
    url,
    {
      ...driverStatus,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddUpdatePreviousEmployment(employer, isUpdate) {
  const url = isUpdate
    ? `${API_URL}/update-previous-employment-verification-request`
    : `${API_URL}/send-previous-employment-verification-request`;
  const user = JSON.parse(localStorage.getItem('user'));
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    },
    url,
    data: employer,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function AddCompensation(staff) {
  const url = `${API_URL}/user/staff/compensation`;
  const { data } = await axios.post(
    url,
    {
      ...staff,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateCompensation(staff, staffId) {
  const url = `${API_URL}/user/staff/compensation/${staffId}`;
  const { data } = await axios.put(
    url,
    {
      ...staff,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function AddStaffDocument(staff) {
  const url = `${API_URL}/user/staff/documents`;
  const { data } = await axios.post(
    url,
    {
      ...staff,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function updateStaffDocument(staff, staffId) {
  const url = `${API_URL}/user/staff/documents/${staffId}`;
  const { data } = await axios.put(
    url,
    {
      ...staff,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function getTabs() {
  const url = `${API_URL}/tabs`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      type: 'staff',
    },
  });
  return data;
}

export async function getStaffCompanyPolicy() {
  const url = `${API_URL}/customer/settings/driver-compensation`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getAddition({ id }) {
  const url = `${API_URL}/staff/additions/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getDeduction({ id }) {
  const url = `${API_URL}/staff/deductions/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getStaffBalances(id) {
  const { data } = await axiosConfigured.get(`staff/balances/${id}`);
  return data;
}

export async function deleteStaffBalance(id) {
  const { data } = await axiosConfigured.delete(`staff/balances/${id}`);
  return data;
}

export async function createStaffBalance(body) {
  const { data } = await axiosConfigured.post('staff/balances', body);
  return data;
}

export async function updateStaffBalanceDeduction(id, body) {
  const { data } = await axiosConfigured.put(`staff/balance/${id}`, body);
  return data;
}

export async function getTaxes({ id }) {
  const url = `${API_URL}/staff/taxes/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getBenefits({ id }) {
  const url = `${API_URL}/staff/benefits/${id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function AddAdditions(driver, signal) {
  const url = `${API_URL}/staff/additions`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() }, signal }
  );
  return data;
}

export async function AddDeductions(driver, signal) {
  const url = `${API_URL}/staff/deductions`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() }, signal }
  );
  return data;
}

export async function AddTaxes(driver, signal) {
  const url = `${API_URL}/staff/taxes`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() }, signal }
  );
  return data;
}

export async function AddBenefits(driver, signal) {
  const url = `${API_URL}/staff/benefits`;
  const { data } = await axios.post(
    url,
    {
      ...driver,
    },
    { headers: { ...authHeader() }, signal }
  );
  return data;
}

export async function OwnerOperator(params) {
  const url = `${API_URL}/operators?page=1&sort[][id]=asc`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function PositionAndApplication(params) {
  const url = `${API_URL}/job-application`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function DriverGroups(params) {
  const url = `${API_URL}/group`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function getstates(countryId) {
  const url = `${API_URL}/states/country/${countryId}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getCities(countryId) {
  const url = `${API_URL}/cities/state/${countryId}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getStaffShipmentBooked(params) {
  const url = `${API_URL}/staff/graph/shipment-booked`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function getStaffWorkingHours(params) {
  const url = `${API_URL}/staff/graph/working-hours`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      ...params,
    },
  });
  return data;
}

export async function uploadDriverDocument(file, staffId = null) {
  if (!file) {
    return;
  }
  const formData = new FormData();
  formData.append('staff_id', staffId || '');
  formData.append('source', 'general');
  formData.append('file_upload', file);
  const user = JSON.parse(localStorage.getItem('user'));
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    },
    url: `${API_URL}/staff/upload-document`,
    data: formData,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function AddGroup({ group_name, color }) {
  const url = `${API_URL}/group`;
  const { data } = await axios.post(
    url,
    {
      group_name,
      color,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function GetConnectedStaffSeries() {
  const url = `${API_URL}/customer/customer-connected-services?type=staff`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function GetAllConnectedSeries() {
  const url = `${API_URL}/customer/all-connected-services`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function getStaffTaxType() {
  const url = `${API_URL}/driver/tax-types`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
  });
  return data;
}

export async function activeService({ staff_id, serviceId, provider_id, isAllService = false, fields }) {
  let url = '';
  let payload = {};
  if (!isAllService) {
    payload = {
      staff_id,
      provider_id,
    };
    url = `${API_URL}/user/staff/connect-connected-service`;
  } else {
    payload = {
      staff_id,
      connected_service_id: serviceId,
      fields,
    };
    url = `${API_URL}/user/staff/connect-connected-service`;
  }
  const { data } = await axios.post(url, payload, { headers: { ...authHeader() } });
  return data;
}

export async function deactivateService(payload) {
  const url = `${API_URL}/user/staff/remove-connected-service`;
  const { data } = await axios.post(url, payload, { headers: { ...authHeader() } });
  return data;
}

export async function deleteTab({ tab }) {
  const url = `${API_URL}/tabs/${tab}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteDeduction({ deId }) {
  const url = `${API_URL}/staff/deductions/${deId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function deleteAddition({ addId }) {
  const url = `${API_URL}/staff/additions/${addId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}
export async function deleteTax({ taxId }) {
  const url = `${API_URL}/staff/taxes/${taxId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}
export async function deleteBenefit({ beneId }) {
  const url = `${API_URL}/staff/benefits/${beneId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

export async function updateAbsenceAllowence(updatedAllowence, id) {
  const url = `${API_URL}/staff/update-absence-allowance/${id}`;
  const { data } = await axios.put(
    url,
    {
      ...updatedAllowence,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function staffRestrictionSchedule(staffData) {
  const url = `${API_URL}/user/staff/restrictions-shift`;
  const { data } = await axios.post(
    url,
    {
      ...staffData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function UpdateStaffRestrictionSchedule(staffData, id) {
  const url = `${API_URL}/user/staff/restrictions-shift/${id}`;
  const { data } = await axios.put(
    url,
    {
      ...staffData,
    },
    { headers: { ...authHeader() } }
  );
  return data;
}

export async function staffNoteData({ page, sort = 'asc', driver_staff_id, itemsPerPage }) {
  const url = `${API_URL}/notes/staff/${driver_staff_id}`;
  const { data } = await axios.get(url, {
    headers: authHeader(),
    params: {
      page,
      driver_staff_id,
      'sort[][id]': sort,
      itemsPerPage,
    },
  });
  return data;
}

export async function getStaffNotes(id, params) {
  const { data } = await axiosInstance.get(`notes/staff/${id}`, { params });
  return data;
}

export async function staffCreateNotes(notesData) {
  const url = `${API_URL}/notes/staff`;
  const user = JSON.parse(localStorage.getItem('user'));
  const config = {
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: '*/*',
      Authorization: `Bearer ${user.accessToken}`,
    },
    url,
    data: notesData,
  };
  return axios(config).then((response) => {
    return response;
  });
}

export async function deleteStaffNote({ noteId }) {
  const url = `${API_URL}/notes/staff/${noteId}`;
  const { data } = axios.delete(url, {
    headers: authHeader(),
  });
  return data;
}

// Sales Commissions
export async function getSalesCommissions(staffId, params) {
  const { data } = await axiosInstance.get(`user/staff/compensation/${staffId}/sales-commissions`, { params });
  return data;
}
export async function createSalesCommission(staffId, body) {
  const { data } = await axiosInstance.post(`user/staff/compensation/${staffId}/sales-commissions`, body);
  return data;
}
export async function updateSalesCommission({ staffId, id }, body) {
  const { data } = await axiosInstance.post(`user/staff/compensation/${staffId}/sales-commissions/${id}`, body);
  return data;
}
export async function deleteSalesCommission({ staffId, id }) {
  const { data } = await axiosInstance.delete(`user/staff/compensation/${staffId}/sales-commissions/${id}`);
  return data;
}
export async function createStaffPolicies(body) {
  const { data } = await axiosInstance.post(`/staff/save-policies`, body);
  return data;
}
