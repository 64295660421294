import React, { useContext, useEffect, useMemo, useState, createContext } from 'react';
import { Form, Formik } from 'formik';
import sm from './BillingCharges.module.css';

import { ShipmentContext } from '../Layout';
import BillingChargesBody from './BillingChargesBody';
import BillingChargesHeader from './BillingChargesHeader';
import BillingChargesFooter from './BillingChargesFooter';
import { getChangesTemplate } from '../ShipmentStops/helpers/constants';

export const BillingChargesContext = createContext();

const BillingCharges = () => {
  const { updateShipmentData, createShipmentData, shipmentChargeType, getFullPriceShipment } =
    useContext(ShipmentContext);
  const [disableNextStep, setDisableNextStep] = useState(false);
  const [customerRecommendation, setCustomerRecommendation] = useState({
    linehaul: false,
    fuelSurcharge: false,
  });

  const onNext = (values) => {
    const data = {
      ...createShipmentData,
      ...{
        ...values,
        billingCharges: values.billingCharges.map((charge) =>
          charge?.charges?.type?.account_type === 'Expense' ? { ...charge, rate: `-${charge.rate}` } : charge
        ),
      },
    };

    updateShipmentData(data);
  };

  const onChangeDisabledNextStep = (value) => {
    setDisableNextStep(value);
  };

  const initialValue = useMemo(() => {
    return {
      customer_id: createShipmentData?.customer_id ? createShipmentData?.customer_id : '',
      reference_id: createShipmentData?.reference_id ? createShipmentData?.reference_id : '',
      group: createShipmentData?.group ? createShipmentData?.group : '',
      contact_user_id: createShipmentData?.contact_user_id ? createShipmentData?.contact_user_id : '',
      payment_term_id: createShipmentData?.payment_term_id ? createShipmentData?.payment_term_id : '',
      billingCharges: createShipmentData?.billingCharges?.length
        ? createShipmentData.billingCharges.map((item) => ({
            ...item,
            charges: shipmentChargeType.find((el) => el.id === (item.charges?.id || item.charges)),
            rate: Math.abs(item.rate),
          }))
        : [{ ...getChangesTemplate() }],
    };
  }, [createShipmentData]);

  useEffect(() => {
    getFullPriceShipment();
  }, []);

  return (
    <div className={sm.billing_charges_container}>
      <Formik onSubmit={onNext} initialValues={initialValue}>
        {({
          values,
          submitForm,
          setFieldValue,
          setFieldTouched,
          isValid,
          errors,
          touched,
          setTouched,
          validateForm,
        }) => (
          <BillingChargesContext.Provider
            value={{
              errors,
              values,
              isValid,
              touched,
              setTouched,
              submitForm,
              validateForm,
              disableNextStep,
              createShipmentData,
              updateShipmentData,
              onChangeDisabledNextStep,
              setFieldValue,
              setFieldTouched,
              customerRecommendation,
              setCustomerRecommendation,
            }}
          >
            <Form>
              <BillingChargesHeader />

              <BillingChargesBody />

              <BillingChargesFooter />
            </Form>
          </BillingChargesContext.Provider>
        )}
      </Formik>
    </div>
  );
};

export default BillingCharges;
