import moment from 'moment/moment';

export const getInitialValues = (data, shipment, convertToCustomerTime) => {
  const nextStop = shipment?.shipment_stops?.[data.index + 1];
  const customer = shipment?.shipment_billing?.[0]?.billing_customer;
  const showNextStopETA =
    !!data.arrival_date && !!nextStop && !!customer?.customer_payment_term?.require_staff_provide_reason;
  const isETADelayed = nextStop
    ? moment(convertToCustomerTime(nextStop.eta)).isAfter(
        [1, 3].includes(Number(nextStop.scheduled_type))
          ? `${nextStop.scheduled_date_to || nextStop.scheduled_date} ${nextStop.to || nextStop.from}`
          : `${nextStop.scheduled_date} ${nextStop.from}`
      )
    : false;

  switch (Number(data?.stop_point_type)) {
    case 1:
      return {
        arrival_date: data.arrival_date
          ? data.arrival_date
          : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
        departure_date: data.departure_date
          ? data.departure_date
          : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
        equipment_action: data.equipment_action,
        equipment_id: data.equipment?.id ? data.equipment?.id : null,
        actual_quantity: '',
        actual_weight: '',
        bill_lading_id: '',
        seal_id: '',
        bill_type: false,
        dock_high: false,
        stackable: false,
        hazardous_materials: false,
        check_in: !data.arrival_date,
        stop_point_id: data.id,
        reported_weight_type: data.reported_weight_type,
        stop_point_type: data.stop_point_type,
        equipment_tbd: data.equipment_tbd,
        signature: '',
        bill_of_lading_ref: '',
        bill_of_lading: '',
        fname: '',
        lname: '',
        cargos: data.cargos || [],
        should_provide_eta: !!showNextStopETA,
        is_eta_delayed: !!isETADelayed,
        next_stop_id: nextStop?.id,
        eta: nextStop && nextStop.eta ? new Date(convertToCustomerTime(nextStop.eta)) : null,
        delay_reason: nextStop && nextStop.delay_reason ? nextStop.delay_reason : null,
      };
    case 2:
      return {
        arrival_date: data.arrival_date
          ? data.arrival_date
          : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
        departure_date: data.departure_date
          ? data.departure_date
          : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
        equipment_action: data.equipment_action,
        equipment_id: data.equipment?.id ? data.equipment?.id : null,
        cargo_onBoard: '1',
        bill_type: false,
        dock_high: false,
        proof_of_delivery_name: '',
        signature_name: '',
        check_in: !data.arrival_date,
        stop_point_id: data.id,
        reported_weight_type: data.reported_weight_type,
        stop_point_type: data.stop_point_type,
        signature: '',
        bill_of_lading_ref: '',
        bill_of_lading: '',
        drop_id: shipment?.equipment?.hooked_to?.id,
        should_provide_eta: !!showNextStopETA,
        is_eta_delayed: !!isETADelayed,
        next_stop_id: nextStop?.id,
        eta: nextStop && nextStop.eta ? new Date(convertToCustomerTime(nextStop.eta)) : null,
        delay_reason: nextStop && nextStop.delay_reason ? nextStop.delay_reason : null,
      };
    case 3:
      return {
        arrival_date: data.arrival_date
          ? data.arrival_date
          : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
        departure_date: data.departure_date
          ? data.departure_date
          : new Date(convertToCustomerTime(new Date(), 'MM/DD/yyyy HH:mm')),
        equipment_action: data.equipment_action,
        equipment_id: data.equipment?.id ? data.equipment?.id : null,
        bill_type: false,
        dock_high: false,
        labor_required: false,
        check_in: !data.arrival_date,
        stop_point_id: data.id,
        reported_weight_type: data.reported_weight_type,
        stop_point_type: data.stop_point_type,
        proof_of_delivery_name: '',
        signature: '',
        bill_of_lading_ref: '',
        bill_of_lading: '',
        signature_name: '',
        drop_id: shipment?.equipment?.hooked_to?.id,
        equipment_tbd: data.equipment_tbd,
        should_provide_eta: !!showNextStopETA,
        is_eta_delayed: !!isETADelayed,
        next_stop_id: nextStop?.id,
        eta: nextStop && nextStop.eta ? new Date(convertToCustomerTime(nextStop.eta)) : null,
        delay_reason: nextStop && nextStop.delay_reason ? nextStop.delay_reason : null,
      };
    default:
  }
};
