import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';
import { useParams, useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { CircularProgress } from '@mui/material';
import { Typography } from 'components/Typography';
import {
  createCustomerPayTerms,
  createCustomPayTerm,
  CustomerPayTerms,
  getCustomerDelayCode,
  getCustomerPayTerms,
  UpdateCustomersPayTerm,
} from 'Api/Customers';
import { GetSettingsAccounting } from 'Api/CompanySettings';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import BackArrow from 'assets/icons/drivers/arrow-left.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warningOutline.svg';
import { getErrorMessage } from 'utils/error';
import { blockNotNumberChars } from 'utils/helpers';
import { useSelector } from 'react-redux';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import Autocomplete from 'common/Autocomplete';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import { documentType } from 'Api/Document';
import useShowToaster from 'hooks/useShowToaster';
import CustomSelect from '../CreateShipment/ShipmentStops/helpers/CustomSelect';
import { formatAmount } from '../Customers/helpers/CustomersTableHeaderFilterByShow';
import sm from './PaymentTermsTab.module.css';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import { validationSchema } from './ValidationSchema';

const dropTrailerStartsAfterOptions = [
  {
    name: 'DAYS',
    value: 'days',
    key: 'days',
    label: 'days',
    labelSelected: null,
  },
  {
    name: 'HOURS',
    value: 'hours',
    key: 'hours',
    label: 'hours',
    labelSelected: null,
  },
];

const PaymentTermsTab = ({ loading, showError }) => {
  const { use } = useTheme();
  const showToaster = useShowToaster();
  const hasBrokerage = useHasBrokerage();
  const { currency, billOfLadingTitle } = useSelector((state) => state.root);
  const [searchParams, setSearchParams] = useSearchParams();
  const isEdit = searchParams.get('edit');
  const [toggle, setToggle] = useState(!!isEdit);
  const [invoicePayTermsOptions, setInvoicePayTermsOptions] = useState([]);
  const [defaultActiveValueTerm, setDefaultActiveValueTerm] = useState(null);
  const [defaultActiveValueTrailerPer, setDefaultActiveValueTrailerPer] = useState();
  const [paymentTerms, setPaymentTerms] = useState();
  const [accountingSettings, setAccountingSettings] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [delayCodes, setDelayCodes] = useState([]);

  const { id } = useParams();
  const {
    pay_term_types,
    invoice_note,
    require_signature_on_stop,
    require_staff_provide_reason,
    require_driver_provide_reason,
    require_bill_of_lading,
    require_confirmations,
    require_cargo_scan_on_pickup,
    is_brokerage_customer,
    send_invoice_to_factoring,
    detention_starts_after_hour,
    billed_at,
    detention_trailer_dropped_starts_after,
    detention_trailer_dropped_start_after_per,
    detention_trailer_dropped_billed_at,
    credit_line_maximum,
    required_document_type,
  } = paymentTerms || {};

  const onSave = () => {
    const payload = {
      customer_id: id,
      invoice_note: formik.values.invoice_note,
      require_signature_on_stop: formik.values.require_signature_on_stop ? 1 : 0,
      require_staff_provide_reason: formik.values.require_staff_provide_reason ? 1 : 0,
      require_driver_provide_reason: formik.values.require_driver_provide_reason ? 1 : 0,
      require_bill_of_lading: formik.values.require_bill_of_lading ? 1 : 0,
      require_confirmations: formik.values.require_confirmations ? 1 : 0,
      is_brokerage_customer: formik.values.is_brokerage_customer ? 1 : 0,
      send_invoice_to_factoring: formik.values.send_invoice_to_factoring ? 1 : 0,
      detention_starts_after_hour: formik.values.detention ? formik.values.detention_starts_after_hour : null,
      detention_trailer_dropped_starts_after: formik.values.detentionTrailer
        ? formik.values.detention_trailer_dropped_starts_after
        : null,
      detention_trailer_dropped_start_after_per: formik.values.detentionTrailer
        ? formik.values.detention_trailer_dropped_start_after_per
        : null,
      detention_trailer_dropped_billed_at: formik.values.detentionTrailer
        ? formik.values.detention_trailer_dropped_billed_at
        : null,
      billed_at: formik.values.billed_at,
      credit_line_maximum: formik.values.credit_line_maximum,
      invoice_pay_term: formik.values.invoice_pay_term,
      required_document_type_id: formik.values.required_document_type?.id || null,
      require_cargo_scan_on_pickup: formik.values.require_cargo_scan_on_pickup ? 1 : 0,
    };
    if (formik.values.invoice_pay_term === 'custom' && formik.values.customTerm && formik.values.net_days) {
      createCustomPayTerm(formik.values.customTerm, formik.values.discount || 0, formik.values.net_days)
        .then((res) => {
          payload.invoice_pay_term = res.data.id;
          if (paymentTerms) {
            UpdateCustomersPayTerm(payload, paymentTerms?.id)
              .then(() => {
                setToggle(!toggle);
                getPayTerms();
              })
              .catch((error) => {
                showError(getErrorMessage(error));
              });
          } else {
            createCustomerPayTerms(payload, id)
              .then(() => {
                setToggle(!toggle);
                getPayTerms();
              })
              .catch((error) => {
                showError(getErrorMessage(error));
              });
          }
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    } else {
      if (paymentTerms) {
        UpdateCustomersPayTerm(payload, paymentTerms?.id)
          .then(() => {
            setToggle(!toggle);
            getPayTerms();
          })
          .catch((err) => {
            showError(getErrorMessage(err));
          });
      } else {
        createCustomerPayTerms(payload, id)
          .then(() => {
            setToggle(!toggle);
            getPayTerms();
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      invoice_pay_term: '',
      invoice_note: '',
      discount: '',
      net_days: '',
      require_signature_on_stop: false,
      require_driver_provide_reason: false,
      require_staff_provide_reason: false,
      require_bill_of_lading: false,
      require_confirmations: false,
      require_cargo_scan_on_pickup: false,
      is_brokerage_customer: false,
      send_invoice_to_factoring: false,
      detention: false,
      detentionTrailer: false,
      detention_starts_after_hour: '',
      detention_start_after: '',
      billed_at: '',
      detention_trailer_dropped_starts_after: '',
      detention_trailer_dropped_start_after_per: '',
      detention_trailer_dropped_billed_at: '',
      credit_line_maximum: '',
      required_document_type: null,
      customTerm: '',
    },
    validationSchema,
    onSubmit: onSave,
  });

  const getPayTerms = () => {
    CustomerPayTerms({ id }).then((result) => {
      setPaymentTerms(result.data);
      getCustomerPayTerms().then((res) => {
        if (res && res?.data) {
          const custom = [{ id: 'custom', title: 'Add custom', net_day: '' }];
          const selectData = custom.concat(res.data).map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.id === 'custom' ? el.title : getPaymentTermTitle(el),
              labelSelected: null,
            };
          });
          const defaultValueTerm = selectData.find((item) => item.id === result?.data?.pay_term_types?.id);
          const defaultValueTrailerPer = dropTrailerStartsAfterOptions.find(
            (item) => item.name === result?.data?.detention_trailer_dropped_start_after_per
          );
          setDefaultActiveValueTerm(defaultValueTerm);
          setDefaultActiveValueTrailerPer(defaultValueTrailerPer);
          setInvoicePayTermsOptions(selectData);
        }
      });
    });
  };

  const getAccountingSettings = async () => {
    try {
      const { data } = await GetSettingsAccounting();
      setAccountingSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDocumentTypes = async () => {
    try {
      const { data } = await documentType({ shipment_id_required: 1, driver_visible: 1 });
      setDocumentTypes(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDelayCodes = async () => {
    try {
      const { data } = await getCustomerDelayCode({ id });
      setDelayCodes(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getAccountingSettings();
    getPayTerms();
    getDocumentTypes();
    getDelayCodes();

    if (isEdit) {
      searchParams.delete('edit');
      searchParams.delete('tab');
      setSearchParams(searchParams, { replace: true });
    }
  }, []);

  useEffect(() => {
    formik.setFieldValue('invoice_pay_term', paymentTerms?.invoice_pay_term || '');
    formik.setFieldValue('billed_at', paymentTerms?.billed_at);
    formik.setFieldValue('invoice_note', paymentTerms?.invoice_note);
    formik.setFieldValue('credit_line_maximum', paymentTerms?.credit_line_maximum);
    formik.setFieldValue('require_signature_on_stop', Boolean(paymentTerms?.require_signature_on_stop));
    formik.setFieldValue('require_staff_provide_reason', Boolean(paymentTerms?.require_staff_provide_reason));
    formik.setFieldValue('require_driver_provide_reason', Boolean(paymentTerms?.require_driver_provide_reason));
    formik.setFieldValue('require_bill_of_lading', Boolean(paymentTerms?.require_bill_of_lading));
    formik.setFieldValue('require_confirmations', Boolean(paymentTerms?.require_confirmations));
    formik.setFieldValue('require_cargo_scan_on_pickup', Boolean(paymentTerms?.require_cargo_scan_on_pickup));
    formik.setFieldValue('is_brokerage_customer', !!is_brokerage_customer);
    formik.setFieldValue('send_invoice_to_factoring', Boolean(Number(paymentTerms?.send_invoice_to_factoring)));
    formik.setFieldValue('detention_starts_after_hour', paymentTerms?.detention_starts_after_hour);
    formik.setFieldValue('required_document_type', paymentTerms?.required_document_type || null);
    if (paymentTerms?.detention_starts_after_hour && paymentTerms?.billed_at) {
      formik.setFieldValue('detention', true);
    }
    if (
      paymentTerms?.detention_trailer_dropped_billed_at &&
      paymentTerms?.detention_trailer_dropped_start_after_per &&
      paymentTerms?.detention_trailer_dropped_starts_after
    ) {
      formik.setFieldValue('detentionTrailer', true);
    }
    formik.setFieldValue('detention_trailer_dropped_billed_at', paymentTerms?.detention_trailer_dropped_billed_at);
    formik.setFieldValue(
      'detention_trailer_dropped_start_after_per',
      paymentTerms?.detention_trailer_dropped_start_after_per
    );
    formik.setFieldValue(
      'detention_trailer_dropped_starts_after',
      paymentTerms?.detention_trailer_dropped_starts_after
    );
  }, [paymentTerms]);

  const onDelayReasonChange = (name, checked) => {
    if (checked && !delayCodes.length) {
      showToaster({
        type: 'error',
        message: 'To activate this setting, please provide at least one reason for the delay!',
      });
      return;
    }

    formik.setFieldValue(name, checked);
  };

  return (
    <div
      className={sm.paymentTermsTab}
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderBottomColor: use(palette.gray50, palette.darkborder),
      }}
    >
      <div
        className={sm['paymentTermsTab-header']}
        style={{
          backgroundColor: use(palette.white, palette.dark800),
          borderBottomColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <p className={sm.heading} style={{ color: use(palette.gray900, palette.gray50) }}>
          Terms & Requirements
        </p>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <button onClick={() => setToggle(!toggle)} className='driver-compansation-cancel-btn'>
              Cancel
            </button>

            {loading ? (
              <CircularProgress size={30} />
            ) : (
              <button onClick={formik.handleSubmit} className='driver-compansation-save-btn'>
                Save
              </button>
            )}
          </div>
        )}
      </div>
      {toggle ? (
        <Form className='driver-style-wrap p-4' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
          <div className='d-flex'>
            <div style={{ marginRight: '15px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Invoice Pay Terms <span className='required'>*</span>
              </p>
              <div className='d-flex' style={{ alignItems: 'center' }}>
                <div>
                  <div style={{ width: '400px' }}>
                    <CustomSelect
                      defaultValue='Select...'
                      options={invoicePayTermsOptions}
                      onChange={(value) => {
                        formik.setFieldValue('invoice_pay_term', value.id);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.invoice_pay_term}
                      styles={{ height: '32px', marginTop: '10px' }}
                      defaultActiveValue={defaultActiveValueTerm}
                    />
                  </div>
                  {formik.touched.invoice_pay_term && formik.errors.invoice_pay_term ? (
                    <span className={sm['tab-error-message']}>{formik.errors.invoice_pay_term}</span>
                  ) : null}
                </div>

                {formik.values.invoice_pay_term && formik.values.invoice_pay_term === 'custom' ? (
                  <div style={{ marginLeft: 15 }}>
                    <img style={{ transform: 'rotate(180deg)' }} src={BackArrow} alt='Back-Arrow' />
                  </div>
                ) : null}
              </div>
            </div>

            {formik.values.invoice_pay_term && formik.values.invoice_pay_term === 'custom' ? (
              <>
                <div style={{ marginRight: '20px' }}>
                  <p
                    className='text-style'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      marginLeft: '0',
                    }}
                  >
                    Term Name <span className='required'>*</span>
                  </p>
                  <div className='d-flex' style={{ alignItems: 'center' }}>
                    <div>
                      <div className='custom-select-wrap-contain'>
                        <input
                          type='text'
                          onBlur={formik.handleBlur}
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                            borderRadius: '6px',
                            marginTop: '10px',
                            height: '32px',
                            boxShadow: '0px 1px 2px rgb(0 0 0 / 6%), 0px 0px 0px 1px rgb(104 113 130 / 16%)',
                          }}
                          value={formik.values.customTerm}
                          onChange={(event) => {
                            formik.setFieldValue('customTerm', event.target.value);
                          }}
                        />
                      </div>
                      {formik.touched.customTerm && formik.errors.customTerm ? (
                        <span className={sm['tab-error-message']}>{formik.errors.customTerm}</span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p
                    className='text-style'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      marginLeft: '0',
                    }}
                  >
                    Discount
                  </p>
                  <div className='per-hour-work' style={{ marginTop: 10 }}>
                    <input
                      type='number'
                      placeholder='0.00'
                      name='discount'
                      id='discount'
                      onBlur={formik.handleBlur}
                      onKeyDown={blockNotNumberChars}
                      onChange={(event) => {
                        if (event.target.value < 0) {
                          return;
                        }
                        formik.setFieldValue('discount', event.target.value);
                      }}
                      value={formik.values.discount}
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                        borderRadius: 6,
                      }}
                    />
                    <span
                      className='doller-icon'
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                        borderRadius: 6,
                        paddingRight: 8,
                      }}
                    >
                      %
                    </span>
                  </div>
                </div>
                <div style={{ marginRight: '20px' }}>
                  <p
                    className='text-style'
                    style={{
                      color: use(palette.gray700, palette.gray200),
                      marginLeft: '0',
                    }}
                  >
                    Net Days <span className='required'>*</span>
                  </p>
                  <div className='custom-select-wrap-contain'>
                    <input
                      type='number'
                      onBlur={formik.handleBlur}
                      style={{
                        maxWidth: 100,
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                        borderRadius: '6px',
                        marginTop: '10px',
                        height: '32px',
                        boxShadow: '0px 1px 2px rgb(0 0 0 / 6%), 0px 0px 0px 1px rgb(104 113 130 / 16%)',
                      }}
                      placeholder='00'
                      value={formik.values.net_days}
                      onKeyDown={blockNotNumberChars}
                      onChange={(event) => {
                        if (event.target.value < 0) {
                          return;
                        }
                        formik.setFieldValue('net_days', event.target.value);
                      }}
                    />
                  </div>
                  {formik.touched.net_days && formik.errors.net_days ? (
                    <span className={sm['tab-error-message']}>{formik.errors.net_days}</span>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>

          <div className='d-flex' style={{ marginTop: '20px' }}>
            <div style={{ marginRight: '20px' }}>
              <p
                className='text-style'
                style={{
                  color: use(palette.gray700, palette.gray200),
                  marginLeft: '0',
                }}
              >
                Invoice Notes
              </p>
              <div className='d-flex flex-column'>
                <div>
                  <textarea
                    className='text-area'
                    name='invoice_note'
                    id='invoice_note'
                    placeholder='Write note...'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.invoice_note}
                    style={{
                      background: use(palette.white, palette.dark800),
                      color: use(palette.gray900, palette.gray50),
                      minWidth: 400,
                    }}
                  />
                </div>
                {formik.touched.invoice_note && formik.errors.invoice_note ? (
                  <span className={sm['tab-error-message']}>{formik.errors.invoice_note}</span>
                ) : null}
              </div>
            </div>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_signature_on_stop}
              onChange={() => {
                formik.setFieldValue('require_signature_on_stop', !formik.values.require_signature_on_stop);
              }}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>Require Signature on Stops</span>
            </CustomCheckbox>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_bill_of_lading}
              onChange={() => {
                formik.setFieldValue('require_bill_of_lading', !formik.values.require_bill_of_lading);
              }}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>Require {billOfLadingTitle}</span>
            </CustomCheckbox>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_confirmations}
              onChange={() => {
                formik.setFieldValue('require_confirmations', !formik.values.require_confirmations);
              }}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>Require Confirmations</span>
            </CustomCheckbox>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_cargo_scan_on_pickup}
              onChange={() => {
                formik.setFieldValue('require_cargo_scan_on_pickup', !formik.values.require_cargo_scan_on_pickup);
              }}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>Require Cargo Scan on Pickups (Driver App)</span>
            </CustomCheckbox>
          </div>
          <div className='d-flex'>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.detention}
              onChange={() => {
                formik.setFieldValue('detention', !formik.values.detention);
              }}
            />
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <span style={{ fontSize: 16, marginRight: 10 }}>Detention starts after</span>
              <div className='payment-option-second-input '>
                <input
                  type='number'
                  placeholder='0'
                  disabled={!formik.values.detention}
                  onBlur={formik.handleBlur}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  value={formik.values.detention_starts_after_hour}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('detention_starts_after_hour', event.target.value);
                  }}
                />
              </div>
              <span style={{ fontSize: 16, margin: '0 10px' }}>hour(s) , billed at</span>
              <div className='per-hour-work m-0'>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0'
                  name='detention_pay'
                  id='detention_pay'
                  onBlur={formik.handleBlur}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('billed_at', event.target.value);
                  }}
                  disabled={!formik.values.detention}
                  value={formik.values.billed_at}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    borderRadius: '0px 6px 6px 0px',
                  }}
                />
              </div>
              <span style={{ fontSize: 16, margin: '0 10px' }}>/hr</span>
            </div>
          </div>
          <div className='d-flex'>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.detentionTrailer}
              onChange={() => {
                formik.setFieldValue('detentionTrailer', !formik.values.detentionTrailer);
              }}
            />
            <div className='d-flex' style={{ alignItems: 'center' }}>
              <span style={{ fontSize: 16, marginRight: 10 }}>Detention - Trailer Dropped starts after</span>
              <div className='payment-option-second-input '>
                <input
                  type='number'
                  placeholder='0'
                  disabled={!formik.values.detentionTrailer}
                  onBlur={formik.handleBlur}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                  }}
                  value={formik.values.detention_trailer_dropped_starts_after}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('detention_trailer_dropped_starts_after', event.target.value);
                  }}
                />
              </div>
              <div className='payment-option-second-input '>
                <CustomSelect
                  options={dropTrailerStartsAfterOptions}
                  onChange={(value) => {
                    formik.setFieldValue('detention_trailer_dropped_start_after_per', value.name);
                  }}
                  onBlur={formik.handleBlur}
                  defaultActiveValue={defaultActiveValueTrailerPer}
                  value={formik.values.detention_trailer_dropped_start_after_per}
                  disabled={!formik.values.detentionTrailer}
                  styles={{ height: 30, width: 90, marginTop: '0' }}
                />
              </div>
              <span style={{ fontSize: 16, margin: '0 20px' }}>, billed at</span>
              <div className='per-hour-work m-0'>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0'
                  name='detention_pay'
                  id='detention_pay'
                  onBlur={formik.handleBlur}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('detention_trailer_dropped_billed_at', event.target.value);
                  }}
                  disabled={!formik.values.detentionTrailer}
                  value={formik.values.detention_trailer_dropped_billed_at}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    borderRadius: '0px 6px 6px 0px',
                  }}
                />
              </div>
              <span style={{ fontSize: 16, margin: '0 10px' }}>
                {formik.values.detention_trailer_dropped_start_after_per === 'DAYS' ? '/day' : '/hr'}
              </span>
            </div>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_staff_provide_reason}
              onChange={(checked) => onDelayReasonChange('require_staff_provide_reason', checked)}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>
                If delayed to next stop, require Staff User to provide Delay Reason on Checkout
              </span>
            </CustomCheckbox>
          </div>
          <div>
            <CustomCheckbox
              style={{ margin: '10px' }}
              checked={formik.values.require_driver_provide_reason}
              onChange={(checked) => onDelayReasonChange('require_driver_provide_reason', checked)}
            >
              <span style={{ fontSize: 16, marginLeft: 10 }}>
                If delayed to next stop, require Driver App to provide Delay Reason on Checkout
              </span>
            </CustomCheckbox>
          </div>
          {!!accountingSettings?.is_factoring && (
            <div>
              <CustomCheckbox
                style={{ margin: '10px' }}
                checked={formik.values.send_invoice_to_factoring}
                onChange={() => {
                  formik.setFieldValue('send_invoice_to_factoring', !formik.values.send_invoice_to_factoring);
                }}
              >
                <div className='d-flex flex-column' style={{ marginLeft: '10px' }}>
                  <span style={{ fontSize: 16 }}>Require Factoring</span>
                  <div className='d-flex align-items-center justify-content-start gap-1'>
                    <WarningIcon />
                    <Typography variant='b2' style={{ color: palette.orange500 }}>
                      Letter of assignment will be sent to this customer to factor all invoices
                    </Typography>
                  </div>
                </div>
              </CustomCheckbox>
            </div>
          )}
          {!!hasBrokerage && (
            <div>
              <CustomCheckbox
                style={{ margin: '10px' }}
                checked={formik.values.is_brokerage_customer}
                onChange={(checked) => formik.setFieldValue('is_brokerage_customer', checked)}
              >
                <span style={{ fontSize: 16, marginLeft: 10 }}>Brokerage Customer</span>
              </CustomCheckbox>
            </div>
          )}
          <div style={{ marginRight: '20px', marginTop: 30 }} className='d-flex align-items-center gap-3'>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Require Document
            </Typography>
            <Autocomplete
              width='250px'
              name='required_document_type'
              options={documentTypes}
              placeholder='Select Document Type'
              value={formik.values.required_document_type}
              disableClearable={false}
              onChange={(e, val) => formik.setFieldValue(`required_document_type`, val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              on Pick Up
            </Typography>
          </div>
          <div style={{ marginRight: '20px', marginTop: 30 }}>
            <p
              className='text-style'
              style={{
                color: use(palette.gray700, palette.gray200),
                marginLeft: '0',
              }}
            >
              Credit Line Maximum
            </p>
            <div className='d-flex flex-column'>
              <div className='per-hour-work' style={{ minWidth: 200 }}>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0.00'
                  name='credit_line_maximum'
                  id='credit_line_maximum'
                  onBlur={formik.handleBlur}
                  onKeyDown={blockNotNumberChars}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue('credit_line_maximum', event.target.value);
                  }}
                  value={formik.values.credit_line_maximum}
                  style={{
                    minWidth: 200,
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    borderRadius: '0px 6px 6px 0px',
                  }}
                />
              </div>
              {formik.touched.credit_line_maximum && formik.errors.credit_line_maximum ? (
                <span className={sm['tab-error-message']}>{formik.errors.credit_line_maximum}</span>
              ) : null}
            </div>
          </div>
        </Form>
      ) : (
        <div className={sm.list}>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Invoice Pay Terms</p>
            <p className={sm['list-text']}>{getPaymentTermTitle(pay_term_types)}</p>
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Invoice Notes</p>
            <p className={sm['list-text']}>{invoice_note}</p>
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Require Signature on Stops</p>
            {require_signature_on_stop ? (
              <p className={sm['success-container']}>YES</p>
            ) : (
              <p className={sm['warning-container']}>NO</p>
            )}
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Require {billOfLadingTitle}</p>
            {require_bill_of_lading ? (
              <p className={sm['success-container']}>YES</p>
            ) : (
              <p className={sm['warning-container']}>NO</p>
            )}
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Require Confirmations</p>
            {require_confirmations ? (
              <p className={sm['success-container']}>YES</p>
            ) : (
              <p className={sm['warning-container']}>NO</p>
            )}
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Require Cargo Scan on Pickups (Driver App)</p>
            {require_cargo_scan_on_pickup ? (
              <p className={sm['success-container']}>YES</p>
            ) : (
              <p className={sm['warning-container']}>NO</p>
            )}
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Detention</p>
            {detention_starts_after_hour ? (
              <p className={sm['list-text']}>
                Starts after
                <span className={sm['list-styled-text']}>{detention_starts_after_hour}</span>hour(s), billed at
                <span className={sm['list-styled-text']}>
                  {currency} {billed_at}
                </span>
                <span>/hr</span>
              </p>
            ) : (
              <> - </>
            )}
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Detention - Trailer Dropped</p>
            {detention_trailer_dropped_starts_after ? (
              <p className={sm['list-text']}>
                Starts after
                <span className={sm['list-styled-text']}>{detention_trailer_dropped_starts_after}</span>
                {detention_trailer_dropped_start_after_per === 'DAYS'
                  ? 'day(s)'
                  : detention_trailer_dropped_start_after_per === 'HOURS'
                  ? 'hour(s)'
                  : ''}
                , billed at
                <span className={sm['list-styled-text']}>
                  {currency} {detention_trailer_dropped_billed_at}
                </span>
                <span>{formik.values.detention_trailer_dropped_start_after_per === 'DAYS' ? '/day' : '/hr'}</span>
              </p>
            ) : (
              <> - </>
            )}
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>
              If delayed to next stop, require Staff User to provide Delay Reason on Checkout
            </p>
            {require_staff_provide_reason ? (
              <p className={sm['success-container']}>YES</p>
            ) : (
              <p className={sm['warning-container']}>NO</p>
            )}
          </div>
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>
              If delayed to next stop, require Driver to provide Delay Reason on Checkout
            </p>
            {require_driver_provide_reason ? (
              <p className={sm['success-container']}>YES</p>
            ) : (
              <p className={sm['warning-container']}>NO</p>
            )}
          </div>
          {!!hasBrokerage && (
            <div className={sm['list-item']}>
              <p className={sm['list-title']}>Brokerage Customer</p>
              {is_brokerage_customer ? (
                <p className={sm['success-container']}>YES</p>
              ) : (
                <p className={sm['warning-container']}>NO</p>
              )}
            </div>
          )}
          <div className={sm['list-item']}>
            <p className={sm['list-title']}>Required Document Type on Pick Up</p>
            <p className={sm['list-text']}>{required_document_type?.name || '-'}</p>
          </div>
          <div
            className={sm['list-item']}
            style={{ borderBottom: !accountingSettings?.is_factoring ? 'none' : '1px solid #E9EDF5' }}
          >
            <p className={sm['list-title']}>Credit Line Maximum</p>
            <p className={sm['list-text']}>
              <span className={sm['list-styled-text']} style={{ margin: 0 }}>
                {currency} {formatAmount(credit_line_maximum)}
              </span>
            </p>
          </div>
          {!!accountingSettings?.is_factoring && (
            <div className={sm['list-item']} style={{ borderBottom: 'none' }}>
              <p className={sm['list-title']}>
                <div className='d-flex flex-column'>
                  <span style={{ fontSize: 16 }}>Require Factoring</span>
                  <div className='d-flex align-items-center justify-content-start gap-1'>
                    <WarningIcon />
                    <Typography variant='b2' style={{ color: palette.orange500 }}>
                      Letter of assignment was sent to this customer to factor all invoices
                    </Typography>
                  </div>
                </div>
              </p>
              {Number(send_invoice_to_factoring) ? (
                <p className={sm['success-container']} style={{ marginLeft: '16px' }}>
                  YES
                </p>
              ) : (
                <p className={sm['warning-container']} style={{ marginLeft: '16px' }}>
                  NO
                </p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentTermsTab;
