import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import MaterialTable from 'material-table';
import { useParams } from 'react-router-dom';
import { getCustomerContactBookType } from 'Api/Customers';
import { palette } from 'utils/constants';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import inbox from 'assets/icons/mail.svg';
import call from 'assets/icons/contact.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import right from 'assets/icons/drivers/right.svg';
import { Icons } from 'assets/icons';
import {
  createStopPointContactBook,
  DeleteStopPointContactBook,
  getStopPointsContactBook,
  updateStopPointContactBook,
} from 'Api/StopPoint';
import Tooltip from 'common/Tooltip';
import { Typography } from 'components/Typography';
import CustomPhoneInput from 'components/CustomPhoneInput';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import { AppendToFormData } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import sm from 'components/ContactBookTab/ContactBookTab.module.css';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';

const ContactBook = () => {
  const showToaster = useShowToaster();
  const formRef = useRef();
  const { id } = useParams();
  const [toggle, setToggle] = useState(false);
  const [contactBooK, setContactBook] = useState();
  const [refetchIndex, setRefetchIndex] = useState(Date.now());
  const [typeData, setTypeData] = useState([]);

  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: palette.red500,
      },
      type: {
        width: '100%',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: palette.indigo500,
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette]);
  const defaultValue = {
    name: '',
    email: '',
    phone_number: '',
    contact_type_id: '',
    portal_access: false,
    email_updates: false,
    sms_updates: false,
  };

  const getTypeData = () => {
    getCustomerContactBookType()
      .then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              labelSelected: null,
            };
          });
          setTypeData(selectData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const validateEmail = (value) => {
    let error;
    const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!isValidEmail.test(value) && value) {
      error = 'Invalid email address';
    }
    return error;
  };

  const onDelete = (el, index, arrayHelpers) => {
    if (el?.id) {
      DeleteStopPointContactBook(el.id)
        .then(() => {
          arrayHelpers.remove(index);
          showToaster({ type: 'success', message: 'Contact deleted successfully' });
        })
        .catch((err) => {
          showToaster({ type: 'error', message: getErrorMessage(err) });
        });
    } else {
      arrayHelpers.remove(index);
    }
  };

  const onUpdate = (
    el,
    index,
    values,
    errors,
    getFieldHelpers,
    arrayHelpers,
    validateForm,
    validateField,
    setFieldValue
  ) => {
    const fieldNames = [
      `contact_books[${index}][name]`,
      `contact_books[${index}][email]`,
      `contact_books[${index}][phone_number]`,
      `contact_books[${index}][contact_type_id]`,
    ];
    const promises = fieldNames.map((fieldName) => {
      const fieldHelper = getFieldHelpers(fieldName);
      fieldHelper.setTouched();
      return validateField(fieldName);
    });

    Promise.all(promises).then((errors) => {
      const isValid = errors.every((error) => !error);
      if (isValid) {
        if (el?.id) {
          updateContactUpdate(el, values);
        } else {
          createContactUpdate(el, values, index, setFieldValue);
        }
      }
    });
  };

  const updateContactUpdate = (el) => {
    const updateData = {
      stop_point_id: el.stop_point_id,
      name: el.name,
      phone_number: el.phone_number,
      contact_type_id: el.contact_type_id,
      portal_access: el.portal_access ? 1 : 0,
      email_updates: el.email_updates ? 1 : 0,
      sms_updates: el.sms_updates ? 1 : 0,
      email: el.email !== '' ? el.email : null,
    };

    updateStopPointContactBook(updateData, el.id)
      .then(() => {
        showToaster({ type: 'success', message: 'Contact updated successfully' });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const createContactUpdate = (el, values, index, setFieldValue) => {
    const formData = new FormData();
    const updateData = AppendToFormData({}, values, index);
    const map = mapper(updateData, index);
    Object.keys(map).map((key) => formData.append(key, map[key]));
    createStopPointContactBook(formData)
      .then((res) => {
        if (res?.data?.data?.[0]) {
          setFieldValue(`contact_books[${index}][id]`, res.data.data[0].id);
        }
        showToaster({ type: 'success', message: 'Contact created successfully!' });
      })
      .catch((err) => {
        showToaster({ type: 'error', message: getErrorMessage(err) });
      });
  };

  const mapper = (obj, index) => {
    const newData = {
      stop_point_id: id,
    };
    const map = {
      [`contact_books[${index}]email_updates`]: obj[`contact_books[${index}]email_updates`] ? '1' : '0',
      [`contact_books[${index}]portal_access`]: obj[`contact_books[${index}]portal_access`] ? '1' : '0',
      [`contact_books[${index}]sms_updates`]: obj[`contact_books[${index}]sms_updates`] ? '1' : '0',
    };
    const namesMap = {
      [`contact_books[${index}]sms_updates`]: [`contact_books[${index}][sms_updates]`],
      [`contact_books[${index}]email_updates`]: [`contact_books[${index}][email_updates]`],
      [`contact_books[${index}]portal_access`]: [`contact_books[${index}][portal_access]`],
      [`contact_books[${index}]name`]: [`contact_books[${index}][name]`],
      [`contact_books[${index}]email`]: [`contact_books[${index}][email]`],
      [`contact_books[${index}]phone_number`]: [`contact_books[${index}][phone_number]`],
      [`contact_books[${index}]contact_type_id`]: [`contact_books[${index}][contact_type_id]`],
    };

    Object.keys(obj).forEach((key) => {
      const name = namesMap[key];
      if (map[key]) {
        newData[name] = map[key];
      } else {
        newData[name] = obj[key];
      }
    });

    return newData;
  };

  const onCancel = () => {
    setToggle(!toggle);
    setRefetchIndex(Date.now());
  };

  const initialValue = contactBooK?.contactBook?.data?.length
    ? {
        contact_books: contactBooK.contactBook.data.map((el) => {
          return {
            ...el,
            portal_access: el?.portal_access === 1,
            email_updates: el?.email_updates === 1,
            sms_updates: el?.sms_updates === 1,
          };
        }),
      }
    : { contact_books: [{ ...defaultValue }] };

  useEffect(() => {
    getStopPointsContactBook(id).then((res) => {
      setContactBook({ contactBook: res });
    });
    getTypeData();
  }, [refetchIndex]);

  return (
    <div
      className={sm.contactBookTab}
      style={{
        backgroundColor: palette.gray0,
        borderColor: palette.gray50,
      }}
    >
      <div
        className={sm['contactBookTab-header']}
        style={{
          backgroundColor: palette.white,
          borderBottomColor: palette.gray50,
        }}
      >
        <p className={sm.heading} style={{ color: palette.gray900 }}>
          Contact Book
        </p>
        {!toggle ? (
          <div>
            <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
          </div>
        ) : (
          <div>
            <button onClick={onCancel} className='driver-compansation-cancel-btn'>
              Close
            </button>
          </div>
        )}
      </div>
      {toggle ? (
        <div className={sm.body}>
          <Formik initialValues={initialValue} innerRef={formRef}>
            {({ values, getFieldHelpers, setFieldValue, errors, validateForm, validateField }) => (
              <Form>
                <FieldArray
                  name='contact_books'
                  render={(arrayHelpers) => {
                    return (
                      <div className={sm.fieldArrayWrapper}>
                        {values.contact_books.map((el, index) => {
                          const namePrefix = `contact_books[${index}]`;
                          return (
                            <div
                              key={namePrefix}
                              className={[sm.row, sm.line, sm.fieldsWrapper].join(' ')}
                              style={{ marginTop: 0 }}
                            >
                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Name <span className={sm.required_system}>*</span>
                                  </Typography>
                                )}
                                <Field
                                  name={`${namePrefix}[name]`}
                                  component={CustomInput}
                                  labelStyle={styles.labelStyle}
                                  validate={validateStopPointInput}
                                  className={sm.input_basic_details}
                                  style={{ paddingLeft: 10, width: '250px' }}
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[name]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>

                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Email
                                  </Typography>
                                )}
                                <Field
                                  name={`${namePrefix}[email]`}
                                  component={CustomInput}
                                  validate={validateEmail}
                                  labelStyle={styles.labelStyle}
                                  className={sm.input_basic_details}
                                  style={{ paddingLeft: 10, width: '250px' }}
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[email]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>

                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Phone Number <span className={sm.required_system}>*</span>
                                  </Typography>
                                )}
                                <Field
                                  name={`${namePrefix}[phone_number]`}
                                  countryCodeEditable={false}
                                  component={CustomPhoneInput}
                                  labelStyle={{ marginTop: 0 }}
                                  inputStyle={{ width: '250px' }}
                                  validate={validateStopPointInput}
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[phone_number]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>

                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Type <span className={sm.required_system}>*</span>
                                  </Typography>
                                )}
                                <div style={{ width: 250 }}>
                                  <Field
                                    name={`${namePrefix}[contact_type_id]`}
                                    options={typeData}
                                    styles={styles.type}
                                    component={CustomSelect}
                                    menuStyles={styles.fullWidthPercent}
                                    validate={validateStopPointInput}
                                  />
                                  <ErrorMessage
                                    name={`${namePrefix}[contact_type_id]`}
                                    render={(error) => (
                                      <Typography variant='c2' style={styles.error}>
                                        {error}
                                      </Typography>
                                    )}
                                  />
                                </div>
                              </div>

                              <div className={sm.field_wrapper2}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Portal Access
                                  </Typography>
                                )}
                                <Tooltip title='This feature is not available'>
                                  <span>
                                    <Field name={`${namePrefix}[portal_access]`}>
                                      {({ field }) => (
                                        <CustomCheckbox
                                          name={`${namePrefix}[portal_access]`}
                                          field={field}
                                          type='switch'
                                          withinForm
                                          checked={!!field.value}
                                          disabled
                                        />
                                      )}
                                    </Field>
                                  </span>
                                </Tooltip>
                              </div>

                              <div className={sm.field_wrapper2}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Email Updates
                                  </Typography>
                                )}
                                <Field name={`${namePrefix}[email_updates]`}>
                                  {({ field }) => (
                                    <CustomCheckbox
                                      name={`${namePrefix}[email_updates]`}
                                      field={field}
                                      type='switch'
                                      withinForm
                                      checked={!!field.value}
                                    />
                                  )}
                                </Field>
                              </div>

                              <div className={sm.field_wrapper2}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    SMS Updates
                                  </Typography>
                                )}
                                <Field name={`${namePrefix}[sms_updates]`}>
                                  {({ field }) => (
                                    <CustomCheckbox
                                      name={`${namePrefix}[sms_updates]`}
                                      field={field}
                                      type='switch'
                                      withinForm
                                      checked={!!field.value}
                                    />
                                  )}
                                </Field>
                              </div>

                              <div className={sm.rowSettingsWrapper}>
                                <DeleteIcon
                                  width={12}
                                  height={12}
                                  fill={palette.indigo500}
                                  style={styles.deleteIcon}
                                  onClick={() => onDelete(el, index, arrayHelpers)}
                                />
                                <CustomButton
                                  type='primary'
                                  title=''
                                  styleButton={{
                                    padding: '6px 12px',
                                    margin: '6px 0 0 0',
                                  }}
                                  styleTitle={{ fontSize: 14 }}
                                  onClick={() =>
                                    onUpdate(
                                      el,
                                      index,
                                      values,
                                      errors,
                                      getFieldHelpers,
                                      arrayHelpers,
                                      validateForm,
                                      validateField,
                                      setFieldValue
                                    )
                                  }
                                  leftIcon={<TickIcon fill={palette.white} style={{ width: 9, height: 9 }} />}
                                  buttonProps={{
                                    type: 'submit',
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}

                        <CustomButton
                          type='secondary'
                          title='Add Another'
                          styleTitle={styles.addAnotherTitle}
                          styleButton={styles.addAnotherButton}
                          onClick={() => arrayHelpers.push({ ...defaultValue })}
                          leftIcon={<PlusIcon fill={palette.indigo500} />}
                        />
                      </div>
                    );
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className='table-wrap'>
          <div className='sub-table-container'>
            <div className='planner_table_wrapper'>
              <MaterialTable
                data={contactBooK?.contactBook?.data}
                columns={[
                  {
                    title: <div>NAME</div>,
                    field: 'name',
                    headerStyle: {
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                    },
                  },
                  {
                    title: <div>EMAIL</div>,
                    field: 'email',
                    headerStyle: {
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                    },
                    render: (rowData) => {
                      const email = rowData.email;
                      return email ? (
                        <div className={sm['call-to-action-wrapper']}>
                          <div className={sm['call-to-action-icons-contactBook']}>
                            <a href={`mailto:${email}`}>
                              <img src={inbox} width={14} height={12} alt='inbox-icon' />
                            </a>
                          </div>
                          <div>
                            <Typography variant='b2' style={{ color: palette.gray700 }}>
                              {email}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        '-'
                      );
                    },
                  },
                  {
                    title: <div>PHONE NUMBER</div>,
                    field: 'phone_number',
                    headerStyle: {
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                    },
                    render: (rowData) => {
                      const phone_number = rowData.phone_number;
                      return (
                        <div style={{ display: 'flex', width: '100%' }}>
                          <div className={sm['call-to-action-icons']}>
                            <a href={`tel:${phone_number}`}>
                              <img src={call} width={14} height={14} alt='call-icon' />
                            </a>
                          </div>
                          <div>
                            <Typography variant='b2' style={{ color: palette.gray700 }}>
                              {phone_number}
                            </Typography>
                          </div>
                        </div>
                      );
                    },
                  },
                  {
                    title: <div>TYPE</div>,
                    field: 'contact_type',
                    headerStyle: {
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                    },
                    render: (rowData) => {
                      let type;
                      typeData.forEach((el) => {
                        if (rowData?.contact_type_id === el.id) {
                          type = el.title;
                        }
                      });
                      return <div>{type}</div>;
                    },
                  },
                  {
                    title: <div className='d-flex justify-content-center'>{'Portal Access'.toUpperCase()}</div>,
                    field: 'portal_access',
                    sorting: false,
                    headerStyle: {
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                      textAlign: 'center',
                    },
                    align: 'center',
                    render: (rowData) => {
                      const portal_access = rowData.portal_access;
                      return portal_access ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />;
                    },
                  },
                  {
                    title: <div className='d-flex justify-content-center'>{'Email Updates'.toUpperCase()}</div>,
                    field: 'email_updates',
                    headerStyle: {
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                      textAlign: 'center',
                    },
                    sorting: false,
                    align: 'center',
                    render: (rowData) => {
                      const email_updates = rowData.email_updates;
                      return (
                        <div className='d-flex justify-content-center'>
                          {email_updates ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />}
                        </div>
                      );
                    },
                  },
                  {
                    title: <div className='d-flex justify-content-center'>{'SMS Updates'.toUpperCase()}</div>,
                    field: 'email_updates',
                    headerStyle: {
                      backgroundColor: palette.white,
                      borderColor: palette.gray50,
                      textAlign: 'center',
                    },
                    sorting: false,
                    align: 'center',
                    render: (rowData) => {
                      const sms_updates = rowData.sms_updates;
                      return (
                        <div className='d-flex justify-content-center'>
                          {sms_updates ? <img src={right} alt='' /> : <img src={Icons.CrossRedIcon} alt='' />}
                        </div>
                      );
                    },
                  },
                ]}
                style={{
                  backgroundColor: palette.white,
                  color: palette.gray700,
                  borderColor: palette.gray50,
                }}
                options={{
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 15],
                  emptyRowsWhenPaging: false,
                  toolbar: false,
                  sorting: false,
                  thirdSortClick: false,
                  draggable: false,
                  tableLayout: 'auto',
                  columnResizable: true,
                  paging: false,
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBook;
