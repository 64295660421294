import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from '@mui/material/Modal';
import Container from 'react-bootstrap/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import authHeader from 'services/auth-header';
import OnboardingService from 'services/onboardingService';
import cardsImg from 'assets/images/onboarding/image 18.png';
import useForm from 'hooks/useForm';
import { formatAmount } from 'utils/helpers';
import { formatNumber, palette } from 'utils/constants';
import useShowToaster from 'hooks/useShowToaster';
import Autocomplete from 'common/Autocomplete';
import { getAgreementNumbers } from 'Api/Billing';
import { removeFromCart } from 'Api/Hardware';
import { LayoutContext } from 'components/layout';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import ShippingAddress, { initialValues, validationSchema } from 'components/ShippingAddress';
import useDateFormat from 'hooks/useDateFormat';
import SpinnerLoadingMultipleTexts from 'components/SpinnerLoadingMultipleTexts/SpinerLoadingTwoTexts';
import TermsAndCondition from '../PaymentType/TermsAndCondition/TermsAndCondition';
import styles from './Cart.module.css';
import CartCard from './CartCard';

const paymentTypeInfo = {
  financing:
    'The order form and credit application will be emailed to you separately. You will not be charged when you click the "Continue" button. Once you complete both the order form and credit application, they will be submitted for credit review.   You may be contacted by the financial institution agent if further information is required. Financing is based upon approval.',
  ach: 'Your invoice will be sent to you via email separately. You will not be charged upon clicking the "Continue" button, payment will only be processed when you complete the invoice payment via email link or by finding the invoice in the "Open Bills" section of your account. ',
};

const OrderSummary = ({ nextStep, CartItemQty, handleClose, onSuccess = () => null }) => {
  const navigate = useNavigate();
  const showToaster = useShowToaster();
  const { formatDate } = useDateFormat();
  const [openTermsAndCondition, setTermsAndConditionOpen] = useState(false);
  const [count, setCount] = useState(0);
  const API_URL = process.env.REACT_APP_API_URL;
  const [totalOfhardware, settotalOfhardware] = useState(0.0);
  const [totalShipping, setTotalShipping] = useState(0.0);
  const [toalOfTax, settotalOfTax] = useState(0.0);
  const [taxRate, setTaxRate] = useState(0);
  const [sumOfTotalHardware, setsumOfTotalHardware] = useState(0.0);
  const [applycoupan, setApplycoupan] = useState(false);
  const [isApplied, setisApplied] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [coupanCodeErr, setCoupanCodeErr] = useState('');
  const [hardware, setHardware] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [carttotal, setCartTotal] = useState(0);
  const [continueButtonDisable, setContinueButtonDisable] = useState(true);
  const [reload, setReload] = useState(false);
  const [checkBox, setCheckBox] = useState({
    terms_and_conditions: false,
    privacy_policy: false,
    billing_policy: false,
  });
  const [deleteLoading, setDeleteLoading] = useState();
  const [error, setError] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [isProRated, setIsProRated] = useState(false);
  const [agreementNumbers, setAgreementNumbers] = useState([]);
  const [selectedAgreement, setSelectedAgreement] = useState(null);
  const [summary, setSummary] = useState();

  const addressForm = useForm({ initialValues, validationSchema });
  const isProRateVisible = cartItems?.every((item) => item.allow_pro_rated_billing === 1);
  const processingFee = Number(totalOfhardware) * 0.0299 + 0.3;

  const handleTermsAndConditionClose = () => setTermsAndConditionOpen(false);

  const getHardware = async () => {
    await OnboardingService.getHardware().then((res) => {
      setHardware(res.data.data);
    });
  };

  const removeHardwareFromCart = async (id, shouldClose) => {
    try {
      await removeFromCart({ id });
      setCartItemsData(Date.now());
      if (shouldClose) {
        handleClose();
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getAgreementNumbersList = async () => {
    try {
      const { data } = await getAgreementNumbers();
      const futureAgreements = (data || []).filter((item) => moment(item.due_date).isAfter(moment()));
      setAgreementNumbers(futureAgreements);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getHardware();
    getAgreementNumbersList();
  }, []);

  useEffect(() => {
    const items = [];
    CartItemQty.map((cart) => {
      const data = hardware?.find((v) => v.id === cart.hardware_id);
      if (!items || !items.length) {
        items.push({
          ...data,
          quantity: cart?.quantity,
          dataId: cart?.hardware_id,
          id: cart?.hardware_id,
          objectId: cart?.id,
        });
      } else {
        if (!items.some((s) => s.id === cart?.hardware_id)) {
          items.push({
            ...data,
            quantity: cart?.quantity,
            dataId: cart?.hardware_id,
            id: cart?.hardware_id,
            objectId: cart?.id,
          });
        }
      }
      return cart;
    });
    setCartItems(items);
  }, [CartItemQty, hardware]);

  useEffect(() => {
    const backCartItems = JSON.parse(localStorage.getItem('hardwareCart'));
    if (backCartItems !== null && backCartItems?.length !== 0) {
      setCartItems(backCartItems);
    }
  }, []);

  useEffect(() => {
    const goBackCartItems = JSON.parse(localStorage.getItem('goBackCartItems'));
    if (goBackCartItems !== null && goBackCartItems?.length !== 0) {
      setCartItems(goBackCartItems);
    }
  }, []);

  const ref = useRef(null);

  useEffect(() => {
    if (count <= 0) {
      setCount(0);
    }
  }, [count]);
  const { setCartItemsData, setHardwarePurchaseTab } = useContext(LayoutContext);

  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    addressForm.setTouched({
      shipping_address1: true,
      shipping_address2: true,
      shipping_city: true,
      shipping_state: true,
      shipping_country: true,
      shipping_zip: true,
    });
    const shippingAddressErrors = await addressForm.validateForm();

    if (Object.keys(shippingAddressErrors).length !== 0) {
      return;
    }

    if (!checkBox.terms_and_conditions || !checkBox.privacy_policy || !checkBox.billing_policy) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      checkTerms();
    } else {
      CheckOut(submitForPrices);
    }
  };

  const CheckOut = async (data) => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));

    try {
      await axios
        .post(
          `${API_URL}/customer/hardwares/prices`,
          {
            ...data,
            state_id: addressForm?.values?.shipping_state?.id || user?.state?.id,
            due_date: selectedAgreement?.due_date,
            agreement_number: selectedAgreement?.agreement_number,
          },
          {
            headers: authHeader(),
          }
        )
        .then((res) => {
          if (res) {
            checkoutComplete(data, res);
          }
        });
    } catch (err) {
      showToaster({ type: 'error', message: 'err !' });
    }
  };
  const checkoutComplete = async (data, res) => {
    const { shipping_address1, shipping_address2, shipping_country, shipping_state, shipping_city, shipping_zip } =
      addressForm.values;
    try {
      const value = {
        ...res?.data,
        payment_method: data?.payment_method,
        due_date: selectedAgreement?.due_date,
        agreement_number: selectedAgreement?.agreement_number,
        customer_data: {
          shipping_address1: shipping_address1 || null,
          shipping_address2: shipping_address2 || null,
          shipping_city: shipping_city?.id || null,
          shipping_state: shipping_state?.id || null,
          shipping_country: shipping_country?.id || null,
          shipping_zip: shipping_zip || null,
        },
      };
      await axios
        .post(`${API_URL}/customer/equipment/hardware/checkout`, value, {
          headers: authHeader(),
        })
        .then((response) => {
          setLoading(false);
          setCartItemsData(response);
          showToaster({ type: 'success', message: 'Hardware Purchased Successfully' });
          onSuccess();
          nextStep();
          localStorage.removeItem('price-summary');
          localStorage.removeItem('hardware');
          localStorage.removeItem('hardwareItem');
        });
    } catch (err) {
      setLoading(false);
      showToaster({ type: 'error', message: err?.response?.data?.error?.message || 'Error !' });
    }
  };
  const checkTerms = () => {
    if (!checkBox.terms_and_conditions) {
      setError('terms_and_conditions');
    } else if (!checkBox.privacy_policy) {
      setError('privacy_policy');
    } else if (!checkBox.billing_policy) {
      setError('billing_policy');
    }
  };

  useEffect(() => {
    if ((paymentType === 'financing' && totalOfhardware < 5000) || !paymentType) {
      setPaymentType('');
      setContinueButtonDisable(true);
    } else {
      setContinueButtonDisable(false);
    }
  }, [totalOfhardware, reload, paymentType]);

  const [wait, setwait] = useState(false);
  const [addToCartData, setAddToCartData] = useState();

  const postPrice = (data) => {
    const user = JSON.parse(localStorage.getItem('user'));

    setAddToCartData(data);
    axios
      .post(
        `${API_URL}/customer/hardwares/prices`,
        {
          ...data,
          state_id: addressForm?.values?.shipping_state?.id || user?.state?.id,
          due_date: selectedAgreement?.due_date,
          agreement_number: selectedAgreement?.agreement_number,
        },
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        localStorage.setItem('price-summary', JSON.stringify(res.data));
        setSummary(res.data);
        setReload(true);
        return res.data;
      });
  };

  const OnCancel = () => {
    AddToCart(addToCartData);
    setCartItemsData(Date.now());
    handleClose();
  };

  const AddToCart = (data) => {
    if (!data || !cartItems?.length) {
      return;
    }
    try {
      axios.post(`${API_URL}/hardware-cart`, data, {
        headers: authHeader(),
      });
    } catch (error) {
      // Do nothing
    }
  };

  const plan = JSON.parse(localStorage.getItem('plan-selected'));
  const submitForPrices = {
    payment_method: paymentType || 'credit-card',
    hardware: cartItems,
    applied_coupon: couponCode,
  };

  useEffect(() => {
    if (cartItems.length !== 0) {
      postPrice(submitForPrices);
    }
    setSummary(JSON.parse(localStorage.getItem('price-summary')));
  }, [cartItems, reload, selectedAgreement, addressForm?.values?.shipping_state?.id]);

  useEffect(() => {
    if (summary === null || summary === undefined) {
      setwait(true);
    } else {
      setwait(false);
    }
  }, [summary, wait, reload]);

  useEffect(() => {
    settotalOfTax(formatAmount(summary?.hardware_prices?.tax));
    setTotalShipping(formatAmount(summary?.hardware_prices?.shipping));
    settotalOfhardware(Number(summary?.hardware_prices?.hardware_total));
    setTaxRate(summary?.hardware_prices?.tax_rate);
    const hwPrice = Number(summary?.hardware_prices?.shipping) + Number(summary?.hardware_prices?.tax);
    setsumOfTotalHardware(Number(summary?.hardware_prices?.hardware_total) - hwPrice);
  }, [summary, reload]);

  const removeCart = (data, qty) => {
    const newCartItems = cartItems.map((i) => (i.id === data.id ? { ...i, quantity: qty } : i));
    const filtered = newCartItems.filter((i) => i.quantity > 0);
    setCartItems(newCartItems.filter((i) => i.quantity > 0));

    submitForPrices.hardware = newCartItems;

    if (data && qty === 0) {
      removeHardwareFromCart(data.objectId, !filtered?.length);
    }
  };

  const DeleteFromCart = (cartItems) => {
    submitForPrices.hardware = cartItems;
    postPrice(submitForPrices);
  };

  const handleCouponCode = (e) => {
    setCoupanCodeErr('');
    setCouponCode(e.target.value);
  };

  const handleAplyCoupan = () => {
    if (couponCode === '') {
      setCoupanCodeErr('Please enter code!');
      return;
    }
    submitForPrices.applied_coupon = couponCode;
    submitForPrices.hardware = cartItems;
    postPrice(submitForPrices);
    setisApplied(true);
  };

  const handleChange = (e) => {
    const { name } = e.target;
    setCheckBox({ ...checkBox, [name]: !checkBox[name] });
  };

  useEffect(() => {
    let cartTotal = 0;
    cartItems?.forEach((value) => {
      if (value.name) {
        cartTotal += parseInt(value?.quantity, 10);
      }
      setCartTotal(cartTotal);
    });
  }, [cartItems, reload, summary]);

  return (
    <>
      <div className={styles.PaymentModalBody}>
        <div>
          <Container>
            <Row>
              <Col xs={6}>
                <div className='mt-4'>
                  <div className={styles.AddEquipmentPaymentHeading}>
                    <p>Payment Type</p>
                  </div>
                  <label
                    className={
                      totalOfhardware >= 5000
                        ? styles.AddEquipmentPaymentCheckbox
                        : styles.AddEquipmentPaymentCheckboxDisable
                    }
                  >
                    <div className='d-flex container'>
                      <div className='mt-3'>
                        <input
                          type='radio'
                          name='paymentType'
                          value='financing'
                          checked={paymentType === 'financing'}
                          onChange={() => {
                            setPaymentType('financing');
                            setContinueButtonDisable(false);
                          }}
                          disabled={totalOfhardware < 5000}
                        />
                      </div>
                      <div
                        className={
                          totalOfhardware < 5000
                            ? styles.PaymentCheckboxMainHeadingDisable
                            : styles.PaymentCheckboxMainHeading
                        }
                      >
                        <span>Finance Agreement</span>
                        <br />
                        {totalOfhardware < 5000 && (
                          <p className='body-b2' style={{ margin: 0 }}>
                            Transaction must be over $5,000.00 for financing.
                          </p>
                        )}
                        <span
                          className={
                            totalOfhardware < 5000
                              ? styles.PaymentCheckboxSubHeadingDisabled
                              : styles.PaymentCheckboxSubHeading
                          }
                        >
                          ${formatAmount((summary?.hardware_prices?.hardware_total_finance || 0) / 36)}
                        </span>
                        <span className={styles.PaymentCheckboxSubP}> /month for 36 months </span>
                        <br />
                        <span
                          className={
                            totalOfhardware < 5000 ? styles.PaymentCheckboxSubPDisable : styles.PaymentCheckboxSubP
                          }
                        >
                          If you choose to pay with the financing option, you will be making monthly payments for 36
                          months + finance charges. Varies on credit approval.
                        </span>
                      </div>
                    </div>
                  </label>
                  {/* <label className={styles.AddEquipmentPaymentCheckbox}> */}
                  {/*  <div className='d-flex container'> */}
                  {/*    <div className='mt-3'> */}
                  {/*      <input */}
                  {/*        type='radio' */}
                  {/*        name='paymentType' */}
                  {/*        value='credit-card' */}
                  {/*        checked={paymentType === 'credit-card'} */}
                  {/*        onChange={() => { */}
                  {/*          setPaymentType('credit-card'); */}
                  {/*          setContinueButtonDisable(false); */}
                  {/*        }} */}
                  {/*      /> */}
                  {/*    </div> */}
                  {/*    <div className={styles.PaymentCheckboxMainHeading}> */}
                  {/*      <div className='d-flex justify-content-between'> */}
                  {/*        <div> */}
                  {/*          <span>Credit Card</span> */}
                  {/*        </div> */}
                  {/*        <div> */}
                  {/*          <img src={cardsImg} alt='img' /> */}
                  {/*        </div> */}
                  {/*      </div> */}
                  {/*      <div> */}
                  {/*        <span className={styles.PaymentCheckboxSubHeading}> */}
                  {/*          ${formatAmount(Number(summary?.hardware_prices?.hardware_total_cc_ach) + processingFee)}{' '} */}
                  {/*        </span> */}
                  {/*        <span className={styles.PaymentCheckboxSubP}> up front </span> */}
                  {/*        <br /> */}
                  {/*        <span className={styles.PaymentCheckboxSubP}> */}
                  {/*          If you choose to pay with Credit Card, you must pay for the order up front for 36 months. If */}
                  {/*          paying with credit card, 2.9% + $0.30 processing fee applies. */}
                  {/*        </span> */}
                  {/*      </div> */}
                  {/*    </div> */}
                  {/*  </div> */}
                  {/* </label> */}
                  <label className={styles.AddEquipmentPaymentCheckbox} style={{ marginBottom: '30px' }}>
                    <div className='d-flex container'>
                      <div className='mt-3'>
                        <input
                          type='radio'
                          name='paymentType'
                          value='ACH'
                          checked={paymentType === 'ach'}
                          onChange={() => {
                            setPaymentType('ach');
                            setContinueButtonDisable(false);
                          }}
                        />
                      </div>
                      <div className={styles.PaymentCheckboxMainHeading}>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <span>Credit Card or ACH</span>
                          </div>
                          <img src={cardsImg} alt='img' />
                        </div>
                        <div>
                          <span className={styles.PaymentCheckboxSubHeading}>
                            {' '}
                            ${formatAmount(summary?.hardware_prices?.hardware_total_cc_ach)}{' '}
                          </span>
                          <span className={styles.PaymentCheckboxSubP}> up front</span>
                          <br />
                          <span className={styles.PaymentCheckboxSubP}>
                            If you choose to pay with Credit Card or ACH, you must pay for the order up front for 36
                            months.
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                  {isProRateVisible && (
                    <div className='legend' style={{ margin: '100px 0 30px 0' }}>
                      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <CustomCheckbox
                          name='pro_rate'
                          type='switch'
                          smail={false}
                          checked={!!isProRated}
                          onChange={(value) => {
                            setIsProRated(value);
                            setSelectedAgreement(null);
                          }}
                        />
                        <Typography variant='s2'>Pro-rate bill to existing License Agreement renewal date?</Typography>
                      </div>
                      {isProRated && (
                        <>
                          <div className='divider' style={{ margin: 0 }} />
                          <div style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}>
                            <Typography variant='s2'>Select Existing Invoice Number:</Typography>
                            <Autocomplete
                              name='agreement_number'
                              labelKey='agreement_number'
                              style={{ marginLeft: '27px', width: '320px' }}
                              options={agreementNumbers || []}
                              isOptionEqualToValue={(option, value) =>
                                option.agreement_number === value.agreement_number
                              }
                              value={selectedAgreement}
                              onChange={(e, value) => setSelectedAgreement(value)}
                            />
                          </div>
                          <div className='divider' style={{ margin: 0 }} />
                          <div style={{ display: 'flex', alignItems: 'center', margin: '16px 0' }}>
                            <Typography variant='s2'>License Agreement Renewal Date:</Typography>
                            {selectedAgreement?.due_date && (
                              <span style={{ marginLeft: '35px' }}>{formatDate(selectedAgreement?.due_date)}</span>
                            )}
                          </div>
                          <div className='divider' style={{ margin: 0 }} />
                          <p className='body-b2' style={{ color: '#687182', margin: '16px 0', textAlign: 'left' }}>
                            By selecting to pro-rate bill to your existing agreement, your hardware amount will be
                            billed from today to your existing agreement renewal date. However, in order to add to
                            existing agreement, you must have purchased hardware in the past with Truckin Digital. If
                            financed, varies on credit approval.
                          </p>
                        </>
                      )}
                    </div>
                  )}
                  <div>
                    <p>By clicking complete order, you must also check and agree to the following below :</p>
                  </div>
                  <div className={styles['checkbox-terms']} style={{ marginTop: '20px' }}>
                    <input type='checkbox' name='terms_and_conditions' onChange={handleChange} />
                    <span className={styles['subtitle-s2']} style={{ color: '#464F60' }}>
                      I agree to the{' '}
                      <a
                        className={styles.TermsAndCoditionLink}
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.truckindigital.com/legal/terms-and-conditions'
                      >
                        Terms & Conditions
                      </a>
                    </span>
                  </div>
                  {error === 'terms_and_conditions' && checkBox.terms_and_conditions === false ? (
                    <div
                      className={styles.AddEquipmentValidation}
                      style={{
                        color: '#D12953',
                        fontSize: '12px',
                        marginTop: '12px',
                      }}
                    >
                      <span>Please Accept Terms And Conditions</span>
                    </div>
                  ) : null}
                  <div className={styles['checkbox-terms']}>
                    <input type='checkbox' name='privacy_policy' onChange={handleChange} />
                    <span className={styles['subtitle-s2']} style={{ color: '#464F60' }}>
                      I agree to the{' '}
                      <a
                        className={styles.TermsAndCoditionLink}
                        href='https://www.truckindigital.com/legal/privacy-policy'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Privacy Policy.
                      </a>
                    </span>
                  </div>
                  {error === 'privacy_policy' && checkBox.privacy_policy === false ? (
                    <div
                      className={styles.AddEquipmentValidation}
                      style={{
                        color: '#D12953',
                        fontSize: '12px',
                        marginTop: '12px',
                      }}
                    >
                      <span>Please Accept Privacy Policy</span>
                    </div>
                  ) : null}
                  <div className={styles['checkbox-terms']}>
                    <input type='checkbox' name='billing_policy' onChange={handleChange} />
                    <span className={styles['subtitle-s2']} style={{ color: '#464F60' }}>
                      I agree to the{' '}
                      <a
                        className={styles.TermsAndCoditionLink}
                        target='_blank'
                        rel='noreferrer'
                        href='https://www.truckindigital.com/legal/billing-policy'
                      >
                        Billing Policy.
                      </a>
                    </span>
                  </div>
                  {error === 'billing_policy' && checkBox.billing_policy === false ? (
                    <div
                      className={styles.AddEquipmentValidation}
                      style={{
                        color: '#D12953',
                        fontSize: '12px',
                        marginTop: '12px',
                      }}
                    >
                      <span>Please Accept Billing Policy</span>
                    </div>
                  ) : null}
                </div>
                <div ref={ref} />
              </Col>
              <Col xs={6}>
                <div className='mt-4'>
                  <div className={styles.AddEquipmentPaymentHeading} style={{ display: 'flex' }}>
                    <p>
                      Order Summary
                      <span className='cart-items ms-2'>
                        {`${carttotal}`}
                        <span className='ms-1'> {hardware.length === 1 ? 'Item' : 'Items'}</span>
                      </span>
                    </p>
                    <p
                      className={styles.AddMoreButton}
                      onClick={() => {
                        navigate('/hardware');
                        setHardwarePurchaseTab(2);
                        handleClose();
                      }}
                    >
                      Add more hardware to cart
                    </p>
                  </div>
                  <div className={styles.OrderSummryDivBox}>
                    <div className={styles.OrderSummryDeviceBox}>
                      <div className={styles.AddEquipmentLine}>
                        <div>
                          {cartItems[0]?.name && (
                            <div>
                              <CartCard
                                cartItems={cartItems}
                                hardware={hardware}
                                removeCart={removeCart}
                                DeleteFromCart={DeleteFromCart}
                                selectedPlan={plan}
                                summary={summary}
                                handleClose={handleClose}
                                API_URL={API_URL}
                                setCartItemsData={setCartItemsData}
                                setDeleteLoading={setDeleteLoading}
                                deleteLoading={deleteLoading}
                              />
                            </div>
                          )}
                          {cartItems[0]?.name ? (
                            <div>
                              <div className='totals'>
                                <p className='body-b2'>Promo Code</p>
                                <div className='applyCouponbox'>
                                  {!isApplied && (
                                    <>
                                      <div
                                        className={applycoupan ? 'applyCoupon hideApplyCoupan' : 'applyCoupon'}
                                        onClick={() => setApplycoupan(!applycoupan)}
                                        style={{ cursor: 'pointer' }}
                                      >
                                        <div className='plusone' />
                                        <div className='plustwo' />
                                        <p className='body-b2'>Apply</p>
                                      </div>
                                      <div className={applycoupan ? 'applyCoupon' : 'applyCoupon  hideApplyCoupan'}>
                                        <button onClick={handleAplyCoupan}>Apply</button>
                                        <input
                                          type='text'
                                          placeholder='Enter Code'
                                          value={couponCode}
                                          onChange={handleCouponCode}
                                        />
                                        <p className='requiredMessage code-err'>{coupanCodeErr}</p>
                                      </div>
                                    </>
                                  )}
                                  {isApplied && !!summary?.hardware_prices?.message && (
                                    <p className='body-b2'>{summary?.hardware_prices?.message}</p>
                                  )}
                                </div>
                              </div>

                              {hardware.length !== 0 && (
                                <div className='totals'>
                                  <p className='body-b2' style={{ margin: '0px' }}>
                                    Subtotal
                                  </p>
                                  <div className='body-b2'>${formatNumber(sumOfTotalHardware)}</div>
                                </div>
                              )}

                              {hardware.length !== 0 && (
                                <>
                                  <div className='totals'>
                                    <p className='body-b2'>Shipping + Handling</p>
                                    <div className='body-b2'>${totalShipping}</div>
                                  </div>
                                  <div className='totals'>
                                    <p className='body-b2'>Sales Tax ({taxRate}%)</p>
                                    <div className='body-b2'>${toalOfTax}</div>
                                  </div>
                                </>
                              )}
                              {hardware.length !== 0 && paymentType === 'credit-card' && (
                                <div className='totals'>
                                  <p className='body-b2'>Processing Fee (2.9% + $0.30)</p>
                                  <div className='body-b2'>${formatNumber(processingFee)}</div>
                                </div>
                              )}
                              {hardware.length !== 0 && (
                                <div className='totals'>
                                  <p className='payment-subtitle mt-2' style={{ marginBottom: '5px' }}>
                                    Total
                                  </p>
                                  <p className='payment-subtitle' style={{ margin: '0px' }}>
                                    $
                                    {paymentType === 'credit-card'
                                      ? formatNumber(totalOfhardware + processingFee)
                                      : formatNumber(totalOfhardware)}
                                  </p>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className='ms-3 mt-3'>No items to display</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <p className={styles.AddEquipmentFooterLine} />

                    <div style={{ paddingBottom: '6px' }}>
                      {loading ? (
                        <CircularProgress style={{ width: '20px', height: '20px' }} />
                      ) : (
                        <div style={{ margin: '0 20px' }}>
                          <div>
                            <button
                              className={
                                !continueButtonDisable
                                  ? styles.AddEquipmentPaymentButton
                                  : styles.AddEquipmentPaymentButtonDisable
                              }
                              onClick={handleSubmit}
                              disabled={continueButtonDisable}
                            >
                              Continue
                            </button>
                          </div>
                          <div>
                            <button className={styles.CancelButton} onClick={OnCancel}>
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className='legend' style={{ width: '470px' }}>
                  <p
                    className='body-b2'
                    style={{
                      color: '#687182',
                      margiTop: 0,
                      marginBottom: '5px',
                    }}
                  >
                    {paymentType === 'credit-card' ? (
                      <>
                        ${formatAmount(Number(summary?.hardware_prices?.hardware_total_cc_ach) + processingFee)} up
                        front
                      </>
                    ) : paymentType === 'ach' ? (
                      <>${formatAmount(summary?.hardware_prices?.hardware_total_cc_ach)} up front</>
                    ) : (
                      <>
                        ${formatAmount((summary?.hardware_prices?.hardware_total_finance || 0) / 36)} /month for 36
                        months
                      </>
                    )}
                  </p>
                  <div className='divider' style={{ margin: 0 }} />
                  <p className='body-b2' style={{ color: '#687182', margin: '5px 0' }}>
                    All prices are in US Dollars (USD).
                  </p>
                  <div className='divider' style={{ margin: 0 }} />
                  <p className='body-b2' style={{ color: '#687182', margin: '5px 0', textAlign: 'left' }}>
                    {paymentTypeInfo[paymentType] || paymentTypeInfo.ach}
                  </p>
                </div>
                <div className='mb-4' style={{ marginTop: '30px', width: '465px' }}>
                  <ShippingAddress {...addressForm} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Modal open={openTermsAndCondition} onClose={handleTermsAndConditionClose}>
        <TermsAndCondition handleTermsAndConditionClose={handleTermsAndConditionClose} />
      </Modal>
      <SpinnerLoadingMultipleTexts
        isVisible={loading}
        fullScreen
        animationDuration={6}
        textStyle={{ fontSize: '28px', fontFamily: 'Inter, sans-serif', fontWeight: 700, color: palette.gray700 }}
        texts={[
          'Processing your order to our fulfilment team..',
          'Please do not close or refresh this page..',
          'Please check your email for agreement and payment invoice, upon payment, your order will be processed..',
        ]}
      />
    </>
  );
};

export default OrderSummary;
