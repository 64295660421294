import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';
import './DriverCompansation.css';
import CircularProgress from '@mui/material/CircularProgress';

import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import ViewPdf from 'components/ViewPdf';
import ImageDropZone from 'components/ImgageDropZone';
import { CompensationContent } from 'components/Drivers/AddDriver/Compensation';
import { CompensationValidationSchema } from 'components/Drivers/AddDriver/ValidationSchema';
import { getErrorMessage } from 'utils/error';
import { useTheme } from 'context/themeContext';
import useDateFormat from 'hooks/useDateFormat';
import useHideDriverSensitiveInfo from 'hooks/useHideDriverSensitiveInfo';
import { formatAmount } from 'utils/helpers';
import { formatNumber, palette, PERMISSIONS } from 'utils/constants';
import { getAdditionsList, getBenefitsList, getDeductionsList, getTaxesList } from 'Api/PayrollSettings';
import {
  getWorkingSchedule,
  deleteDeduction,
  AddWorkingSchedule,
  deleteAddition,
  deleteTax,
  deleteSchedule,
  deleteBenefit,
  getAddition,
  getDeduction,
  getTaxes,
  getBenefits,
  AddAdditions,
  AddDeductions,
  getDriverBalances,
  AddTaxes,
  AddBenefits,
  getDriverCompanyPolicy,
  getTaxType,
  uploadDriverDocument,
} from 'Api/Driver';
import EditBalance from 'componentsV2/EditBalance';
import { Typography } from 'components/Typography';
import { convertBalanceDetails } from 'components/DriverCompansation/DriverCompensation.data';
import {
  additionsValidationSchema,
  benefitsValidationSchema,
  deductionsValidationSchema,
  taxesValidationSchema,
} from './validationSchema';

const DriverCompansation = ({ updateCompensation, showError, onSuccess, loading, driverCompansation }) => {
  const hideSensitiveInfo = useHideDriverSensitiveInfo();
  const { permissions } = useSelector((state) => state?.root);
  const { convertToCustomerTime, formatDateTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [toggle, setToggle] = React.useState(false);
  const [taxOption, setTaxOption] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [companyPolicy, setCompanyPolicy] = useState([]);
  const [deductionType, setDeductionType] = useState([]);
  const [additionType, setAdditionType] = useState([]);
  const [benefitType, setBenefitType] = useState([]);
  const [taxesType, setTaxesType] = useState([]);
  const [week, setWeek] = useState('Mon');
  const [recurringModalShow, setRecurringModalShow] = useState(false);
  const [recurringModalData, setRecurringModalData] = useState({});
  const [balances, setBalances] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [addtion, setAddtion] = useState([]);
  const [benefit, setBenefit] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [schedule, setSchedule] = useState([]);
  const [balanceToEdit, setBalanceToEdit] = useState(null);
  const didMountRefDeductions = useRef(false);
  const didMountRefAdditions = useRef(false);
  const didMountRefTaxes = useRef(false);
  const didMountRefBenefits = useRef(false);

  const additionsController = useRef(new AbortController());
  const deductionsController = useRef(new AbortController());
  const taxesController = useRef(new AbortController());
  const benefitsController = useRef(new AbortController());

  const { id } = useParams();
  const { use, theme } = useTheme();

  const getOptions = () => {
    getAdditionsList().then((res) => {
      const types = res?.data?.map((item) => ({ id: item.id, amount: item.amount, type: item.addition_type }));
      setAdditionType(types || []);
    });

    getBenefitsList().then((res) => {
      const types = res?.data?.map((item) => ({ id: item.id, amount: item.amount, type: item.benefit_type }));
      setBenefitType(types || []);
    });

    getDeductionsList().then((res) => {
      const types = res?.data?.map((item) => ({ id: item.id, amount: item.amount, type: item.deduction_type }));
      setDeductionType(types || []);
    });

    getTaxesList().then((res) => {
      setTaxesType(res?.data);
    });
  };

  useEffect(() => {
    getTaxType().then((res) => {
      setTaxOption(res?.data);
    });

    getDriverCompanyPolicy()
      .then((res) => {
        setCompanyPolicy(res?.data);
      })
      .catch(() => {
        // Do nothing
      });

    getOptions();
  }, []);

  const getBalances = async () => {
    try {
      const { data } = await getDriverBalances(id);
      setBalances(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDeductions = () => {
    didMountRefDeductions.current = false;
    getDeduction({ id }).then((res) => {
      const filtered = res?.data?.filter((deduction) => {
        if (!deduction.one_time_deduction) {
          if (deduction.is_balance_deduction) {
            return !!deduction.balance_id;
          }
          return true;
        }
        return false;
      });

      setDeduction(filtered);

      deductionFormik.setValues({
        deductions: filtered.map((deduction) => {
          const processedSettlements = (deduction.settlement || [])?.filter(
            (settlement) => settlement.driver_settlement.status_id === 3
          );
          const isFulfilledBalance = processedSettlements.length >= deduction.amortized_months;

          return {
            date: new Date(`${deduction.date} ${deduction.time}`),
            time: deduction.time,
            deduction_type_id: deduction.deduction_type?.id,
            quantity: Number(deduction.quantity) || 0,
            per_quantity_amount: Number(deduction.per_quantity_amount) || 0,
            amount: deduction.amount,
            every_settlement: deduction.every_settlement,
            month_week_settlement: deduction.month_week_settlement,
            amortized_settlement: deduction.amortized_settlement,
            day: deduction.day,
            month_week: deduction.month_week,
            amortized_total: deduction.amortized_total,
            amortized_month: deduction.amortized_month,
            amortized_months: deduction.amortized_months,
            deduction_id: deduction.id,
            is_balance_deduction: deduction.is_balance_deduction,
            isFulfilledBalance,
            balanceName: deduction.balance_type?.name || '',
            balanceId: deduction.balance_id || null,
          };
        }),
      });

      didMountRefDeductions.current = true;
    });
  };
  const getAddtion = () => {
    didMountRefAdditions.current = false;
    getAddition({ id }).then((res) => {
      const filtered = res?.data?.filter((item) => item.one_time_addition !== 1) || [];

      setAddtion(filtered);

      Additionsformik.setValues({
        additions: filtered.map((addition) => {
          return {
            date: new Date(`${addition.date} ${addition.time}`),
            time: addition.time,
            addition_type_id: addition.addition_type?.id,
            quantity: Number(addition.quantity) || 0,
            per_quantity_amount: Number(addition.per_quantity_amount) || 0,
            amount: addition.amount,
            every_settlement: addition.every_settlement,
            month_week_settlement: addition.month_week_settlement,
            day: addition.day,
            month_week: addition.month_week,
            addition_id: addition.id,
          };
        }),
      });

      didMountRefAdditions.current = true;
    });
  };

  const getBenefit = () => {
    didMountRefBenefits.current = false;
    getBenefits({ id }).then((res) => {
      setBenefit(res?.data);
      Benefitformik.setValues({
        benefits: (res?.data || []).map((benefit) => {
          return {
            date: new Date(`${benefit.date} ${benefit.time}`),
            time: benefit.time,
            benefit_type_id: benefit.benefit_type?.id,
            quantity: Number(benefit.quantity) || 0,
            per_quantity_amount: Number(benefit.per_quantity_amount) || 0,
            amount: benefit.amount,
            every_settlement: benefit.every_settlement,
            month_week_settlement: benefit.month_week_settlement,
            day: benefit.day,
            month_week: benefit.month_week,
            benefit_id: benefit.id,
          };
        }),
      });
      didMountRefBenefits.current = true;
    });
  };

  const getAllTaxes = () => {
    didMountRefTaxes.current = false;
    getTaxes({ id }).then((res) => {
      setTaxes(res?.data);
      taxesformik.setValues({
        taxes: (res?.data || []).map((tax) => {
          return {
            date: new Date(`${tax.date} ${tax.time}`),
            time: tax.time,
            tax_type_id: tax.tax_type?.id,
            percent: tax.percent,
            auto_deduct: tax.auto_deduct,
            tax_id: tax.id,
          };
        }),
      });
      didMountRefTaxes.current = true;
    });
  };

  const getAllWorkingSchedule = () => {
    getWorkingSchedule({ id }).then((res) => {
      setSchedule(res?.data);
      for (let i = 0; i < workScheduleformik.values.workSchedule?.length; i++) {
        const workSchedule = workScheduleformik.values.workSchedule[i];
        const hasValue = res?.data.find((val) => val.day === workSchedule?.day);
        if (hasValue) {
          workScheduleformik.setFieldValue(`workSchedule[${i}].start_time`, hasValue?.start_time);
          workScheduleformik.setFieldValue(`workSchedule[${i}].end_time`, hasValue?.end_time);
          workScheduleformik.setFieldValue(`workSchedule[${i}].work_schedule_id`, hasValue?.id);
          workScheduleformik.setFieldValue(`workSchedule[${i}].isSelected`, true);
        }
      }
    });
  };

  const taxDetails = [
    {
      property: 'Tax ID',
      value: driverCompansation?.tax_id,
    },
    {
      property: 'Tax Type',
      value: driverCompansation?.tax_type,
    },
  ];

  const bankDetails = [
    {
      property: 'Bank Name',
      value: driverCompansation?.bank_name,
    },
    {
      property: 'Direct Deposit Form',
      value: driverCompansation?.bank_direct_deposit_form ? 'View' : '-',
    },
    {
      property: 'Routing Number',
      value: driverCompansation?.bank_routing,
    },
    {
      property: 'Account Number',
      value: driverCompansation?.bank_account,
    },
  ];

  useEffect(() => {
    if (!driverCompansation) {
      formik.setFieldValue('tax_id', '');
      return;
    }
    formik.setFieldValue('tax_id', driverCompansation?.tax_id || '');
    formik.setFieldValue('payment_method', driverCompansation?.payment_method || 'check');
    formik.setFieldValue('third_party_name', driverCompansation?.third_party_name || '');
    formik.setFieldValue('third_party_id', driverCompansation?.third_party_id || '');
    formik.setFieldValue('confirm_bank_routing', driverCompansation?.bank_routing);
    formik.setFieldValue('confirm_bank_account', driverCompansation?.bank_account);
    formik.setFieldValue('tax_type', driverCompansation?.tax_type);
    formik.setFieldValue('bank_name', driverCompansation?.bank_name);
    formik.setFieldValue('bank_routing', driverCompansation?.bank_routing);
    formik.setFieldValue('bank_account', driverCompansation?.bank_account);
    formik.setFieldValue('over_time_pay', driverCompansation?.over_time_pay);
    formik.setFieldValue('over_time_hours_type', driverCompansation?.over_time_hours_type);
    formik.setFieldValue('over_time_hours_after', driverCompansation?.over_time_hours_after);
    formik.setFieldValue('bank_direct_deposit_form', driverCompansation?.bank_direct_deposit_form);
    formik.setFieldValue('pay_option', driverCompansation?.pay_option);
    formik.setFieldValue('per_hour_worked', driverCompansation?.per_hour_worked);
    formik.setFieldValue(
      'break_time_allowed',
      Number(driverCompansation?.break_time_allowed) ? driverCompansation?.break_time_allowed : '1'
    );
    formik.setFieldValue('breaks_allowed_in_a_day', driverCompansation?.breaks_allowed_in_a_day);
    formik.setFieldValue('break_time_paid', driverCompansation?.break_time_paid);
    formik.setFieldValue('mile_loaded_amt', driverCompansation?.mile_loaded_amt);
    formik.setFieldValue('mile_empty_amt', driverCompansation?.mile_empty_amt);
    formik.setFieldValue('shipment_percentage', driverCompansation?.shipment_percentage);
    formik.setFieldValue('flat_rate_amount', driverCompansation?.flat_rate_amount);
    formik.setFieldValue('paid_time_off_option', driverCompansation?.paid_time_off_option === '1');
    formik.setFieldValue('paid_time_off_amount', driverCompansation?.paid_time_off_amount);
    formik.setFieldValue('detention_pay_option', driverCompansation?.detention_pay_option);
    formik.setFieldValue('detention_amt', driverCompansation?.detention_amt);
    formik.setFieldValue('allow_shipment_offers', driverCompansation?.allow_shipment_offers);
    formik.setFieldValue('detention_hrs', driverCompansation?.detention_hrs);
    formik.setFieldValue('layover_option', driverCompansation?.layover_option);
    formik.setFieldValue('layover_amt', driverCompansation?.layover_amt);
    formik.setFieldValue('stopoff_option', driverCompansation?.stopoff_option);
    formik.setFieldValue('per_stop_amt', driverCompansation?.per_stop_amt);
    formik.setFieldValue('no_of_stops', driverCompansation?.no_of_stops);
    formik.setFieldValue('live_unload_option', driverCompansation?.live_unload_option);
    formik.setFieldValue('unload_amt', driverCompansation?.unload_amt);
    formik.setFieldValue('truck_order_unused_option', driverCompansation?.truck_order_unused_option);
    formik.setFieldValue('each_truck_order_unused_amt', driverCompansation?.each_truck_order_unused_amt);
    formik.setFieldValue('breakdown_option', driverCompansation?.breakdown_option);
    formik.setFieldValue('after_breakdown_hrs', driverCompansation?.after_breakdown_hrs);
    formik.setFieldValue('redelivery_option', driverCompansation?.redelivery_option);
    formik.setFieldValue('after_first_attempt_amt', driverCompansation?.after_first_attempt_amt);
    formik.setFieldValue('tarp_pay_option', driverCompansation?.tarp_pay_option);
    formik.setFieldValue('isCompanyPolice', driverCompansation?.isCompanyPolice);
    formik.setFieldValue('each_stop_amt', driverCompansation?.each_stop_amt);
    formik.setFieldValue('is_pay_raise', driverCompansation?.is_pay_raise);
    if (driverCompansation?.pay_raise?.length) {
      formik.setFieldValue('is_pay_raise', true);
      for (let i = 0; i < driverCompansation?.pay_raise?.length; i++) {
        formik.setFieldValue(
          `pay_raise[${i}].pay_raise_schedule_amt`,
          driverCompansation?.pay_raise[i]?.pay_raise_schedule_amt
        );
        formik.setFieldValue(
          `pay_raise[${i}].pay_raise_schedule_days`,
          driverCompansation?.pay_raise[i]?.pay_raise_schedule_days
        );
        formik.setFieldValue(
          `pay_raise[${i}].pay_raise_schedule_months`,
          driverCompansation?.pay_raise[i]?.pay_raise_schedule_months
        );
        formik.setFieldValue(
          `pay_raise[${i}].pay_raise_schedule_years`,
          driverCompansation?.pay_raise[i]?.pay_raise_schedule_years
        );
        formik.setFieldValue(`pay_raise[${i}].loaded`, driverCompansation?.pay_raise[i]?.loaded);
        formik.setFieldValue(`pay_raise[${i}].empty`, driverCompansation?.pay_raise[i]?.empty);
        formik.setFieldValue(`pay_raise[${i}].id`, driverCompansation?.pay_raise[i]?.id);
      }
    }

    formik.setFieldValue('pay_on_each_shipment', !!driverCompansation?.pay_on_each_shipment);
    if (driverCompansation?.shipment_additions?.length) {
      for (let i = 0; i < driverCompansation?.shipment_additions?.length; i++) {
        formik.setFieldValue(`shipment_addition[${i}].payroll_addition`, {
          id: driverCompansation?.shipment_additions[i]?.payroll_addition?.id,
          type: driverCompansation?.shipment_additions[i]?.payroll_addition?.addition_type,
        });
        formik.setFieldValue(`shipment_addition[${i}].amount`, driverCompansation?.shipment_additions[i]?.amount);
      }
    }
  }, [driverCompansation]);

  const formik = useFormik({
    initialValues: {
      driver_id: id,
      tax_id: '',
      payment_method: 'check',
      third_party_name: '',
      third_party_id: '',
      confirm_bank_routing: '',
      confirm_bank_account: '',
      tax_type: '',
      bank_name: '',
      bank_routing: '',
      bank_account: '',
      bank_direct_deposit_form: '',
      pay_option: 'mile',
      per_hour_worked: 0,
      break_time_allowed: '1',
      breaks_allowed_in_a_day: 0,
      break_time_paid: 0,
      deduct_break_time: 0,
      mile_loaded_amt: 0,
      mile_empty_amt: 0,
      shipment_percentage: 0,
      flat_rate_amount: 0,
      paid_time_off_option: false,
      paid_time_off_amount: 0,
      detention_pay_option: false,
      detention_amt: 0,
      detention_hrs: 1,
      layover_option: false,
      layover_amt: 0,
      stopoff_option: false,
      per_stop_amt: 0,
      no_of_stops: 1,
      live_unload_option: false,
      unload_amt: 0,
      truck_order_unused_option: false,
      each_truck_order_unused_amt: 0,
      breakdown_option: 0,
      after_breakdown_hrs: 0,
      redelivery_option: false,
      after_first_attempt_amt: 0,
      tarp_pay_option: false,
      isCompanyPolice: false,
      each_stop_amt: 0,
      is_pay_raise: false,
      allow_shipment_offers: 0,
      over_time_hours_after: null,
      over_time_hours_type: 'Week',
      over_time_pay: null,
      pay_raise: [
        {
          driver_id: id,
          empty: null,
          loaded: null,
          pay_raise_schedule_amt: '',
          pay_raise_schedule_days: 0,
          pay_raise_schedule_months: 0,
          pay_raise_schedule_years: 0,
          id: null,
        },
      ],
      pay_on_each_shipment: false,
      shipment_addition: [
        {
          payroll_addition: null,
          amount: '',
        },
      ],
    },
    validationSchema: CompensationValidationSchema,
  });

  const deductionFormik = useFormik({
    initialValues: {
      deductions: [
        {
          driver_id: id,
          date: new Date(convertToCustomerTime()),
          time: '',
          deduction_type_id: null,
          quantity: 0,
          per_quantity_amount: 0,
          amount: 0,
          every_settlement: 0,
          month_week_settlement: 1,
          amortized_settlement: 0,
          one_time_deduction: 0,
          day: 'Monday',
          month_week: 'Week',
          amortized_total: 0,
          amortized_month: 0,
          amortized_months: 0,
          deduction_id: 'new',
          type: 'deductions',
          is_balance_deduction: 0,
          balanceName: '',
          balanceId: null,
        },
      ],
    },
    validationSchema: deductionsValidationSchema,
  });

  const Additionsformik = useFormik({
    initialValues: {
      additions: [
        {
          driver_id: id,
          date: new Date(convertToCustomerTime()),
          time: '',
          addition_type_id: null,
          quantity: 0,
          per_quantity_amount: 0,
          amount: 0,
          every_settlement: 0,
          month_week_settlement: 1,
          one_time_addition: 0,
          day: 'Monday',
          month_week: 'Week',
          addition_id: 'new',
          type: 'additions',
        },
      ],
    },
    validationSchema: additionsValidationSchema,
  });

  const taxesformik = useFormik({
    initialValues: {
      taxes: [
        {
          driver_id: id,
          date: new Date(convertToCustomerTime()),
          time: '',
          tax_type_id: null,
          percent: 0,
          auto_deduct: 0,
          tax_id: 'new',
        },
      ],
    },
    validationSchema: taxesValidationSchema,
  });

  const Benefitformik = useFormik({
    initialValues: {
      benefits: [
        {
          driver_id: id,
          date: new Date(convertToCustomerTime()),
          time: '',
          benefit_type_id: null,
          quantity: 0,
          per_quantity_amount: 0,
          amount: 0,
          every_settlement: 0,
          month_week_settlement: 1,
          day: 'Monday',
          month_week: 'Week',
          benefit_id: 'new',
          type: 'benefits',
        },
      ],
    },
    validationSchema: benefitsValidationSchema,
  });

  const workScheduleformik = useFormik({
    initialValues: {
      workSchedule: [
        {
          work_schedule_id: 'new',
          day: 'Mon',
          dayFullName: 'Monday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Tue',
          dayFullName: 'Tuesday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Wed',
          dayFullName: 'Wednesday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Thur',
          dayFullName: 'Thursday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Fri',
          dayFullName: 'Friday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Sat',
          dayFullName: 'Saturday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Sun',
          dayFullName: 'Sunday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
      ],
    },
  });

  const saveCompensation = () => {
    if (formik.values.tax_type === 'Select') {
      showError('Please Select Tax type');
    } else {
      const { is_pay_raise, break_time_paid, pay_on_each_shipment } = formik.values;
      const payload = {
        ...driverCompansation,
        ...formik.values,
        pay_raise: is_pay_raise ? formik.values.pay_raise : [],
        shipment_addition:
          pay_on_each_shipment && formik.values.shipment_addition?.length
            ? formik.values.shipment_addition.map((el) => ({
                amount: el.amount,
                payroll_addition_id: el.payroll_addition.id,
              }))
            : [],
        break_time_paid: Number(break_time_paid),
      };
      delete payload.shipment_additions;
      if (payload?.bank_direct_deposit_form && payload?.bank_direct_deposit_form.includes('https')) {
        delete payload.bank_direct_deposit_form;
      }
      updateCompensation(payload, null, successResponse, 'Compensation has been updated successfully');
    }
  };

  const saveCompensationData = (val) => {
    if (val === 'taxes') {
      const requestPostData = {
        driver_id: +id,
        date: [],
        time: [],
        tax_type_id: [],
        tax_id: [],
        percent: [],
        auto_deduct: [],
      };
      for (const val of taxesformik.values.taxes) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.tax_type_id.push(+val.tax_type_id);
        requestPostData.percent.push(+val.percent);
        requestPostData.tax_id.push(val.tax_id);
        requestPostData.auto_deduct.push(+val.auto_deduct);
      }
      AddTaxes(requestPostData)
        .then(() => {
          onSuccess('Taxes has been added successfully');
          getAllData();
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    } else if (val === 'deductions') {
      const requestPostData = {
        driver_id: +id,
        date: [],
        time: [],
        deduction_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        deduction_id: [],
        amortized_settlement: [],
        amortized_total: [],
        amortized_month: [],
        amortized_months: [],
        one_time_deduction: [],
      };
      for (const val of deductionFormik.values.deductions) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.deduction_type_id.push(+val.deduction_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.deduction_id.push(val.deduction_id);
        requestPostData.amortized_settlement.push(+val.amortized_settlement);
        requestPostData.amortized_total.push(val.amortized_total);
        requestPostData.amortized_month.push(val.amortized_month);
        requestPostData.amortized_months.push(val.amortized_months);
        requestPostData.one_time_deduction.push(val.one_time_deduction || 0);
      }
      AddDeductions(requestPostData)
        .then(() => {
          onSuccess('Deduction has been added successfully');
          getAllData();
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    } else if (val === 'additions') {
      const requestPostData = {
        driver_id: +id,
        date: [],
        time: [],
        addition_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        addition_id: [],
        one_time_addition: [],
      };
      for (const val of Additionsformik.values.additions) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.addition_type_id.push(+val.addition_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.addition_id.push(val.addition_id);
        requestPostData.one_time_addition.push(val.one_time_addition || 0);
      }
      AddAdditions(requestPostData).then(() => {
        onSuccess('Additions has been added successfully');
        getAllData();
      });
    } else if (val === 'benefits') {
      const requestPostData = {
        driver_id: +id,
        date: [],
        time: [],
        benefit_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        benefit_id: [],
      };
      for (const val of Benefitformik.values.benefits) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.benefit_type_id.push(+val.benefit_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.benefit_id.push(val.benefit_id);
      }
      AddBenefits(requestPostData)
        .then(() => {
          onSuccess('Benefits has been added successfully');
          getAllData();
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    } else if (val === 'workingSchedule') {
      const requestPostData = {
        driver_id: +id,
        work_schedule_id: [],
        day: [],
        start_time: [],
        end_time: [],
      };
      for (const val of workScheduleformik.values.workSchedule) {
        if (val.isSelected) {
          requestPostData.work_schedule_id.push(val.work_schedule_id);
          requestPostData.day.push(val.day);
          requestPostData.start_time.push(val.start_time);
          requestPostData.end_time.push(val.end_time);
        }
      }
      AddWorkingSchedule(requestPostData)
        .then(() => {
          onSuccess('Working schedule has been added successfully');
          getAllData();
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    }
  };

  const saveDriverCompensation = (val) => {
    if (val === 'taxes') {
      const requestPostData = {
        driver_id: +id,
        date: [],
        time: [],
        tax_type_id: [],
        tax_id: [],
        percent: [],
        auto_deduct: [],
      };
      for (const val of taxesformik.values.taxes) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.tax_type_id.push(+val.tax_type_id);
        requestPostData.percent.push(+val.percent);
        requestPostData.tax_id.push(val.tax_id);
        requestPostData.auto_deduct.push(+val.auto_deduct);
      }
      if (taxesController?.current) {
        taxesController?.current?.abort();
        taxesController.current = new AbortController();
      }

      AddTaxes(requestPostData, taxesController.current?.signal)
        .then((res) => {
          const newItems = res.new || [];

          if (newItems.length) {
            taxesformik.setValues((prevState) => ({
              ...prevState,
              taxes: prevState.taxes.map((item) => {
                if (item.tax_id === 'new') {
                  const currentItem = newItems.shift();
                  return { ...item, tax_id: currentItem.id };
                }

                return item;
              }),
            }));
          }

          onSuccess('Taxes have been updated successfully!');
        })
        .catch(() => {
          // Do nothing
        });
    } else if (val === 'deductions') {
      const requestPostData = {
        driver_id: +id,
        date: [],
        time: [],
        deduction_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        deduction_id: [],
        amortized_settlement: [],
        amortized_total: [],
        amortized_month: [],
        amortized_months: [],
        one_time_deduction: [],
      };
      for (const val of deductionFormik.values.deductions) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.deduction_type_id.push(+val.deduction_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.deduction_id.push(val.deduction_id);
        requestPostData.amortized_settlement.push(+val.amortized_settlement);
        requestPostData.amortized_total.push(val.amortized_total);
        requestPostData.amortized_month.push(val.amortized_month);
        requestPostData.amortized_months.push(val.amortized_months);
        requestPostData.one_time_deduction.push(val.one_time_deduction || 0);
      }
      if (deductionsController?.current) {
        deductionsController?.current?.abort();
        deductionsController.current = new AbortController();
      }

      AddDeductions(requestPostData, deductionsController.current?.signal)
        .then((res) => {
          const newItems = res.new || [];

          if (newItems.length) {
            deductionFormik.setValues((prevState) => ({
              ...prevState,
              deductions: prevState.deductions.map((item) => {
                if (item.deduction_id === 'new') {
                  const currentItem = newItems.shift();
                  return { ...item, deduction_id: currentItem.id };
                }

                return item;
              }),
            }));
          }

          onSuccess('Deductions have been updated successfully!');
        })
        .catch(() => {
          // Do nothing
        });
    } else if (val === 'additions') {
      const requestPostData = {
        driver_id: +id,
        date: [],
        time: [],
        addition_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        addition_id: [],
        one_time_addition: [],
      };
      for (const val of Additionsformik.values.additions) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.addition_type_id.push(+val.addition_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.addition_id.push(val.addition_id);
        requestPostData.one_time_addition.push(val.one_time_addition || 0);
      }
      if (additionsController?.current) {
        additionsController?.current?.abort();
        additionsController.current = new AbortController();
      }

      AddAdditions(requestPostData, additionsController.current?.signal)
        .then((res) => {
          const newItems = res.new || [];

          if (newItems.length) {
            Additionsformik.setValues((prevState) => ({
              ...prevState,
              additions: prevState.additions.map((item) => {
                if (item.addition_id === 'new') {
                  const currentItem = newItems.shift();
                  return { ...item, addition_id: currentItem.id };
                }

                return item;
              }),
            }));
          }
          onSuccess('Additions have been updated successfully!');
        })
        .catch(() => {
          // Do nothing
        });
    } else if (val === 'benefits') {
      const requestPostData = {
        driver_id: +id,
        date: [],
        time: [],
        benefit_type_id: [],
        quantity: [],
        per_quantity_amount: [],
        amount: [],
        every_settlement: [],
        month_week_settlement: [],
        day: [],
        month_week: [],
        benefit_id: [],
      };
      for (const val of Benefitformik.values.benefits) {
        const dateTime = moment(val.date || new Date())
          ?.format('MM/DD/YYYY HH:mm')
          .split(' ');
        requestPostData.date.push(dateTime[0]);
        requestPostData.time.push(dateTime[1]);
        requestPostData.benefit_type_id.push(+val.benefit_type_id);
        requestPostData.quantity.push(+val.quantity);
        requestPostData.per_quantity_amount.push(+val.per_quantity_amount);
        requestPostData.amount.push(+val.amount);
        requestPostData.every_settlement.push(+val.every_settlement);
        requestPostData.month_week_settlement.push(+val.month_week_settlement);
        requestPostData.day.push(val.day);
        requestPostData.month_week.push(val.month_week);
        requestPostData.benefit_id.push(val.benefit_id);
      }
      if (benefitsController?.current) {
        benefitsController?.current?.abort();
        benefitsController.current = new AbortController();
      }

      AddBenefits(requestPostData, benefitsController.current?.signal)
        .then((res) => {
          const newItems = res.new || [];

          if (newItems.length) {
            Benefitformik.setValues((prevState) => ({
              ...prevState,
              benefits: prevState.benefits.map((item) => {
                if (item.benefit_id === 'new') {
                  const currentItem = newItems.shift();
                  return { ...item, benefit_id: currentItem.id };
                }

                return item;
              }),
            }));
          }
          onSuccess('Benefits have been updated successfully!');
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const successResponse = (msg) => {
    onSuccess(msg);
    setToggle(!toggle);
  };

  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], id)
      .then((res) => {
        formik.setFieldValue(propertyName, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const getAllData = () => {
    getBalances();
    getDeductions();
    getAddtion();
    getBenefit();
    getAllTaxes();
    getAllWorkingSchedule();
  };

  useEffect(() => {
    getAllData();
  }, []);

  const setReccuring = (recurringInfo) => {
    const { index } = recurringInfo;
    if (recurringInfo?.type === 'deductions') {
      if (recurringInfo?.reccurring?.every_settlement === 0) {
        deductionFormik.setFieldValue(`deductions[${index}].day`, recurringInfo?.reccurring?.day);
        deductionFormik.setFieldValue(`deductions[${index}].month_week`, recurringInfo?.reccurring?.month_week);
        deductionFormik.setFieldValue(`deductions[${index}].every_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].month_week_settlement`, 1);
        deductionFormik.setFieldValue(`deductions[${index}].amortized_settlement`, 0);
      } else if (recurringInfo?.reccurring?.every_settlement === 1) {
        deductionFormik.setFieldValue(`deductions[${index}].month_week_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].every_settlement`, 1);
        deductionFormik.setFieldValue(`deductions[${index}].amortized_settlement`, 0);
      } else if (recurringInfo?.reccurring?.every_settlement === 2) {
        deductionFormik.setFieldValue(`deductions[${index}].every_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].month_week_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].amortized_settlement`, 1);
        deductionFormik.setFieldValue(
          `deductions[${index}].amortized_month`,
          recurringInfo?.reccurring?.amortized_month
        );
        deductionFormik.setFieldValue(
          `deductions[${index}].amortized_months`,
          recurringInfo?.reccurring?.amortized_months
        );
        deductionFormik.setFieldValue(
          `deductions[${index}].amortized_total`,
          recurringInfo?.reccurring?.amortized_total
        );
      }
    } else if (recurringInfo?.type === 'additions') {
      if (recurringInfo?.reccurring?.every_settlement === 0) {
        Additionsformik.setFieldValue(`additions[${index}].day`, recurringInfo?.reccurring?.day);
        Additionsformik.setFieldValue(`additions[${index}].every_settlement`, 0);
        deductionFormik.setFieldValue(`deductions[${index}].month_week_settlement`, 1);
        Additionsformik.setFieldValue(`additions[${index}].month_week`, recurringInfo?.reccurring?.month_week);
      } else if (recurringInfo?.reccurring?.every_settlement === 1) {
        Additionsformik.setFieldValue(`additions[${index}].every_settlement`, 1);
        Additionsformik.setFieldValue(`additions[${index}].month_week_settlement`, 0);
      }
    } else if (recurringInfo?.type === 'benefits') {
      if (recurringInfo?.reccurring?.every_settlement === 0) {
        Benefitformik.setFieldValue(`benefits[${index}].day`, recurringInfo?.reccurring?.day);
        Benefitformik.setFieldValue(`benefits[${index}].every_settlement`, 0);
        Benefitformik.setFieldValue(`benefits[${index}].month_week_settlement`, 1);
        Benefitformik.setFieldValue(`benefits[${index}].month_week`, recurringInfo?.reccurring?.month_week);
      } else if (recurringInfo?.reccurring?.every_settlement === 1) {
        Benefitformik.setFieldValue(`benefits[${index}].every_settlement`, 1);
        Benefitformik.setFieldValue(`benefits[${index}].month_week_settlement`, 0);
      }
    }
    setRecurringModalShow(false);
    setRecurringModalData({});
  };

  const remove = (index, data, item) => {
    if (data === 'pay_raise') {
      const temp = formik.values.pay_raise;
      temp.splice(index, 1);
      formik.setFieldValue('pay_raise', temp);
    } else if (data === 'shipment_addition') {
      const temp = formik.values.shipment_addition;
      temp.splice(index, 1);
      formik.setFieldValue('shipment_addition', temp);
    } else if (data === 'deductions') {
      if (deductionFormik.values.deductions[index]?.deduction_id === 'new') {
        const temp = deductionFormik.values.deductions;
        temp.splice(index, 1);
        deductionFormik.setFieldValue('deductions', temp);
      } else {
        const deId = deductionFormik.values.deductions[index]?.deduction_id;
        deleteDeduction({ deId })
          .then(() => {
            const temp = deductionFormik.values.deductions;
            temp.splice(index, 1);
            deductionFormik.setFieldValue('deductions', temp);
            onSuccess('Deduction has been deleted successfully');
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    } else if (data === 'additions') {
      if (Additionsformik.values.additions[index]?.addition_id === 'new') {
        const temp = Additionsformik.values.additions;
        temp.splice(index, 1);
        Additionsformik.setFieldValue('additions', temp);
      } else {
        const addId = Additionsformik.values.additions[index]?.addition_id;
        deleteAddition({ addId })
          .then(() => {
            const temp = Additionsformik.values.additions;
            temp.splice(index, 1);
            Additionsformik.setFieldValue('additions', temp);
            onSuccess('Addition has been deleted successfully');
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    } else if (data === 'benefits') {
      if (Benefitformik.values.benefits[index]?.benefit_id === 'new') {
        const temp = Benefitformik.values.benefits;
        temp.splice(index, 1);
        Benefitformik.setFieldValue('benefits', temp);
      } else {
        const beneId = Benefitformik.values.benefits[index]?.benefit_id;
        deleteBenefit({ beneId })
          .then(() => {
            const temp = Benefitformik.values.benefits;
            temp.splice(index, 1);
            Benefitformik.setFieldValue('benefits', temp);
            onSuccess('Benefits has been deleted successfully');
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    } else if (data === 'taxes') {
      if (taxesformik.values.taxes[index]?.tax_id === 'new') {
        const temp = taxesformik.values.taxes;
        temp.splice(index, 1);
        taxesformik.setFieldValue('taxes', temp);
      } else {
        const taxId = taxesformik.values.taxes[index]?.tax_id;
        deleteTax({ taxId })
          .then(() => {
            const temp = taxesformik.values.taxes;
            temp.splice(index, 1);
            taxesformik.setFieldValue('taxes', temp);
            onSuccess('Tax has been deleted successfully');
          })
          .catch((error) => {
            showError(getErrorMessage(error));
          });
      }
    } else if (data === 'workingSchedule') {
      const workingId = workScheduleformik.values.workSchedule[index]?.work_schedule_id;
      deleteSchedule({ workingId })
        .then(() => {
          const temp = workScheduleformik.values.workSchedule;
          temp[index] = {
            isSelected: false,
            work_schedule_id: 'new',
            start_time: '00:00',
            end_time: '00:00',
            day: item?.day,
            dayFullName: item?.dayFullName,
          };
          workScheduleformik.setFieldValue('workSchedule', temp);
          onSuccess('Wroking Schedule has been deleted successfully');
        })
        .catch((error) => {
          showError(getErrorMessage(error));
        });
    }
  };

  const copyToAll = (val) => {
    workScheduleformik.values.workSchedule.forEach((item, index) => {
      if (index > 0) {
        const startTime = workScheduleformik.values.workSchedule[val].start_time;
        const endTime = workScheduleformik.values.workSchedule[val].end_time;
        workScheduleformik.setFieldValue(`workSchedule[${index}].start_time`, startTime);
        workScheduleformik.setFieldValue(`workSchedule[${index}].end_time`, endTime);
      }
    });
  };

  const onViewBalanceDetails = (balance) => {
    setBalanceToEdit(convertBalanceDetails(balance));
  };

  const onEditBalanceSuccess = (settlement) => {
    setBalanceToEdit((prevState) => convertBalanceDetails({ ...prevState, deductions: [{ settlement }] }));
  };

  useEffect(() => {
    if (!didMountRefDeductions.current) {
      return;
    }
    saveDriverCompensation('deductions');
  }, [deductionFormik?.values]);

  useEffect(() => {
    if (!didMountRefAdditions.current) {
      return;
    }
    saveDriverCompensation('additions');
  }, [Additionsformik?.values]);

  useEffect(() => {
    if (!didMountRefTaxes.current) {
      return;
    }
    saveDriverCompensation('taxes');
  }, [taxesformik?.values]);

  useEffect(() => {
    if (!didMountRefBenefits.current) {
      return;
    }
    saveDriverCompensation('benefits');
  }, [Benefitformik?.values]);

  return (
    <div
      className='Driver-compansation-wrapper driver-style-wrap'
      style={{
        backgroundColor: use(palette.white, palette.dark800),
        borderColor: use(palette.gray50, palette.dark600),
      }}
    >
      <div className='header' style={{ borderColor: use(palette.gray50, palette.darkborder) }}>
        <p className='heading' style={{ color: use(palette.gray900, palette.gray50) }}>
          Compensation
        </p>
        {permissions.includes(PERMISSIONS.DRIVERS.name) ? (
          !toggle ? (
            <div>{!hideSensitiveInfo && <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />}</div>
          ) : (
            <div>
              <button
                onClick={() => {
                  setToggle(!toggle);
                  getAllData();
                }}
                className='driver-compansation-cancel-btn'
              >
                Cancel
              </button>

              {loading ? (
                <CircularProgress size={30} />
              ) : (
                <button
                  style={{ opacity: formik.isValid ? 1 : 0.7 }}
                  disabled={!formik.isValid}
                  onClick={() => {
                    saveCompensation();
                    getAllData();
                  }}
                  className='driver-compansation-save-btn'
                >
                  Save
                </button>
              )}
            </div>
          )
        ) : null}
      </div>
      {permissions.includes(PERMISSIONS.DRIVERS.name) ? (
        toggle ? (
          <div className='driver-compansation-edit-mode'>
            <CompensationContent
              formik={formik}
              use={use}
              theme={theme}
              taxOption={taxOption}
              uploadDocument={uploadDocument}
              companyPolicy={companyPolicy}
              driver_id={id}
              driverId={id}
              remove={remove}
              isDriverProfile
              setRecurringModalShow={setRecurringModalShow}
              recurringModalShow={recurringModalShow}
              deductionFormik={deductionFormik}
              setWeek={setWeek}
              week={week}
              deductionType={deductionType}
              addtionType={additionType}
              benefitType={benefitType}
              taxesType={taxesType}
              saveCompensationData={saveCompensationData}
              setRecurringModalData={setRecurringModalData}
              recurringModalData={recurringModalData}
              setReccuring={setReccuring}
              Additionsformik={Additionsformik}
              Benefitformik={Benefitformik}
              taxesformik={taxesformik}
              getDeductions={getDeductions}
              balances={balances}
              getBalances={getBalances}
              getBenefits={getBenefit}
              getAllTaxes={getAllTaxes}
              ImageDropZone={ImageDropZone}
              workScheduleformik={workScheduleformik}
              copyToAll={copyToAll}
              refreshOptions={() => getOptions()}
              onViewBalanceDetails={onViewBalanceDetails}
            />
          </div>
        ) : (
          <div className='Driver-compansation'>
            {!hideSensitiveInfo && (
              <div>
                <div
                  className='compansation-name-container'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                    tax details
                  </p>
                </div>
                <div className='details-container'>
                  {taxDetails.map((item) => (
                    <div className='Driver-compansation-details' key={item.property}>
                      <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {item.property}
                      </p>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {item.value}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {driverCompansation?.payment_method && (
              <div>
                <div
                  className='compansation-name-container'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray200) }}>
                    Payout Method
                  </p>
                </div>
                <div className='details-container'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                      Payout Method
                    </p>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      {driverCompansation?.payment_method === 'check' && 'Check'}
                      {driverCompansation?.payment_method === 'ach' && 'ACH'}
                      {driverCompansation?.payment_method === 'third_party' && '3rd Party'}
                    </p>
                  </div>
                  {driverCompansation?.payment_method === 'ach' &&
                    bankDetails.map((item) => {
                      return (
                        <div key={item.value} className='Driver-compansation-details'>
                          <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                            {item.property}
                          </p>
                          {item.value === 'View' ? (
                            <p
                              className='details-value details-value-text'
                              style={{ color: '#4F5AED' }}
                              onClick={() => setPdfUrl(driverCompansation?.bank_direct_deposit_form)}
                            >
                              View
                            </p>
                          ) : (
                            <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                              {item.value}
                            </p>
                          )}
                        </div>
                      );
                    })}
                  {driverCompansation?.payment_method === 'third_party' && (
                    <>
                      <div className='Driver-compansation-details'>
                        <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                          3rd Party Name
                        </p>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          {driverCompansation?.third_party_name}
                        </p>
                      </div>
                      <div className='Driver-compansation-details'>
                        <p className='details-property' style={{ color: use(palette.gray700, palette.gray50) }}>
                          3rd Party Identifier
                        </p>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          {driverCompansation?.third_party_id}
                        </p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            <div>
              <div
                className='compansation-name-container'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Payment Option
                </p>
              </div>
              {driverCompansation?.pay_option === 'per_hour' ? (
                <div className='details-container-payemnt'>
                  <div
                    className='Driver-compansation-details-payment'
                    style={{
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  >
                    <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                      Per Hour
                    </p>
                    <div className='payment-per-hour' style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}>
                      <p className='payment-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {currency} {formatNumber(driverCompansation?.per_hour_worked)}
                      </p>
                    </div>
                  </div>
                  <div className='Driver-compansation-details-wrapper'>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                        Breaks
                      </p>
                      <div className='Driver-compansation-details-wrap'>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {driverCompansation?.breaks_allowed_in_a_day}
                          </p>
                        </div>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Breaks Allowed In Given Work Day for{' '}
                        </p>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {driverCompansation?.break_time_allowed} minutes
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Break Time Paid
                      </p>
                      {driverCompansation?.break_time_paid === 0 ? (
                        <div className='Driver-compansation-details-wrap'>
                          <div
                            className='details-value-highlight success'
                            style={{
                              backgroundColor: use('#FAF0F3', palette.green900),
                            }}
                          >
                            <p className='details-value success-text'>No</p>
                          </div>
                        </div>
                      ) : (
                        <div className='Driver-compansation-details-wrap'>
                          <div
                            className='details-value-highlight success'
                            style={{
                              backgroundColor: use('#E1FCEF', palette.green900),
                            }}
                          >
                            <p className='details-value success-text'>Yes</p>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                        Overtime Hour(s)
                      </p>
                      <div className='Driver-compansation-details-wrap'>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          Overtime Hour(s), After{' '}
                        </p>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {driverCompansation?.over_time_hours_after}
                          </p>
                        </div>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          hour(s) per{' '}
                        </p>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {driverCompansation?.over_time_hours_type}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                        Overtime Pay X
                      </p>
                      <div className='Driver-compansation-details-wrap'>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {driverCompansation?.over_time_pay}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : driverCompansation?.pay_option === 'mile' ? (
                <div className='details-container-payemnt'>
                  <div
                    className='Driver-compansation-details-payment'
                    style={{
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  >
                    <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                      Per Mile
                    </p>
                  </div>
                  <div className='Driver-compansation-details-wrapper'>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                        Loaded
                      </p>
                      <div className='Driver-compansation-details-wrap'>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {currency} {formatNumber(driverCompansation?.mile_loaded_amt)}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Empty
                      </p>
                      <div className='Driver-compansation-details-wrap'>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {currency} {formatNumber(driverCompansation?.mile_empty_amt)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : driverCompansation?.pay_option === 'flat_rate' ? (
                <div className='details-container-payemnt'>
                  <div
                    className='Driver-compansation-details-payment'
                    style={{
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  >
                    <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                      Flat Rate
                    </p>
                  </div>
                </div>
              ) : driverCompansation?.pay_option === 'percentage' ? (
                <div className='details-container-payemnt'>
                  <div
                    className='Driver-compansation-details-payment'
                    style={{
                      borderColor: use(palette.gray50, palette.darkborder),
                    }}
                  >
                    <p className='payment-property' style={{ color: use(palette.gray900, palette.gray50) }}>
                      Percentage
                    </p>
                  </div>
                  <div className='Driver-compansation-details-wrapper'>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray900, palette.gray700) }}>
                        Shipment Percentage
                      </p>
                      <div className='Driver-compansation-details-wrap'>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            % {formatNumber(driverCompansation?.shipment_percentage)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className='details-container-payemnt'>
              <div className='Driver-compansation-details-wrapper'>
                <div className='Driver-compansation-details'>
                  <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Allow Shipment Offers
                  </p>
                  {driverCompansation?.allow_shipment_offers === 0 ? (
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight success'
                        style={{
                          backgroundColor: use('#FAF0F3', palette.green900),
                        }}
                      >
                        <p className='details-value success-text'>No</p>
                      </div>
                    </div>
                  ) : (
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight success'
                        style={{
                          backgroundColor: use('#E1FCEF', palette.green900),
                        }}
                      >
                        <p className='details-value success-text'>Yes</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {driverCompansation?.pay_option === 'per_hour' && (
              <div>
                <div
                  className='compansation-name-container compansation-name-style-custom'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name ' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Working Schedule
                  </p>
                </div>
                <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                  <p
                    className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Day
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Start Time
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-amount-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    End Time
                  </p>
                </div>
                {schedule?.map((item) => {
                  const day =
                    item?.day === 'Mon'
                      ? 'Monday'
                      : item?.day === 'Tue'
                      ? 'Tuesday'
                      : item?.day === 'Wed'
                      ? 'Wednesday'
                      : item?.day === 'Thur'
                      ? 'Thursday'
                      : item?.day === 'Fri'
                      ? 'Friday'
                      : item?.day === 'Sat'
                      ? 'Saturday'
                      : item?.day === 'Sun'
                      ? 'Sunday'
                      : '';
                  return (
                    <div
                      key={item.id}
                      className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                    >
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                          {day}
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {item?.start_time}
                        </p>
                      </div>
                      <div className='compensation-recurring-wraper'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {item?.end_time}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div>
              <div
                className='compansation-name-container other-payment'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Other Payment Conditions
                </p>
              </div>
              <div className='details-container-payemnt details-container-payemnt-condition'>
                <div className='Driver-compansation-details'>
                  <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Paid Time Off &#40;PTO&#41;
                  </p>
                  <div className='Driver-compansation-details-wrap'>
                    <div
                      className='details-value-highlight'
                      style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                    >
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                        {currency} {driverCompansation?.paid_time_off_amount}
                      </p>
                    </div>
                    <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                      per day
                    </p>
                  </div>
                </div>
              </div>
              <div className='payment-conditions'>
                <div className='details-container-payemnt details-container-payemnt-condition'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Detention Pay
                    </p>
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight'
                        style={{
                          backgroundColor: use('#F0F2F7', palette.dark600),
                        }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {currency} {driverCompansation?.detention_amt}
                        </p>
                      </div>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        per hour after
                      </p>
                      <div
                        className='details-value-highlight'
                        style={{
                          backgroundColor: use('#F0F2F7', palette.dark600),
                        }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {driverCompansation?.detention_hrs}
                        </p>
                      </div>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        hour&#40;s&#41; have passed
                      </p>
                    </div>
                  </div>
                </div>
                <div className='details-container-payemnt details-container-payemnt-condition'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Layover Pay
                    </p>
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight'
                        style={{
                          backgroundColor: use('#F0F2F7', palette.dark600),
                        }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {currency} {driverCompansation?.layover_amt}
                        </p>
                      </div>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        for each layover
                      </p>
                    </div>
                  </div>
                </div>
                <div className='details-container-payemnt details-container-payemnt-condition'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Stop Off Pay
                    </p>
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight'
                        style={{
                          backgroundColor: use('#F0F2F7', palette.dark600),
                        }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {currency} {driverCompansation?.per_stop_amt}
                        </p>
                      </div>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        per each stop off after
                      </p>
                      <div
                        className='details-value-highlight'
                        style={{
                          backgroundColor: use('#F0F2F7', palette.dark600),
                        }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {driverCompansation?.no_of_stops}
                        </p>
                      </div>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        stops
                      </p>
                    </div>
                  </div>
                </div>
                <div className='details-container-payemnt details-container-payemnt-condition'>
                  <div className='Driver-compansation-details'>
                    <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                      Truck Order Not Used Pay
                    </p>
                    <div className='Driver-compansation-details-wrap'>
                      <div
                        className='details-value-highlight'
                        style={{
                          backgroundColor: use('#F0F2F7', palette.dark600),
                        }}
                      >
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray400) }}>
                          {currency} {driverCompansation?.each_truck_order_unused_amt}
                        </p>
                      </div>
                      <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                        per each truck order not used
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {driverCompansation?.pay_raise?.length && (
                <div
                  className='compansation-name-container compansation-name-style-custom'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Pay Raise Schedule
                  </p>
                </div>
              )}
              {driverCompansation?.pay_raise?.map((item) => {
                return (
                  <div key={item.id} className='details-container-payemnt details-container-raise'>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Raise pay by
                      </p>
                      <div className='Driver-compansation-details-wrap'>
                        {driverCompansation?.pay_option === 'mile' ? (
                          <>
                            <div
                              className='details-value-highlight'
                              style={{
                                backgroundColor: use('#F0F2F7', palette.dark600),
                              }}
                            >
                              <p
                                className='details-value'
                                style={{
                                  color: use(palette.gray900, palette.gray400),
                                }}
                              >
                                {currency} {item?.loaded}
                              </p>
                            </div>
                            <span
                              className='text-style'
                              style={{
                                color: use(palette.gray500, palette.gray200),
                              }}
                            >
                              Loaded
                            </span>
                            <div
                              className='details-value-highlight'
                              style={{
                                backgroundColor: use('#F0F2F7', palette.dark600),
                              }}
                            >
                              <p
                                className='details-value'
                                style={{
                                  color: use(palette.gray900, palette.gray400),
                                }}
                              >
                                {currency} {item?.empty}
                              </p>
                            </div>
                            <span
                              className='text-style'
                              style={{
                                color: use(palette.gray500, palette.gray200),
                              }}
                            >
                              Empty
                            </span>
                          </>
                        ) : (
                          <>
                            <div
                              className='details-value-highlight'
                              style={{
                                backgroundColor: use('#F0F2F7', palette.dark600),
                              }}
                            >
                              <p
                                className='details-value'
                                style={{
                                  color: use(palette.gray900, palette.gray400),
                                }}
                              >
                                {`${driverCompansation?.pay_option === 'percentage' ? '%' : currency}`}{' '}
                                {item?.pay_raise_schedule_amt}
                              </p>
                            </div>
                            <span
                              className='text-style'
                              style={{
                                color: use(palette.gray500, palette.gray200),
                              }}
                            >
                              {driverCompansation?.pay_option === 'per_hour'
                                ? 'per hour'
                                : driverCompansation?.pay_option === 'mile'
                                ? 'per mile'
                                : driverCompansation?.pay_option === 'percentage'
                                ? 'percentage'
                                : driverCompansation?.pay_option === 'flat_rate'
                                ? 'flat rate'
                                : ''}
                            </span>
                          </>
                        )}
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {item?.pay_raise_schedule_days}
                          </p>
                        </div>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          days,
                        </p>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {item?.pay_raise_schedule_months}
                          </p>
                        </div>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          months,
                        </p>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {item?.pay_raise_schedule_years}
                          </p>
                        </div>
                        <p className='details-value' style={{ color: use(palette.gray900, palette.gray50) }}>
                          years
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div>
              {driverCompansation?.shipment_additions?.length && (
                <div
                  className='compansation-name-container compansation-name-style-custom'
                  style={{ borderColor: use(palette.gray50, palette.darkborder) }}
                >
                  <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Additions on Shipments
                  </p>
                </div>
              )}
              {driverCompansation?.shipment_additions?.map((item) => {
                return (
                  <div key={item.id} className='details-container-payemnt details-container-raise'>
                    <div className='Driver-compansation-details'>
                      <p className='details-property' style={{ color: use(palette.gray700, palette.gray200) }}>
                        Pay On Each Shipment
                      </p>
                      <div className='Driver-compansation-details-wrap'>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {currency} {item?.amount}
                          </p>
                        </div>
                        <span
                          className='text-style'
                          style={{
                            color: use(palette.gray500, palette.gray200),
                          }}
                        >
                          for
                        </span>
                        <div
                          className='details-value-highlight'
                          style={{
                            backgroundColor: use('#F0F2F7', palette.dark600),
                          }}
                        >
                          <p
                            className='details-value'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {item?.payroll_addition?.addition_type}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <div className='d-flex align-items-center gap-3'>
                  <p className='compansation-name ' style={{ color: use(palette.gray900, palette.gray50) }}>
                    Balances
                  </p>
                  <Tooltip
                    placement='right'
                    title={
                      <Typography variant='s2' style={{ color: palette.gray700 }}>
                        Truckin Digital Balances is a feature that enables companies to manage and track financial
                        balances for their drivers or staff members. Users can add specific balances for each driver or
                        staff member, allowing the company to maintain a clear overview of any deductions or outstanding
                        balances.
                      </Typography>
                    }
                    tooltipStyles={{ bgcolor: palette.white, border: '1px solid #dadde9', maxWidth: '600px' }}
                  >
                    <InfoIcon width={16} height={16} />
                  </Tooltip>
                </div>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date Created
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Name
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Type
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Payments
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Payment Amount
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Term
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Original Balance
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Balance Remain
                </p>
              </div>
              {balances?.map((item) => {
                const processedSettlements = item.deductions?.[0]?.settlement?.filter(
                  (settlement) => settlement.driver_settlement.status_id === 3
                );
                const paidAmount = (processedSettlements || []).reduce((acc, cur) => acc + Number(cur.amount), 0);
                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode balance-row'
                    onClick={() => onViewBalanceDetails(item)}
                  >
                    <div className='compensation-amount-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {convertToCustomerTime(item.created_at)}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {item.name}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap' style={{ display: 'flex' }}>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item.type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {(processedSettlements || []).length} out of {item.amortized_months}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.payment_amount)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item.month_week_settlement
                          ? `Every ${item.day} of the ${item.month_week}`
                          : `Over ${item.amortized_months} settlements`}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.original_balance)}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {currency}
                        {formatAmount(item.original_balance - paidAmount)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>

            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name ' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Deductions
                </p>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date / Time
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Deduction Type
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Amount Per Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Total
                </p>
                <p className='compensation-feilds-header-head' style={{ color: use(palette.gray700, palette.gray50) }}>
                  Set Interval
                </p>
              </div>
              {deduction?.map((item) => {
                if (item.one_time_deduction === 1) {
                  return null;
                }

                const processedSettlements = (item.settlement || [])?.filter(
                  (settlement) => settlement.driver_settlement.status_id === 3
                );

                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                  >
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {formatDateTime(`${item?.date} ${item?.time}`)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap' style={{ display: 'flex' }}>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.deduction_type?.deduction_type}
                      </p>
                      {!!item?.balance && (
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'
                          style={{ color: use(palette.gray900, palette.gray50), marginLeft: '50px' }}
                        >
                          Balance: {currency}
                          {item?.balance}
                        </p>
                      )}
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.quantity ? `${item?.quantity}` : ''}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.per_quantity_amount ? `${currency} ${item?.per_quantity_amount}` : ''}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      {!!item.is_balance_deduction && processedSettlements.length >= item.amortized_months ? (
                        <Chip label='BALANCE FULFILLED' labelColor={palette.green500} bgColor={palette.green0} />
                      ) : (
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {item?.amount ? `- ${currency} ${formatNumber(item?.amount)}` : ''}
                        </p>
                      )}
                    </div>
                    {item?.month_week_settlement === 1 ? (
                      <div className='compensation-recuuring-subline-main-wrap'>
                        <p className='compensation-feilds-header-head mb-0 me-0'>Every</p>
                        <div
                          className='details-value-highlight'
                          style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                        >
                          <p
                            className='compensation-feilds-header-head mb-0  me-0'
                            style={{ color: use(palette.gray900, palette.gray400) }}
                          >
                            {item?.day}
                          </p>
                        </div>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'>
                          of the {item?.month_week}
                        </p>
                      </div>
                    ) : item?.every_settlement === 1 ? (
                      <div className='compensation-recurring-wraper'>
                        <p className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'>
                          Every Settlement
                        </p>
                      </div>
                    ) : item?.amortized_settlement === 1 ? (
                      <div className='compensation-recuuring-subline-main-wrap'>
                        <p className='compensation-feilds-header-head mb-0 me-0'>Amortized</p>
                        <div
                          className='details-value-highlight'
                          style={{ backgroundColor: use('#F0F2F7', palette.dark600) }}
                        >
                          <p
                            className='compensation-feilds-header-head mb-0  me-0'
                            style={{ color: use(palette.gray900, palette.gray400) }}
                          >
                            {currency}
                            {item?.amortized_total}
                          </p>
                        </div>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          over {item?.amortized_months} settlements, deducted on each settlement.
                        </p>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Additions
                </p>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date / Time
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Addition Type
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Amount Per Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Total
                </p>
                <p className='compensation-feilds-header-head' style={{ color: use(palette.gray700, palette.gray50) }}>
                  Set Interval
                </p>
              </div>
              {addtion?.map((item) => {
                if (item.one_time_addition === 1) {
                  return null;
                }

                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                  >
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {formatDateTime(`${item?.date} ${item?.time}`)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.addition_type?.addition_type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.quantity ? `${item?.quantity}` : ''}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.per_quantity_amount ? `${currency} ${item?.per_quantity_amount}` : ''}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.amount ? `${currency} ${item?.amount}` : ''}
                      </p>
                    </div>
                    {item?.month_week_settlement === 1 && (
                      <div className='compensation-recuuring-subline-main-wrap'>
                        <p
                          className='compensation-feilds-header-head mb-0 me-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          Every
                        </p>
                        <div
                          className='details-value-highlight'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          <p
                            className='compensation-feilds-header-head mb-0  me-0'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {item?.day}
                          </p>
                        </div>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          per {item?.month_week}
                        </p>
                      </div>
                    )}
                    {item?.every_settlement === 1 && (
                      <div className='compensation-recurring-wraper'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          Every Settlement
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Taxes
                </p>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date / Time
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Tax Description
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Percent
                </p>
                <p className='compensation-feilds-header-head' style={{ color: use(palette.gray700, palette.gray50) }}>
                  Set Interval
                </p>
              </div>
              {taxes?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                  >
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property  mb-0' style={{ color: use(palette.gray900, palette.gray50) }}>
                        {formatDateTime(`${item?.date} ${item?.time}`)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.tax_type?.tax_type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.percent ? `${item?.percent} %` : ''}
                      </p>
                    </div>
                    <div className='compensation-recurring-wraper'>
                      <input type='checkbox' checked={item?.auto_deduct === 1} />
                      <p
                        className='taxes-compensation-subline  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        Auto deduct taxes on each settlement
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <div
                className='compansation-name-container compansation-name-style-custom'
                style={{ borderColor: use(palette.gray50, palette.darkborder) }}
              >
                <p className='compansation-name' style={{ color: use(palette.gray900, palette.gray50) }}>
                  Benefits
                </p>
              </div>
              <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date / Time
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Benefit Description
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Amount Per Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Total
                </p>
                <p className='compensation-feilds-header-head' style={{ color: use(palette.gray700, palette.gray50) }}>
                  Set Interval
                </p>
              </div>
              {benefit?.map((item) => {
                return (
                  <div
                    key={item.id}
                    className='compensation-types-header-wraper compensation-types-header-wraper-view-mode'
                  >
                    <div className='compensation-datepicker-main-wrap'>
                      <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                        {formatDateTime(`${item?.date} ${item?.time}`)}
                      </p>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.benefit_type?.benefit_type}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.quantity ? `${item?.quantity}` : ''}
                      </p>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.per_quantity_amount ? `${currency} ${item?.per_quantity_amount}` : ''}
                      </p>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <p
                        className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                        style={{ color: use(palette.gray900, palette.gray50) }}
                      >
                        {item?.amount ? `- ${currency} ${item?.amount}` : ''}
                      </p>
                    </div>
                    {item?.every_settlement === 0 ? (
                      <div className='compensation-recuuring-subline-main-wrap'>
                        <p
                          className='compensation-feilds-header-head mb-0 me-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          Every
                        </p>
                        <div
                          className='details-value-highlight'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          <p
                            className='compensation-feilds-header-head mb-0  me-0'
                            style={{
                              color: use(palette.gray900, palette.gray400),
                            }}
                          >
                            {item?.day}
                          </p>
                        </div>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          per {item?.month_week}
                        </p>
                      </div>
                    ) : (
                      <div className='compensation-recurring-wraper'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          Every Settlement
                        </p>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )
      ) : (
        <div className='d-flex justify-content-center align-items-center'>
          <p style={{ color: use(palette.gray400, palette.gray50) }} className='mb-0 mt-2'>
            You are not authorized to view this information, attempt is logged.
          </p>
        </div>
      )}
      <ViewPdf open={!!pdfUrl} title='View Document' pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
      {!!balanceToEdit && (
        <EditBalance
          open={!!balanceToEdit}
          balance={balanceToEdit}
          onClose={() => {
            setBalanceToEdit(null);
            getBalances();
            getDeductions();
          }}
          userType='driver'
          onSuccess={onEditBalanceSuccess}
        />
      )}
    </div>
  );
};

export default DriverCompansation;
