import uuid from 'react-uuid';

export const initialValues = {
  isNew: true,
  id: uuid(),
  reason: null,
  edi_api_code: '',
  create_note: false,
  note_text: '',
  contact: null,
};

export const getInitialValues = (delayCode) => {
  if (!delayCode) {
    return initialValues;
  }

  return {
    id: delayCode.id,
    isNew: delayCode.isNew || false,
    reason: delayCode.reason || null,
    edi_api_code: delayCode.edi_api_code || '',
    create_note: !!delayCode.create_note || false,
    note_text: delayCode.note_text || '',
    contact: delayCode.contact || null,
  };
};
