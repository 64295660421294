import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import { Overlay } from 'react-bootstrap';
import { palette } from 'utils/constants';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import AddDocument from 'componentsV2/Shipment/AddDocument';
import ActionLocked from 'componentsV2/Shipment/ActionLocked';
import useShipmentLocked from 'hooks/useShipmentLocked';
import styles from '../steps/DocumentsRecurring/documentsRecurring.module.css';

const OverLayDocumentsItem = ({
  doc,
  onClickBlock,
  secondParamKey,
  onSubmitEdit,
  setAttachmentUrl,
  onDelete,
  infoHeader,
}) => {
  const { use } = useTheme();
  const { isShipmentLocked } = useShipmentLocked();
  const target = useRef(null);
  const [isOpenItemEdit, setIsOpenItemEdit] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);

  function onEdit(e) {
    e.stopPropagation();
    setIsOpenItemEdit(false);
    setIsOpenEditModal(true);
  }

  function onView(e) {
    e.stopPropagation();
    setIsOpenItemEdit(false);
    setAttachmentUrl(doc.path);
  }

  function onDeleteItem(e) {
    e.stopPropagation();
    typeof onDelete === 'function' && onDelete(e, doc);
  }

  const onDocumentUpdateSuccess = () => {
    onSubmitEdit();
  };

  return (
    <>
      <div
        onClick={(e) => onClickBlock(doc, e)}
        className={classNames(styles.filed_item, use(styles.light, styles.dark))}
      >
        <div className={styles.filed_name_item}>
          <Typography variant='s2' style={{ color: palette.indigo500 }}>
            {doc?.reference_id}
          </Typography>
        </div>
        {doc?.customer && (
          <div className={styles.filed_name_item}>
            <Typography variant='s2'>
              {doc?.customer}
              {!!doc?.slave_doc && <small> ({doc?.slave_doc?.shipment_id})</small>}
            </Typography>
          </div>
        )}
        <div className={styles.filed_name_item}>
          <Typography variant='s2'>{doc?.shipment_stop?.stop_point?.location_name || '-'}</Typography>
        </div>
        <div className={styles.filed_name_item}>
          {secondParamKey === 'doc_type' ? (
            <Typography variant='s2'>{doc?.[secondParamKey]?.name}</Typography>
          ) : secondParamKey === 'date' ? (
            <Typography variant='s2'>{doc?.date}</Typography>
          ) : null}
        </div>
        <div style={{ width: '40px', textAlign: 'right' }}>
          {isShipmentLocked(infoHeader) ? (
            <ActionLocked shipment={infoHeader} size={16} />
          ) : (
            <>
              <div
                ref={target}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenItemEdit(true);
                }}
              >
                <EditIcon style={{ transform: 'rotate(90deg)' }} />
              </div>
              <Overlay
                placement='bottom'
                target={target.current}
                show={isOpenItemEdit}
                onHide={() => setIsOpenItemEdit(false)}
                rootCloseEvent='click'
                rootClose
              >
                {({ placement, arrowProps, show: _show, popper, ...props }) => {
                  return (
                    <div
                      {...props}
                      style={{ backgroundColor: use(palette.white, palette.dark800), ...props.style }}
                      className={classNames(styles.popover_documents_item_edit, use(styles.light, styles.dark))}
                    >
                      <div onClick={onView} className={styles.popover_item}>
                        <Typography variant='s2'>View</Typography>
                      </div>
                      {!doc.doc_type.hidden && (
                        <div onClick={onEdit} className={styles.popover_item}>
                          <Typography variant='s2'>Edit</Typography>
                        </div>
                      )}
                      <div onClick={onDeleteItem} className={styles.popover_item}>
                        <Typography variant='s2' style={{ color: use(palette.red400, palette.red500) }}>
                          Delete
                        </Typography>
                      </div>
                    </div>
                  );
                }}
              </Overlay>
            </>
          )}
        </div>
      </div>
      {isOpenEditModal && (
        <AddDocument
          open={isOpenEditModal}
          onClose={() => setIsOpenEditModal(false)}
          shipment={infoHeader}
          document={doc}
          onSuccess={onDocumentUpdateSuccess}
        />
      )}
    </>
  );
};

export default OverLayDocumentsItem;
