import React, { useState } from 'react';
import Chip from 'common/Chip';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import ActionButtons from 'common/ActionButtons';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { createDelayCode, deleteCustomersDelayCode, updateCustomersDelayCode } from 'Api/Customers';
import { getInitialValues } from './DelayReasonCodes.data';
import { validationSchema } from './validationSchema';

const DelayReason = ({
  delayReasons,
  customerContacts,
  customerPaymentTerms,
  existingDelayCodes,
  delayCode,
  onCreateSuccess,
  index,
  editMode,
  customerId,
  onDeleteSuccess,
}) => {
  const showToaster = useShowToaster();
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoadingSave(true);

      if (values.isNew) {
        const formData = new FormData();
        formData.append('customer_id', customerId);
        formData.append(`delay_codes[${index}][reason_id]`, values.reason.id);
        formData.append(`delay_codes[${index}][edi_api_code]`, values.edi_api_code);
        formData.append(`delay_codes[${index}][create_note]`, Number(values.create_note));
        if (values.create_note) {
          formData.append(`delay_codes[${index}][note_text]`, values.note_text);
          formData.append(`delay_codes[${index}][contact_id]`, values.contact.id);
        }
        const { data } = await createDelayCode(formData);
        setValues((prevState) => ({ ...prevState, isNew: false, id: data[0]?.id }));
        onCreateSuccess({
          ...delayCode,
          ...data[0],
          reason: values.reason,
          isNew: false,
        });
      } else {
        const body = {
          reason_id: values.reason.id,
          edi_api_code: values.edi_api_code,
          create_note: Number(values.create_note),
          note_text: values.create_note ? values.note_text : null,
          contact_id: values.create_note ? values.contact.id : null,
        };

        const { data } = await updateCustomersDelayCode(body, values.id);
        onCreateSuccess({ ...delayCode, ...data[0], reason: values.reason, contact: values.contact });
      }
      showToaster({
        type: 'success',
        message: `Delay reason code has been ${values.isNew ? 'created' : 'updated'} successfully!`,
      });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingSave(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors, setValues } = useForm({
    initialValues: getInitialValues(delayCode),
    onSubmit,
    validationSchema,
  });

  const deleteDelayCode = async () => {
    try {
      if (!values.isNew) {
        if (
          (customerPaymentTerms?.require_staff_provide_reason || customerPaymentTerms?.require_driver_provide_reason) &&
          existingDelayCodes.length === 1
        ) {
          showToaster({
            type: 'error',
            message:
              'Please turn off delay reason requirement settings from requirements tab before deleting the last delay reason code!',
          });
          return;
        }

        setLoadingDelete(true);
        await deleteCustomersDelayCode(values.id);
        showToaster({ type: 'success', message: `Delay reason code has been deleted successfully!` });
      }
      onDeleteSuccess(delayCode.id);
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingDelete(false);
    }
  };

  return (
    <tr className='body-row'>
      <td>
        <div>
          {!editMode ? (
            <Typography variant='s2' style={{ color: palette.gray900 }}>
              {delayCode.reason?.title}
            </Typography>
          ) : (
            <Autocomplete
              width='300px'
              name='reason'
              options={delayReasons}
              value={values.reason}
              onChange={(e, val) => setValues((prevState) => ({ ...prevState, reason: val, edi_api_code: val.code }))}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) => `${option.title} (${option.code})`}
              error={touchedErrors.reason}
            />
          )}
        </div>
      </td>
      <td>
        <div>
          {!editMode ? (
            <Chip label={delayCode.edi_api_code} />
          ) : (
            <Input
              name='edi_api_code'
              value={values.edi_api_code}
              onChange={handleChange}
              error={touchedErrors.edi_api_code}
            />
          )}
        </div>
      </td>
      <td>
        <div>
          {!editMode ? (
            <div className='d-flex align-items-center gap-2'>
              <Typography variant='s2' style={{ color: palette.gray700 }} className='nowrap'>
                Add Note
              </Typography>
              {delayCode.create_note ? (
                <Typography variant='s2' style={{ color: palette.gray700 }} className='nowrap'>
                  {values.note_text} to Shipment & Send Email to {delayCode.contact?.contact_name} upon delay
                </Typography>
              ) : (
                <Typography variant='s2' style={{ color: palette.gray700 }} className='nowrap'>
                  - Disabled
                </Typography>
              )}
            </div>
          ) : (
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                type='switch'
                smail={false}
                name='create_note'
                checked={values.create_note}
                onChange={(checked) => handleChange('create_note', checked)}
              />
              <Typography variant='s2' style={{ color: palette.gray700 }} className='nowrap'>
                Add Note
              </Typography>
              <Input
                name='note_text'
                value={values.note_text}
                onChange={handleChange}
                error={touchedErrors.note_text}
                disabled={!values.create_note}
              />
              <Typography variant='s2' style={{ color: palette.gray700 }} className='nowrap'>
                to Shipment & Send Email to
              </Typography>
              <div>
                <Autocomplete
                  width='200px'
                  name='contact'
                  options={customerContacts}
                  value={values.contact}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, value) => handleChange('contact', value)}
                  getOptionLabel={(option) => `${option.contact_type?.title} - ${option.contact_name}`}
                  error={touchedErrors.contact}
                  disabled={!values.create_note}
                />
              </div>
              <Typography variant='s2' style={{ color: palette.gray700 }} className='nowrap'>
                upon delay
              </Typography>
            </div>
          )}
        </div>
      </td>
      <td>
        {!!editMode && (
          <ActionButtons
            data={{ ...values, isEdit: true }}
            onEdit={() => null}
            onSave={handleSubmit}
            onDelete={() => deleteDelayCode()}
            loadingSave={loadingSave}
            loadingDelete={loadingDelete}
          />
        )}
      </td>
    </tr>
  );
};

export default DelayReason;
