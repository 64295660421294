import React, { useState } from 'react';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import Modal from 'common/Modal';
import { Typography } from 'components/Typography';
import { refundAndRevertPaidInvoices, revertInvoice, revertStandaloneInvoice } from 'Api/AccountingReceivables';

const RevertInvoice = ({ open, onClose, onSuccess, invoices, tab, type }) => {
  const showToaster = useShowToaster();
  const invoiceIds = invoices.map((invoice) => invoice.invoice_id);
  const [loading, setLoading] = useState(false);
  const hasAppliedPayment =
    tab === 'open' && invoices.some((invoice) => invoice.billing?.total_amount !== invoice.billing?.open_balance);

  const onRevertStandaloneInvoice = async () => {
    try {
      if (type === 'open') {
        await revertStandaloneInvoice({ invoice_id: invoiceIds });
      }
      if (type === 'paid') {
        await refundAndRevertPaidInvoices({ invoice_id: invoiceIds });
      }
      onClose();
      onSuccess();
      showToaster({ type: 'success', message: 'Invoice(s) has been successfully reverted!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const body = {
        status: 'revert',
        invoice_id: invoiceIds,
      };
      if (type === 'open') {
        await revertInvoice(body);
      }
      if (type === 'paid') {
        await refundAndRevertPaidInvoices({ invoice_id: invoiceIds });
      }
      showToaster({ type: 'success', message: 'Invoice(s) has been successfully reverted!' });
      onClose();
      onSuccess();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={invoices.length === 1 ? 'Revert Invoice' : 'Revert Invoice(s)'}
      $bgColor={palette.white}
      $maxWidth='382px'
      $minWidth='382px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'Cancel',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: invoices.length === 1 ? 'Revert Invoice' : 'Revert Invoice(s)',
          disabled: loading,
          onClick: () => (tab === 'shipment' ? handleSubmit() : onRevertStandaloneInvoice()),
        },
      ]}
    >
      {invoices.length === 1 && !hasAppliedPayment ? (
        <Typography variant='b2'>Are you sure you want to revert {invoices[0].invoice_id} invoice?</Typography>
      ) : invoices.length > 1 && !hasAppliedPayment ? (
        <Typography variant='b2'>
          Are you sure you want to revert following invoices: {invoiceIds.join(', ')}?
        </Typography>
      ) : (
        <Typography variant='b2'>
          Reverting a partially paid invoice will revert any payments applied. Are you sure you want to proceed?
        </Typography>
      )}
    </Modal>
  );
};

export default RevertInvoice;
