import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  reference_id: string().trim().required('Required').nullable(),
  type: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  doc: string().trim().required('Required').nullable(),
  shipment: object().when('shipment_type', {
    is: 'ltl',
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});
