import React from 'react';
import { Typography } from 'components/Typography';
import ChangeLogTable from 'componentsV2/ChangeLog';
import { palette } from 'utils/constants';
import { SSection } from './ChangeLog.styles';

const LogsSection = ({ type, params, title }) => {
  return (
    <SSection>
      <div className='section-header'>
        <Typography variant='h4' style={{ color: palette.gray900 }}>
          {title || 'Log'}
        </Typography>
      </div>
      <div className='section-body'>
        <ChangeLogTable type={type} additionalParams={params} />
      </div>
    </SSection>
  );
};

export default LogsSection;
