import React, { useEffect, useState } from 'react';
import Autocomplete from 'common/Autocomplete';
import { getHookToTrailers } from 'Api/Equipment';

const HookTrailerAutocomplete = ({ vehicleId, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [trailers, setTrailers] = useState([]);

  const getTrailers = async () => {
    try {
      setLoading(true);
      const { data } = await getHookToTrailers(vehicleId);
      setTrailers(data.filter((i) => i?.equipment_status?.equipment_status === 'Not Assigned'));
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTrailers();
  }, [vehicleId]);

  return (
    <Autocomplete
      name='hooked_to'
      placeholder='Select Trailer..'
      options={trailers}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) =>
        `${option.equipment_id} (${option.make ? option.make : ''} ${option.model_id ? option.model_id : ''})`
      }
      loading={loading}
      {...props}
    />
  );
};

export default HookTrailerAutocomplete;
