import { array, date, number, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  cargos: array().of(
    object().shape({
      reported_quantity: number().required('Required').nullable(),
      reported_weight: number().required('Required').nullable(),
    })
  ),
  bill_lading_id: string().trim().required('Required').nullable(),
  eta: date().when('should_provide_eta', {
    is: (should_provide_eta) => !!should_provide_eta,
    then: validator.requiredDate(),
    otherwise: validator.date(),
  }),
  delay_reason: object().when(['is_eta_delayed', 'should_provide_eta'], {
    is: (is_eta_delayed, should_provide_eta) => !!is_eta_delayed && !!should_provide_eta,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});

export const requireETAValidationSchema = object().shape({
  eta: date().when('should_provide_eta', {
    is: (should_provide_eta) => !!should_provide_eta,
    then: validator.requiredDate(),
    otherwise: validator.date(),
  }),
  delay_reason: object().when(['is_eta_delayed', 'should_provide_eta'], {
    is: (is_eta_delayed, should_provide_eta) => !!is_eta_delayed && !!should_provide_eta,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});
