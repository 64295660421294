import React from 'react';
import useForm from 'hooks/useForm';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/drivers/warn.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import Input from 'common/Input';
import { DatePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import Attempt from './Attempt';
import { validationSchema } from './validationSchema';
import { attemptInitialValues, getInitialValues } from './AddPrevEmployment.data';
import { SEditWrapper, SAddMore } from '../../PreviousEmployment.styles';

const AddUpdatePrevEmployment = ({ previousEmployments, onSave, userType, userId, applicantPrevEmployment }) => {
  const form = useForm({
    initialValues: getInitialValues(previousEmployments, applicantPrevEmployment),
    validationSchema,
    onSubmit: onSave,
  });
  const { values, handleChange, setValues, handleSubmit, handleBlur, touchedErrors } = form;

  const addEmployer = () => {
    setValues((prevState) => [
      ...prevState,
      { isNew: true, name: '', start_date: null, end_date: null, attempts: [attemptInitialValues] },
    ]);
  };

  const addAttempt = (index, attempts) => {
    handleChange(`${index}.attempts`, [...attempts, attemptInitialValues]);
  };

  const deleteEmployer = (index) => {
    setValues((prevState) => prevState.filter((el, i) => i !== index));
  };

  return (
    <SEditWrapper>
      <div className='d-flex align-items-center gap-2'>
        <WarningIcon />
        <Typography variant='s2' style={{ color: palette.orange500 }}>
          Please make sure you have authorization of the {userType} to conduct a previous employment check.
        </Typography>
      </div>
      <form id='previous-employment-form' onSubmit={handleSubmit}>
        {values.map((employer, index) => (
          <div className='employer-box'>
            <div className='d-flex align-items-center justify-content-between'>
              <Typography variant='s1' style={{ color: palette.gray700 }}>
                Employer {index + 1}
              </Typography>
              {index !== 0 && employer.isNew && (
                <DeleteIcon fill={palette.red500} onClick={() => deleteEmployer(index)} />
              )}
            </div>
            <div className='d-flex gap-3'>
              <Input
                required
                width='300px'
                name={`${index}.name`}
                label='Employer Name'
                onChange={handleChange}
                value={employer.name}
                error={touchedErrors?.[index]?.name}
              />
              <div className='d-flex'>
                <div>
                  <DatePicker
                    required
                    width='160px'
                    label='Start Date'
                    borderRadius='6px 0 0 6px'
                    name={`${index}.start_date`}
                    value={employer.start_date}
                    onChange={(date) => handleChange(`${index}.start_date`, date)}
                    onBlur={handleBlur}
                    disableFuture
                    error={touchedErrors?.[index]?.start_date}
                  />
                </div>
                <div>
                  <DatePicker
                    required
                    width='160px'
                    label='End Date'
                    borderRadius='0 6px 6px 0'
                    name={`${index}.end_date`}
                    value={employer.end_date}
                    onChange={(date) => handleChange(`${index}.end_date`, date)}
                    onBlur={handleBlur}
                    disableFuture
                    error={touchedErrors?.[index]?.end_date}
                  />
                </div>
              </div>
            </div>
            {employer.attempts.map((attempt, i) => (
              <Attempt
                key={attempt.id}
                attempt={attempt}
                employerIndex={index}
                index={i}
                form={form}
                userType={userType}
                userId={userId}
              />
            ))}
            <SAddMore onClick={() => addAttempt(index, employer.attempts)}>
              <PlusIcon />
              <Typography variant='s2' style={{ color: palette.indigo500 }}>
                Add Attempt
              </Typography>
            </SAddMore>
          </div>
        ))}
        {!previousEmployments?.length && (
          <SAddMore onClick={addEmployer}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Employer
            </Typography>
          </SAddMore>
        )}
      </form>
    </SEditWrapper>
  );
};

export default AddUpdatePrevEmployment;
