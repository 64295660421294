import React, { useState, useEffect, useMemo, useRef, useCallback, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Toast, Spinner } from 'react-bootstrap';
import uuid from 'react-uuid';
import moment from 'moment';

import axios from 'axios';
import hexRgb from 'hex-rgb';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Speed, Icons } from 'assets/icons';

import AddGroup from 'pages/landing/Equipment/DialogueModals/AddGroup/AddGroup';
import AddHook from 'pages/landing/Equipment/DialogueModals/AddHook/AddhookTrailer';
import AlertMsg from 'pages/landing/Equipment/DialogueModals/ProfileAlert/AlertIcon';
import UnHooked from 'pages/landing/Equipment/DialogueModals/AddHook/UnhookedVehicle';
import RemoveGroup from 'pages/landing/Equipment/DialogueModals/AddGroup/RemoveGroup';
import DriverInfo from 'pages/landing/Equipment/DialogueModals/DriverInfo/DriverInfo';
import RemoveDriver from 'pages/landing/Equipment/DialogueModals/DriverInfo/RemoveDriver';
import AddDriverSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddDriverMsg';
import AddHookedError from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddHookedError';
import AddGroupSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddGroupSuccess';
import AlreadyReserved from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AlreadyReserved';
import UnHookedVehiclemsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/UnhookedVehicle';
import ReserveEquipment from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/ReserveEquipment';
import AddHookedSucsss from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/VehicleHookedsuccess';
import RemovedDriverMsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/RemoveDriverSuccess';
import RemoveGroupSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/RemoveGroupSuccess';
import UnresevedEquipment from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/UnreservedEquipment';
import { trailerStatus } from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileTrailer/Trailer.data';
import ReserveEquipmentMsg from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/ReservedEquipmentMsg';
import UnreservedEquipmentmsg from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/UnreserveEquipmentmsg';

import Chip from 'common/Chip';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import TableFooter from 'components/Pagination/EquipmentTableFooter';
import { getUTCTimeDifference } from 'components/TableShipments/helpers/constants';
import { formatSeconds } from 'utils/helpers';
import authHeader from 'services/auth-header';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, motionColors, palette } from 'utils/constants';
import { getMapTabs } from 'Api/Map';
import { Groups, getEquipmentRequiredTrailers, Trailer } from 'Api/Equipment';
import SubTabs from '../EquipmentTab/SubTab';
import SwitchRadio from '../EquipmentTab/SwitchRadio';
import TrailerHeader from './TrailerHeader/TrailerHeader';
import CustomModal from '../EquipmentTab/EquipmentAddTabModal';
import EquipmentTableModal from '../EquipmentTab/EquipmentTabModal';
import { LOCATION_INITIAL_VALUE } from '../EquipmentFilters/constant';
import Preloader from '../EquipmentPreloader/EquipmentPreloaderSkeleton';
import MaterialTableWrapper, { EquipmentTableSort } from '../EquipmentTableWrapper/EquipmentTableWrapper';
import './EquipmentTable.css';
import { NavLinkWrapper } from './NavLinkWrapper';
import { FILTER_SEARCH_BY_TRAILER } from './constat';
import ColumnHeader from 'common/ColumnHeader';

export const TableEquipmentTrailerContext = createContext();

const TrailerTable = ({ setTabName, setCurrentPageIds = () => null, setMainTab, createdEquipmentId }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { use } = useTheme();
  const { convertToCustomerTime, dateFormat, timeFormat } = useDateFormat();

  const [tab, setTab] = useState(2);
  const [loadingTab, setLoadingTab] = useState(false);
  const [showA, setShowA] = useState(false);
  const [reserveMsgData, setReserveMsgData] = useState();
  const [reserveEquipment, setReserveEquipment] = useState(false);
  const [id, setId] = useState();
  const [removeDrivermsg, setRemovedDrivermsg] = useState(false);
  const [unHookedMsg, setUnhookedMsg] = useState(false);
  const [removeGroup, setRemoveGroup] = useState(false);
  const [RemoveGroupData, setRemoveGroupData] = useState({ data: [] });
  const [removeGroupSuccess, setRemoveGroupSuccess] = useState(false);
  const [hook, setHook] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [driverPopupData] = useState({ data: [] });
  const [delDriver, setDeleteDriver] = useState(false);
  const [removeDriverData, setRemoveDriverData] = useState({ data: [] });
  const [TYPES, SET_TYPES] = useState([]);
  const [trailerTabData, setTrailerTabData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [addGroup, setAddGroup] = useState(false);
  const [success, setSuccess] = useState(false);
  const [unreserveSuccess, setUnReserveSuccess] = useState(false);
  const [Hookederr, setHookederr] = useState(false);
  const [unHooked, setUnHooked] = useState(false);
  const [unHookedData, setUnhookedData] = useState({ data: [] });
  const [getHookederr, setGetHookedErr] = useState();
  const [columnOrder, setColumnOrder] = useState([]);
  const [, setQueryParameterData] = useState();
  const [alertData, setAlertData] = useState();
  const [alertId, setAlertId] = useState(null);
  const [, setFilterByGroupsData] = useState([]);
  const [tableSettingSwitch, setTableSettingSwitch] = useState({});
  const [, setFilterByEquipmentTypeData] = useState([]);
  const [, setShowHeaderFilters] = useState([]);
  const [trailerData, setTrailerData] = useState({ data: [] });
  const [unReseverdEq, setUnReserverdEq] = useState(false);
  const [unReseverdEqId, setUnReserverdEqId] = useState({ data: [] });
  const [driverAddedmsg, setDriverAddedmsg] = useState(false);
  const [addGroupSuccess, setAddGroupSuccess] = useState(false);
  const [profileAlert, setProfileAlert] = useState(null);
  const [alreadyReserveid, setAlreadyReserveId] = useState();
  const [alreadyReserveMsg, setAlreadyReserveMsg] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('sort[][equipment_id]=asc');
  const didMountRef = useRef(false);
  const [firstUpdate, setFirstUpdate] = useState(true);
  const [tableIndex, setTableIndex] = useState(Date.now());
  const trailers = useRef([]);
  const { value: userData } = useAuth();

  const [filter, setFilter] = useState({
    searchValue: '',
    searchBy: { id: 1, title: 'Equipment', value: 'equipment_id', key: 9999 },
    allValue: '',
    selectedValues: [],
    customerSelectData: [],
    ColumnOrder: columnOrder,
    location: LOCATION_INITIAL_VALUE,
    tableColumn: [],
    switchRadioButtons: tableSettingSwitch,
    applyAllUsers: { value: '0' },
    filterTableTop: [
      { key: 'vehicle', label: `Vehicles`, value: 1 },
      { key: 'trailer', label: 'Trailers', value: 2 },
    ],
  });
  const [switcherData, setSwitcherData] = useState(filter.filterTableTop);

  const [multiSelectOptions] = useState({});
  const [modalSelectData] = useState([
    { title: 'Customer 1', id: 1 },
    { title: 'Customer 2', id: 2 },
    { title: 'Customer 3', id: 3 },
    { title: 'Customer 4', id: 4 },
    { title: 'Customer 5', id: 5 },
    { title: 'Customer 6', id: 6 },
  ]);
  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
      [`& .${tooltipClasses.tooltip}`]: {
        boxShadow:
          '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '18px',
        backgroundColor: use(palette.white, palette.dark800),
        color: use(palette.dark800, palette.gray200),
      },
    })
  );
  const navigate = useNavigate();

  const setLoadingStatus = (id, status) => {
    const trailerDataCopy = { ...{}, ...trailerData };
    const index = trailerDataCopy.data.findIndex((i) => i.id === id);
    const row = document.getElementsByClassName('MuiTableRow-root')[index + 1];

    row?.classList?.add('flash-in-row');
    if (!status) {
      row?.classList?.remove('flash-in-row');
    }
  };

  const AlreadyReserveMsgModal = (id) => {
    setAlreadyReserveId(id);
    setAlreadyReserveMsg(true);
    setTimeout(() => {
      AlreadyReserveMsgModalClose();
    }, 3000);
  };
  const AlreadyReserveMsgModalClose = () => setAlreadyReserveMsg(false);
  const RemoveGroupSuccessModal = (id) => {
    setLoadingStatus(id, true);
    setRemoveGroupSuccess(true);
    setTimeout(() => {
      RemoveGroupSuccessModalClose();
    }, 3000);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 4000);
  };
  const RemoveGroupSuccessModalClose = () => {
    setRemoveGroupSuccess(false);
  };

  const RemoveGroupModal = (id, eq_id, row_id, title, group_name) => {
    setRemoveGroup(true);
    setRemoveGroupData([id, eq_id, row_id, title, group_name]);
  };
  const RemoveGroupModalClose = () => {
    setRemoveGroup(false);
  };
  const AddGroupSuccessClose = () => {
    setAddGroupSuccess(false);
  };
  const AddGroupSuccessModal = (id) => {
    setLoadingStatus(id, true);
    setAddGroupSuccess(true);
    setTimeout(() => {
      AddGroupSuccessClose();
    }, 3000);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 4000);
  };

  const DriversAddedmsgModalClose = () => setDriverAddedmsg(false);
  const RemoveDriverModal = () => setRemovedDrivermsg(true);
  const RemoveDriverModalClose = () => setRemovedDrivermsg(false);
  const GetreserveMsgData = (val) => {
    setReserveMsgData(val);
  };
  const GetHookedError = (err) => {
    setGetHookedErr(err);
  };
  const HookedErrShow = () => {
    setHookederr(true);
  };
  const HookedErrClose = () => {
    setHookederr(false);
  };
  const UnhookedMsgClose = () => {
    setUnhookedMsg(false);
  };
  const UnReserveSuccessShow = (id) => {
    setLoadingStatus(id, true);
    setUnReserveSuccess(true);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 4000);
  };
  const UnReserveSuccessClose = () => {
    setUnReserveSuccess(false);
  };
  const UnReservedModalClose = () => {
    setUnReserverdEq(false);
  };
  const UnReservedEqModal = (id, row_id, title, equipment_id) => {
    setUnReserverdEqId([id, row_id, title, equipment_id]);
    setUnReserverdEq(true);
  };
  const SuccessShow = () => {
    setLoadingStatus(id, true);
    setSuccess(true);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 4000);
  };
  const SuccessClose = () => setSuccess(false);

  const UnHookedModalShow = (row_id, id, title, eq_id) => {
    setUnhookedData([row_id, id, title, eq_id]);
    setUnHooked(true);
  };
  const UnHookedModalClose = () => {
    setUnHooked(false);
  };

  const ReserveEquipmentModal = () => {
    setReserveEquipment(true);
  };
  const ReserveEquipmentModalClose = () => {
    setReserveEquipment(false);
  };
  const hookModalClose = () => setHook(false);
  const hookModalShow = (id) => {
    setId(id);
    setHook(true);
  };
  const UnHoookedMsgShow = (id) => {
    setLoadingStatus(id, true);
    setUnhookedMsg(true);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 4000);
    setTimeout(() => {
      UnhookedMsgClose();
    }, 3000);
  };
  const addGroupModal = (id) => {
    setId(id);
    setAddGroup(true);
  };
  const addGroupModalClose = () => setAddGroup(false);

  const getId = (id) => {
    setId(id);
    ReserveEquipmentModal(true);
  };
  const ProfileAlertOpen = Boolean(profileAlert);
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;

  const user = JSON.parse(localStorage.getItem('user'));

  const deleteDriverModal = (id, rowId, fname, lname, Equipment_id, Equipment_title) => {
    handleClose();
    setDeleteDriver(true);
    setRemoveDriverData([id, rowId, fname, lname, Equipment_id, Equipment_title]);
  };
  const deleteDriverModalClose = () => {
    setDeleteDriver(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const toggleShowA = (id) => {
    setLoadingStatus(id, true);
    setShowA(true);
    setTimeout(() => {
      toggleCloseA();
    }, 3000);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 4000);
  };
  const toggleCloseA = () => setShowA(false);

  const ProfileAlerthandleClick = (e, alerts, id) => {
    setProfileAlert(e.currentTarget);
    setAlertData(alerts);
    setAlertId(id);
  };
  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };
  const open = Boolean(anchorEl);
  const Popup = open ? 'simple-popover' : undefined;

  const columns = useMemo(() => {
    const state = { tab: 'trailer' };
    const navLinkInnerClickableElement = { position: 'relative', zIndex: 1, width: 'fit-content' };
    const getDefaultTo = (rowData) => `/equipment-profile/trailer/${rowData?.id}`;
    return [
      {
        field: 'equipment_id',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='EQUIPMENT ID' field='equipment_id' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {rowData.loading && <Spinner size='sm' animation='grow' variant='primary' className='me-2' />}
              <div className='equipment-id-column fade-in-row'>
                <div className='d-flex flex-column' key={rowData.equipment_id}>
                  {rowData?.location_distance && (
                    <span className='location-distance'>
                      {rowData?.location_distance.toString().split('.')[0]} miles away
                    </span>
                  )}
                  <span className='equipment-id-text-style' style={{ color: use(palette.dark800, palette.gray200) }}>
                    <span className='hooked-numbers-sub' style={{ color: use(palette.dark800, palette.gray200) }}>
                      {rowData.equipment_id} {rowData?.length?.length}
                    </span>
                    <span style={{ color: use(palette.dark800, palette.gray200) }}>{rowData?.length?.unit} </span>
                    {rowData?.equipment_type?.title}
                  </span>

                  <div className='flex-row d-flex align-items-center'>
                    <div className='d-flex mt-0 p-0'>
                      {rowData?.equipment_reserve?.id ? (
                        <div
                          style={navLinkInnerClickableElement}
                          onClick={($event) => {
                            $event.stopPropagation();
                            user?.department?.department_name === 'Management' ||
                            user.id === rowData?.equipment_reserve?.user?.id
                              ? UnReservedEqModal(
                                  rowData?.equipment_reserve?.id,
                                  rowData?.id,
                                  rowData?.equipment_type?.title,
                                  rowData.equipment_id
                                )
                              : AlreadyReserveMsgModal(rowData?.equipment_reserve?.id);
                          }}
                        >
                          <svg
                            className='ms-1 me-1'
                            width='12'
                            height='14'
                            viewBox='0 0 12 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M0.5 2C0.5 0.895431 1.39543 0 2.5 0H9.5C10.6046 0 11.5 0.895431 11.5 2V13.5993C11.5 13.8965 11.1873 14.0899 10.9213 13.9572L6.4467 11.723C6.16546 11.5826 5.83454 11.5826 5.55329 11.723L1.07868 13.9572C0.812734 14.0899 0.5 13.8965 0.5 13.5993V2Z'
                              fill='#767FFB'
                            />
                          </svg>
                        </div>
                      ) : (
                        <div
                          style={navLinkInnerClickableElement}
                          className='reserve-equipment'
                          onClick={(e) => {
                            e.stopPropagation();

                            getId(rowData?.id);
                          }}
                        >
                          <img src={Icons.buttonIcon} alt='button-icon' className='bookmark' />
                        </div>
                      )}
                      {rowData?.expiring_equipment_alerts ? (
                        <div
                          className='ms-1 me-1'
                          onClick={(e) => e.stopPropagation()}
                          style={navLinkInnerClickableElement}
                        >
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            onClick={(e) => ProfileAlerthandleClick(e, rowData?.expiring_equipment_alerts, rowData?.id)}
                          >
                            <path
                              d='M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z'
                              fill='#C97A20'
                            />
                            <path
                              d='M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z'
                              fill='#C97A20'
                            />
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z'
                              fill='#C97A20'
                            />
                          </svg>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'hooked_to',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='HOOKED TO' field='[id]' nested_field='hook_to' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='hooked-to-column-wrapper fade-in-row'>
              {rowData?.hook_to ? (
                <div
                  style={navLinkInnerClickableElement}
                  onClick={(e) => {
                    e.stopPropagation();
                    UnHookedModalShow(
                      rowData?.id,
                      rowData?.hook_to?.id,
                      rowData?.hook_to?.equipment_type?.title,
                      rowData?.hook_to.equipment_id
                    );
                  }}
                >
                  <span className='hooked-numbers' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {rowData?.hook_to?.equipment_id}
                  </span>
                  <span className='hooked-numbers' style={{ color: use(palette.dark800, palette.gray200) }}>
                    {rowData?.hook_to?.equipment_type?.title}
                  </span>
                </div>
              ) : (
                ''
              )}
              {!rowData.hook_to ? (
                <div className='row-style'>
                  <div className='add-driver'>
                    <div
                      style={navLinkInnerClickableElement}
                      className=' add-driver-wrapper '
                      onClick={(e) => {
                        e.stopPropagation();
                        hookModalShow(rowData?.id);
                      }}
                    >
                      <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                          fill='#4f5aed'
                        />
                      </svg>

                      <span className='add-driver-content'>Hook</span>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'group',
        title: (
          <EquipmentTableSort
            sortingQuery={sortingQuery}
            title='TAGS'
            field='[short_name]'
            nested_field='equipment_group'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <div className='equipment-group fade-in-row'>
                <div className='group-wrapper'>
                  {rowData?.equipment_group
                    ? rowData?.equipment_group.map((g) => {
                        const colorCode = hexRgb(g.color);
                        const TextColor =
                          colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70
                            ? 'white'
                            : 'rgb(23, 28, 38)';
                        return (
                          <span key={g.id} style={navLinkInnerClickableElement}>
                            <span
                              className='equipment-groups'
                              style={{
                                backgroundColor: g.color,
                                color: TextColor,
                              }}
                              onClick={($event) => {
                                RemoveGroupModal(
                                  g.id,
                                  rowData.equipment_id,
                                  rowData?.id,
                                  rowData?.equipment_type?.title,
                                  g?.group_name
                                );
                                $event.stopPropagation();
                              }}
                            >
                              {g.short_name}
                            </span>
                          </span>
                        );
                      })
                    : ''}
                </div>

                {rowData?.equipment_group && rowData?.equipment_group.length >= 1 ? (
                  ''
                ) : (
                  <div className='row-style' style={navLinkInnerClickableElement}>
                    <div className='add-group'>
                      <div
                        className='d-flex add-group-wrapper'
                        onClick={($event) => {
                          $event.stopPropagation();
                          addGroupModal(rowData.id);
                        }}
                      >
                        <div>
                          <svg
                            width='12'
                            height='12'
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            style={{ cursor: 'pointer' }}
                          >
                            <path
                              d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                              fill='#4f5aed'
                            />
                          </svg>
                        </div>
                        <span className='add-group-content '>Add Tag</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'status',
        title: (
          <EquipmentTableSort
            title='STATUS'
            sortingQuery={sortingQuery}
            field='[equipment_status]'
            nested_field='equipment_status'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <div className='equipment-status-wrapper fade-in-row'>
                <div className='d-flex justify-content-start'>
                  <span
                    style={{
                      backgroundColor: rowData?.equipment_status?.background_color,
                      color: rowData?.equipment_status?.color,
                    }}
                    className='equipment_status'
                  >
                    {trailerStatus[rowData?.equipment_status?.equipment_status] ||
                      rowData?.equipment_status?.equipment_status}
                  </span>
                </div>
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'owned_by',
        title: (
          <EquipmentTableSort
            sortingQuery={sortingQuery}
            title='OWNED BY'
            field='[owner]'
            nested_field='equipment_ownership'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div
              style={{
                color: use(palette.dark800, palette.gray200),
                fontSize: '13px',
                marginTop: '-12px',
              }}
            >
              {rowData?.equipment_ownership?.owner_operator === '1' && userData?.user?.customer?.company_name}
              {rowData?.equipment_ownership?.owner_operator === '2' &&
                rowData?.equipment_ownership?.leasing_company_name}
              {rowData?.equipment_ownership?.owner_operator === '3' && rowData?.equipment_ownership?.owner}
              {rowData?.equipment_ownership?.owner_operator === '4' && '3rd Party'}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'current_location',
        title: (
          <EquipmentTableSort
            title='CURRENT LOCATION'
            sortingQuery={sortingQuery}
            field='[location]'
            nested_field='gps'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-list-current-location fade-in-row'>
              <div className='d-flex'>
                {rowData?.location_target === 'Vehicle' ? (
                  <>
                    <img src={Icons.EquipmentProfileIcon} alt='' />
                    {rowData?.hooked_to?.location_target === 'Equipment' ? (
                      <div>
                        {rowData?.hooked_to?.equipment_gps?.location ? (
                          <p
                            className='current-location'
                            style={{
                              color: use(palette.dark800, palette.gray200),
                              marginLeft: '40px',
                            }}
                          >
                            {rowData?.hooked_to?.equipment_gps?.location}
                          </p>
                        ) : (
                          <p
                            className='current-location'
                            style={{
                              color: use(palette.dark800, palette.gray200),
                              marginLeft: '40px',
                            }}
                          >
                            {rowData?.hooked_to?.last_known_location}
                          </p>
                        )}
                      </div>
                    ) : rowData?.hooked_to?.location_target === 'Driver' ? (
                      <div>
                        {rowData?.hooked_to?.drivers ? (
                          <p
                            className='current-location'
                            style={{
                              color: use(palette.dark800, palette.gray200),
                              marginLeft: '40px',
                            }}
                          >
                            {rowData?.hooked_to?.drivers.map((v) => v?.driver_gps?.location)[0]}
                          </p>
                        ) : (
                          <p
                            className='current-location'
                            style={{
                              color: use(palette.dark800, palette.gray200),
                              marginLeft: '40px',
                            }}
                          >
                            {rowData?.hooked_to?.last_known_location}
                          </p>
                        )}
                      </div>
                    ) : (
                      <div>
                        <p
                          className='current-location'
                          style={{
                            color: use(palette.dark800, palette.gray200),
                            marginLeft: '40px',
                          }}
                        >
                          {rowData?.hooked_to?.last_known_location}
                        </p>
                      </div>
                    )}
                  </>
                ) : rowData?.location_target === 'Equipment' ? (
                  <div className='d-flex'>
                    <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M9.20022 2.25556C9.26833 2.11572 9.40323 2.01964 9.55849 2.00042C9.5731 1.9998 9.58789 1.99989 9.60261 2.00051C11.8881 2.00051 13.75 3.85041 13.75 6.1212C13.7616 6.24928 13.7185 6.37624 13.6312 6.4712C13.5438 6.56606 13.4203 6.62013 13.2909 6.62013C13.1615 6.62013 13.038 6.56606 12.9507 6.4712C12.8633 6.37624 12.8202 6.24928 12.8317 6.1212C12.8317 4.34522 11.39 2.91273 9.60252 2.91273C9.44623 2.90854 9.3027 2.82574 9.22141 2.69296C9.14013 2.56018 9.13211 2.3955 9.20022 2.25556Z'
                        fill='#868FA0'
                      />
                      <path
                        d='M9.20301 4.05788C9.28543 3.916 9.4377 3.8286 9.60252 3.8286C10.8699 3.82868 11.91 4.86194 11.9099 6.12107C11.9099 6.28482 11.822 6.43611 11.6792 6.51799C11.5365 6.59986 11.3606 6.59986 11.2179 6.51799C11.075 6.43611 10.9872 6.28482 10.9872 6.12107C10.9872 5.35688 10.3717 4.74541 9.60252 4.74541C9.4377 4.74541 9.28542 4.65812 9.20301 4.51624C9.1206 4.37446 9.1206 4.19966 9.20301 4.05788Z'
                        fill='#868FA0'
                      />
                      <path
                        d='M9.83429 6.9169C9.83429 7.50062 9.358 7.97382 8.77046 7.97382C8.18292 7.97382 7.70663 7.50062 7.70663 6.9169C7.70663 6.33318 8.18292 5.85999 8.77046 5.85999C9.358 5.85999 9.83429 6.33318 9.83429 6.9169Z'
                        fill='#868FA0'
                      />
                      <path
                        d='M3.44775 6.29748C3.63558 6.11117 3.89107 6.00436 4.15588 6.00052C4.43149 5.99668 4.69648 6.10398 4.89136 6.29752L9.21744 10.5955C9.61285 10.9884 9.61269 11.6364 9.21744 12.0297C8.45321 12.7901 7.4155 13.2193 6.33293 13.2196C5.86689 13.2198 5.41829 13.1411 5 12.9963V13.2504C5 13.5945 4.76832 13.8844 4.45247 13.9728C4.39798 13.9907 4.33975 14.0004 4.27925 14.0004L4.26574 14.0003L4.25 14.0004C3.83579 14.0004 3.5 13.6646 3.5 13.2504V12.077C2.73023 11.3378 2.24961 10.303 2.25 9.16323C2.25031 8.0877 2.6823 7.05674 3.44775 6.29748Z'
                        fill='#868FA0'
                      />
                    </svg>

                    {rowData?.equipment_gps?.location ? (
                      <>
                        <div className='d-flex me-1' style={{ width: '30px' }}>
                          <div className='ms-1'>
                            {!rowData?.equipment_connected_services_faultcode?.faultcode_id ? (
                              rowData?.equipment_connected_services_device?.engine_state === 'Idle' ? (
                                <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                                    fill='#81D6AB'
                                  />
                                </svg>
                              ) : rowData?.equipment_connected_services_device?.engine_state === 'On' ? (
                                <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                                    fill='#38A06C'
                                  />
                                </svg>
                              ) : rowData?.equipment_connected_services_device?.engine_state === 'Off' ? (
                                <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                                  <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                                    fill='#5A6376'
                                  />
                                </svg>
                              ) : null
                            ) : (
                              <div className='popover__wrapper mb-2'>
                                <svg
                                  width='14'
                                  height='12'
                                  viewBox='0 0 14 12'
                                  xmlns='http://www.w3.org/2000/svg'
                                  style={{ position: 'relative' }}
                                >
                                  <path
                                    fillRule='evenodd'
                                    clipRule='evenodd'
                                    d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                                    fill='#DC4067'
                                  />
                                </svg>
                              </div>
                            )}
                          </div>

                          <div className='d-flex flex-column'>
                            {rowData?.equipment_gps?.signal !== null ? (
                              rowData?.equipment_gps?.signal === 'poor' ? (
                                <div className='equipment-signalstrenth  me-1'>
                                  <img src={Icons.PoorSignal} className='mt-0' alt='' />
                                </div>
                              ) : rowData?.equipment_gps?.signal === 'good' ? (
                                <div className='equipment-signalstrenth  me-1'>
                                  {' '}
                                  <img src={Icons.GoodSignal} className='mt-0 me-1' alt='' />
                                </div>
                              ) : rowData?.equipment_gps?.signal === 'great' ? (
                                <div className='equipment-signalstrenth  me-1'>
                                  <img src={Icons.StrongSignal} className='mt-0 me-1' alt='' />
                                </div>
                              ) : (
                                ''
                              )
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                        <p
                          className='current-location'
                          style={{
                            color: use(palette.dark800, palette.gray200),
                          }}
                        >
                          {rowData?.equipment_gps?.location}
                        </p>
                      </>
                    ) : (
                      rowData?.last_known_location && (
                        <p
                          className='current-location'
                          style={{
                            color: use(palette.dark800, palette.gray200),
                            marginLeft: '60px',
                          }}
                        >
                          {rowData?.last_known_location}
                        </p>
                      )
                    )}
                  </div>
                ) : rowData?.location_target === null ? (
                  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.79114 3.72156C4.49577 3.42619 4.01689 3.42619 3.72152 3.72156C3.42616 4.01693 3.42616 4.49581 3.72152 4.79117L6.93037 8.00002L3.72155 11.2088C3.42619 11.5042 3.42619 11.9831 3.72155 12.2784C4.01692 12.5738 4.4958 12.5738 4.79117 12.2784L7.99998 9.06963L11.2088 12.2785C11.5042 12.5738 11.9831 12.5738 12.2784 12.2785C12.5738 11.9831 12.5738 11.5042 12.2784 11.2089L9.06959 8.00002L12.2785 4.79114C12.5738 4.49577 12.5738 4.01689 12.2785 3.72152C11.9831 3.42616 11.5042 3.42616 11.2089 3.72152L7.99998 6.9304L4.79114 3.72156Z'
                      fill='#DC4067'
                    />
                  </svg>
                ) : (
                  ''
                )}
              </div>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },

      {
        field: 'last_update',
        title: (
          <EquipmentTableSort sortingQuery={sortingQuery} title='LAST UPDATE' field='[updated_at]' nested_field='gps' />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <div style={{ fontSize: '13px' }}>
                {rowData?.location_target === 'Vehicle' ? (
                  rowData?.hooked_to?.location_target === 'Equipment' ? (
                    rowData?.hooked_to?.equipment_gps?.updated_at ? (
                      <p className='current-location' style={{ color: use(palette.dark800, palette.gray200) }}>
                        {rowData?.hooked_to?.equipment_gps?.updated_at
                          ? convertToCustomerTime(rowData?.hooked_to?.equipment_gps?.updated_at, timeFormat)
                          : ''}
                        <br />
                        {rowData?.hooked_to?.equipment_gps?.updated_at
                          ? convertToCustomerTime(rowData?.hooked_to?.equipment_gps?.updated_at, dateFormat)
                          : ''}
                      </p>
                    ) : (
                      <p className='current-location' style={{ color: use(palette.dark800, palette.gray200) }}>
                        {rowData?.hooked_to?.last_known_update
                          ? convertToCustomerTime(rowData?.rowData?.hooked_to?.last_known_update, timeFormat)
                          : ''}
                        <br />
                        {rowData?.hooked_to?.last_known_update
                          ? convertToCustomerTime(rowData?.hooked_to?.last_known_update, dateFormat)
                          : ''}
                      </p>
                    )
                  ) : rowData?.hooked_to?.location_target === 'Driver' ? (
                    rowData?.hooked_to?.drivers ? (
                      rowData?.hooked_to?.drivers?.map(() => (
                        <p className='current-location' style={{ color: use(palette.dark800, palette.gray200) }}>
                          {rowData?.hooked_to?.drivers?.map((v) => v?.driver_gps?.updated_at)[0]
                            ? convertToCustomerTime(
                                rowData?.hooked_to?.drivers?.map((v) => v?.driver_gps?.updated_at)[0],
                                timeFormat
                              )
                            : ''}
                          <br />
                          {rowData?.hooked_to?.drivers?.map((v) => v?.driver_gps?.updated_at)[0]
                            ? convertToCustomerTime(
                                rowData?.hooked_to?.drivers?.map((v) => v?.driver_gps?.updated_at)[0],
                                dateFormat
                              )
                            : ''}
                        </p>
                      ))
                    ) : (
                      <p className='current-location' style={{ color: use(palette.dark800, palette.gray200) }}>
                        {rowData?.hooked_to?.last_known_update
                          ? convertToCustomerTime(rowData?.hooked_to?.last_known_update, timeFormat)
                          : ''}
                        <br />
                        {rowData?.hooked_to?.last_known_update
                          ? convertToCustomerTime(rowData?.hooked_to?.last_known_update, dateFormat)
                          : ''}
                      </p>
                    )
                  ) : (
                    <div
                      className='flex-row d-flex fade-in-row'
                      key={id}
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      {rowData?.hooked_to?.last_known_update
                        ? convertToCustomerTime(rowData?.hooked_to?.last_known_update, timeFormat)
                        : ''}
                      <br />
                      {rowData?.last_known_update ? convertToCustomerTime(rowData?.last_known_update, dateFormat) : ''}
                    </div>
                  )
                ) : (
                  <div style={{ fontSize: '13px' }}>
                    {rowData?.location_target === 'Equipment' ? (
                      rowData?.equipment_gps?.updated_at ? (
                        <div
                          className='flex-row d-flex fade-in-row'
                          key={id}
                          style={{ color: use(palette.dark800, palette.gray200) }}
                        >
                          {convertToCustomerTime(rowData?.equipment_gps?.updated_at, timeFormat)}
                          <br />
                          {convertToCustomerTime(rowData?.equipment_gps?.updated_at, dateFormat)}
                        </div>
                      ) : (
                        ''
                      )
                    ) : (
                      rowData?.last_known_update && (
                        <div
                          className='flex-row d-flex fade-in-row'
                          key={id}
                          style={{ color: use(palette.dark800, palette.gray200) }}
                        >
                          {convertToCustomerTime(rowData?.last_known_update, timeFormat)}
                          <br />
                          {convertToCustomerTime(rowData?.last_known_update, dateFormat)}
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'vin',
        title: <EquipmentTableSort title='VIN' sortingQuery={sortingQuery} field='vin' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.vin}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },

      {
        field: 'make',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='MAKE' field='make' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.make}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'year',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='YEAR' field='year' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.year}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'axles',
        title: <EquipmentTableSort title='AXLES' sortingQuery={sortingQuery} field='axles' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.axles}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },

      {
        field: 'model',
        title: <EquipmentTableSort title='MODEL' sortingQuery={sortingQuery} fuel_percent field='model_id' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.model_id}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'plate',
        title: (
          <EquipmentTableSort
            sortingQuery={sortingQuery}
            title='PLATE'
            field='[license_plate_number]'
            nested_field='equipment_ownership'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.equipment_ownership?.license_plate_number}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'dimensions',
        title: <Typography variant='c3'>DIMENSIONS/PAYLOAD</Typography>,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              {rowData?.length ? (
                <div className='d-flex flex-column gap-1'>
                  <Typography variant='s3' style={{ color: palette.dark800, ...navLinkInnerClickableElement }}>
                    {rowData.length.length * 12} x {rowData.width || '-'} x {rowData.height || '-'} in
                  </Typography>
                  {rowData.cargo_maximum_capacity_weight && (
                    <Typography variant='s3' style={{ color: palette.dark800, ...navLinkInnerClickableElement }}>
                      Payload {formatNumber(rowData.cargo_maximum_capacity_weight, 0)}{' '}
                      {rowData.cargo_capacity_measuring_unit}
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography variant='s3' style={{ color: palette.dark800, ...navLinkInnerClickableElement }}>
                  -
                </Typography>
              )}
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'odometer',
        title: (
          <EquipmentTableSort
            title='ODOMETER'
            sortingQuery={sortingQuery}
            field='[obd_odometer_miles]'
            nested_field='equipment_connected_services_device'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            {rowData?.location_target === 'Vehicle' ? (
              <div className='mb-2' style={navLinkInnerClickableElement}>
                <span>
                  <LightTooltip title='No telematics equipped.' placement='right'>
                    <span> {Speed} </span>
                  </LightTooltip>
                </span>
              </div>
            ) : rowData?.odometer_unit === 'miles' ? (
              <div className='equipment-vehicle-columns'>
                {rowData?.hooked_to?.equipment_connected_services_device?.obd_odometer_miles ? (
                  <p
                    className='equipment-vehicle-columns-text'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    {format(rowData?.hooked_to?.equipment_connected_services_device?.obd_odometer_miles)}
                    <span className='ms-1'> {rowData?.odometer_unit}</span>
                  </p>
                ) : (
                  ''
                )}
              </div>
            ) : rowData?.odometer_unit === 'meter' ? (
              <div className='equipment-vehicle-columns'>
                <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                  {rowData?.hooked_to?.equipment_connected_services_device?.obd_odometer_meter}
                  {rowData?.odometer_unit}
                </p>
              </div>
            ) : null}
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'next_pm',
        title: <EquipmentTableSort title='NEXT PM' sortingQuery={sortingQuery} field='next_pm' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />;
        },
      },

      {
        field: 'lift_gate',
        title: <EquipmentTableSort title='LIFT GATE' sortingQuery={sortingQuery} field='lift_gate' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.lift_gate}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'join_date',
        title: <EquipmentTableSort title='JOIN DATE' sortingQuery={sortingQuery} field='created_at' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              {rowData?.created_at ? (
                <div>
                  <span
                    className='equipment-vehicle-columns-text'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    {convertToCustomerTime(rowData?.created_at, timeFormat)}
                  </span>
                  <p
                    className='equipment-vehicle-columns-text'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    {moment.utc(rowData?.created_at).format(dateFormat)}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'next_available_date',
        title: (
          <EquipmentTableSort title='NEXT AVAILABLE DATE' field='next_available_date' sortingQuery={sortingQuery} />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              {rowData?.next_available_date ? (
                <div>
                  <span
                    className='equipment-vehicle-columns-text'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    {rowData?.next_available_date
                      ? convertToCustomerTime(rowData?.next_available_date, timeFormat)
                      : ''}
                  </span>
                  <p
                    className='equipment-vehicle-columns-text'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    {convertToCustomerTime(rowData?.next_available_date, dateFormat)}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'next_available',
        title: (
          <EquipmentTableSort title='NEXT AVAILABLE' sortingQuery={sortingQuery} field='next_available_location' />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <span className='equipment-vehicle-columns-text' style={{ width: '60px', whiteSpace: 'normal' }}>
              {rowData?.next_available_location}
            </span>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'payload',
        title: <EquipmentTableSort title='PAYLOAD' sortingQuery={sortingQuery} field='cargo_maximum_capacity_weight' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.cargo_maximum_capacity_weight}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'cargo_onboard',
        title: <EquipmentTableSort title='CARGO ONBOARD' sortingQuery={sortingQuery} field='cargo_onboard' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.hooked_to?.equipment_status?.equipment_status === 'On Shipment' ? 'Yes' : 'No'}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'motion',
        title: <EquipmentTableSort title='MOTION' sortingQuery={sortingQuery} nested_field='gps' field='[motion]' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <div className='equipment-vehicle-columns'>
                {(() => {
                  const isVehicle = rowData?.location_target === 'Vehicle';
                  const hookedTo = rowData?.hooked_to;
                  const isEquipment = rowData?.location_target === 'Equipment';
                  const isDriver = rowData?.location_target === 'Driver';

                  if (isVehicle && hookedTo) {
                    if (hookedTo?.location_target === 'Equipment' && hookedTo?.equipment_gps?.motion?.motion) {
                      const motion = hookedTo?.equipment_gps?.motion;
                      return (
                        <>
                          <div>
                            <Chip
                              fontWeight={500}
                              fontSize='11px'
                              bgColor={motionColors[motion?.motion]?.bgColor}
                              labelColor={motionColors[motion?.motion]?.color}
                              style={{ textTransform: 'uppercase' }}
                              label={`${motion?.motion} ${formatSeconds(motion?.duration)}`}
                            />
                          </div>
                          <Typography variant='c3'>Updated {getUTCTimeDifference(motion?.updated_at)} ago </Typography>
                        </>
                      );
                    }

                    if (hookedTo?.location_target === 'Driver' && hookedTo?.drivers?.length) {
                      const driverMotion = hookedTo?.drivers?.[0]?.driver_gps?.motion;
                      if (driverMotion?.motion) {
                        return (
                          <>
                            <div>
                              <Chip
                                fontWeight={500}
                                fontSize='11px'
                                bgColor={motionColors[driverMotion.motion]?.bgColor}
                                labelColor={motionColors[driverMotion.motion]?.color}
                                style={{ textTransform: 'uppercase' }}
                                label={`${driverMotion?.motion} ${formatSeconds(driverMotion?.duration)}`}
                              />
                            </div>
                            <Typography variant='c3'>
                              Updated {getUTCTimeDifference(driverMotion?.updated_at)} ago
                            </Typography>
                          </>
                        );
                      }
                    }
                  }

                  if (isEquipment && rowData?.equipment_gps?.motion?.motion) {
                    const motion = rowData?.equipment_gps?.motion;
                    return (
                      <>
                        <div>
                          <Chip
                            fontWeight={500}
                            fontSize='11px'
                            bgColor={motionColors[motion.motion]?.bgColor}
                            labelColor={motionColors[motion.motion]?.color}
                            style={{ textTransform: 'uppercase' }}
                            label={`${motion?.motion} ${formatSeconds(motion?.duration)}`}
                          />
                        </div>
                        <Typography variant='c3'>Updated {getUTCTimeDifference(motion?.updated_at)} ago </Typography>
                      </>
                    );
                  }

                  if (isDriver && rowData?.drivers?.length) {
                    const driverMotion = rowData?.drivers?.[0]?.driver_gps?.motion;
                    if (driverMotion?.motion) {
                      return (
                        <>
                          <div>
                            <Chip
                              fontWeight={500}
                              fontSize='11px'
                              bgColor={motionColors[driverMotion.motion].bgColor}
                              labelColor={motionColors[driverMotion.motion].color}
                              style={{ textTransform: 'uppercase' }}
                              label={`${driverMotion.motion} ${formatSeconds(driverMotion.duration)}`}
                            />
                          </div>
                          <Typography variant='c3'>
                            Updated {getUTCTimeDifference(driverMotion?.updated_at)} ago
                          </Typography>
                        </>
                      );
                    }
                  }

                  return '–';
                })()}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'last_inspection',
        title: (
          <EquipmentTableSort
            title='LAST INSPECTION'
            field='[service_date_time]'
            nested_field='equipment_maintenance_record'
            sortingQuery={sortingQuery}
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              {rowData?.hooked_to?.equipment_maintenance_record?.service_date_time ? (
                <div>
                  <span
                    className='equipment-vehicle-columns-text'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    {convertToCustomerTime(
                      rowData?.hooked_to?.equipment_maintenance_record?.service_date_time,
                      timeFormat
                    )}
                  </span>
                  <p style={{ color: use(palette.dark800, palette.gray200) }}>
                    {moment.utc(rowData?.hooked_to?.equipment_maintenance_record?.service_date_time).format(dateFormat)}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'next_inspection',
        title: (
          <EquipmentTableSort
            title='NEXT INSPECTION'
            field='[dot_inspection_date]'
            nested_field='equipment_insurance'
            sortingQuery={sortingQuery}
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <div className='equipment-vehicle-columns'>
                {rowData?.equipment_insurance?.dot_inspection_date ? (
                  <div>
                    <p
                      className='equipment-vehicle-columns-text'
                      style={{ color: use(palette.dark800, palette.gray200) }}
                    >
                      {moment.utc(rowData?.equipment_insurance?.dot_inspection_date).format(dateFormat)}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'plate_expiration',
        title: (
          <EquipmentTableSort
            title='PLATE EXPIRATION'
            field='[license_plate_expire_date]'
            nested_field='equipment_ownership'
            sortingQuery={sortingQuery}
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              {rowData?.equipment_ownership?.license_plate_expire_date ? (
                <div>
                  <p
                    className='equipment-vehicle-columns-text'
                    style={{ color: use(palette.dark800, palette.gray200) }}
                  >
                    {moment.utc(rowData?.equipment_ownership?.license_plate_expire_date).format(dateFormat)}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'gross_vehicle_weight',
        title: (
          <EquipmentTableSort title='GROSS VEHICLE WEIGHT' field='gross_vehicle_weight' sortingQuery={sortingQuery} />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {rowData?.gross_vehicle_weight}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'insurance_expiration',
        title: (
          <EquipmentTableSort
            title='INSURANCE EXPIRATION'
            field='[physical_insurance_expire_date]'
            nested_field='equipment_insurance'
            sortingQuery={sortingQuery}
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: use(palette.dark800, palette.gray200) }}>
                {moment.utc(rowData?.equipment_insurance?.physical_insurance_expire_date).format(dateFormat)}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'connected_service',
        title: <ColumnHeader title='CONNECTED SERVICE' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <Typography variant='b2'>{rowData.equipment_connected_services_device ? 'Yes' : 'No'}</Typography>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'connected_service_serial_number',
        title: <ColumnHeader title='CONNECTED SERVICE - SERIAL NUMBER' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <Typography variant='b2'>{rowData.equipment_connected_services_device?.serial_id || '-'}</Typography>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
    ];
  }, [trailerData, tableSettingSwitch]);

  const [dragItem, setDragItem] = useState([]);

  function sortingQuery(field, sortBy, nested_field) {
    let urlQuery;
    if (nested_field) {
      urlQuery = `sort[${nested_field}]${field}=${sortBy}`;
    } else {
      urlQuery = `sort[][${field}]=${sortBy}`;
    }

    setSort(urlQuery);
  }

  const onPageChange = (page) => {
    const lastPage = trailerData.last_page;
    if (lastPage && lastPage !== 1) {
      setLoadingTab(true);
      GetTableEquipment({ data: filteredValues, page });
    }
  };

  const onChangeRowPerPage = (amount) => {
    setLoadingTab(true);
    setRowPerPage(amount);
    setPage(1);
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };
  const OnTableSettingChange = (value) => {
    if (value) {
      TableSetting();
    }
  };

  const PaginationComponent = () => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={trailerData.total}
        totalLength={trailerData.data.length}
        lastPage={trailerData.last_page}
        currentPage={trailerData.current_page}
        onPageChange={onPageChange}
        onChangeRowPerPage={onChangeRowPerPage}
      />
    );
  };

  const filterTableColumn = (columns) => {
    if (filter?.tableColumn) {
      const values = [];
      Object.keys(filter?.tableColumn).map((item) => filter?.tableColumn[item] === true && values.push(item));
      const cols = [];

      dragItem.forEach((value) => {
        const col = columns.find((c) => c?.field === value);
        cols.push({ ...col, hidden: !values.includes(col?.field) });
        cols.sort(
          (a, b) => Number(filter?.ColumnOrder.indexOf(a.field)) - Number(filter?.ColumnOrder.indexOf(b.field))
        );
      });
      return cols;
    }
  };

  async function trailersTab(query = '', preventLoading = false) {
    if (!preventLoading) {
      setLoadingTab(true);
    }
    if (query) {
      setQueryParameterData(query);
    } else {
      setQueryParameterData('');
    }

    if (query?.allFilterData) {
      Trailer({
        page,
        itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
        allFilterData: { ...query?.allFilterData, equipment_id_query: query?.allFilterData?.equipment_id || undefined },
      }).then((res) => {
        setCurrentPageIds(res?.data?.map((i) => i.id) || []);
        if (res && res.data && res.data.length) {
          setTrailerData(res);
        } else {
          setTrailerData({ data: [] });
        }

        setLoadingTab(false);
      });
    } else {
      Trailer({
        page,
        itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
        query,
      }).then((res) => {
        setCurrentPageIds(res?.data?.map((i) => i.id) || []);
        if (res && res.data && res.data.length) {
          setTrailerData(res);
        } else {
          setTrailerData({ data: [] });
        }

        setLoadingTab(false);
      });
    }
  }
  async function Tabs() {
    await axios.get(`${API_URL}/tabs?type=trailer`, { headers: authHeader() }).then((data) => {
      setTrailerTabData(data?.data?.data);
    });
  }
  const TabData = trailerTabData;
  async function TableSetting() {
    await axios
      .get(`${API_URL}/table-settings?type=trailer`, { headers: authHeader() })
      .then((data) => {
        SET_TYPES(data.data?.data?.data.columns);
        setColumnOrder(data.data?.data?.data?.columns_order);
        setTableSettingSwitch({
          date: data.data?.data?.data?.date,
          amount: data.data?.data?.data?.amount,
          time: data.data?.data?.data?.time,
        });
        setFilter({
          ...filter,
          tableColumn: data.data?.data?.data.columns,
          ColumnOrder: data.data?.data?.data?.columns_order,
          TableSetting: data.data,
          switchRadioButtons: {
            date: data.data?.data?.data?.date,
            amount: data.data?.data?.data?.amount,
            time: data.data?.data?.data?.time,
          },
        });
        setDragItem(Object.keys(data.data?.data?.data?.columns).map((t) => t));
      })
      .catch(() => {
        // Do nothing
      });
  }

  const format = (num) => {
    if (!num) return;
    const numArray = num.toString().split('').reverse();
    for (let i = 3; i < numArray.length; i += 4) {
      numArray.splice(i, 0, ',');
    }
    return numArray.reverse().join('');
  };
  const getFilterByEquipmentType = useCallback(async () => {
    const filterByEquipmentData = [];

    await Promise.all([getEquipmentRequiredTrailers()])
      .then(([vehicle]) => {
        if (vehicle && vehicle?.data) {
          vehicle.data.forEach((el) => {
            if (+el.id !== 0) {
              const filterByData = {
                title: el.title,
                id: el.id,
                value: 'equipment_types',
                type: 'checkbox',
                key: uuid(),
                typeData: 'vehicle',
              };
              filterByEquipmentData.push(filterByData);
            }
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
    setFilterByEquipmentTypeData(filterByEquipmentData);
  }, []);
  const getFilterByGroups = useCallback(() => {
    Groups()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              title: el.group_name,
              id: el.id,
              value: 'groups',
              type: 'checkbox',
              key: uuid(),
              typeData: 'groups',
            };
          });
          setFilterByGroupsData(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);

  useEffect(() => {
    getFilterByGroups();
    getFilterByEquipmentType().then();
  }, []);

  useEffect(() => {
    setTabName('trailer');
    Tabs();
    TableSetting();
    trailersTab(sort).catch(null);
  }, []);

  // useEffect(() => {
  //   const channelName = `private-equipmentList-Update.${userData?.user?.customer?.dot}`;
  //   const channel = PusherJs.subscribe(channelName);
  //   channel.bind('equipment-event', (data) => {
  //     if (filter.searchBy.value.search('location') > -1 || queryParameterData?.allFilterData?.radius) {
  //       return;
  //     }
  //     const updatedData = data?.message?.data;
  //     const currentVehicles = trailers?.current || [];
  //
  //     if (!updatedData) {
  //       return;
  //     }
  //
  //     const trailerIndex = currentVehicles.findIndex((item) => item.id === updatedData.id);
  //
  //     if (trailerIndex !== -1) {
  //       const newVehicles = currentVehicles.map((item, index) =>
  //         index !== trailerIndex
  //           ? item
  //           : {
  //               ...item,
  //               ...updatedData,
  //               equipment_group: updatedData.equipment_group ? updatedData.equipment_group : null,
  //               equipment_reserve: updatedData.equipment_reserve ? updatedData.equipment_reserve : null,
  //             }
  //       );
  //       setTrailerData((prevState) => ({ ...prevState, data: newVehicles }));
  //     }
  //   });
  //
  //   return () => {
  //     PusherJs.unsubscribe(channelName);
  //   };
  // }, []);

  useEffect(() => {
    if (trailerData.data) {
      trailers.current = trailerData.data;
    }
  }, [trailerData]);

  useEffect(() => {
    if (trailerData && (trailerData?.total || trailerData?.total === 0) && tab === 2) {
      getMapTabs()
        .then((res) => {
          if (res) {
            const data = filter.filterTableTop.map((i) => {
              if (i.key === 'trailer') {
                i.count = trailerData?.total;
              } else {
                i.count = res?.data?.[0]?.count || 0;
              }
              return i;
            });
            setSwitcherData(data);
            setFirstUpdate(false);
          }
        })
        .catch(() => {
          // Do nothing
        });
    }
  }, [trailerData, firstUpdate]);

  useEffect(() => {
    if (createdEquipmentId) {
      setFilter((prevState) => ({ ...prevState, searchValue: createdEquipmentId }));
      trailersTab({ allFilterData: { equipment_id: createdEquipmentId } });
      setShowHeaderFilters([
        {
          searchValue: createdEquipmentId,
          title: 'Equipment',
          valuePrefix: 'equipment_id',
        },
      ]);
    }
  }, [createdEquipmentId]);

  const onSubmitModal = () => {};
  const OnTabChange = (value, key, item) => {
    if (key === 'trailer') {
      filterTab(value, item);
    }
  };
  const filterTab = (tab, item) => {
    // const v = TabData.find((c) => c.id === tab && c.type === 'vehicle');
    if (tab !== 1) {
      let query = '';
      if (item?.data?.filters?.status) {
        if (!query) {
          query += `status_id=${item?.data?.filters?.status}`;
        } else {
          query += `&status_id=${item?.data?.filters?.status}`;
        }
      }
      if (item?.data?.filters?.equipment_type_id) {
        if (!query) {
          query += `equipment_type_id=${item?.data?.filters?.equipment_type_id}`;
        } else {
          query += `&equipment_type_id=${item?.data?.filters?.equipment_type_id}`;
        }
      }
      if (item?.data?.filters?.group_id) {
        if (!query) {
          query += `group_id=${item?.data?.filters?.group_id}`;
        } else {
          query += `&group_id=${item?.data?.filters?.group_id}`;
        }
      }

      trailersTab(query);
    }
  };
  const EditModalData = (item) => {
    filterTab(item?.data?.id, item?.data);
  };

  /** PAGINATION ROW_PER_PAGE ADVANCED FILTER AND FILTER WORKED HERE* */
  const controller = useRef(new AbortController());
  const [searchBy, setSearchBy] = useState(FILTER_SEARCH_BY_TRAILER[0]);
  const [filteredValues, setFilteredValues] = useState({});

  const onSearch = (data) => {
    const filterObject = data || filteredValues;
    GetTableEquipment({ data: filterObject }).catch();
  };
  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        if (key === 'ownership') {
          requestData[key] = value.map((el) => el.title).toString();
          requestData.ownership_type = value.map((el) => el.ownership_type).toString();
        } else {
          requestData[key] = value.map((el) => el.id).toString();
        }
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const GetTableEquipment = async ({ data = {}, page = 1 }) => {
    setLoadingTab(true);
    try {
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const requestData = convertFilterData(data);
      const options = {
        signal: controller?.current?.signal,
        page,
        query: sort,
        itemsPerPage: rowPerPage,
        allFilterData: requestData,
      };

      const res = await Trailer(options);
      res?.data ? setTrailerData(res) : setTrailerData({ data: [] });
      setPage(page);
    } catch (e) {
      // Do nothing
    } finally {
      setLoadingTab(false);
    }
  };

  useEffect(() => {
    if (didMountRef.current) {
      GetTableEquipment({ data: filteredValues }).catch();
    } else {
      didMountRef.current = true;
    }
  }, [sort, tableIndex, rowPerPage, filter]);
  /** PAGINATION ROW_PER_PAGE ADVANCED FILTER AND FILTER WORKED HERE* */

  return (
    <>
      <>
        <TrailerHeader
          onSearch={onSearch}
          filter={filteredValues}
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          setFilter={setFilteredValues}
          TYPES={TYPES}
          dragItem={dragItem}
          tablePopoverFilter={filter}
          updateTablePopoverFilter={updateFilter}
          OnTableSettingChange={OnTableSettingChange}
          onChangeOrder={(items) => setDragItem(items)}
        />
        <SwitchRadio
          name={`tableTopTabMenu ${trailerData?.total}`}
          items={switcherData || []}
          value={tab}
          type='tab'
          title='trailer'
          TabData={TabData}
          multiSelectOptions={multiSelectOptions}
          OnTabChange={OnTabChange}
          onChange={(n, v) => {
            setMainTab(+v);
          }}
          plus={false}
        />
        <div className='ms-4 mb-2 mt-0' style={{ height: '50px' }}>
          <SubTabs
            name={`tableTopTabMenu ${trailerData?.total}`}
            value={tab}
            type='tab'
            EditModalData={EditModalData}
            TabData={trailerTabData}
            Tabs={Tabs}
            multiSelectOptions={multiSelectOptions}
            OnTabChange={OnTabChange}
            onChange={(n, v) => setTab(+v)}
            plus
            onClickPlus={() => setShowModal(true)}
          />
        </div>

        {loadingTab ? (
          <Preloader />
        ) : (
          <div className='tableFixHead table-fixed-header-400'>
            <MaterialTableWrapper
              data={trailerData?.data}
              rowPerPage={rowPerPage}
              components={{
                Pagination: PaginationComponent,
              }}
              isLoading={loadingTab}
              columns={filterTableColumn(columns)}
              style={{ backgroundColor: use(palette.white, palette.dark800) }}
              onRowClick={(e, rowData) => {
                navigate(`/equipment-profile/trailer/${rowData?.id}`, { state: { tab: 'trailer' } });
              }}
            />
          </div>
        )}
      </>
      <CustomModal
        showModal={showModal}
        styleBody={{ width: 446 }}
        onHide={() => setShowModal(false)}
        headerTitle='Add Tab'
        buttons={[
          { key: 'close', type: 'secondary', title: 'Cancel' },
          {
            key: 'submit',
            type: 'primary',
            title: 'Add Tab',
            onClick: onSubmitModal,
          },
        ]}
      >
        <EquipmentTableModal
          Tabs={Tabs}
          data={modalSelectData}
          onHide={() => setShowModal(false)}
          // onChange={(modalData) => setModalSelectData(modalData)}
          multiSelectOptions={multiSelectOptions}
          onChange={(n, v) => setTab(+v)}
          OnTabChange={OnTabChange}
          currentType='Trailer Type'
        />
      </CustomModal>
      <Modal show={reserveEquipment} centered>
        <ReserveEquipment
          id={id}
          handleClose={ReserveEquipmentModalClose}
          ReserveEquipmentModalClose={ReserveEquipmentModalClose}
          toggleShowA={(id) => toggleShowA(id)}
          GetreserveMsgData={GetreserveMsgData}
          onSuccess={() => setTableIndex(Date.now())}
        />
      </Modal>
      <Modal show={hook} centered>
        {/* <h1>zkcsm,x</h1> */}
        <AddHook
          id={id}
          handleClose={hookModalClose}
          SuccessShow={SuccessShow}
          SuccessClose={SuccessClose}
          HookedErrClose={HookedErrClose}
          HookedErrShow={HookedErrShow}
          GetHookedError={GetHookedError}
          onSuccess={() => setTableIndex(Date.now())}
        />
      </Modal>
      <Modal show={delDriver} onHide={handleClose} centered className='remove-driver-dialogue-modal'>
        <RemoveDriver
          RemoveDriverData={removeDriverData}
          deleteDriverModalClose={deleteDriverModalClose}
          RemoveDriverModal={RemoveDriverModal}
          RemoveDriverModalClose={RemoveDriverModalClose}
          onSuccess={() => setTableIndex(Date.now())}
        />
      </Modal>
      <Modal show={addGroup} onHide={addGroupModalClose} centered>
        <AddGroup
          handleClose={addGroupModalClose}
          id={id}
          AddGroupSuccessModal={(id) => AddGroupSuccessModal(id)}
          onSuccess={() => setTableIndex(Date.now())}
        />
      </Modal>
      <Popover
        id={Popup}
        anchorPosition={{ top: 200, left: 400 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'Bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DriverInfo driverData={driverPopupData} PopupHandleClose={handleClose} deleteDriverModal={deleteDriverModal} />
      </Popover>
      <Modal show={unHooked} onHide={UnHookedModalClose} centered>
        <UnHooked
          handleClose={UnHookedModalClose}
          unHookedData={unHookedData}
          UnHoookedMsgShow={(id) => UnHoookedMsgShow(id)}
          HookedErrClose={HookedErrClose}
          HookedErrShow={HookedErrShow}
          UnhookedMsgClose={UnhookedMsgClose}
          GetHookedError={GetHookedError}
          onSuccess={() => setTableIndex(Date.now())}
        />
      </Modal>
      <Modal show={unReseverdEq} onHide={UnReservedModalClose} centered>
        <UnresevedEquipment
          handleClose={UnReservedModalClose}
          unReseverdEqId={unReseverdEqId}
          UnReserveSuccessShow={(id) => UnReserveSuccessShow(id)}
          UnReserveSuccessClose={UnReserveSuccessClose}
          onSuccess={() => setTableIndex(Date.now())}
        />
      </Modal>

      <Toast show={Hookederr} onClose={HookedErrClose} className='equipment-err-msg'>
        {/* <div className="errText">{getHookederr}</div> */}.
        <AddHookedError getHookederr={getHookederr} handleClose={HookedErrClose} />
      </Toast>

      <Toast show={showA} onClose={toggleCloseA} className='reserve-equipment-toast'>
        <ReserveEquipmentMsg
          handleClose={toggleCloseA}
          reserveMsgData={reserveMsgData}
          userFname={user.first_name}
          userLname={user.last_name}
        />
      </Toast>
      <Toast show={unreserveSuccess} onClose={UnReserveSuccessClose} className='equipment-success-msg'>
        <div>
          <UnreservedEquipmentmsg handleClose={UnReserveSuccessClose} />
        </div>
      </Toast>
      <Toast show={success} onClose={SuccessClose} className='equipment-success-msg'>
        <AddHookedSucsss handleClose={SuccessClose} />
      </Toast>
      <Toast show={driverAddedmsg} onClose={DriversAddedmsgModalClose} className='equipment-success-msg'>
        <div>
          <AddDriverSuccess DriversAddedmsgModalClose={DriversAddedmsgModalClose} />
        </div>
      </Toast>
      <Toast show={removeDrivermsg} onClose={RemoveDriverModalClose} className='equipment-success-msg'>
        <div>
          <RemovedDriverMsg RemoveDriverModalClose={RemoveDriverModalClose} />
        </div>
      </Toast>
      <Toast show={unHookedMsg} onClose={UnhookedMsgClose} className='equipment-success-msg'>
        <div>
          <UnHookedVehiclemsg handleClose={UnhookedMsgClose} UnhookedMsgClose={UnhookedMsgClose} />
        </div>
      </Toast>
      <Modal show={removeGroup} onHide={RemoveGroupModalClose} centered>
        <RemoveGroup
          handleClose={RemoveGroupModalClose}
          RemoveGroupData={RemoveGroupData}
          RemoveGroupSuccessModal={RemoveGroupSuccessModal}
        />
      </Modal>
      <Toast show={addGroupSuccess} onClose={AddGroupSuccessClose} className='equipment-success-msg'>
        <div>
          <AddGroupSuccess AddGroupSuccessClose={AddGroupSuccessClose} />
        </div>
      </Toast>
      <Toast show={removeGroupSuccess} onClose={RemoveGroupSuccessModalClose} className='equipment-success-msg'>
        <div>
          <RemoveGroupSuccess RemoveGroupSuccessModalClose={RemoveGroupSuccessModalClose} />
        </div>
      </Toast>
      <Toast show={alreadyReserveMsg} onClose={AlreadyReserveMsgModalClose} className='already-reserve-equipment-toast'>
        <div>
          <AlreadyReserved
            alreadyReserveid={alreadyReserveid}
            AlreadyReserveMsgModalClose={AlreadyReserveMsgModalClose}
          />
        </div>
      </Toast>
      <Popover
        id={ProfileAlertContent}
        // anchorPosition={{ top: 299, left: 669 }}
        open={ProfileAlertOpen}
        anchorEl={profileAlert}
        onClose={ProfileAlerthandleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '20px' }}
      >
        <AlertMsg alertData={alertData} alertID={alertId} equipmentAlert />
      </Popover>
    </>
  );
};

export default TrailerTable;
