import React, { useContext, useEffect, useState } from 'react';
import { ErrorMessage, Field } from 'formik';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as EditIcon } from 'assets/icons/createShipment/edit.svg';
import { ReactComponent as RefreshIcon } from 'assets/icons/createShipment/recurringLane.svg';
import Error from 'common/ErrorMessage';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { ShipmentContext } from 'components/CreateShipment/Layout';
import CustomInput from 'components/CreateShipment/helpers/CustomInput';
import { numberWithCommas, palette } from 'utils/constants';
import sm from 'components/CreateShipment/BillingCharges/BillingCharges.module.css';
import styles from 'components/TablePlaner/helpers/RecurrningDetails/steps/BillingCharges/billingCharges.module.css';

const chargesStyles = {
  addAnotherTitle: {
    fontSize: 14,
    color: palette.indigo500,
    marginLeft: 8,
  },
  addAnotherButton: {
    padding: '7px 12px',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    paddingLeft: 0,
  },
  editIcon: {
    height: 15,
    cursor: 'pointer',
  },
  isSearchableStyles: {
    width: 400,
    borderRadius: 6,
  },
};

const BillingCharge = ({
  charge,
  index,
  values,
  setFieldValue,
  onSaveCharge,
  onEditCharge,
  onDeleteCharge,
  currencyData,
  chargeErrors,
  setChargeErrors,
}) => {
  const { selectedCharges, shipmentChargeType, fullPrice, totalData, billToData, createShipmentData } =
    useContext(ShipmentContext);
  const [recommendationApplied, setRecommendationApplied] = useState({ linehaul: false, fuelSurcharge: false });

  const SELECTED_CHARGES = selectedCharges[0];

  const namePrefix = `billingCharges[${index}]`;
  const savedCharge = SELECTED_CHARGES?.find((item) => item.id === charge.id);
  const selectInput = shipmentChargeType.find((el) => el.key === (Number(charge.charges?.id) || +charge.charges));
  const selectValue =
    typeof values.billingCharges[index].charges === 'object' && values.billingCharges[index].charges !== null
      ? values.billingCharges[index].charges || null
      : (shipmentChargeType || []).find((i) => i.id === Number(values.billingCharges[index].charges));

  const showIconCondition =
    (Number(charge.charges?.id) === 1 && recommendationApplied.linehaul) ||
    (Number(charge.charges?.id) === 2 && recommendationApplied.fuelSurcharge);
  const type = Number(charge.charges?.id) === 1 ? 'lineHaul' : Number(charge.charges?.id) === 2 ? 'fuel' : null;

  const renderTooltip = (props, type) => {
    return (
      <Tooltip id='button-tooltip' {...props}>
        <div>
          {type === 'lineHaul'
            ? 'Applied rules from Linehaul Rate Matrix'
            : 'Applied rules from Fuel Surcharge Rate Matrix'}
        </div>
      </Tooltip>
    );
  };

  const validateRequired = (value) => {
    let error;
    if (value === '') {
      error = 'Required';
    }
    return error;
  };

  const checkRecommendation = (formValues) => {
    const charge = formValues.billingCharges?.[index];
    const selectedCustomer = billToData.find((customer) => Number(customer.id) === Number(formValues.customer_id));
    const { customer_linehaul_rate_matrix, customer_fuel_surcharge_matrices } = selectedCustomer || {};

    if (!selectedCustomer || (!customer_fuel_surcharge_matrices?.length && !customer_linehaul_rate_matrix?.length)) {
      setRecommendationApplied({ linehaul: false, fuelSurcharge: false });

      if (
        Number(createShipmentData?.shipment_details_type) !== 3 &&
        (Number(charge?.charges?.id) === 1 || Number(charge?.charges?.id) === 2)
      ) {
        setFieldValue(`billingCharges[${index}].rate`, '');
        setFieldValue(`billingCharges[${index}].qty`, '');
      }

      return;
    }

    if (customer_linehaul_rate_matrix?.length) {
      const miles = totalData?.miles;

      if (!!charge.qty && !!charge.rate && Number(charge?.charges?.id) !== 1) {
        setRecommendationApplied({ linehaul: false, fuelSurcharge: false });
      }

      if (Number(charge?.charges?.id) === 1 && !charge.qty && !charge.rate) {
        const matchingLinehaulMatrix = customer_linehaul_rate_matrix.find((el) => {
          const start = el?.loaded_miles_start;
          const end = el?.loaded_miles_end;
          return Number(miles) > Number(start) && Number(miles) < Number(end);
        });

        if (matchingLinehaulMatrix?.rate_per_mile) {
          setFieldValue(
            `billingCharges[${index}].qty`,
            matchingLinehaulMatrix?.rate_type === 'flat' ? 1 : Math.round(miles)
          );
          setFieldValue(`billingCharges[${index}].rate`, matchingLinehaulMatrix.rate_per_mile);

          setRecommendationApplied((prevState) => ({ ...prevState, linehaul: true }));
        }
      }
    }

    if (customer_fuel_surcharge_matrices?.length) {
      const miles = totalData?.miles;
      const price = fullPrice?.diesel?.price;

      if (!!charge.qty && !!charge.rate && Number(charge?.charges?.id) !== 2) {
        setRecommendationApplied({ linehaul: false, fuelSurcharge: false });
      }

      if (Number(charge?.charges?.id) === 2 && !charge.qty && !charge.rate) {
        const matchingFuelMatrix = customer_fuel_surcharge_matrices.find((el) => {
          const { from, to } = el;
          return Number(price) > Number(from) && Number(price) < Number(to);
        });

        if (matchingFuelMatrix?.fsc) {
          setFieldValue(`billingCharges[${index}].qty`, Math.round(miles));
          setFieldValue(`billingCharges[${index}].rate`, matchingFuelMatrix.fsc);
          setRecommendationApplied((prevState) => ({ ...prevState, fuelSurcharge: true }));
        }
      }
    }
  };

  const resetRecommendation = (charge) => {
    if (Number(charge?.charges?.id === 1) || Number(charge?.charges?.id === 2)) {
      setRecommendationApplied((prevState) => ({
        linehaul: Number(charge?.charges?.id) === 1 && prevState.linehaul ? false : prevState.linehaul,
        fuelSurcharge: Number(charge?.charges?.id) === 2 && prevState.fuelSurcharge ? false : prevState.fuelSurcharge,
      }));
    }
  };

  useEffect(() => {
    if (!values?.customer_id || !billToData?.length) {
      return;
    }

    checkRecommendation(values);
  }, [values?.customer_id, billToData?.length]);

  if (savedCharge) {
    return (
      <div key={namePrefix} className={sm.charge_wrapper}>
        <div className={sm.charge_refreshIcon_wrapper}>
          <Typography variant='s2'>{selectInput?.label}</Typography>
          {showIconCondition ? (
            <OverlayTrigger
              placement='bottom'
              overlay={(props) => {
                return renderTooltip(props, type);
              }}
            >
              <RefreshIcon fill={palette.green400} style={{ marginLeft: 8 }} />
            </OverlayTrigger>
          ) : (
            <div style={{ width: 24 }} />
          )}
        </div>
        <Typography variant='s2' style={{ width: 80 }}>
          {charge?.qty}
        </Typography>
        <Typography variant='s2' style={{ width: 160 }}>
          {charge?.charges?.type?.account_type === 'Expense' ? '-' : ''} {charge?.rate}
        </Typography>
        <Typography variant='s2' style={{ width: 160 }}>
          {`${charge?.charges?.type?.account_type === 'Expense' ? '-' : ''} ${currencyData?.symbol || '$'}`}
          {numberWithCommas((charge.qty * charge.rate).toFixed(2))}
        </Typography>
        <Typography variant='s2' style={{ width: 120, display: 'flex', justifyContent: 'flex-end' }}>
          <EditIcon style={chargesStyles.editIcon} onClick={() => onEditCharge(charge)} />
        </Typography>
      </div>
    );
  }

  return (
    <div key={charge.id} className={sm.charge_container}>
      <div className={sm.charge_wrapper}>
        <div className='d-flex align-items-center gap-2'>
          <div>
            <Autocomplete
              width='424px'
              name={`${namePrefix}.charges`}
              labelKey='title'
              options={shipmentChargeType || []}
              value={selectValue || null}
              onChange={(e, val) => {
                setFieldValue(`${namePrefix}.charges`, val);
                setFieldValue(`${namePrefix}.qty`, '');
                setFieldValue(`${namePrefix}.rate`, '');
                checkRecommendation(
                  {
                    ...values,
                    billingCharges: values.billingCharges.map((item, i) =>
                      i === index ? { ...item, charges: val, qty: '', rate: '' } : item
                    ),
                  },
                  setFieldValue
                );
                setChargeErrors((prevState) => ({ ...prevState, [index]: false }));
              }}
              isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
            />
            <Error error={!!chargeErrors?.[index] && 'Required'} />
          </div>
          {showIconCondition ? (
            <OverlayTrigger
              placement='bottom'
              overlay={(props) => {
                return renderTooltip(props, type);
              }}
            >
              <RefreshIcon fill={palette.green400} style={{ marginLeft: 8 }} />
            </OverlayTrigger>
          ) : (
            <div style={{ width: 24 }} />
          )}
        </div>

        <div className='charge_input_wrapper'>
          <Field
            name={`${namePrefix}.qty`}
            type='number'
            component={CustomInput}
            labelStyle={{ margin: 0 }}
            validate={validateRequired}
            onChange={() => resetRecommendation(charge)}
            value={values.billingCharges?.[index].qty}
            style={{
              width: 80,
              borderRadius: 6,
              height: 32,
              padding: '0 6px',
            }}
          />
          <ErrorMessage
            name={`${namePrefix}.qty`}
            render={(error) => (
              <Typography variant='c2' style={{ color: palette.red500 }}>
                {error}
              </Typography>
            )}
          />
        </div>

        <div className={styles.charge_input_wrapper}>
          <Field
            name={`${namePrefix}.rate`}
            // fixed={2}
            type='number'
            placeholder='0.00'
            component={CustomInput}
            labelStyle={{ margin: 0 }}
            validate={validateRequired}
            onChange={() => resetRecommendation(charge)}
            style={{ width: 160, borderRadius: 6, height: 32 }}
            leftIcon={
              <span style={{ color: palette.gray400 }}>
                {`${currencyData?.symbol || '$'} ${charge?.charges?.type?.account_type === 'Expense' ? '-' : ''}`}
              </span>
            }
          />
          <ErrorMessage
            name={`${namePrefix}.rate`}
            render={(error) => (
              <Typography variant='c2' style={{ color: palette.red500 }}>
                {error}
              </Typography>
            )}
          />
        </div>

        <div className={sm.subTotal_wrapper}>
          <Typography variant='s2'>
            {`${charge?.charges?.type?.account_type === 'Expense' ? '-' : ''} ${currencyData?.symbol || '$'}`}
            {numberWithCommas(((charge?.qty ? charge.qty : 0) * (charge?.rate ? charge.rate : 0)).toFixed(2))}
          </Typography>
        </div>

        <div className={sm.control_buttons}>
          <CustomButton
            type='secondary'
            leftIcon={<DeleteIcon fill={palette.gray700} style={{ width: 9, height: 9 }} />}
            styleButton={{ padding: '7px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => onDeleteCharge()}
          />

          <CustomButton
            type='primary'
            leftIcon={<TickIcon fill={palette.white} style={{ width: 9, height: 9 }} />}
            styleButton={{ padding: '7px 12px' }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => onSaveCharge()}
          />
        </div>
      </div>
      <div className={sm.line} style={{ backgroundColor: palette.gray50 }} />
    </div>
  );
};

export default BillingCharge;
