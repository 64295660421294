import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { Skeleton } from '@material-ui/lab';
import { useDispatch } from 'react-redux';

import license from 'assets/icons/drivers/license.svg';
import licenseDark from 'assets/icons/drivers/licenseDark.svg';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { deleteShipmentDocument, getDocsFiles, getDocumentRequired } from 'Api/Shipment';
import OverLayDocumentsItem from 'components/TablePlaner/helpers/RecurrningDetails/helpers/OverLayDocumentsItem';

import { ReactComponent as ArrowUp } from 'assets/icons/arrowUp.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDown.svg';
import { ReactComponent as FailedIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warningOutline.svg';
import AddDocument from 'componentsV2/Shipment/AddDocument';
import { handleToaster } from 'store/reducers/root.reducer';
import styles from './documents.module.css';
import ViewPdf from '../../../../ViewPdf';

const Documents = ({ infoHeader }) => {
  const refFigure = useRef(null);
  const { use } = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [docsFilesList, setDocsFilesList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [nameTop, setNameTop] = useState(false);
  const [typeTop, setTypeTop] = useState(false);
  const [docRequired, setSocRequired] = useState([]);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [loadingRequired, setLoadingRequired] = useState(false);
  const [loadingFilesList, setLoadingFilesList] = useState(false);

  const getDocRequired = () => {
    setLoadingRequired(true);
    getDocumentRequired(id)
      .then((res) => setSocRequired(res?.data))
      .catch(() => {
        // Do nothing
      })
      .finally(() => setLoadingRequired(false));
  };

  const getFilesList = () => {
    setLoadingFilesList(true);
    getDocsFiles(id)
      .then((res) => res?.data && setDocsFilesList(res?.data))
      .catch(() => {
        // Do nothing
      })
      .finally(() => setLoadingFilesList(false));
  };

  const sortBy = (type) => {
    let sortedName = [];
    if (type === 'name') {
      !nameTop
        ? (sortedName = docsFilesList.sort((a, b) => (a.reference_id < b.reference_id ? -1 : 1)))
        : (sortedName = docsFilesList.sort((a, b) => (a.reference_id > b.reference_id ? -1 : 1)));
      setDocsFilesList([...sortedName]);
      setNameTop((prev) => !prev);
    } else {
      !typeTop
        ? (sortedName = docsFilesList.sort((a, b) => (a.type?.name < b.type?.name ? -1 : 1)))
        : (sortedName = docsFilesList.sort((a, b) => (a.type?.name > b.type?.name ? -1 : 1)));
      setDocsFilesList([...sortedName]);
      setTypeTop((prev) => !prev);
    }
  };

  const onClickBlock = (doc) => {
    setSelectedFiles(doc);
  };

  const onDeleteItem = (e, doc) => {
    if (doc?.id) {
      deleteShipmentDocument({ id: doc?.id })
        .then((res) => {
          if (res?.success) {
            dispatch(handleToaster({ message: 'Document deleted successfully!', visible: true, type: 'success' }));
            getDocRequired();
            getFilesList();
          }
        })
        .catch(() => {
          // Do nothing
        });
    }
  };

  const onDocumentCreateSuccess = () => {
    getDocRequired();
    getFilesList();
  };

  useEffect(() => {
    getDocRequired();
    getFilesList();
  }, []);

  return (
    <div className={classNames(styles.container, use(styles.light, styles.dark))}>
      <div className={styles.documents_block}>
        {loadingRequired ? (
          <div className={styles.skeletonWrapper}>
            <Skeleton style={{ width: '20%' }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: '30%' }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
            <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
          </div>
        ) : (
          <>
            <div className={styles.documents_wrapper}>
              <Typography variant='h2'>Documents</Typography>
              <CustomButton
                type='secondary'
                title='Add Document'
                styleButton={{ margin: 0 }}
                onClick={() => setIsOpen(true)}
                leftIcon={<PlusIcon fill={use(palette.gray700, palette.gray50)} style={{ marginRight: 10 }} />}
              />
            </div>

            <div className={styles.documents_data}>
              <div className={styles.sorting_wrapper}>
                <div onClick={() => sortBy('name')} className={styles.sort_item}>
                  <Typography variant='overLine'>REFERENCE ID</Typography>
                  <div className={styles.arrows_wrapper}>
                    <ArrowUp fill={nameTop ? palette.indigo500 : palette.gray200} />
                    <ArrowDown fill={!nameTop ? palette.indigo500 : palette.gray200} />
                  </div>
                </div>
                <div className={styles.sort_item}>
                  <Typography variant='overLine'>CUSTOMER</Typography>
                </div>
                <div className={styles.sort_item}>
                  <Typography variant='overLine'>SHIPMENT STOP</Typography>
                </div>
                <div onClick={() => sortBy('type')} className={styles.sort_item}>
                  <Typography variant='overLine'>TYPE</Typography>
                  <div className={styles.arrows_wrapper}>
                    <ArrowUp fill={typeTop ? palette.indigo500 : palette.gray200} />
                    <ArrowDown fill={!typeTop ? palette.indigo500 : palette.gray200} />
                  </div>
                </div>
                <div style={{ width: '40px' }} />
              </div>

              <div className={styles.files_wrapper}>
                {docsFilesList.map((doc) => {
                  return (
                    <OverLayDocumentsItem
                      key={doc}
                      doc={doc}
                      infoHeader={infoHeader}
                      secondParamKey='doc_type'
                      onClickBlock={onClickBlock}
                      onDelete={onDeleteItem}
                      setAttachmentUrl={setAttachmentUrl}
                      onSubmitEdit={() => {
                        getDocRequired();
                        getFilesList();
                      }}
                    />
                  );
                })}
              </div>
            </div>
          </>
        )}
        {docRequired.length > 0 &&
          (loadingFilesList ? (
            <div className={styles.skeletonWrapper}>
              <Skeleton style={{ width: '20%' }} className='mt-3 me-5 ' />
              <Skeleton style={{ width: '30%' }} className='mt-3 me-5 ' />
              <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
              <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
              <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
              <Skeleton style={{ width: '100%' }} className='mt-3 me-5 ' />
            </div>
          ) : (
            <div className={styles.required_document}>
              <div className={styles.documents_wrapper_types}>
                <Typography variant='h2'>Required Documents</Typography>
                <div className={styles.subTitle_types}>
                  <WarningIcon />
                  <Typography variant='b2' style={{ color: palette.orange500 }}>
                    These documents are required by customer.
                  </Typography>
                </div>
              </div>

              <div className={styles.documents_data}>
                <div className={styles.sorting_wrapper_bottom}>
                  <Typography variant='overLine'>DOCUMENT TYPE</Typography>
                  <Typography variant='overLine'>CUSTOMER</Typography>
                  <Typography variant='overLine'>RECEIVED</Typography>
                </div>

                <div className={styles.files_wrapper}>
                  {docRequired.map((el) => {
                    const { type, customer } = el || {};
                    const founded = docsFilesList.find(
                      (doc) =>
                        Number(doc?.customer_id) === Number(el?.customer_id) &&
                        Number(el?.type_id) === Number(doc?.doc_type?.id)
                    );
                    const icon = founded ? <TickIcon fill={palette.green500} /> : <FailedIcon fill={palette.red500} />;

                    return (
                      <div key={el.id} className={styles.required_item}>
                        <Typography variant='s2'>{type}</Typography>
                        <Typography variant='s2'>{customer}</Typography>
                        <div>{icon}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ))}
      </div>

      {/* //License Block */}
      <div className={styles.iframe_wrapper}>
        <figure ref={refFigure} style={{ width: 430, height: 580 }}>
          <iframe
            onLoad={() => {
              if (selectedFiles?.path) {
                refFigure.current.style.width = '100%';
                refFigure.current.style.height = '100%';
                refFigure.current.style.margin = '0';
              }
            }}
            src={selectedFiles?.path ? selectedFiles?.path : use(license, licenseDark)}
            style={{ height: '100%', width: '100%', borderRadius: 8 }}
            title='license'
          />
        </figure>
      </div>

      <ViewPdf
        title='View Attachment'
        open={!!attachmentUrl}
        onClose={() => setAttachmentUrl('')}
        pdfUrl={attachmentUrl}
      />

      {isOpen && (
        <AddDocument
          open={isOpen}
          onClose={() => setIsOpen(false)}
          shipment={infoHeader}
          onSuccess={onDocumentCreateSuccess}
        />
      )}
    </div>
  );
};

export default Documents;
