import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Tab from '@mui/material/Tab';
import { ReactComponent as DollarIcon } from 'assets/icons/createShipment/dollar.svg';
import { Field, Form, Formik } from 'formik';
import Chip from 'common/Chip';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { planMapper } from 'components/Billing/PlanCard/PlanCard.data';
import { formatNumber, numberWithCommas, palette } from 'utils/constants';
import ViewPdf from 'components/ViewPdf';
import styles from './PurchaseAddOnsModal.module.css';
import { STabs } from './PurchaseAddOnsModal.styles';

const PurchaseAddOnsModal = ({ onClosePurchaseAddons, purchaseAddons, onPurchase, purchasedAddons }) => {
  const handleSubmit = () => {};

  return (
    <Formik onSubmit={handleSubmit} enableReinitialize initialValues={{}}>
      {(props) => {
        return (
          <PurchaseAddOnsForm
            {...props}
            purchaseAddons={purchaseAddons}
            onClosePurchaseAddons={onClosePurchaseAddons}
            onPurchase={onPurchase}
            purchasedAddons={purchasedAddons}
          />
        );
      }}
    </Formik>
  );
};

function PurchaseAddOnsForm({
  values,
  onClosePurchaseAddons,
  setFieldValue,
  purchaseAddons,
  onPurchase,
  purchasedAddons,
}) {
  const [activeId, setActiveId] = useState(null);
  const [tab, setTab] = useState('All');
  const [categories, setCategories] = useState([]);
  const { billingDetail } = useSelector((state) => state.billing);
  const [viewGuideModal, setViewGuideModal] = useState(null);
  const duration = billingDetail?.customer_plan?.plan?.duration;

  const handleTabChange = (e, value) => {
    setTab(value);
  };

  const onViewGuide = (addon) => {
    if (addon.guide_type === 'pdf') {
      setViewGuideModal(addon.guide);
      return;
    }

    window.open(addon.guide, '_blank');
  };

  // const purchasedAddonsList = purchasedAddons.reduce((acc, addon) => {
  //   if (addon.status !== 'Cancelled' && addon.addon_plan?.addon?.bill_per === '4') {
  //     acc.push(addon.addon_plan?.addon_id);
  //   }
  //
  //   return acc;
  // }, []);

  const purchasedAddonPlansList = purchasedAddons.reduce((acc, addon) => {
    if (addon.status !== 'Cancelled' && addon.addon_plan?.addon?.bill_per === '4') {
      acc.push(addon.addon_plan.id);
    }

    return acc;
  }, []);

  useEffect(() => {
    const categories = purchaseAddons?.reduce(
      (acc, cur) => {
        if (!cur.category || acc.some((item) => item === cur.category)) {
          return acc;
        }
        return [...acc, cur.category];
      },
      ['All']
    );

    setCategories(categories);
  }, [purchaseAddons]);

  return (
    <>
      <Form>
        <div className={styles.body_wrapper}>
          <STabs value={tab} onChange={handleTabChange} variant='scrollable' visibleScrollbar dissableRipple>
            {categories.map((item) => (
              <Tab
                value={item}
                label={
                  <span style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
                    {item}{' '}
                    <Chip
                      label={
                        item === 'All'
                          ? purchaseAddons.length
                          : purchaseAddons?.filter((i) => i.category === item)?.length || 0
                      }
                      style={{ borderRadius: '12px' }}
                    />
                  </span>
                }
                key={item}
                disableRipple
              />
            ))}
          </STabs>
          {(tab === 'All' ? purchaseAddons : purchaseAddons.filter((i) => i.category === tab))?.map((item) => {
            return (
              <div key={item?.id} className={styles.item_wrapper}>
                <div className={styles.image_wrapper}>
                  <figure className={styles.figure}>
                    <img className={styles.image} src={item?.image} alt='Img' />
                  </figure>
                </div>
                <div className={styles.info_wrapper}>
                  <div className={styles.center}>
                    <div className={styles.title_subtitle}>
                      <Typography variant='h5' style={{ color: palette.gray900 }}>
                        {item?.name}
                      </Typography>
                      <Typography variant='b2'>{item?.description}</Typography>
                    </div>
                    <div className={styles.plans}>
                      {item?.plans?.map((plan) => {
                        return (
                          <div key={plan.id} className={styles.plan}>
                            {purchasedAddonPlansList.includes(plan.id) || Number(plan?.monthly_amount) === 0 ? (
                              <div className='d-flex align-items-center'>
                                <div className='d-flex justify-content-center' style={{ width: '16px' }}>
                                  {purchasedAddonPlansList.includes(plan.id) && Number(plan?.monthly_amount) !== 0 && (
                                    <DollarIcon fill={palette.green500} />
                                  )}
                                </div>
                                <Typography
                                  variant='s2'
                                  style={{
                                    color:
                                      purchasedAddonPlansList.includes(plan.id) && Number(plan?.monthly_amount) !== 0
                                        ? palette.green500
                                        : palette.gray700,
                                    marginLeft: 5,
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {plan?.plan_title},
                                  <Typography
                                    variant='s2'
                                    style={{
                                      color:
                                        purchasedAddonPlansList.includes(plan.id) && Number(plan?.monthly_amount) !== 0
                                          ? palette.green500
                                          : palette.gray500,
                                      marginLeft: 5,
                                      whiteSpace: 'nowrap',
                                    }}
                                  >
                                    {Number(plan?.monthly_amount) === 0
                                      ? `Included with plan`
                                      : `$${formatNumber(Number(plan?.monthly_amount))} ${
                                          planMapper[duration]?.addonBillTitle
                                        }`}{' '}
                                    {!!plan?.setup_one_time_fee &&
                                    plan?.setup_one_time_fee !== '0.00' &&
                                    plan?.setup_one_time_fee !== '0'
                                      ? `+ One Time Setup Fee $${numberWithCommas(
                                          Number(plan?.setup_one_time_fee).toFixed(2)
                                        )}`
                                      : ''}
                                  </Typography>
                                </Typography>
                              </div>
                            ) : (
                              <label className={styles.label}>
                                <Field name={`addon_plan_${plan?.id}`}>
                                  {({ field }) => {
                                    return (
                                      <CustomCheckbox
                                        field={field}
                                        checked={!!field.value}
                                        name={`addon_plan_${plan?.id}`}
                                        withinForm
                                        disabled={item.status === 0}
                                        checkmarkStyle={{ cursor: item.status === 0 ? 'not-allowed' : 'pointer' }}
                                        onChange={() => {
                                          setActiveId(plan?.id);
                                          Object.keys(values).forEach((key) => {
                                            `addon_plan_${plan?.id}` !== key
                                              ? setFieldValue(key, false)
                                              : setFieldValue(`addon_plan_${plan?.id}`, setActiveId(null));
                                          });
                                        }}
                                      >
                                        <Typography
                                          variant='s2'
                                          style={{
                                            color: palette.gray700,
                                            marginLeft: 5,
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          {plan?.plan_title},
                                          <Typography
                                            variant='s2'
                                            style={{
                                              color: palette.gray500,
                                              marginLeft: 5,
                                              whiteSpace: 'nowrap',
                                            }}
                                          >
                                            {Number(plan?.monthly_amount) === 0
                                              ? `Included with plan`
                                              : `$${formatNumber(Number(plan?.monthly_amount))} ${
                                                  planMapper[duration]?.addonBillTitle
                                                }`}{' '}
                                            {!!plan?.setup_one_time_fee &&
                                            plan?.setup_one_time_fee !== '0.00' &&
                                            plan?.setup_one_time_fee !== '0'
                                              ? `+ One Time Setup Fee $${numberWithCommas(
                                                  Number(plan?.setup_one_time_fee).toFixed(2)
                                                )}`
                                              : ''}
                                          </Typography>
                                        </Typography>
                                      </CustomCheckbox>
                                    );
                                  }}
                                </Field>
                              </label>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '8px',
                      alignItems: 'center',
                      minWidth: '110px',
                    }}
                  >
                    {(item.status === 0 ||
                      (item.assigned_type.id !== 4 &&
                        purchasedAddons.some((i) => i.addon_plan?.addon_id === item.id)) ||
                      item?.plans.find((el) => el?.id === activeId)) && (
                      <CustomButton
                        type={
                          !item?.plans.find((el) => el?.id === activeId) || item.status === 0 ? 'secondary' : 'primary'
                        }
                        title={
                          item.status === 0
                            ? 'NOT AVAILABLE'
                            : item.assigned_type.id !== 4 &&
                              purchasedAddons.some((i) => i.addon_plan?.addon_id === item.id)
                            ? 'Upgrade/Downgrade'
                            : 'Purchase'
                        }
                        disabled={!item?.plans.find((el) => el?.id === activeId) || item.status === 0}
                        styleButton={{ padding: '6px 12px', margin: 0 }}
                        styleTitle={{ fontSize: 14 }}
                        onClick={() =>
                          onPurchase({ ...item, activePlan: item?.plans.find((el) => el?.id === activeId) })
                        }
                      />
                    )}
                    {item.guide && (
                      <CustomButton
                        type='secondary'
                        title='View Guide'
                        styleButton={{ padding: '6px 12px', margin: 0 }}
                        styleTitle={{ fontSize: 14 }}
                        onClick={() => onViewGuide(item)}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className='customFooter' style={{ background: palette.white }}>
          <div className='footer_buttons'>
            <CustomButton
              type='secondary'
              onClick={onClosePurchaseAddons}
              title='Done'
              styleButton={{ margin: 10, padding: '6px 12px' }}
              styleTitle={{
                fontSize: 12,
                marginTop: 1,
              }}
            />
          </div>
        </div>
      </Form>
      <ViewPdf open={!!viewGuideModal} title='Guide' pdfUrl={viewGuideModal} onClose={() => setViewGuideModal(null)} />
    </>
  );
}

export default PurchaseAddOnsModal;
