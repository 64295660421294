import { object, string } from 'yup';

export const validationSchema = object().shape({
  length: object().when('equipment_type_id', {
    is: 3,
    then: object()
      .shape({ id: string().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  width: string().when('equipment_type_id', {
    is: 3,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  height: string().when('equipment_type_id', {
    is: 3,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
});
