import React from 'react';
import { useParams } from 'react-router-dom';
import Logs from 'componentsV2/Customer/EdiApi/Logs';
import Connections from 'componentsV2/Customer/EdiApi/Connections';
import DelayReasonCodes from 'componentsV2/Customer/EdiApi/DelayReasonCodes';
import RejectReasonCodes from 'componentsV2/Customer/EdiApi/RejectReasonCodes';

const DelayCodesTab = () => {
  const { id } = useParams();

  return (
    <div className='mb-4'>
      <DelayReasonCodes customerId={id} />
      <RejectReasonCodes customerId={id} />
      <Connections customerId={id} />
      <Logs customerId={id} />
    </div>
  );
};

export default DelayCodesTab;
