import { STOP_POINT_TAB } from 'utils/constants';

export const HEADER_STATUSES = [
  { title: 'ACTIVE', id: 1, color: '#14804A', background: '#E1FCEF' },
  { title: 'INACTIVE', id: 2, color: '#000000', background: '#E9EDF5' },
];

export const WALMART_TABS = [
  { title: 'General', id: 1, tab: STOP_POINT_TAB.GENERAL },
  { title: 'Contact Book', id: 2, tab: STOP_POINT_TAB.CONTACT_BOOK },
  { title: 'Working Hours', id: 3, tab: STOP_POINT_TAB.WORKING_HOURS },
  { title: 'History', id: 4, tab: STOP_POINT_TAB.EQUIPMENT_HISTORY },
  { title: 'Inventory', id: 5, tab: STOP_POINT_TAB.INVENTORY },
  { title: 'Average Wait Time', id: 6, tab: STOP_POINT_TAB.AVERAGE_WAIT_TIME },
  { title: 'Log', id: 7, tab: STOP_POINT_TAB.LOG },
];

export const OVERVIEW_STOPS_TOP_MODAL = [
  { id: 1, type: 'pickup', title: 'Pick Up' },
  { id: 2, type: 'delivery', title: 'Delivery' },
  { id: 3, type: 'waypoint', title: 'Waypoint' },
];
export const WEEK_DAYS = [
  { day: 'Monday', id: 0 },
  { day: 'Tuesday', id: 1 },
  { day: 'Wednesday', id: 2 },
  { day: 'Thursday', id: 3 },
  { day: 'Friday', id: 4 },
  { day: 'Saturday', id: 5 },
  { day: 'Sunday', id: 6 },
];
