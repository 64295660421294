import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Autocomplete from 'common/Autocomplete';
import CustomSelect from 'components/CreateShipment/ShipmentStops/helpers/CustomSelect';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import ErrorMessage from 'common/ErrorMessage';
import { getStops } from 'Api/Shipment';
import Uploader from 'common/Uploader';
import { validationSchema } from './validationSchema';
import styles from './AddDocumentModalContent.module.css';

const AddDocumentModalContent = ({ documentTypeList, shipmentTypeList, open, onClose, onSubmit, loading }) => {
  const [shipmentStops, setShipmentStops] = useState([]);

  const { values, handleChange, setValues, handleSubmit, touchedErrors } = useForm({
    initialValues: {
      document_type: '',
      shipment_id: '',
      shipment_stop: null,
      reference_id: '',
      uploadDocument: '',
      notes: '',
    },
    validationSchema,
    onSubmit,
  });

  const getShipmentStops = async () => {
    try {
      const { data } = await getStops({ shipment_id: values.shipment_id });
      setShipmentStops(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (values.shipment_id && values.shipment_id !== 'No Shipment') {
      getShipmentStops();
    }
  }, [values.shipment_id]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Add Document'
      className='modified-scrollbar'
      backdrop='static'
      $bgColor={palette.gray0}
      $maxWidth='446px'
      $minWidth='446px'
      padding='16px 0'
      styleButtons={{
        padding: '6px 12px',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        fontFamily: 'Inter',
        margin: '10px',
      }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Upload Document',
          onClick: handleSubmit,
          disabled: loading,
        },
      ]}
    >
      <div className={styles.container}>
        <div>
          <label className={styles.label_wrapper}>
            <span className={styles.label_required}>Reference</span>
            <input
              type='text'
              name='reference_id'
              id='reference_id'
              className='input-field'
              onChange={handleChange}
              value={values.reference_id}
            />
            <ErrorMessage error={touchedErrors.reference_id} />
          </label>
        </div>

        <div className='mt-4'>
          <label className={styles.label_wrapper}>
            <span className={styles.label_required}>Document Type</span>
          </label>
          <div>
            <CustomSelect
              defaultValue='Select...'
              options={documentTypeList.filter((el) => !el.hidden)}
              onChange={(value) => {
                handleChange('document_type', value.id);
              }}
              styles={{ height: 32, marginTop: 12 }}
              value={values.document_type}
              id='document_type'
              name='name'
              patchProperty='id'
            />
            <ErrorMessage error={touchedErrors.document_type} />
          </div>
        </div>

        <div className='mt-4 mb-4'>
          <label className={styles.label_wrapper}>
            <span className={styles.label_required}>Shipment ID</span>
          </label>
          <div>
            <CustomSelect
              isSearchable
              options={shipmentTypeList}
              onChange={(value) => {
                setValues((prevState) => ({
                  ...prevState,
                  shipment_id: value.shipment_id,
                  shipment_stop: null,
                }));
              }}
              styles={{ height: 32, marginTop: 12 }}
              dropDownStyles={{ flexGrow: 0, minWidth: '100%' }}
              isSearchableStyles={{ width: '100%', borderRadius: 6, marginTop: 12 }}
            />
            <ErrorMessage error={touchedErrors.shipment_id} />
          </div>
        </div>

        {!!values.shipment_id && values.shipment_id !== 'No Shipment' && (
          <div className='mt-4 mb-4'>
            <Autocomplete
              name='shipment_stop'
              label='Shipment Stop'
              options={shipmentStops}
              value={values.shipment_stop}
              onChange={(e, val) => handleChange('shipment_stop', val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {option.stop_point.location_name}
                </li>
              )}
              getOptionLabel={(option) => `${option.stop_point.location_name}`}
            />
          </div>
        )}

        <div className='mt-4'>
          <label className={styles.label_wrapper}>
            <span>Notes</span>
            <input
              type='text'
              name='notes'
              id='notes'
              className='input-field'
              onChange={handleChange}
              value={values.notes}
            />
          </label>
        </div>

        {/* <div className='mt-4 mb-4'> */}
        {/*  <div className='d-flex mb-1'> */}
        {/*    <Typography variant='s2' style={{ color: palette.gray700 }}> */}
        {/*      Upload Document{' '} */}
        {/*    </Typography> */}
        {/*    <Typography variant='s2' style={{ color: palette.indigo500 }}> */}
        {/*      * */}
        {/*    </Typography> */}
        {/*  </div> */}
        {/*  <div */}
        {/*    className='upload-document' */}
        {/*    style={{ */}
        {/*      backgroundColor: use(palette.white, palette.dark600), */}
        {/*      borderColor: use(palette.gray50, palette.darkborder), */}
        {/*      height: 150, */}
        {/*      width: '100%', */}
        {/*      margin: '0', */}
        {/*    }} */}
        {/*  > */}
        {/*    <label htmlFor='myFile' className={styles['drop-zone-style']}> */}
        {/*      <span className='upload-icon me-1'> */}
        {/*        <img src={uploadBig} alt='upload' /> */}
        {/*      </span> */}
        {/*      <br /> */}
        {/*      {values?.uploadDocument ? ( */}
        {/*        <p> */}
        {/*          <img src={right} alt='attachment' className='attachment-icon' /> */}
        {/*          <span className='text-style' style={{ color: '#38A06C' }}> */}
        {/*            File Uploaded */}
        {/*          </span> */}
        {/*          <img */}
        {/*            src={cross} */}
        {/*            alt='cross' */}
        {/*            className='upload-cancel-icon' */}
        {/*            onClick={(e) => resetUploadedDocument(e)} */}
        {/*          /> */}
        {/*        </p> */}
        {/*      ) : ( */}
        {/*        <p> */}
        {/*          <span style={{ color: '#4F5AED' }}>Upload document</span> or drag and drop */}
        {/*        </p> */}
        {/*      )} */}
        {/*    </label> */}
        {/*    <ImageDropZone */}
        {/*      onDrop={(file) => uploadDocument(file, 'uploadDocument')} */}
        {/*      width='100%' */}
        {/*      height='100%' */}
        {/*      name='small' */}
        {/*      deletable={false} */}
        {/*      accept={['application/pdf']} */}
        {/*      customStyle={{ */}
        {/*        position: 'absolute', */}
        {/*        width: '100%', */}
        {/*        height: '100%', */}
        {/*        border: '1px dashed #BCC2CE', */}
        {/*        borderRadius: '8px', */}
        {/*      }} */}
        {/*    /> */}
        {/*  </div> */}
        {/*  <ErrorMessage error={touchedErrors.uploadDocument} /> */}
        {/* </div> */}
        <div className='mt-4 mb-4'>
          <Uploader
            type={1}
            required
            label='Upload Document'
            document={values.uploadDocument}
            maxSize={5242880}
            showMaxSizeLabel
            accept={['application/pdf']}
            onDrop={(files, fileRejections) => {
              if (!fileRejections?.length) {
                handleChange('uploadDocument', files[0]);
              }
            }}
            onRemove={() => handleChange('uploadDocument', null)}
            error={touchedErrors.uploadDocument}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddDocumentModalContent;
