export const getInitialValues = (shipment) => {
  return {
    replace1: true,
    replace2: !!shipment?.assigned_to_2,
    assigned_to1: null,
    assigned_to2: null,
    replace_vehicle: false,
    equipment: null,
    amount1: '',
    amount2: '',
    previous_driver1_amount: '',
    previous_driver2_amount: '',
    hooked_to: shipment?.equipment?.hooked_to || null,
  };
};
