import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { palette } from 'utils/constants';
import { Typography } from 'components/Typography';
import { useTheme } from 'context/themeContext';
import Preloader from 'components/Equipment/EquipmentPreloader/EquipmentPreloaderSkeleton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import CustomButton from 'components/CustomButton/CustomButton';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import TableFooter from 'pages/ApplicantsAndReferrals/components/TableFooter';
import { getHardwareList } from 'Api/Billing';
import { useHardwareLicensesColumns } from './HardwareLicenses.data';
import { SFlexWrapper, SCheckboxWrapper, SHeader } from './HardwareLicenses.styles';

const HardwareLicenses = () => {
  const { use } = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hardwareLicenses, setHardwareLicenses] = useState({ data: [] });
  const [sort, setSort] = useState({ field: 'id', sortBy: 'desc' });
  const [selectedFilters, setSelectedFilters] = useState({
    page: 1,
    itemsPerPage: 25,
    showCancelled: false,
  });

  const getHardwareLicensesData = async () => {
    setLoading(true);
    const { page, itemsPerPage, showCancelled } = selectedFilters || {};
    const sortField = `sort[][${sort.field}]`;

    try {
      const params = {
        page,
        [sortField]: sort.sortBy,
        itemsPerPage,
        tab: showCancelled ? 'cancelled' : undefined,
      };
      const response = await getHardwareList(params);
      setHardwareLicenses(response);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters({ ...selectedFilters, page: 1, itemsPerPage: rowPage });
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    setSort({ field, sortBy: direction });
  };

  const PaginationComponent = () => (
    <TableFooter
      setLoading={(v) => setLoading(v)}
      rowPerPage={selectedFilters.itemsPerPage}
      totalCount={hardwareLicenses.total}
      totalLength={hardwareLicenses.data.length}
      lastPage={hardwareLicenses.last_page}
      currentPage={hardwareLicenses.current_page}
      onChangeRowPerPage={onChangeRowPerPage}
      onPageChange={onPageChange}
    />
  );

  useEffect(() => {
    getHardwareLicensesData();
  }, [selectedFilters, sort]);

  const columns = useHardwareLicensesColumns(sort, sortingQuery);

  return (
    <>
      <SHeader>
        <div className='d-flex align-items-center gap-3'>
          <Typography variant='h2' style={{ color: palette.gray900 }}>
            Hardware
          </Typography>
          <div>
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Recurring Amount -
            </Typography>{' '}
            <Typography variant='h5' style={{ color: palette.gray900 }}>
              ${hardwareLicenses?.hardwareRecurringTotal}
            </Typography>
          </div>
        </div>
        <SFlexWrapper>
          <SCheckboxWrapper>
            <CustomCheckbox
              name='cancelled'
              checked={selectedFilters.showCancelled}
              onChange={(value) => setSelectedFilters({ ...selectedFilters, showCancelled: value })}
            />
            <Typography variant='s2' style={{ color: use(palette.gray700, palette.gray50) }}>
              Show Canceled
            </Typography>
          </SCheckboxWrapper>
          <CustomButton
            type='primary'
            title='Purchase Hardware'
            styleButton={{ padding: '6px 12px', margin: 0 }}
            styleTitle={{ fontSize: 14 }}
            onClick={() => navigate('/hardware')}
          />
        </SFlexWrapper>
      </SHeader>
      {loading ? (
        <Preloader />
      ) : (
        <MaterialTableWrapper
          data={hardwareLicenses?.data}
          rowPerPage={selectedFilters.itemsPerPage}
          style={{ backgroundColor: use(palette.white, palette.dark800) }}
          columns={columns}
          components={{ Pagination: PaginationComponent }}
          onRowClick={(e, row) => {
            const urls = row?.agreement_document.split(',');
            const firstUrl = urls[0];
            if (firstUrl) {
              window.open(firstUrl);
            }
          }}
        />
      )}
    </>
  );
};

export default HardwareLicenses;
