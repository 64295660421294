import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Tab from '@mui/material/Tab';
import Search from 'common/Search';
import Divider from 'common/Divider';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import MaterialTableWrapper from 'components/MaterialTableWrapper';
import ChangeLog, { CHANGE_LOG_TYPE } from 'componentsV2/ChangeLog';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { getStaffLoginLog } from 'Api/Staff';
import { initialFilters, initialDateRange, useLoginLogColumns } from './StaffLogTab.data';
import { SWrapper, SHeader, STabs } from './StaffLogTab.styles';

const StaffLogTab = () => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState('loginLog');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [dateRange, setDateRange] = useState(initialDateRange);

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginSelectedFilters, setLoginSelectedFilters] = useState(initialFilters);
  const [loginSearch, setLoginSearch] = useState('');
  const loginDebouncedSearch = useDebounce(loginSearch, 500);
  const [loginSort, setLoginSort] = useState({ field: 'id', sortBy: 'desc' });
  const [loginLogs, setLoginLogs] = useState(null);

  const [changeLogSelectedFilters, setChangeLogSelectedFilters] = useState(initialFilters);
  const [changeSearch, setChangeSearch] = useState('');
  const changeDebouncedSearch = useDebounce(changeSearch, 500);
  const [changeLogSort, setChangeLogSort] = useState({ field: 'id', sortBy: 'desc' });

  const getLoginLogs = async () => {
    setLoginLoading(true);
    const { page, itemsPerPage } = loginSelectedFilters || {};
    const sortField = `sort[][${loginSort.field}]`;

    const params = {
      page,
      itemsPerPage,
      from_date: moment(dateRange.start.toUTCString()).format('MM/DD/YYYY'),
      to_date: moment(dateRange.end.toUTCString()).format('MM/DD/YYYY'),
      [sortField]: loginSort.sortBy,
      'user_id[]': id,
      query: loginDebouncedSearch || undefined,
    };

    try {
      const response = await getStaffLoginLog(params);
      setLoginLogs(response);
    } catch (e) {
      // Do nothing
    } finally {
      setLoginLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setLoginSearch('');
    setLoginSelectedFilters(initialFilters);
    setLoginSort({ field: 'id', sortBy: 'desc' });

    setChangeSearch('');
    setChangeLogSelectedFilters(initialFilters);
    setChangeLogSort({ field: 'id', sortBy: 'desc' });

    setActiveTab(newValue);
  };

  const onChangeRowPerPage = (rowPage) => {
    if (activeTab === 'loginLog') {
      setLoginSelectedFilters({ page: 1, itemsPerPage: rowPage });
    } else {
      setChangeLogSelectedFilters({ page: 1, itemsPerPage: rowPage });
    }
  };

  const onPageChange = (page) => {
    if (activeTab === 'loginLog') {
      setLoginSelectedFilters((prevState) => ({ ...prevState, page }));
    } else {
      setChangeLogSelectedFilters((prevState) => ({ ...prevState, page }));
    }
  };

  const sortingQuery = (field) => {
    const direction = loginSort?.sortBy === 'asc' ? 'desc' : 'asc';
    const changeLogDirection = changeLogSort?.sortBy === 'asc' ? 'desc' : 'asc';
    if (activeTab === 'loginLog') {
      setLoginSort({ field, sortBy: direction });
    } else {
      setChangeLogSort({ field, sortBy: changeLogDirection });
    }
  };

  useEffect(() => {
    if (activeTab === 'loginLog') {
      getLoginLogs();
    }
  }, [
    dateRange,
    loginDebouncedSearch,
    loginSort,
    loginSelectedFilters,
    changeDebouncedSearch,
    changeLogSort,
    changeLogSelectedFilters,
    activeTab,
  ]);

  const loginLogColumns = useLoginLogColumns({ sort: loginSort, sortingQuery });

  return (
    <SWrapper>
      <SHeader>
        <Typography className='header-title' variant='h2'>
          Log
        </Typography>
        {activeTab === 'loginLog' ? (
          <Search className='search-input' width='250px' search={loginSearch} onChange={setLoginSearch} />
        ) : (
          <Search className='search-input' width='250px' search={changeSearch} onChange={setChangeSearch} />
        )}
        <DateRangePicker initialRangeName='Last 30 days' dateRange={dateRange} setDateRange={setDateRange} />
      </SHeader>
      <div>
        <STabs value={activeTab} onChange={handleTabChange}>
          <Tab id='tab-1' value='loginLog' label='Login Log' disableRipple />
          <Tab id='tab-2' value='changeLog' label='Change Log' disableRipple />
        </STabs>
        {activeTab === 'loginLog' ? (
          loginLoading ? (
            <TableSkeleton />
          ) : (
            <MaterialTableWrapper
              data={loginLogs?.data}
              rowPerPage={loginSelectedFilters.itemsPerPage}
              style={{ backgroundColor: palette.white }}
              components={{
                Pagination: () =>
                  Pagination({
                    data: loginLogs,
                    rowPerPage: loginSelectedFilters.itemsPerPage,
                    onChangeRowPerPage,
                    onPageChange,
                    rowsPerPageOptions: [25, 50, 100, 150],
                  }),
              }}
              columns={loginLogColumns}
            />
          )
        ) : (
          <div>
            <Divider margin='0 0 12px' />
            <ChangeLog
              type={CHANGE_LOG_TYPE.STAFF}
              additionalParams={{
                staff_id: id,
                from_date: moment(dateRange.start.toUTCString()).format('MM/DD/YYYY'),
                to_date: moment(dateRange.end.toUTCString()).format('MM/DD/YYYY'),
                query: changeDebouncedSearch || undefined,
              }}
              hideFilters
            />
          </div>
        )}
      </div>
      <ViewPdf title='View Attachment' open={!!pdfUrl} onClose={() => setPdfUrl(null)} pdfUrl={pdfUrl} />
    </SWrapper>
  );
};

export default StaffLogTab;
