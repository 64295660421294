import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import { Button, Modal, Dropdown } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import copy from 'assets/copy.png';
import add from 'assets/icons/drivers/add.png';
import cross from 'assets/icons/drivers/cross.svg';
import sorting from 'assets/icons/drivers/sorting.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as Arrows } from 'assets/icons/arrows.svg';
import setting, { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';

import Chip from 'common/Chip';
import Tooltip from 'common/Tooltip';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import AddBalance from 'componentsV2/AddBalance';
import { Typography } from 'components/Typography';
import ConfirmationModal from 'common/ConfirmationModal';
import CustomTimeInput from 'components/CustomTimeInput';
import CustomSelect from 'components/CustomSelect/CustomSelect';
import PaymentMethod from 'components/Drivers/AddDriver/PaymentMethod';
import RecurringModal from 'components/ReccuringTypeModal/ReccuringTypeModal';
import { formatNumber, palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import { blockNonPositiveNumbers, blockNotNumberChars, formatAmount } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { useTheme } from 'context/themeContext';
import useShowToaster from 'hooks/useShowToaster';
import { operatorCompansationData } from 'Api/OwnerOperator';
import { getAdditionsList } from 'Api/PayrollSettings';
import { saveFinishLater } from 'Api/DriverV2';
import {
  AddCompensation,
  AddWorkingSchedule,
  deleteDriverBalance,
  getDriverCompanyPolicy,
  getTaxType,
  uploadDriverDocument,
} from 'Api/Driver';
import TaxesSettings from 'pages/Payroll/Settlement/Tables/Taxes/components/TaxesSettings';
import BenefitsSettings from 'pages/Payroll/Settlement/Tables/Benefits/components/BenefitsSettings';
import AdditionsSettings from 'pages/Payroll/Settlement/Tables/Additions/components/AdditionsSettings';
import DeductionsSettings from 'pages/Payroll/Settlement/Tables/Deduction/components/DeductionsSettings';
import { CompensationValidationSchema } from './ValidationSchema';
import { SInput } from './AddDriver.styles';

const weekData = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const monthData = ['Month', 'Week'];
const OverTimeData = ['Week', 'Day'];

const month = [
  '1st',
  '2nd',
  '3rd',
  '4th',
  '5th',
  '6th',
  '7th',
  '8th',
  '9th',
  '10th',
  '11th',
  '12th',
  '13th',
  '14th',
  '15th',
  '16th',
  '17th',
  '18th',
  '19th',
  '20th',
  '21st',
  '22nd',
  '23rd',
  '24th',
  '25th',
  '26th',
  '27th',
  '28th',
  '29th',
  '30th',
];

const paymentConditions = [
  {
    paymentCondition: 'Paid Time Off (PTO)',
    timePeriod: 'per day',
    name1: 'paid_time_off_option',
    name2: 'paid_time_off_amount',
  },
  {
    paymentCondition: 'Detention Pay',
    timePeriod: 'per hour',
    after: 'hour(s) has passed',
    name1: 'detention_pay_option',
    name2: 'detention_amt',
    name3: 'detention_hrs',
  },
  {
    paymentCondition: 'Layover Pay',
    timePeriod: 'for each layover',
    name1: 'layover_option',
    name2: 'layover_amt',
  },
  {
    paymentCondition: 'Stop off Pay',
    timePeriod: 'per each stop off',
    after: 'stops',
    name1: 'stopoff_option',
    name2: 'per_stop_amt',
    name3: 'no_of_stops',
  },
  // {
  //   paymentCondition: 'Live Unload Pay',
  //   timePeriod: 'per live unload',
  //   name1: 'live_unload_option',
  //   name2: 'unload_amt',
  // },
  {
    paymentCondition: 'Truck Order Not Used Pay',
    timePeriod: 'per each truck order not used',
    name1: 'truck_order_unused_option',
    name2: 'each_truck_order_unused_amt',
  },
  // {
  //   paymentCondition: 'Break Down Pay',
  //   timePeriod: 'per every hover broken down',
  //   name1: 'breakdown_option',
  //   name2: 'after_breakdown_hrs',
  // },
  // {
  //   paymentCondition: 'Tarp Pay',
  //   timePeriod: 'per each stop if tarp type shipment',
  //   name1: 'tarp_pay_option',
  //   name2: 'each_stop_amt',
  // },
  // {
  //   paymentCondition: 'Re-Delivery Pay',
  //   name1: 'redelivery_option',
  //   name2: 'after_first_attempt_amt',
  //   timePeriod: 'per each re-delivery of a shipment',
  // },
];

const AddDriverListStep4 = ({
  nextStep,
  showError,
  prevStep,
  Cancel,
  compensation = {},
  stepsDone,
  setStepsDone,
  driverId,
  isDriverProfile = false,
  Schedule,
  scheduleId,
  ownerData,
  draftDriver,
  getDrivers,
}) => {
  const { use, theme } = useTheme();
  const [onNextloading, setOnNextloading] = useState(false);

  const [taxOption, setTaxOption] = useState([]);
  const [additionType, setAdditionType] = useState([]);
  const [companyPolicy, setCompanyPolicy] = useState([]);

  const {
    driver_id,
    tax_id,
    payment_method,
    third_party_name,
    third_party_id,
    bank_name,
    bank_routing,
    confirm_bank_routing,
    confirm_bank_account,
    bank_account,
    bank_direct_deposit_form,
    pay_option,
    per_hour_worked,
    break_time_allowed,
    breaks_allowed_in_a_day,
    break_time_paid,
    deduct_break_time,
    mile_loaded_amt,
    mile_empty_amt,
    shipment_percentage,
    flat_rate_amount,
    paid_time_off_option,
    paid_time_off_amount,
    detention_pay_option,
    detention_amt,
    detention_hrs,
    layover_option,
    layover_amt,
    stopoff_option,
    per_stop_amt,
    no_of_stops,
    live_unload_option,
    unload_amt,
    truck_order_unused_option,
    each_truck_order_unused_amt,
    breakdown_option,
    after_breakdown_hrs,
    redelivery_option,
    after_first_attempt_amt,
    tarp_pay_option,
    each_stop_amt,
    pay_raise,
    tax_type,
    is_pay_raise,
    isCompanyPolice,
    allow_shipment_offers,
    over_time_hours_after,
    over_time_hours_type,
    over_time_pay,
    pay_on_each_shipment,
    shipment_addition,
  } = compensation || {};

  const formik = useFormik({
    initialValues: {
      driver_id: driver_id || driverId,
      tax_id: tax_id || '',
      confirm_bank_routing: confirm_bank_routing || '',
      confirm_bank_account: confirm_bank_account || '',
      tax_type: tax_type || '',
      payment_method: payment_method || 'check',
      third_party_name: third_party_name || '',
      third_party_id: third_party_id || '',
      bank_name: bank_name || '',
      bank_routing: bank_routing || '',
      bank_account: bank_account || '',
      bank_direct_deposit_form: bank_direct_deposit_form || '',
      pay_option: pay_option || 'mile',
      per_hour_worked: per_hour_worked || 0,
      break_time_allowed: break_time_allowed || '',
      breaks_allowed_in_a_day: breaks_allowed_in_a_day || 0,
      break_time_paid: break_time_paid || false,
      deduct_break_time: deduct_break_time || 0,
      mile_loaded_amt: mile_loaded_amt || 0,
      mile_empty_amt: mile_empty_amt || 0,
      allow_shipment_offers: allow_shipment_offers || 0,
      shipment_percentage: shipment_percentage || 0,
      flat_rate_amount: flat_rate_amount || 0,
      paid_time_off_option: paid_time_off_option || false,
      paid_time_off_amount: paid_time_off_amount || 0,
      detention_pay_option: detention_pay_option || false,
      detention_amt: detention_amt || 0,
      detention_hrs: detention_hrs || 1,
      layover_option: layover_option || false,
      layover_amt: layover_amt || 0,
      stopoff_option: stopoff_option || false,
      per_stop_amt: per_stop_amt || 0,
      no_of_stops: no_of_stops || 1,
      live_unload_option: live_unload_option || false,
      unload_amt: unload_amt || 0,
      truck_order_unused_option: truck_order_unused_option || false,
      each_truck_order_unused_amt: each_truck_order_unused_amt || 0,
      breakdown_option: breakdown_option || 0,
      after_breakdown_hrs: after_breakdown_hrs || 0,
      redelivery_option: redelivery_option || false,
      after_first_attempt_amt: after_first_attempt_amt || 0,
      tarp_pay_option: tarp_pay_option || false,
      isCompanyPolice: isCompanyPolice || false,
      each_stop_amt: each_stop_amt || 0,
      over_time_hours_after: over_time_hours_after || null,
      over_time_hours_type: over_time_hours_type || 'week',
      over_time_pay: over_time_pay || null,
      is_pay_raise: is_pay_raise || false,
      pay_raise: pay_raise ?? [
        {
          driver_id: driver_id || driverId,
          pay_raise_schedule_amt: '',
          pay_raise_schedule_days: 0,
          pay_raise_schedule_months: 0,
          pay_raise_schedule_years: 0,
        },
      ],
      pay_on_each_shipment: !!pay_on_each_shipment || false,
      shipment_addition: shipment_addition ?? [
        {
          payroll_addition: null,
          amount: '',
        },
      ],
    },
    validationSchema: CompensationValidationSchema,
  });

  const workScheduleformik = useFormik({
    initialValues: {
      workSchedule: Schedule ?? [
        {
          work_schedule_id: scheduleId || 'new',
          day: 'Mon',
          dayFullName: 'Monday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Tue',
          dayFullName: 'Tuesday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Wed',
          dayFullName: 'Wednesday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Thur',
          dayFullName: 'Thursday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Fri',
          dayFullName: 'Friday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Sat',
          dayFullName: 'Saturday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
        {
          work_schedule_id: 'new',
          day: 'Sun',
          dayFullName: 'Sunday',
          isSelected: false,
          start_time: '00:00',
          end_time: '00:00',
        },
      ],
    },
  });

  const onPrevStep = () => {
    setStepsDone((prevState) => ({
      ...prevState,
      isPreviousEmployment: true,
    }));
    prevStep();
  };

  const getAdditionTypes = async () => {
    try {
      const { data } = await getAdditionsList();
      const types = data?.map((item) => ({ id: item.id, amount: item.amount, type: item.addition_type }));
      setAdditionType(types || []);
    } catch (e) {
      // Do nothing
    }
  };

  const onNextStep = () => {
    let errorMsg = '';
    for (const payment of paymentConditions) {
      if (formik.values[payment.name1]) {
        if (!formik.values[payment.name2]) {
          errorMsg = `Please filled ${payment.name2}`;
          break;
        } else if (payment.name3 && !formik.values[payment.name3]) {
          errorMsg = `Please filled ${payment.name3}`;
          break;
        }
      }
    }
    if (errorMsg) {
      showError(errorMsg);
      return;
    }
    setOnNextloading(true);

    const requestPostData = {
      driver_id: driverId,
      work_schedule_id: [],
      day: [],
      start_time: [],
      end_time: [],
    };
    for (const val of workScheduleformik.values.workSchedule) {
      if (val.isSelected) {
        requestPostData.work_schedule_id.push(val.work_schedule_id);
        requestPostData.day.push(val.day);
        requestPostData.start_time.push(val.start_time);
        requestPostData.end_time.push(val.end_time);
      }
    }

    const { is_pay_raise, pay_on_each_shipment } = formik.values;

    AddCompensation({
      ...formik.values,
      pay_raise: is_pay_raise ? formik.values.pay_raise : [],
      shipment_addition:
        pay_on_each_shipment && formik.values.shipment_addition?.length
          ? formik.values.shipment_addition.map((el) => ({
              amount: el.amount,
              payroll_addition_id: el.payroll_addition.id,
            }))
          : [],
    })
      .then(() => {
        if (formik.values.pay_option === 'per_hour') {
          if (requestPostData?.day?.length) {
            AddWorkingSchedule(requestPostData)
              .then((response) => {
                workScheduleformik?.values?.workSchedule.forEach((item, index) => {
                  const hasValue = response?.data?.find((i) => i?.day === item?.day);
                  if (hasValue) {
                    workScheduleformik.values.workSchedule[index] = {
                      ...item,
                      work_schedule_id: hasValue.id,
                    };
                  }
                });
                nextStep(
                  { formikData: formik.values },
                  workScheduleformik?.values.workSchedule,
                  `Compensation has been ${stepsDone ? 'updated' : 'created'}`
                );
              })
              .catch((error) => {
                showError(getErrorMessage(error));
              });
          } else {
            nextStep(
              { formikData: formik.values },
              workScheduleformik?.values.workSchedule,
              `Compensation has been ${stepsDone ? 'updated' : 'created'}`
            );
          }
        } else {
          nextStep(
            { formikData: formik.values },
            workScheduleformik?.values.workSchedule,
            `Compensation has been ${stepsDone ? 'updated' : 'created'}`
          );
        }

        setOnNextloading(false);
      })
      .catch((error) => {
        setOnNextloading(false);
        showError(getErrorMessage(error));
      });
  };

  useEffect(() => {
    if (!stepsDone) {
      formik.setFieldValue('tax_id', '');
    }
    getTaxType().then((res) => {
      setTaxOption(res.data);
    });

    getAdditionTypes();

    getDriverCompanyPolicy()
      .then((res) => {
        setCompanyPolicy(res.data);
      })
      .catch(() => {
        // Do nothing
      });

    if (ownerData?.driver_type === 'owner') {
      const id = ownerData?.operator?.id;
      operatorCompansationData({ id }).then((res) => {
        const data = res?.data?.compensation;
        formik.setFieldValue('confirm_bank_routing', data?.routing);
        formik.setFieldValue('confirm_bank_account', data?.account);
        formik.setFieldValue('tax_type', data?.tax_type?.name);
        formik.setFieldValue('tax_id', data?.tax_id);
        formik.setFieldValue('bank_name', data?.bank_name);
        formik.setFieldValue('bank_routing', data?.routing);
        formik.setFieldValue('bank_account', data?.account);
        formik.setFieldValue('bank_direct_deposit_form', data?.deposit_form_path);
        formik.setFieldValue(
          'pay_option',
          data?.pay_option?.name === 'By Percentage'
            ? 'percentage'
            : data?.pay_option?.name === 'Per Mile'
            ? 'mile'
            : data?.pay_option?.name === 'Flat Rate'
            ? 'flat_rate'
            : data?.pay_option?.name === 'Per Hour'
            ? 'per_hour'
            : data?.pay_option?.name
        );
        formik.setFieldValue('per_hour_worked', data?.per_hour_worked);
        formik.setFieldValue('break_time_allowed', data?.break_time_allowed);
        formik.setFieldValue('breaks_allowed_in_a_day', data?.breaks_allowed_in_a_day);
        formik.setFieldValue('mile_empty_amt', data?.pay_per_mile_empty);
        formik.setFieldValue('mile_loaded_amt', data?.pay_per_mile_loaded);
        formik.setFieldValue('shipment_percentage', data?.pay_by_percentage);
        formik.setFieldValue('paid_time_off_option', data?.paid_time_off_option);
        formik.setFieldValue('paid_time_off_amount', data?.paid_time_off_amount);
        formik.setFieldValue('detention_pay_option', data?.detention_pay_option);
        formik.setFieldValue('detention_amt', data?.detention_amt);
        formik.setFieldValue('detention_hrs', data?.detention_hrs);
        formik.setFieldValue('layover_option', data?.layover_option);
        formik.setFieldValue('layover_amt', data?.layover_amt);
        formik.setFieldValue('stopoff_option', data?.stopoff_option);
        formik.setFieldValue('per_stop_amt', data?.per_stop_amt);
        formik.setFieldValue('no_of_stops', data?.no_of_stops);
        formik.setFieldValue('live_unload_option', data?.live_unload_option);
        formik.setFieldValue('unload_amt', data?.unload_amt);
        formik.setFieldValue('truck_order_unused_option', data?.truck_order_unused_option);
        formik.setFieldValue('each_truck_order_unused_amt', data?.each_truck_order_unused_amt);
        formik.setFieldValue('breakdown_option', data?.breakdown_option);
        formik.setFieldValue('after_breakdown_hrs', data?.breakdown_amt);
        formik.setFieldValue('redelivery_option', data?.redelivery_option);
        formik.setFieldValue('after_first_attempt_amt', data?.after_first_attempt_amt);
        formik.setFieldValue('tarp_pay_option', data?.tarp_pay_option);
        formik.setFieldValue('isCompanyPolice', data?.isCompanyPolice);
        formik.setFieldValue('each_stop_amt', data?.each_stop_amt);
      });
    }
  }, []);

  const remove = (index, data) => {
    if (data === 'pay_raise') {
      const temp = formik.values.pay_raise;
      temp.splice(index, 1);
      formik.setFieldValue('pay_raise', temp);
    } else if (data === 'shipment_addition') {
      const temp = formik.values.shipment_addition;
      temp.splice(index, 1);
      formik.setFieldValue('shipment_addition', temp);
    }
  };

  const copyToAll = (val) => {
    workScheduleformik.values.workSchedule.forEach((item, index) => {
      if (index > 0) {
        const startTime = workScheduleformik.values.workSchedule[val].start_time;
        const endTime = workScheduleformik.values.workSchedule[val].end_time;
        workScheduleformik.setFieldValue(`workSchedule[${index}].start_time`, startTime);
        workScheduleformik.setFieldValue(`workSchedule[${index}].end_time`, endTime);
      }
    });
  };
  const uploadDocument = (file, propertyName) => {
    uploadDriverDocument(file[0], driverId)
      .then((res) => {
        formik.setFieldValue(propertyName, res.data.document_path);
      })
      .catch((e) => showError(getErrorMessage(e)));
  };

  const saveAndFinishLater = () => {
    const body = {
      draft_step_number: 4,
    };

    saveFinishLater(driverId, body).then(() => {
      getDrivers();
      Cancel();
    });
  };

  if (isDriverProfile) {
    <CompensationContent
      formik={formik}
      use={use}
      taxOption={taxOption}
      uploadDocument={uploadDocument}
      companyPolicy={companyPolicy}
      driver_id={driver_id}
      driverId={driverId}
      copyToAll={copyToAll}
      theme={theme}
    />;
  }

  return (
    <>
      <Modal.Body className='add-driver-modal-body' style={{ backgroundColor: use(palette.gray0, palette.dark800) }}>
        <CompensationContent
          formik={formik}
          use={use}
          taxOption={taxOption}
          uploadDocument={uploadDocument}
          companyPolicy={companyPolicy}
          driver_id={driver_id}
          driverId={driverId}
          remove={remove}
          addtionType={additionType}
          refreshOptions={getAdditionTypes}
          workScheduleformik={workScheduleformik}
          copyToAll={copyToAll}
          theme={theme}
        />
      </Modal.Body>
      <Modal.Footer
        style={{
          backgroundColor: use(palette.white, palette.dark600),
          borderColor: use(palette.gray50, palette.darkborder),
        }}
      >
        <div className='footer-btn'>
          <div>
            <Button className='cancel-button' onClick={saveAndFinishLater}>
              Save and Finish Later
            </Button>
          </div>
          <div className='pagination-btn'>
            {!draftDriver && (
              <Button className='cancel-button' onClick={onPrevStep}>
                Back
              </Button>
            )}
            <Button className='cancel-button ms-0 me-2' onClick={nextStep}>
              Skip
            </Button>
            {onNextloading ? (
              <div className='ms-2'>
                <CircularProgress size={30} />
              </div>
            ) : (
              <button
                type='button'
                onClick={onNextStep}
                className='next-step-btn'
                disabled={!formik.isValid}
                style={{ opacity: formik.isValid ? 1 : 0.7 }}
              >
                Next Step
              </button>
            )}
          </div>
        </div>
      </Modal.Footer>
    </>
  );
};
export default AddDriverListStep4;

export const CompensationContent = ({
  setRecurringModalData,
  recurringModalData,
  formik,
  use,
  taxOption,
  uploadDocument,
  companyPolicy,
  driver_id,
  driverId,
  remove,
  isDriverProfile,
  setRecurringModalShow,
  recurringModalShow,
  deductionFormik,
  deductionType,
  addtionType,
  benefitType,
  taxesType,
  Benefitformik,
  saveCompensationData,
  setReccuring,
  Additionsformik,
  taxesformik,
  workScheduleformik,
  copyToAll,
  theme,
  refreshOptions,
  balances,
  getBalances,
  getDeductions,
  onViewBalanceDetails,
}) => {
  const showToaster = useShowToaster();
  const { convertToCustomerTime } = useDateFormat();
  const { currency } = useSelector((state) => state.root);
  const [openSettings, setOpenSettings] = useState(null);
  const [balanceToDeleteId, setBalanceToDeleteId] = useState(null);
  const [loadingDeleteBalance, setLoadingDeleteBalance] = useState(false);
  const [openAddBalance, setOpenAddBalance] = useState(false);

  const deleteBalance = async () => {
    try {
      setLoadingDeleteBalance(true);
      await deleteDriverBalance(balanceToDeleteId);
      getBalances();
      getDeductions();
      setBalanceToDeleteId(null);
      showToaster({ type: 'success', message: 'Balance has been successfully removed!' });
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoadingDeleteBalance(false);
    }
  };

  const onSettingsClose = () => {
    if (refreshOptions) {
      refreshOptions();
    }
    setOpenSettings(null);
  };

  useEffect(() => {
    deductionFormik?.validateForm();
    Additionsformik?.validateForm();
    taxesformik?.validateForm();
    Benefitformik?.validateForm();
  }, []);

  return (
    <div className='step-wrapper driver-style-wrap'>
      <div className='d-flex'>
        <div className='flex-col'>
          <p className='text-style tax-id' style={{ color: use(palette.gray700, palette.gray200) }}>
            Tax ID <span className='required'>*</span>
          </p>
          <div
            className='tax-id-input'
            style={{
              backgroundColor: use(palette.white, palette.dark600),
              borderColor: use(palette.gray50, palette.darkborder),
            }}
          >
            <input
              type='text'
              style={{
                backgroundColor: use(palette.white, palette.dark600),
                color: palette.gray900,
              }}
              className='input-field'
              name='tax_id'
              id='tax_id'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.tax_id}
            />
          </div>
          {formik.touched.tax_id && formik.errors.tax_id ? (
            <span className='error-message'>{formik.errors.tax_id}</span>
          ) : null}
        </div>
        <div className='custom-select-wrap-contain'>
          <div className='flex-col'>
            <p className='text-style tax-id' style={{ color: use(palette.gray700, palette.gray200) }}>
              Tax Type <span className='required'>*</span>
            </p>
            <CustomSelect
              defaultValue='Select'
              options={taxOption}
              id='tax_type'
              name='tax_type'
              value={formik.values.tax_type}
              onBlur={formik.handleBlur}
              onChange={(value) => {
                if (value === 'Select') {
                  formik.setFieldValue('tax_type', null);
                } else {
                  formik.setFieldValue('tax_type', value);
                }
              }}
              patchProperty='name'
              styles={{ color: palette.gray900 }}
            />
          </div>
        </div>
      </div>
      <div className='bank-details mb-2'>
        <p className='bank-details-heading' style={{ color: use(palette.gray900, palette.gray200) }}>
          Payout Method
        </p>
      </div>
      <PaymentMethod formik={formik} uploadDocument={uploadDocument} />
      <div>
        <p className='payment-option-heading' style={{ color: use(palette.gray900, palette.gray200) }}>
          Payment Option
        </p>
        <p
          className='text-style payment-details-sub-heading text-focus'
          style={{ color: use(palette.gray900, palette.gray200) }}
        >
          How does this driver get paid?
        </p>
        <div className='d-flex' style={{ marginTop: '12px' }}>
          <p className='payment-option'>
            <input
              checked={formik.values.pay_option === 'mile'}
              value={formik.values.pay_option}
              type='radio'
              name='pay_option'
              onChange={() => formik.setFieldValue('pay_option', 'mile')}
              className='radio-button'
              defaultChecked
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              Per Mile
            </span>
          </p>
          <p className='payment-option'>
            <input
              type='radio'
              name='pay_option'
              checked={formik.values.pay_option === 'percentage'}
              value={formik.values.pay_option}
              onChange={() => formik.setFieldValue('pay_option', 'percentage')}
              className='radio-button'
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              By Percentage
            </span>
          </p>
          <p className='payment-option'>
            <input
              type='radio'
              name='pay_option'
              checked={formik.values.pay_option === 'per_hour'}
              value={formik.values.pay_option}
              onChange={() => formik.setFieldValue('pay_option', 'per_hour')}
              className='radio-button'
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              Per Hour
            </span>
          </p>
          <p className='payment-option'>
            <input
              type='radio'
              name='pay_option'
              checked={formik.values.pay_option === 'flat_rate'}
              value={formik.values.pay_option}
              onChange={() => {
                formik.setFieldValue('pay_option', 'flat_rate');
                formik.setFieldValue('is_pay_raise', false);
              }}
              className='radio-button'
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              Flat Rate
            </span>
          </p>
        </div>

        {formik.values.pay_option === 'mile' ? (
          <div>
            <div className='per-hour-work'>
              <span
                className='doller-icon'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                  color: palette.gray400,
                }}
              >
                {currency}
              </span>
              <input
                type='number'
                placeholder='0'
                name='mile_loaded_amt'
                id='mile_loaded_amt'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.mile_loaded_amt}
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
              <span className='per-hour-text text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                Loaded
              </span>
            </div>
            <div className='per-hour-work'>
              <span
                className='doller-icon'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                  color: palette.gray400,
                }}
              >
                {currency}
              </span>
              <input
                type='number'
                placeholder='0'
                name='mile_empty_amt'
                id='mile_empty_amt'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.mile_empty_amt}
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                }}
              />
              <span className='per-hour-text text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                Empty
              </span>
            </div>
          </div>
        ) : formik.values.pay_option === 'percentage' ? (
          <div className='per-hour-work'>
            <span
              className='doller-icon'
              style={{
                backgroundColor: use(palette.white, palette.dark600),
                borderColor: use(palette.gray50, palette.darkborder),
              }}
            >
              %
            </span>
            <input
              type='number'
              placeholder='0'
              name='shipment_percentage'
              id='shipment_percentage'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.shipment_percentage}
              style={{
                backgroundColor: use(palette.white, palette.dark600),
                borderColor: use(palette.gray50, palette.darkborder),
                color: use(palette.gray500, palette.gray200),
              }}
            />
            <span className='per-hour-text text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
              of each shipment
            </span>
          </div>
        ) : formik.values.pay_option === 'per_hour' ? (
          <>
            <div className='per-hour-work'>
              <span
                className='doller-icon'
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                  color: palette.gray400,
                }}
              >
                {currency}
              </span>
              <input
                type='number'
                placeholder='0'
                name='per_hour_worked'
                id='per_hour_worked'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.per_hour_worked}
                style={{
                  backgroundColor: use(palette.white, palette.dark600),
                  borderColor: use(palette.gray50, palette.darkborder),
                  color: use(palette.gray500, palette.gray200),
                }}
              />
              <span className='per-hour-text text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                per hour worked
              </span>
            </div>
            <div className='d-flex breaks-value-wrapper align-items-center'>
              <div className='breaks-input-wrapper'>
                <input
                  type='number'
                  placeholder='0'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use(palette.gray500, palette.gray200),
                  }}
                  name='breaks_allowed_in_a_day'
                  id='breaks_allowed_in_a_day'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.breaks_allowed_in_a_day}
                />
              </div>
              <p className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                Breaks Allowed In Given Work Day for{' '}
              </p>
              <div className='break-minutes p-1'>
                <input
                  type='number'
                  placeholder='0'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use(palette.gray500, palette.gray200),
                  }}
                  name='break_time_allowed'
                  id='break_time_allowed'
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    if (Number(e.target.value) < 1) {
                      formik.setFieldValue('break_time_allowed', 1);
                      return;
                    }
                    formik.handleChange(e);
                  }}
                  value={formik.values.break_time_allowed}
                />
                <span
                  className=' d-flex justify-content-center text-style'
                  style={{ color: use(palette.gray700, palette.gray200) }}
                >
                  minutes
                </span>
              </div>
            </div>
            <div className='compensation-recurring-wraper mt-2'>
              <label
                className='text-style compensation-text-style-lable'
                htmlFor='flexSwitchCheckChecked'
                style={{ color: use(palette.gray700, palette.gray200) }}
              >
                Overtime Hour(s), After
              </label>
              <div className='breaks-input-wrapper'>
                <input
                  type='number'
                  placeholder='0'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use(palette.gray500, palette.gray200),
                  }}
                  name='over_time_hours_after'
                  id='over_time_hours_after'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.over_time_hours_after}
                />
              </div>
              <label
                className='text-style compensation-text-style-lable-of'
                htmlFor='flexSwitchCheckChecked'
                style={{ color: use(palette.gray700, palette.gray200) }}
              >
                hour(s) per
              </label>
              <Dropdown
                className='custom-dropdown-in-compensation'
                onSelect={(value) => {
                  formik.setFieldValue(`over_time_hours_type`, value);
                }}
              >
                <Dropdown.Toggle
                  placement='top'
                  variant='outline-secondary'
                  id='dropdown-basic'
                  className='tablePlaner_dropdownToggle  compensation__dropdownToggle'
                  style={{
                    color: use(palette.gray700, palette.gray200),
                    backgroundColor: use(palette.white, palette.dark800),
                  }}
                >
                  {formik?.values?.over_time_hours_type}
                  <div className='tablePlaner_arrows_wrapper'>
                    <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  className='tablePlaner_menu compansation_menu'
                  style={{ backgroundColor: use(palette.white, palette.dark800) }}
                >
                  {OverTimeData?.map((el) => {
                    return (
                      <Dropdown.Item
                        eventKey={el}
                        active={formik?.values?.over_time_hours_typek === el}
                        key={el}
                        style={{
                          color: use(palette.gray700, palette.gray200),
                        }}
                      >
                        {el}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className='compensation-recurring-wraper mt-2'>
              <label
                className='text-style compensation-text-style-lable'
                htmlFor='flexSwitchCheckChecked'
                style={{ color: use(palette.gray700, palette.gray200) }}
              >
                Overtime Pay X
              </label>
              <div className='breaks-input-wrapper'>
                <input
                  type='number'
                  placeholder='0'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: use(palette.gray500, palette.gray200),
                  }}
                  name='over_time_pay'
                  id='over_time_pay'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.over_time_pay}
                />
              </div>
            </div>
            <div className='checkbox-wrapper'>
              <div className='checkbox'>
                <input
                  type='checkbox'
                  className='checkbox-style'
                  name='break_time_paid'
                  id='break_time_paid'
                  value={isDriverProfile ? 1 : formik.values.break_time_paid}
                  checked={formik.values.break_time_paid === 1 || formik.values.break_time_paid === true}
                  onChange={() => {
                    formik.setFieldValue('break_time_paid', !formik.values.break_time_paid);
                  }}
                />
                <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                  Break Time Paid
                </span>
              </div>
            </div>

            {formik.values.pay_option === 'per_hour' && (
              <div className='checkbox-wrapper'>
                <div className='checkbox'>
                  <input
                    type='checkbox'
                    className='checkbox-style'
                    name='allow_shipment_offers'
                    id='allow_shipment_offers'
                    value={formik.values.allow_shipment_offers}
                    checked={formik.values.allow_shipment_offers === 1 || formik.values.allow_shipment_offers === true}
                    onChange={() => {
                      formik.setFieldValue('allow_shipment_offers', !formik.values.allow_shipment_offers);
                    }}
                  />
                  <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                    Allow Shipment Offers
                  </span>
                </div>
              </div>
            )}
          </>
        ) : null}
      </div>
      {formik.values.pay_option === 'per_hour' && (
        <div>
          <div className='deduction-header-wrap'>
            <p
              className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
              style={{ color: use(palette.gray900, palette.gray200) }}
            >
              Working Schedule
            </p>

            {isDriverProfile && (
              <button
                onClick={() => {
                  saveCompensationData('workingSchedule');
                }}
                className='driver-compansation-save-btn'
              >
                Save
              </button>
            )}
          </div>
          <div className='week-opt'>
            {workScheduleformik?.values?.workSchedule?.map((item, index) => {
              return (
                <span
                  className={
                    workScheduleformik?.values?.workSchedule[index]?.isSelected === true
                      ? 'week-days-opt selected-week-restrict'
                      : 'week-days-opt'
                  }
                  onClick={() =>
                    workScheduleformik?.setFieldValue(
                      `workSchedule[${index}].isSelected`,
                      !workScheduleformik?.values?.workSchedule[index]?.isSelected
                    )
                  }
                >
                  {item?.day}
                </span>
              );
            })}
          </div>

          <div className='compensation-week'>
            {workScheduleformik?.values?.workSchedule?.map((item, index) => {
              return (
                item?.isSelected === true && (
                  <div className='compensation-week-container'>
                    <div className='compensation-week-wrapper'>
                      <div className='compensation-week-left'>
                        <div className='comp-week-name'>
                          <p className='' style={{ color: use(palette.gray500, palette.gray200) }}>
                            {item?.dayFullName}
                          </p>
                        </div>
                        <div className='comp-week-date'>
                          <div>
                            <CustomTimeInput
                              name='start_time'
                              value={workScheduleformik.values.workSchedule[index].start_time}
                              form={workScheduleformik}
                              field={{
                                name: `workSchedule[${index}].start_time`,
                                onChange: workScheduleformik.onChange,
                                onBlur: workScheduleformik.onChange,
                              }}
                            />
                          </div>
                          <div>
                            <p className='mx-2'>to</p>
                          </div>
                          <div className='d-flex'>
                            <CustomTimeInput
                              name='end_time'
                              form={workScheduleformik}
                              value={workScheduleformik.values.workSchedule[index].end_time}
                              field={{
                                name: `workSchedule[${index}].end_time`,
                                onChange: workScheduleformik.onChange,
                                onBlur: workScheduleformik.onChange,
                              }}
                            />
                            {item?.work_schedule_id !== 'new' ? (
                              <div className='ms-3'>
                                <img src={cross} alt='' onClick={() => remove(index, 'workingSchedule', item)} />
                              </div>
                            ) : (
                              <span />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className='compensation-week-right'>
                        {index === 0 && item.isSelected ? (
                          <div className='d-flex' onClick={() => copyToAll(0)}>
                            <img src={copy} alt='copy' className='copy-icon' />
                            <p className='ms-2'>Copy set time to all days</p>
                          </div>
                        ) : !workScheduleformik?.values?.workSchedule[0].isSelected &&
                          index === 1 &&
                          item.isSelected ? (
                          <div className='d-flex' onClick={() => copyToAll(1)}>
                            <img src={copy} alt='copy' className='copy-icon' />
                            <p className='ms-2'>Copy set time to all days</p>
                          </div>
                        ) : !workScheduleformik?.values?.workSchedule[0].isSelected &&
                          !workScheduleformik?.values?.workSchedule[1].isSelected &&
                          index === 2 &&
                          item.isSelected ? (
                          <div className='d-flex' onClick={() => copyToAll(2)}>
                            <img src={copy} alt='copy' className='copy-icon' />
                            <p className='ms-2'>Copy set time to all days</p>
                          </div>
                        ) : !workScheduleformik?.values?.workSchedule[0].isSelected &&
                          !workScheduleformik?.values?.workSchedule[1].isSelected &&
                          !workScheduleformik?.values?.workSchedule[2].isSelected &&
                          index === 3 &&
                          item.isSelected ? (
                          <div className='d-flex' onClick={() => copyToAll(3)}>
                            <img src={copy} alt='copy' className='copy-icon' />
                            <p className='ms-2'>Copy set time to all days</p>
                          </div>
                        ) : !workScheduleformik?.values?.workSchedule[0].isSelected &&
                          !workScheduleformik?.values?.workSchedule[1].isSelected &&
                          !workScheduleformik?.values?.workSchedule[2].isSelected &&
                          !workScheduleformik?.values?.workSchedule[3].isSelected &&
                          index === 4 &&
                          item.isSelected ? (
                          <div className='d-flex' onClick={() => copyToAll(4)}>
                            <img src={copy} alt='copy' className='copy-icon' />
                            <p className='ms-2'>Copy set time to all days</p>
                          </div>
                        ) : !workScheduleformik?.values?.workSchedule[0].isSelected &&
                          !workScheduleformik?.values?.workSchedule[1].isSelected &&
                          !workScheduleformik?.values?.workSchedule[2].isSelected &&
                          !workScheduleformik?.values?.workSchedule[3].isSelected &&
                          !workScheduleformik?.values?.workSchedule[4].isSelected &&
                          index === 5 &&
                          item.isSelected ? (
                          <div className='d-flex' onClick={() => copyToAll(5)}>
                            <img src={copy} alt='copy' className='copy-icon' />
                            <p className='ms-2'>Copy set time to all days</p>
                          </div>
                        ) : !workScheduleformik?.values?.workSchedule[0].isSelected &&
                          !workScheduleformik?.values?.workSchedule[1].isSelected &&
                          !workScheduleformik?.values?.workSchedule[2].isSelected &&
                          !workScheduleformik?.values?.workSchedule[3].isSelected &&
                          !workScheduleformik?.values?.workSchedule[4].isSelected &&
                          !workScheduleformik?.values?.workSchedule[5].isSelected &&
                          index === 6 &&
                          item.isSelected ? (
                          <div className='d-flex' onClick={() => copyToAll(6)}>
                            <img src={copy} alt='copy' className='copy-icon' />
                            <p className='ms-2'>Copy set time to all days</p>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      )}

      {(formik.values.pay_option === 'mile' ||
        formik.values.pay_option === 'percentage' ||
        formik.values.pay_option === 'flat_rate') && (
        <div className='checkbox-wrapper'>
          <div className='checkbox'>
            <input
              type='checkbox'
              className='checkbox-style'
              name='allow_shipment_offers'
              id='allow_shipment_offers'
              value={formik.values.allow_shipment_offers}
              checked={formik.values.allow_shipment_offers === 1 || formik.values.allow_shipment_offers === true}
              onChange={() => {
                formik.setFieldValue('allow_shipment_offers', !formik.values.allow_shipment_offers);
              }}
            />
            <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
              Allow Shipment Offers
            </span>
          </div>
        </div>
      )}

      <div>
        <p
          className='other-payment-conditions-heading d-flex align-items-center'
          style={{ color: use(palette.gray900, palette.gray200) }}
        >
          Other Payment Conditions
          <input
            type='checkbox'
            className='check-input ms-3 mt-1.2'
            name='isCompanyPolice'
            id='isCompanyPolice'
            value={formik.values.isCompanyPolice}
            checked={formik.values.isCompanyPolice}
            onChange={() => {
              formik.setFieldValue('isCompanyPolice', !formik.values.isCompanyPolice);

              const {
                paid_time_off_per_day,
                paid_time_off_required,
                pay_raise_per_hour,
                pay_raise_per_hour_days,
                pay_raise_per_hour_months,
                pay_raise_per_hour_years,
                pay_raise_per_mile_days,
                pay_raise_per_mile_months,
                pay_raise_per_mile_years,
                pay_raise_percentage,
                pay_raise_percentage_days,
                pay_raise_percentage_months,
                pay_raise_percentage_years,
                default_pay_raise,
                pay_on_each_shipment,
                shipment_additions,
                redelivery_pay,
                tarp_pay,
                break_down_pay,
                track_order_not_used_pay,
                live_unload_pay,
                stop_off_pay_each_stop,
                stop_off_pay_after_stop,
                each_layover_pay,
                detention_pay_per_hour,
                detention_pay_after_hours_passed,
                pay_per_hour_break_time_paid,
              } = companyPolicy;
              if (formik.values.isCompanyPolice) {
                return;
              }

              if (paid_time_off_required) {
                formik.setFieldValue('paid_time_off_option', true);
                formik.setFieldValue('paid_time_off_amount', paid_time_off_per_day);
              }
              if (redelivery_pay) {
                formik.setFieldValue('redelivery_option', true);
                formik.setFieldValue('after_first_attempt_amt', redelivery_pay);
              }
              if (tarp_pay) {
                formik.setFieldValue('tarp_pay_option', true);
                formik.setFieldValue('each_stop_amt', tarp_pay);
              }
              if (break_down_pay) {
                formik.setFieldValue('breakdown_option', true);
                formik.setFieldValue('after_breakdown_hrs', break_down_pay);
              }
              if (track_order_not_used_pay) {
                formik.setFieldValue('truck_order_unused_option', true);
                formik.setFieldValue('each_truck_order_unused_amt', track_order_not_used_pay);
              }
              if (live_unload_pay) {
                formik.setFieldValue('live_unload_option', true);
                formik.setFieldValue('unload_amt', live_unload_pay);
              }
              if (stop_off_pay_each_stop || stop_off_pay_after_stop) {
                formik.setFieldValue('stopoff_option', true);
                formik.setFieldValue('per_stop_amt', stop_off_pay_each_stop || '');
                formik.setFieldValue('no_of_stops', stop_off_pay_after_stop || '');
              }
              if (each_layover_pay) {
                formik.setFieldValue('layover_option', true);
                formik.setFieldValue('layover_amt', each_layover_pay);
              }
              if (detention_pay_per_hour || detention_pay_after_hours_passed) {
                formik.setFieldValue('detention_pay_option', true);
                formik.setFieldValue('detention_amt', detention_pay_per_hour || '');
                formik.setFieldValue('detention_hrs', detention_pay_after_hours_passed || '');
              }
              if (pay_per_hour_break_time_paid) {
                formik.setFieldValue('paid_time_off_option', true);
                formik.setFieldValue('paid_time_off_amount', pay_per_hour_break_time_paid);
              }
              if (default_pay_raise) {
                if (formik.values.pay_option === 'per_hour') {
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_amt', pay_raise_per_hour);
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_days', pay_raise_per_hour_days);
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_months', pay_raise_per_hour_months);
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_years', pay_raise_per_hour_years);
                } else if (formik.values.pay_option === 'mile') {
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_amt', '');
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_days', pay_raise_per_mile_days);
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_months', pay_raise_per_mile_months);
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_years', pay_raise_per_mile_years);
                } else if (formik.values.pay_option === 'percentage') {
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_amt', pay_raise_percentage);
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_days', pay_raise_percentage_days);
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_months', pay_raise_percentage_months);
                  formik.setFieldValue('pay_raise[0].pay_raise_schedule_years', pay_raise_percentage_years);
                }
              }
              formik.setFieldValue('pay_on_each_shipment', !!pay_on_each_shipment);
              if (pay_on_each_shipment) {
                const shipmentAdditions = shipment_additions.map((item) => {
                  return {
                    amount: item.amount,
                    payroll_addition: { id: item.payroll_addition.id, type: item.payroll_addition.addition_type },
                  };
                });
                formik.setFieldValue('shipment_addition', shipmentAdditions);
              }
            }}
          />
          <span className='ms-2 text-14'>Apply from Company Policies – Driver Compensation</span>
        </p>
        {paymentConditions.map((value) => (
          <div
            key={value.name1}
            className='d-flex align-items-center justify-content-start mt-2 mb-2 paymentConditionsSwitch'
          >
            <div className='form-switch switch-wrapper'>
              <input
                className='form-check-input'
                type='checkbox'
                role='switch'
                name={value.name1}
                id={value.name1}
                value={formik.values[value.name1]}
                checked={formik.values[value.name1]}
                onChange={() => formik.setFieldValue(value.name1, !formik.values[value.name1])}
              />
              <label
                className='text-style'
                htmlFor='flexSwitchCheckChecked'
                style={{ color: use(palette.gray700, palette.gray200) }}
              >
                {value.paymentCondition}
              </label>
            </div>
            <div className='mb-2'>
              <div className='per-day-work'>
                <span
                  className='doller-icon'
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray400,
                  }}
                >
                  {currency}
                </span>
                <input
                  type='number'
                  placeholder='0'
                  id={value.name2}
                  name={value.name2}
                  disabled={!formik.values[value.name1]}
                  onBlur={formik.handleBlur}
                  onChange={(event) => {
                    if (event.target.value < 0) {
                      return;
                    }
                    formik.setFieldValue(value.name2, event.target.value);
                  }}
                  style={{
                    backgroundColor: use(palette.white, palette.dark600),
                    borderColor: use(palette.gray50, palette.darkborder),
                    color: palette.gray900,
                  }}
                  value={formik.values[value.name2]}
                />
                <span
                  className='text-style input-text-compansation'
                  style={{ color: use(palette.gray500, palette.gray200) }}
                >
                  {value.timePeriod}
                </span>
              </div>
              <ErrorMessage error={formik.touched[value.name2] && formik.errors[value.name2]} />
            </div>
            {value.after ? (
              <div className='d-flex align-items-center'>
                <span className='text-style' style={{ color: use(palette.gray700, palette.gray200) }}>
                  after
                </span>
                <div>
                  <div className='payment-option-second-input mb-1'>
                    <input
                      type='number'
                      placeholder='1'
                      id={value.name3}
                      name={value.name3}
                      min={1}
                      disabled={!formik.values[value.name1]}
                      onBlur={formik.handleBlur}
                      style={{
                        backgroundColor: use(palette.white, palette.dark600),
                        borderColor: use(palette.gray50, palette.darkborder),
                        color: palette.gray900,
                      }}
                      value={formik.values[value.name3]}
                      onChange={(event) => {
                        if (event.target.value < 0) {
                          return;
                        }
                        formik.setFieldValue(value.name3, event.target.value);
                      }}
                    />
                  </div>

                  <ErrorMessage error={formik.touched[value.name3] && formik.errors[value.name3]} />
                </div>
                <span className='text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                  {value.after}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>
        ))}
        <div className='form-switch switch-wrapper switch-wrapper-last '>
          <input
            className='form-check-input'
            type='checkbox'
            role='switch'
            id='is_pay_raise'
            name='is_pay_raise'
            disabled={formik?.values?.pay_option === 'flat_rate'}
            checked={formik.values.is_pay_raise}
            value={formik.values.is_pay_raise}
            onChange={() => formik.setFieldValue('is_pay_raise', !formik.values.is_pay_raise)}
          />
          <label className='text-style' htmlFor='is_pay_raise' style={{ color: use(palette.gray700, palette.gray200) }}>
            Pay Raise Schedule
          </label>
        </div>
        <div className='mb-2 mt-2 paymentConditionsSwitch'>
          {formik.values.is_pay_raise ? (
            <>
              {formik?.values?.pay_raise?.map((item, index) => {
                return (
                  <div key={item.id || index}>
                    <div className='raise-shedule'>
                      <label
                        className='text-style'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        Raise pay by
                      </label>
                      {isDriverProfile ? (
                        formik.values.pay_option === 'mile' ? (
                          <>
                            <div className='per-day-work  me-2'>
                              <span
                                className='doller-icon'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                  color: use(palette.gray500, palette.gray200),
                                }}
                              >
                                {formik.values.pay_option === 'percentage' ? '%' : currency}
                              </span>
                              <input
                                type='number'
                                placeholder='0'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                  color: use(palette.gray500, palette.gray200),
                                }}
                                id={`pay_raise[${index}].loaded`}
                                name={`pay_raise[${index}].loaded`}
                                onBlur={formik.handleBlur}
                                value={formik.values.pay_raise[index].loaded}
                                onKeyDown={(event) => {
                                  ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                                }}
                                onChange={(e) => {
                                  if (e.target.value < 0) {
                                    return;
                                  }
                                  formik?.setFieldValue(`pay_raise[${index}].loaded`, Number(e.target.value) || '');
                                }}
                              />
                              <span
                                className='text-style'
                                style={{
                                  color: use(palette.gray500, palette.gray200),
                                }}
                              >
                                Loaded
                              </span>
                            </div>
                            <div className='per-day-work'>
                              <span
                                className='doller-icon'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                  color: use(palette.gray500, palette.gray200),
                                }}
                              >
                                {formik.values.pay_option === 'percentage' ? '%' : currency}
                              </span>
                              <input
                                type='number'
                                placeholder='0'
                                style={{
                                  backgroundColor: use(palette.white, palette.dark600),
                                  borderColor: use(palette.gray50, palette.darkborder),
                                }}
                                id={`pay_raise[${index}].empty`}
                                name={`pay_raise[${index}].empty`}
                                onBlur={formik.handleBlur}
                                value={formik.values.pay_raise[index].empty}
                                onKeyDown={(event) => {
                                  ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                                }}
                                onChange={(e) => {
                                  if (e.target.value < 0) {
                                    return;
                                  }
                                  formik?.setFieldValue(`pay_raise[${index}].empty`, Number(e.target.value) || '');
                                }}
                              />
                              <span
                                className='text-style'
                                style={{
                                  color: use(palette.gray500, palette.gray200),
                                }}
                              >
                                Empty
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className='per-day-work'>
                            <span
                              className='doller-icon'
                              style={{
                                backgroundColor: use(palette.white, palette.dark600),
                                borderColor: use(palette.gray50, palette.darkborder),
                                color: palette.gray400,
                              }}
                            >
                              {formik.values.pay_option === 'percentage' ? '%' : currency}
                            </span>
                            <input
                              type='number'
                              placeholder='0'
                              style={{
                                backgroundColor: use(palette.white, palette.dark600),
                                borderColor: use(palette.gray50, palette.darkborder),
                              }}
                              id={`pay_raise[${index}].pay_raise_schedule_amt`}
                              name={`pay_raise[${index}].pay_raise_schedule_amt`}
                              onBlur={formik.handleBlur}
                              value={formik.values.pay_raise[index].pay_raise_schedule_amt}
                              onKeyDown={(event) => {
                                ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                              }}
                              onChange={(e) => {
                                if (e.target.value < 0) {
                                  return;
                                }
                                formik?.setFieldValue(`pay_raise[${index}].pay_raise_schedule_amt`, e.target.value);
                              }}
                            />
                            <span
                              className='text-style'
                              style={{
                                color: use(palette.gray500, palette.gray200),
                              }}
                            >
                              {formik.values.pay_option === 'per_hour'
                                ? 'per hour'
                                : formik.values.pay_option === 'mile'
                                ? 'per mile'
                                : formik.values.pay_option === 'percentage'
                                ? 'percentage'
                                : formik.values.pay_option === 'flat_rate'
                                ? 'flat rate'
                                : ''}
                            </span>
                          </div>
                        )
                      ) : (
                        <div className='per-day-work'>
                          <span
                            className='doller-icon'
                            style={{
                              backgroundColor: use(palette.white, palette.dark600),
                              borderColor: use(palette.gray50, palette.darkborder),
                              color: palette.gray400,
                            }}
                          >
                            {formik.values.pay_option === 'percentage' ? '%' : currency}
                          </span>
                          <input
                            type='number'
                            placeholder='0'
                            style={{
                              backgroundColor: use(palette.white, palette.dark600),
                              borderColor: use(palette.gray50, palette.darkborder),
                            }}
                            id={`pay_raise[${index}].pay_raise_schedule_amt`}
                            name={`pay_raise[${index}].pay_raise_schedule_amt`}
                            onBlur={formik.handleBlur}
                            value={formik.values.pay_raise[index].pay_raise_schedule_amt}
                            onKeyDown={(event) => {
                              ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                            }}
                            onChange={(e) => {
                              if (e.target.value < 0) {
                                return;
                              }
                              formik?.setFieldValue(`pay_raise[${index}].pay_raise_schedule_amt`, e.target.value);
                            }}
                          />
                          <span
                            className='text-style'
                            style={{
                              color: use(palette.gray500, palette.gray200),
                            }}
                          >
                            {formik.values.pay_option === 'per_hour'
                              ? 'per hour'
                              : formik.values.pay_option === 'mile'
                              ? 'per mile'
                              : formik.values.pay_option === 'percentage'
                              ? 'percentage'
                              : formik.values.pay_option === 'flat_rate'
                              ? 'flat rate'
                              : ''}
                          </span>
                        </div>
                      )}

                      <label
                        className='text-style'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        after
                      </label>
                      <div className='work-days'>
                        <input
                          type='number'
                          placeholder='0'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                          }}
                          id={`pay_raise[${index}].pay_raise_schedule_days`}
                          name={`pay_raise[${index}].pay_raise_schedule_days`}
                          onBlur={formik.handleBlur}
                          value={formik.values.pay_raise[index].pay_raise_schedule_days}
                          onKeyDown={(event) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            formik?.setFieldValue(`pay_raise[${index}].pay_raise_schedule_days`, e.target.value);
                          }}
                        />
                        <span className='text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                          Days
                        </span>
                      </div>
                      <div className='work-days'>
                        <input
                          type='number'
                          placeholder='0'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                          }}
                          id={`pay_raise[${index}].pay_raise_schedule_months`}
                          name={`pay_raise[${index}].pay_raise_schedule_months`}
                          onBlur={formik.handleBlur}
                          value={formik.values.pay_raise[index].pay_raise_schedule_months}
                          onKeyDown={(event) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            formik?.setFieldValue(`pay_raise[${index}].pay_raise_schedule_months`, e.target.value);
                          }}
                        />
                        <span className='text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                          Months
                        </span>
                      </div>
                      <div className='work-days'>
                        <input
                          type='number'
                          placeholder='0'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                          }}
                          id={`pay_raise[${index}].pay_raise_schedule_years`}
                          name={`pay_raise[${index}].pay_raise_schedule_years`}
                          value={formik.values.pay_raise[index].pay_raise_schedule_years}
                          onBlur={formik.handleBlur}
                          onKeyDown={(event) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            formik?.setFieldValue(`pay_raise[${index}].pay_raise_schedule_years`, e.target.value);
                          }}
                        />
                        <span className='text-style' style={{ color: use(palette.gray500, palette.gray200) }}>
                          Years
                        </span>
                      </div>
                      <div>
                        {index > 0 ? (
                          <div className=''>
                            <img src={cross} alt='' onClick={() => remove(index, 'pay_raise')} />
                          </div>
                        ) : (
                          <span />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className='add-another'>
                <img src={add} className='add-employee-icon' alt='add' />
                <button
                  onClick={() =>
                    formik.setFieldValue('pay_raise', [
                      ...formik.values.pay_raise,
                      {
                        driver_id: driver_id || driverId,
                        pay_raise_schedule_amt: '',
                        pay_raise_schedule_days: 0,
                        pay_raise_schedule_months: 0,
                        pay_raise_schedule_years: 0,
                      },
                    ])
                  }
                  className='add-employer-button'
                >
                  Add Another
                </button>
              </div>
            </>
          ) : null}
        </div>
        <div className='form-switch switch-wrapper switch-wrapper-last '>
          <input
            className='form-check-input'
            type='checkbox'
            role='switch'
            id='pay_on_each_shipment'
            name='pay_on_each_shipment'
            checked={formik.values.pay_on_each_shipment}
            value={formik.values.pay_on_each_shipment}
            onChange={() => formik.setFieldValue('pay_on_each_shipment', !formik.values.pay_on_each_shipment)}
          />
          <label
            className='text-style'
            htmlFor='pay_on_each_shipment'
            style={{ color: use(palette.gray700, palette.gray200) }}
          >
            Additions on Shipments
          </label>
        </div>
        <div>
          {!!formik.values.pay_on_each_shipment && (
            <>
              {formik?.values?.shipment_addition?.map((item, index) => {
                return (
                  <div key={item.id || index}>
                    <div className='raise-shedule'>
                      <label
                        className='text-style'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        Pay On Each Shipment
                      </label>

                      <div className='per-day-work  me-2'>
                        <span
                          className='doller-icon'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                            color: use(palette.gray500, palette.gray200),
                          }}
                        >
                          {currency}
                        </span>
                        <input
                          type='number'
                          placeholder='0'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                            color: use(palette.gray500, palette.gray200),
                            borderRight: 'none',
                            borderRadius: '6px',
                          }}
                          id={`shipment_addition[${index}].amount`}
                          name={`shipment_addition[${index}].amount`}
                          onBlur={formik.handleBlur}
                          value={formik.values.shipment_addition[index].amount}
                          onKeyDown={(event) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            formik?.setFieldValue(`shipment_addition[${index}].amount`, Number(e.target.value) || '');
                          }}
                        />
                      </div>

                      <label
                        className='text-style'
                        htmlFor='flexSwitchCheckChecked'
                        style={{ color: use(palette.gray700, palette.gray200) }}
                      >
                        for
                      </label>
                      <Autocomplete
                        width='250px'
                        labelKey='type'
                        value={formik.values.shipment_addition[index].payroll_addition}
                        onChange={(e, val) => {
                          formik?.setFieldValue(`shipment_addition[${index}].payroll_addition`, val);
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        options={addtionType}
                      />
                      <GearIcon className='ms-2 me-2' onClick={() => setOpenSettings('additions')} />
                      <div>
                        {index > 0 ? (
                          <div className=''>
                            <img src={cross} alt='' onClick={() => remove(index, 'shipment_addition')} />
                          </div>
                        ) : (
                          <span />
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className='add-another'>
                <img src={add} className='add-employee-icon' alt='add' />
                <button
                  onClick={() =>
                    formik.setFieldValue('shipment_addition', [
                      ...formik.values.shipment_addition,
                      {
                        payroll_addition: null,
                        amount: '',
                      },
                    ])
                  }
                  className='add-employer-button'
                >
                  Add Another
                </button>
              </div>
            </>
          )}
        </div>

        {isDriverProfile ? (
          <>
            <div>
              <div>
                <div className='deduction-header-wrap'>
                  <div className='d-flex align-items-center gap-3'>
                    <p
                      className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                      style={{ color: use(palette.gray900, palette.gray200) }}
                    >
                      Balances
                    </p>
                    <Tooltip
                      placement='right'
                      title={
                        <Typography variant='s2' style={{ color: palette.gray700 }}>
                          Truckin Digital Balances is a feature that enables companies to manage and track financial
                          balances for their drivers or staff members. Users can add specific balances for each driver
                          or staff member, allowing the company to maintain a clear overview of any deductions or
                          outstanding balances.
                        </Typography>
                      }
                      tooltipStyles={{ bgcolor: palette.white, border: '1px solid #dadde9', maxWidth: '600px' }}
                    >
                      <InfoIcon width={16} height={16} />
                    </Tooltip>
                  </div>
                </div>
                <div className='compensation-feilds-header-wraper compensation-types-header-wraper-view-mode'>
                  <p
                    className='compensation-feilds-header-head compensation-amount-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Date Created
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Name
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-amount-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Type
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-amount-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Payments
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Payment Amount
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Term
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Original Balance
                  </p>
                  <p
                    className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                    style={{ color: use(palette.gray700, palette.gray50) }}
                  >
                    Balance Remain
                  </p>
                </div>
                {balances?.map((item) => {
                  const processedSettlements = item.deductions?.[0]?.settlement?.filter(
                    (settlement) => settlement.driver_settlement.status_id === 3
                  );
                  const paidAmount = (processedSettlements || []).reduce((acc, cur) => acc + Number(cur.amount), 0);

                  return (
                    <div
                      key={item.id}
                      className='compensation-types-header-wraper compensation-types-header-wraper-view-mode balance-row'
                      onClick={() => onViewBalanceDetails(item)}
                    >
                      <div className='compensation-amount-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                          {convertToCustomerTime(item.created_at)}
                        </p>
                      </div>
                      <div className='compensation-datepicker-main-wrap'>
                        <p className='payment-property mb-0' style={{ color: use(palette.gray700, palette.gray200) }}>
                          {item.name}
                        </p>
                      </div>
                      <div className='compensation-amount-main-wrap' style={{ display: 'flex' }}>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {item.type}
                        </p>
                      </div>
                      <div className='compensation-amount-main-wrap'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {(processedSettlements || []).length} out of {item.amortized_months}
                        </p>
                      </div>
                      <div className='compensation-datepicker-main-wrap'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {currency}
                          {formatAmount(item.payment_amount)}
                        </p>
                      </div>
                      <div className='compensation-custom-select-main-wrap'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {item.month_week_settlement
                            ? `Every ${item.day} of the ${item.month_week}`
                            : `Over ${item.amortized_months} settlements`}
                        </p>
                      </div>
                      <div className='compensation-datepicker-main-wrap'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {currency}
                          {formatAmount(item.original_balance)}
                        </p>
                      </div>
                      <div className='compensation-datepicker-main-wrap'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap  mb-0'
                          style={{ color: use(palette.gray900, palette.gray50) }}
                        >
                          {currency}
                          {formatAmount(item.original_balance - paidAmount)}
                        </p>
                      </div>
                      <div className='compensation-datepicker-main-wrap'>
                        <p
                          className='compensation-feilds-header-head compensation-custom-select-main-wrap delete-action-text  mb-0'
                          onClick={(e) => {
                            e.stopPropagation();
                            setBalanceToDeleteId(item.id);
                          }}
                        >
                          Delete
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='add-another compensation-add-another-feild'>
                <img src={add} className='add-employee-icon' alt='add' />
                <button className='add-employer-button' onClick={() => setOpenAddBalance(true)}>
                  Add Another
                </button>
              </div>
            </div>
            <div>
              <div className='deduction-header-wrap'>
                <p
                  className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                  style={{ color: use(palette.gray900, palette.gray200) }}
                >
                  Deductions
                </p>
              </div>
              <div className='compensation-feilds-header-wraper'>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date / Time
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Deduction Type
                  <GearIcon onClick={() => setOpenSettings('deductions')} className='ms-2' />
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Amount Per Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Total
                </p>
                <p className='compensation-feilds-header-head' style={{ color: use(palette.gray700, palette.gray50) }}>
                  Set Interval
                </p>
              </div>
              {deductionFormik?.values?.deductions?.map((item, index) => {
                if (item.one_time_deduction) {
                  return null;
                }

                return (
                  <div className='compensation-types-header-wraper' key={item.id || index}>
                    <div className='compensation-datepicker-main-wrap'>
                      <div
                        className='compensation-datepicker-wrapper'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      >
                        <DatePicker
                          selected={deductionFormik?.values?.deductions[index]?.date}
                          showYearDropdown
                          placeholderText='mm/dd/yyyy'
                          className='text-style ms-0'
                          id={`deductions[${index}].date`}
                          name={`deductions[${index}].date`}
                          onBlur={deductionFormik?.handleBlur}
                          value={deductionFormik?.values?.deductions[index]?.date}
                          onChange={(date) => {
                            deductionFormik?.setFieldValue(`deductions[${index}].date`, date);
                          }}
                          showTimeInput
                          disabled={!!item?.is_balance_deduction}
                        />
                        <span className='text-style'>
                          {' '}
                          <img src={sorting} alt='calendar' />
                        </span>
                      </div>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <div
                        className='compensation-custom-select-wrapper'
                        style={{ borderBottomColor: use(palette.gray50, palette.darkborder) }}
                      >
                        <div className='compensation-custom-select'>
                          <CustomSelect
                            defaultValue='Select'
                            options={deductionType}
                            onBlur={deductionFormik.handleBlur}
                            id={`deductions[${index}].deduction_type_id`}
                            name={`deductions[${index}].deduction_type_id`}
                            value={deductionFormik?.values?.deductions[index]?.deduction_type_id}
                            propertyName='type'
                            onChange={(val) => {
                              const option = deductionType?.find((item) => item.id === Number(val));

                              if (option) {
                                deductionFormik?.setFieldValue(`deductions[${index}]`, {
                                  ...deductionFormik?.values?.deductions[index],
                                  deduction_type_id: val,
                                  quantity: 1,
                                  per_quantity_amount: Number(option.amount),
                                  amount: option.amount,
                                });
                              } else {
                                deductionFormik?.setFieldValue(`deductions[${index}].deduction_type_id`, val);
                              }
                            }}
                            disabled={!!item?.is_balance_deduction}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <div className='compensation-quantity-wrapper'>
                        <SInput
                          type='number'
                          value={item.quantity || ''}
                          onChange={(e) => {
                            if (e.target.value < 1) {
                              return;
                            }
                            deductionFormik?.setFieldValue(`deductions[${index}].quantity`, e.target.value);
                            deductionFormik?.setFieldValue(
                              `deductions[${index}].amount`,
                              Number(e.target.value) * Number(item.per_quantity_amount)
                            );
                          }}
                          onKeyDown={(e) => {
                            blockNonPositiveNumbers(e);
                            blockNotNumberChars(e);
                          }}
                          disabled={!!item?.is_balance_deduction}
                        />
                      </div>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <div className='compensation-amount-wrapper'>
                        <span
                          className='doller-icon'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                            color: palette.gray400,
                          }}
                        >
                          {currency}
                        </span>
                        <input
                          type='number'
                          placeholder='0.00'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                          }}
                          name={`deductions[${index}].per_quantity_amount`}
                          id={`deductions[${index}].per_quantity_amount`}
                          onKeyDown={(event) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            deductionFormik?.setFieldValue(`deductions[${index}].per_quantity_amount`, e.target.value);
                            deductionFormik?.setFieldValue(
                              `deductions[${index}].amount`,
                              Number(e.target.value) * Number(item.quantity)
                            );
                          }}
                          value={
                            item?.is_balance_deduction
                              ? formatNumber(item?.amount)
                              : deductionFormik?.values?.deductions[index]?.per_quantity_amount
                          }
                          disabled={!!item?.is_balance_deduction}
                        />
                      </div>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <div style={{ marginTop: '8px' }}>
                        {!!item.is_balance_deduction && !!item.isFulfilledBalance ? (
                          <Chip label='BALANCE FULFILLED' labelColor={palette.green500} bgColor={palette.green0} />
                        ) : (
                          <Typography variant='s3'>
                            - {currency}
                            {formatNumber(item.amount)}
                          </Typography>
                        )}
                      </div>
                    </div>
                    <div
                      className={`compensation-recurring-wraper ${
                        item.amortized_settlement === 1 ? 'type-compensation-recurring-wraper' : ''
                      }`}
                    >
                      {item.every_settlement === 1 && <p className='taxes-compensation-subline'>Every Settlement</p>}
                      {item.month_week_settlement === 1 && (
                        <>
                          <label
                            className='text-style compensation-text-style-lable'
                            htmlFor='flexSwitchCheckChecked'
                            style={{ color: use(palette.gray700, palette.gray200) }}
                          >
                            Every
                          </label>
                          <Dropdown
                            className='custom-dropdown-in-compensation'
                            onSelect={(value) => {
                              deductionFormik?.setFieldValue(`deductions[${index}].day`, value);
                            }}
                          >
                            <Dropdown.Toggle
                              placement='top'
                              variant='outline-secondary'
                              id='dropdown-basic'
                              className='tablePlaner_dropdownToggle compensation__dropdownToggle'
                              style={{
                                color: use(palette.gray700, palette.gray200),
                                backgroundColor: use(palette.white, palette.dark800),
                              }}
                              disabled={!!item?.is_balance_deduction}
                            >
                              {deductionFormik?.values?.deductions[index]?.day}
                            </Dropdown.Toggle>
                            {item?.month_week === 'Month' ? (
                              <Dropdown.Menu
                                className='tablePlaner_menu compansation_menu'
                                style={{ backgroundColor: use(palette.white, palette.dark800) }}
                              >
                                {month?.map((el, index) => {
                                  return (
                                    <Dropdown.Item
                                      eventKey={el}
                                      active={deductionFormik?.values?.deductions[index]?.day === el}
                                      key={el}
                                      style={{
                                        color:
                                          deductionFormik?.values?.deductions[index]?.day === el
                                            ? use(palette.white, palette.white)
                                            : use(palette.gray700, palette.gray200),
                                      }}
                                    >
                                      {el}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            ) : (
                              <Dropdown.Menu
                                className='tablePlaner_menu compansation_menu'
                                style={{ backgroundColor: use(palette.white, palette.dark800) }}
                              >
                                {weekData?.map((el, index) => {
                                  return (
                                    <Dropdown.Item
                                      eventKey={el}
                                      active={deductionFormik?.values?.deductions[index]?.day === el}
                                      key={el}
                                      style={{
                                        color:
                                          deductionFormik?.values?.deductions[index]?.day === el
                                            ? use(palette.white, palette.white)
                                            : use(palette.gray700, palette.gray200),
                                      }}
                                    >
                                      {el}
                                    </Dropdown.Item>
                                  );
                                })}
                              </Dropdown.Menu>
                            )}
                          </Dropdown>
                          <label
                            className='text-style compensation-text-style-lable-of'
                            htmlFor='flexSwitchCheckChecked'
                            style={{ color: use(palette.gray700, palette.gray200) }}
                          >
                            of the
                          </label>
                          <Dropdown
                            className='custom-dropdown-in-compensation'
                            onSelect={(value) => {
                              deductionFormik.setFieldValue(`deductions[${index}].month_week`, value);
                              if (value === 'Month') {
                                deductionFormik.setFieldValue(`deductions[${index}].day`, '1st');
                              } else {
                                deductionFormik.setFieldValue(`deductions[${index}].day`, 'Monday');
                              }
                            }}
                          >
                            <Dropdown.Toggle
                              placement='top'
                              variant='outline-secondary'
                              id='dropdown-basic'
                              className='tablePlaner_dropdownToggle  compensation__dropdownToggle'
                              style={{
                                color: use(palette.gray700, palette.gray200),
                                backgroundColor: use(palette.white, palette.dark800),
                              }}
                              disabled={!!item?.is_balance_deduction}
                            >
                              {deductionFormik?.values?.deductions[index]?.month_week}
                              <div className='tablePlaner_arrows_wrapper'>
                                <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
                              </div>
                            </Dropdown.Toggle>

                            <Dropdown.Menu
                              className='tablePlaner_menu compansation_menu'
                              style={{ backgroundColor: use(palette.white, palette.dark800) }}
                            >
                              {monthData?.map((el, index) => {
                                return (
                                  <Dropdown.Item
                                    eventKey={el}
                                    active={deductionFormik?.values?.deductions[index]?.month_week === el}
                                    key={el}
                                    style={{
                                      color:
                                        deductionFormik?.values?.deductions[index]?.month_week === el
                                          ? use(palette.white, palette.white)
                                          : use(palette.gray700, palette.gray200),
                                    }}
                                  >
                                    {el}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </>
                      )}
                      {item.amortized_settlement === 1 && (
                        <>
                          <label
                            className='text-style compensation-text-style-lable'
                            htmlFor='flexSwitchCheckChecked'
                            style={{ color: use(palette.gray700, palette.gray200) }}
                          >
                            Amortized
                          </label>
                          <div className='compensation-amount-wrapper mt-0'>
                            <span
                              className='doller-icon'
                              style={{
                                backgroundColor: use(palette.white, palette.dark600),
                                borderColor: use(palette.gray50, palette.darkborder),
                                color: palette.gray400,
                              }}
                            >
                              {currency}
                            </span>
                            <input
                              type='number'
                              placeholder='0.00'
                              style={{
                                backgroundColor: use(palette.white, palette.dark600),
                                borderColor: use(palette.gray50, palette.darkborder),
                              }}
                              name={`deductions[${index}].amortized_total`}
                              id={`deductions[${index}].amortized_total`}
                              onChange={deductionFormik?.handleChange}
                              value={deductionFormik?.values?.deductions[index]?.amortized_total}
                              disabled={!!item?.is_balance_deduction}
                            />
                          </div>
                          <label
                            className='text-style compensation-text-style-lable-of'
                            htmlFor='flexSwitchCheckChecked'
                            style={{ color: use(palette.gray700, palette.gray200) }}
                          >
                            over
                          </label>
                          <div
                            className='re-enter-routing-in-recurring'
                            style={{
                              backgroundColor: use(palette.white, palette.dark600),
                              borderColor: use(palette.gray50, palette.darkborder),
                            }}
                          >
                            <input
                              type='number'
                              style={{ backgroundColor: use(palette.white, palette.dark600) }}
                              className='input-field'
                              placeholder='00'
                              name={`deductions[${index}].amortized_months`}
                              id={`deductions[${index}].amortized_months`}
                              onChange={deductionFormik?.handleChange}
                              value={deductionFormik?.values?.deductions[index]?.amortized_months}
                              disabled={!!item?.is_balance_deduction}
                            />
                          </div>
                          <label
                            className='text-style compensation-text-style-lable-of'
                            htmlFor='flexSwitchCheckChecked'
                            style={{ color: use(palette.gray700, palette.gray200) }}
                          >
                            settlements, deducted on each settlement.
                          </label>
                        </>
                      )}
                      <div className='setting-icon-container taxes-setting-icon-container'>
                        {!item?.is_balance_deduction ? (
                          <img
                            src={setting}
                            alt=''
                            onClick={() => {
                              setRecurringModalShow(true);
                              setRecurringModalData({ ...item, index });
                            }}
                          />
                        ) : (
                          <span
                            className='download-sample'
                            onClick={() => onViewBalanceDetails(balances.find((el) => el.id === item.balanceId))}
                          >
                            Edit Payment
                          </span>
                        )}
                      </div>
                      {!item?.is_balance_deduction && (
                        <div className=''>
                          <img src={cross} alt='' onClick={() => remove(index, 'deductions')} />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className='add-another compensation-add-another-feild'>
                <img src={add} className='add-employee-icon' alt='add' />
                <button
                  className='add-employer-button'
                  onClick={() =>
                    deductionFormik.setFieldValue('deductions', [
                      ...deductionFormik.values.deductions,
                      {
                        driver_id: driverId,
                        date: new Date(convertToCustomerTime()),
                        time: '',
                        deduction_type_id: 0,
                        quantity: 1,
                        per_quantity_amount: '',
                        amount: 0,
                        every_settlement: 0,
                        month_week_settlement: 1,
                        amortized_settlement: 0,
                        day: 'Monday',
                        month_week: 'Week',
                        amortized_total: 0,
                        amortized_month: 0,
                        amortized_months: 0,
                        deduction_id: 'new',
                        type: 'deductions',
                      },
                    ])
                  }
                >
                  Add Another
                </button>
              </div>
            </div>
            <div>
              <div className='deduction-header-wrap'>
                <p
                  className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                  style={{ color: use(palette.gray900, palette.gray200) }}
                >
                  Additions
                </p>
              </div>
              <div className='compensation-feilds-header-wraper'>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date / Time
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Addition Type
                  <GearIcon onClick={() => setOpenSettings('additions')} className='ms-2' />
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Amount Per Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Total
                </p>
                <p className='compensation-feilds-header-head' style={{ color: use(palette.gray700, palette.gray50) }}>
                  Set Interval
                </p>
              </div>

              {Additionsformik?.values?.additions?.map((item, index) => {
                return (
                  <div className='compensation-types-header-wraper' key={item.id || index}>
                    <div className='compensation-datepicker-main-wrap'>
                      <div
                        className='compensation-datepicker-wrapper'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      >
                        <DatePicker
                          selected={Additionsformik?.values?.additions[index]?.date}
                          showYearDropdown
                          placeholderText='mm/dd/yyyy'
                          className={theme?.appTheme === 3 ? 'text-style-dark' : 'text-style ms-0'}
                          id={`additions[${index}].date`}
                          name={`additions[${index}].date`}
                          onBlur={Additionsformik?.handleBlur}
                          value={Additionsformik?.values?.additions[index]?.date}
                          onChange={(date) => {
                            Additionsformik?.setFieldValue(`additions[${index}].date`, date);
                          }}
                          showTimeInput
                        />
                        <span className='text-style'>
                          {' '}
                          <img src={sorting} alt='calendar' />
                        </span>
                      </div>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <div
                        className='compensation-custom-select-wrapper'
                        style={{
                          borderBottomColor: use(palette.gray50, palette.darkborder),
                        }}
                      >
                        <div className='compensation-custom-select'>
                          <CustomSelect
                            defaultValue='Select'
                            options={addtionType}
                            onBlur={Additionsformik.handleBlur}
                            id={`additions[${index}].addition_type_id`}
                            name={`additions[${index}].addition_type_id`}
                            onChange={(val) => {
                              const option = addtionType?.find((item) => item.id === Number(val));
                              if (option) {
                                Additionsformik?.setFieldValue(`additions[${index}]`, {
                                  ...Additionsformik?.values?.additions[index],
                                  addition_type_id: val,
                                  quantity: 1,
                                  per_quantity_amount: Number(option.amount),
                                  amount: option.amount,
                                });
                              } else {
                                Additionsformik?.setFieldValue(`additions[${index}].addition_type_id`, val);
                              }
                            }}
                            value={Additionsformik?.values?.additions[index]?.addition_type_id}
                            propertyName='type'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <div className='compensation-quantity-wrapper'>
                        <SInput
                          type='number'
                          value={item.quantity || ''}
                          onChange={(e) => {
                            if (e.target.value < 1) {
                              return;
                            }
                            Additionsformik?.setFieldValue(`additions[${index}].quantity`, e.target.value);
                            Additionsformik?.setFieldValue(
                              `additions[${index}].amount`,
                              Number(e.target.value) * Number(item.per_quantity_amount)
                            );
                          }}
                          onKeyDown={(e) => {
                            blockNonPositiveNumbers(e);
                            blockNotNumberChars(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <div className='compensation-amount-wrapper'>
                        <span
                          className='doller-icon'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                            color: palette.gray400,
                          }}
                        >
                          {currency}
                        </span>
                        <input
                          type='number'
                          placeholder='0.00'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                          }}
                          name={`additions[${index}].per_quantity_amount`}
                          id={`additions[${index}].per_quantity_amount`}
                          onKeyDown={(event) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            Additionsformik?.setFieldValue(`additions[${index}].per_quantity_amount`, e.target.value);
                            Additionsformik?.setFieldValue(
                              `additions[${index}].amount`,
                              Number(e.target.value) * Number(item.quantity)
                            );
                          }}
                          value={Additionsformik?.values?.additions[index]?.per_quantity_amount}
                        />
                      </div>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <div style={{ marginTop: '8px' }}>
                        <Typography variant='s3'>
                          {currency}
                          {formatNumber(item.amount)}
                        </Typography>
                      </div>
                    </div>
                    {item.every_settlement === 0 ? (
                      <div className='compensation-recurring-wraper'>
                        <label
                          className='text-style compensation-text-style-lable'
                          htmlFor='flexSwitchCheckChecked'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                          }}
                        >
                          Every
                        </label>
                        <Dropdown
                          className='custom-dropdown-in-compensation'
                          onSelect={(value) => {
                            Additionsformik?.setFieldValue(`additions[${index}].day`, value);
                          }}
                        >
                          <Dropdown.Toggle
                            placement='top'
                            variant='outline-secondary'
                            id='dropdown-basic'
                            className='tablePlaner_dropdownToggle compensation__dropdownToggle'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              backgroundColor: use(palette.white, palette.dark800),
                            }}
                          >
                            {Additionsformik?.values?.additions[index]?.day}
                          </Dropdown.Toggle>

                          {item?.month_week === 'Month' ? (
                            <Dropdown.Menu
                              className='tablePlaner_menu compansation_menu'
                              style={{
                                backgroundColor: use(palette.white, palette.dark800),
                              }}
                            >
                              {month?.map((el, index) => {
                                return (
                                  <Dropdown.Item
                                    eventKey={el}
                                    active={Additionsformik?.values?.additions[index]?.day === el}
                                    key={el}
                                    style={{
                                      color:
                                        Additionsformik?.values?.additions[index]?.day === el
                                          ? use(palette.white, palette.white)
                                          : use(palette.gray700, palette.gray200),
                                    }}
                                  >
                                    {el}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          ) : (
                            <Dropdown.Menu
                              className='tablePlaner_menu compansation_menu'
                              style={{
                                backgroundColor: use(palette.white, palette.dark800),
                              }}
                            >
                              {weekData?.map((el, index) => {
                                return (
                                  <Dropdown.Item
                                    eventKey={el}
                                    active={Additionsformik?.values?.additions[index]?.day === el}
                                    key={el}
                                    style={{
                                      color:
                                        Additionsformik?.values?.additions[index]?.day === el
                                          ? use(palette.white, palette.white)
                                          : use(palette.gray700, palette.gray200),
                                    }}
                                  >
                                    {el}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                        <label
                          className='text-style compensation-text-style-lable-of'
                          htmlFor='flexSwitchCheckChecked'
                          style={{ color: use(palette.gray700, palette.gray200) }}
                        >
                          per
                        </label>
                        <Dropdown
                          className='custom-dropdown-in-compensation'
                          onSelect={(value) => {
                            Additionsformik.setFieldValue(`additions[${index}].month_week`, value);
                            if (value === 'Month') {
                              Additionsformik.setFieldValue(`additions[${index}].day`, '1st');
                            } else {
                              Additionsformik.setFieldValue(`additions[${index}].day`, 'Monday');
                            }
                          }}
                        >
                          <Dropdown.Toggle
                            placement='top'
                            variant='outline-secondary'
                            id='dropdown-basic'
                            className='tablePlaner_dropdownToggle  compensation__dropdownToggle'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              backgroundColor: use(palette.white, palette.dark800),
                            }}
                          >
                            {Additionsformik?.values?.additions[index]?.month_week}
                            <div className='tablePlaner_arrows_wrapper'>
                              <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className='tablePlaner_menu compansation_menu'
                            style={{
                              backgroundColor: use(palette.white, palette.dark800),
                            }}
                          >
                            {monthData?.map((el, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={el}
                                  active={Additionsformik?.values?.additions[index]?.month_week === el}
                                  key={el}
                                  style={{
                                    color:
                                      Additionsformik?.values?.additions[index]?.month_week === el
                                        ? use(palette.white, palette.white)
                                        : use(palette.gray700, palette.gray200),
                                  }}
                                >
                                  {el}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className='setting-icon-container'>
                          <img
                            src={setting}
                            alt=''
                            onClick={() => {
                              setRecurringModalShow(true);
                              setRecurringModalData({ ...item, index });
                            }}
                          />
                        </div>
                        <div className=''>
                          <img src={cross} alt='' onClick={() => remove(index, 'additions')} />
                        </div>
                      </div>
                    ) : (
                      <div className='compensation-recurring-wraper'>
                        <p className='taxes-compensation-subline'>Every Settlement</p>
                        <div className='setting-icon-container taxes-setting-icon-container'>
                          <img
                            src={setting}
                            alt=''
                            onClick={() => {
                              setRecurringModalShow(true);
                              setRecurringModalData({ ...item, index });
                            }}
                          />
                        </div>
                        <div className=''>
                          <img src={cross} alt='' onClick={() => remove(index, 'additions')} />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              <div className='add-another compensation-add-another-feild'>
                <img src={add} className='add-employee-icon' alt='add' />
                <button
                  className='add-employer-button'
                  onClick={() =>
                    Additionsformik.setFieldValue('additions', [
                      ...Additionsformik.values.additions,
                      {
                        driver_id: driverId,
                        date: new Date(convertToCustomerTime()),
                        time: '',
                        addition_type_id: null,
                        quantity: 1,
                        per_quantity_amount: '',
                        amount: 0,
                        every_settlement: 0,
                        month_week_settlement: 1,
                        day: 'Monday',
                        month_week: 'Week',
                        addition_id: 'new',
                        type: 'additions',
                      },
                    ])
                  }
                >
                  Add Another
                </button>
              </div>
            </div>

            <div>
              <div className='deduction-header-wrap'>
                <p
                  className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                  style={{ color: use(palette.gray900, palette.gray200) }}
                >
                  Taxes
                </p>
              </div>
              <div className='compensation-feilds-header-wraper'>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date / Time
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Tax Description
                  <GearIcon onClick={() => setOpenSettings('taxes')} className='ms-2' />
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Percent
                </p>
                <p className='compensation-feilds-header-head' style={{ color: use(palette.gray700, palette.gray50) }}>
                  Set Interval
                </p>
              </div>
              {taxesformik?.values?.taxes?.map((item, index) => {
                return (
                  <div className='compensation-types-header-wraper' key={item?.id || index}>
                    <div className='compensation-datepicker-main-wrap'>
                      <div
                        className='compensation-datepicker-wrapper'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      >
                        <DatePicker
                          selected={taxesformik?.values?.taxes[index]?.date}
                          showYearDropdown
                          placeholderText='mm/dd/yyyy'
                          className={theme?.appTheme === 3 ? 'text-style-dark' : 'text-style ms-0'}
                          id={`taxes[${index}].date`}
                          name={`taxes[${index}].date`}
                          onBlur={taxesformik?.handleBlur}
                          value={taxesformik?.values?.taxes[index]?.date}
                          onChange={(date) => {
                            taxesformik?.setFieldValue(`taxes[${index}].date`, date);
                          }}
                          showTimeInput
                        />
                        <span className='text-style'>
                          {' '}
                          <img src={sorting} alt='calendar' />
                        </span>
                      </div>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <div
                        className='compensation-custom-select-wrapper'
                        style={{
                          borderBottomColor: use(palette.gray50, palette.darkborder),
                        }}
                      >
                        <div className='compensation-custom-select'>
                          <CustomSelect
                            defaultValue='Select'
                            options={taxesType}
                            onBlur={taxesformik.handleBlur}
                            id={`taxes[${index}].tax_type_id`}
                            name={`taxes[${index}].tax_type_id`}
                            value={taxesformik?.values?.taxes[index]?.tax_type_id}
                            propertyName='tax_type'
                            onChange={(val) => {
                              const option = taxesType?.find((item) => item.id === Number(val));
                              if (option) {
                                taxesformik?.setFieldValue(`taxes[${index}]`, {
                                  ...taxesformik?.values?.taxes[index],
                                  tax_type_id: val,
                                  percent: option.percent,
                                });
                              } else {
                                taxesformik?.setFieldValue(`taxes[${index}].tax_type_id`, val);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <div className='compensation-per-amount-wrapper'>
                        <input
                          type='number'
                          placeholder='0'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                            color: use(palette.gray500, palette.gray200),
                          }}
                          name={`taxes[${index}].percent`}
                          id={`taxes[${index}].percent`}
                          onKeyDown={(event) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            taxesformik?.setFieldValue(`taxes[${index}].percent`, e.target.value);
                          }}
                          value={taxesformik?.values?.taxes[index]?.percent}
                        />
                      </div>
                    </div>
                    <div className='compensation-recurring-wraper'>
                      <input
                        type='checkbox'
                        name={`taxes[${index}].auto_deduct`}
                        checked={item?.auto_deduct}
                        value={1}
                        onChange={() =>
                          taxesformik.setFieldValue(
                            `taxes[${index}].auto_deduct`,
                            !taxesformik?.values.taxes[index]?.auto_deduct
                          )
                        }
                      />
                      <p
                        className='taxes-compensation-subline'
                        style={{ color: use(palette.gray500, palette.gray200) }}
                      >
                        Auto deduct taxes on each settlement
                      </p>
                      <div className=''>
                        <img src={cross} alt='' onClick={() => remove(index, 'taxes')} />
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className='add-another compensation-add-another-feild'>
                <img src={add} className='add-employee-icon' alt='add' />
                <button
                  className='add-employer-button'
                  onClick={() =>
                    taxesformik.setFieldValue('taxes', [
                      ...taxesformik.values.taxes,
                      {
                        driver_id: driverId,
                        date: new Date(convertToCustomerTime()),
                        time: '',
                        tax_type_id: null,
                        percent: 0,
                        auto_deduct: 0,
                        tax_id: 'new',
                      },
                    ])
                  }
                >
                  Add Another
                </button>
              </div>
            </div>

            <div>
              <div className='deduction-header-wrap'>
                <p
                  className='other-payment-conditions-heading d-flex align-items-center deduction-header-wrap-heading'
                  style={{ color: use(palette.gray900, palette.gray200) }}
                >
                  Benefits
                </p>
              </div>
              <div className='compensation-feilds-header-wraper'>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Date / Time
                </p>
                <p
                  className='compensation-feilds-header-head compensation-custom-select-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Benefit Description
                  <GearIcon onClick={() => setOpenSettings('benefits')} className='ms-2 pointer' />
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-datepicker-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Amount Per Quantity
                </p>
                <p
                  className='compensation-feilds-header-head compensation-amount-main-wrap'
                  style={{ color: use(palette.gray700, palette.gray50) }}
                >
                  Total
                </p>
                <p className='compensation-feilds-header-head' style={{ color: use(palette.gray700, palette.gray50) }}>
                  Set Interval
                </p>
              </div>
              {Benefitformik?.values?.benefits?.map((item, index) => {
                return (
                  <div className='compensation-types-header-wraper' key={item?.id || index}>
                    <div className='compensation-datepicker-main-wrap'>
                      <div
                        className='compensation-datepicker-wrapper'
                        style={{
                          backgroundColor: use(palette.white, palette.dark600),
                          borderColor: use(palette.gray50, palette.darkborder),
                        }}
                      >
                        <DatePicker
                          selected={Benefitformik?.values?.benefits[index]?.date}
                          showYearDropdown
                          placeholderText='mm/dd/yyyy'
                          className={theme?.appTheme === 3 ? 'text-style-dark' : 'text-style ms-0'}
                          id={`benefits[${index}].date`}
                          name={`benefits[${index}].date`}
                          onBlur={Benefitformik?.handleBlur}
                          value={Benefitformik?.values?.benefits[index]?.date}
                          onChange={(date) => {
                            Benefitformik?.setFieldValue(`benefits[${index}].date`, date);
                          }}
                          showTimeInput
                        />
                        <span className='text-style'>
                          {' '}
                          <img src={sorting} alt='calendar' />
                        </span>
                      </div>
                    </div>
                    <div className='compensation-custom-select-main-wrap'>
                      <div
                        className='compensation-custom-select-wrapper'
                        style={{
                          borderBottomColor: use(palette.gray50, palette.darkborder),
                        }}
                      >
                        <div className='compensation-custom-select'>
                          <CustomSelect
                            defaultValue='Select'
                            options={benefitType}
                            onBlur={Benefitformik.handleBlur}
                            id={`benefits[${index}].benefit_type_id`}
                            name={`benefits[${index}].benefit_type_id`}
                            onChange={(val) => {
                              const option = benefitType?.find((item) => item.id === Number(val));

                              if (option) {
                                Benefitformik?.setFieldValue(`benefits[${index}]`, {
                                  ...Benefitformik?.values?.benefits[index],
                                  benefit_type_id: val,
                                  quantity: 1,
                                  per_quantity_amount: Number(option.amount),
                                  amount: option.amount,
                                });
                              } else {
                                Benefitformik?.setFieldValue(`benefits[${index}].benefit_type_id`, val);
                              }
                            }}
                            value={Benefitformik?.values?.benefits[index]?.benefit_type_id}
                            propertyName='type'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <div className='compensation-quantity-wrapper'>
                        <SInput
                          type='number'
                          value={item.quantity || ''}
                          onChange={(e) => {
                            if (e.target.value < 1) {
                              return;
                            }
                            Benefitformik?.setFieldValue(`benefits[${index}].quantity`, e.target.value);
                            Benefitformik?.setFieldValue(
                              `benefits[${index}].amount`,
                              Number(e.target.value) * Number(item.per_quantity_amount)
                            );
                          }}
                          onKeyDown={(e) => {
                            blockNonPositiveNumbers(e);
                            blockNotNumberChars(e);
                          }}
                        />
                      </div>
                    </div>
                    <div className='compensation-datepicker-main-wrap'>
                      <div className='compensation-amount-wrapper'>
                        <span
                          className='doller-icon'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                            color: palette.gray400,
                          }}
                        >
                          {currency}
                        </span>
                        <input
                          type='number'
                          placeholder='0.00'
                          style={{
                            backgroundColor: use(palette.white, palette.dark600),
                            borderColor: use(palette.gray50, palette.darkborder),
                          }}
                          name={`benefits[${index}].per_quantity_amount`}
                          id={`benefits[${index}].per_quantity_amount`}
                          onKeyDown={(event) => {
                            ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
                          }}
                          onChange={(e) => {
                            if (e.target.value < 0) {
                              return;
                            }
                            Benefitformik?.setFieldValue(`benefits[${index}].per_quantity_amount`, e.target.value);
                            Benefitformik?.setFieldValue(
                              `benefits[${index}].amount`,
                              Number(e.target.value) * Number(item.quantity)
                            );
                          }}
                          value={Benefitformik?.values?.benefits[index]?.per_quantity_amount}
                        />
                      </div>
                    </div>
                    <div className='compensation-amount-main-wrap'>
                      <div style={{ marginTop: '8px' }}>
                        <Typography variant='s3'>
                          - {currency}
                          {formatNumber(item.amount)}
                        </Typography>
                      </div>
                    </div>
                    {item.every_settlement === 0 ? (
                      <div className='compensation-recurring-wraper'>
                        <label
                          className='text-style compensation-text-style-lable'
                          htmlFor='flexSwitchCheckChecked'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                          }}
                        >
                          Every
                        </label>
                        <Dropdown
                          className='custom-dropdown-in-compensation'
                          onSelect={(value) => {
                            Benefitformik?.setFieldValue(`benefits[${index}].day`, value);
                          }}
                        >
                          <Dropdown.Toggle
                            placement='top'
                            variant='outline-secondary'
                            id='dropdown-basic'
                            className='tablePlaner_dropdownToggle compensation__dropdownToggle'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              backgroundColor: use(palette.white, palette.dark800),
                            }}
                          >
                            {Benefitformik?.values?.benefits[index]?.day}
                          </Dropdown.Toggle>

                          {item?.month_week === 'Month' ? (
                            <Dropdown.Menu
                              className='tablePlaner_menu compansation_menu'
                              style={{
                                backgroundColor: use(palette.white, palette.dark800),
                              }}
                            >
                              {month?.map((el, index) => {
                                return (
                                  <Dropdown.Item
                                    eventKey={el}
                                    active={Benefitformik?.values?.benefits[index]?.day === el}
                                    key={el}
                                    style={{
                                      color:
                                        Benefitformik?.values?.benefits[index]?.day === el
                                          ? use(palette.white, palette.white)
                                          : use(palette.gray700, palette.gray200),
                                    }}
                                  >
                                    {el}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          ) : (
                            <Dropdown.Menu
                              className='tablePlaner_menu compansation_menu'
                              style={{
                                backgroundColor: use(palette.white, palette.dark800),
                              }}
                            >
                              {weekData?.map((el, index) => {
                                return (
                                  <Dropdown.Item
                                    eventKey={el}
                                    active={Benefitformik?.values?.benefits[index]?.day === el}
                                    key={el}
                                    style={{
                                      color:
                                        Benefitformik?.values?.benefits[index]?.day === el
                                          ? use(palette.white, palette.white)
                                          : use(palette.gray700, palette.gray200),
                                    }}
                                  >
                                    {el}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          )}
                        </Dropdown>
                        <label
                          className='text-style compensation-text-style-lable-of'
                          htmlFor='flexSwitchCheckChecked'
                          style={{
                            color: use(palette.gray700, palette.gray200),
                          }}
                        >
                          per
                        </label>
                        <Dropdown
                          className='custom-dropdown-in-compensation'
                          onSelect={(value) => {
                            Benefitformik.setFieldValue(`benefits[${index}].month_week`, value);
                            if (value === 'Month') {
                              Benefitformik.setFieldValue(`benefits[${index}].day`, '1st');
                            } else {
                              Benefitformik.setFieldValue(`benefits[${index}].day`, 'Monday');
                            }
                          }}
                        >
                          <Dropdown.Toggle
                            placement='top'
                            variant='outline-secondary'
                            id='dropdown-basic'
                            className='tablePlaner_dropdownToggle  compensation__dropdownToggle'
                            style={{
                              color: use(palette.gray700, palette.gray200),
                              backgroundColor: use(palette.white, palette.dark800),
                            }}
                          >
                            {Benefitformik?.values?.benefits[index]?.month_week}
                            <div className='tablePlaner_arrows_wrapper'>
                              <Arrows width={10} height={10} fill={use(palette.gray500, palette.gray200)} />
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu
                            className='tablePlaner_menu compansation_menu'
                            style={{
                              backgroundColor: use(palette.white, palette.dark800),
                            }}
                          >
                            {monthData?.map((el, index) => {
                              return (
                                <Dropdown.Item
                                  eventKey={el}
                                  active={Benefitformik?.values?.benefits[index]?.month_week === el}
                                  key={el}
                                  style={{
                                    color:
                                      Benefitformik?.values?.benefits[index]?.month_week === el
                                        ? use(palette.white, palette.white)
                                        : use(palette.gray700, palette.gray200),
                                  }}
                                >
                                  {el}
                                </Dropdown.Item>
                              );
                            })}
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className='setting-icon-container'>
                          <img
                            src={setting}
                            alt=''
                            onClick={() => {
                              setRecurringModalShow(true);
                              setRecurringModalData({ ...item, index });
                            }}
                          />
                        </div>
                        <div className=''>
                          <img src={cross} alt='' onClick={() => remove(index, 'benefits')} />
                        </div>
                      </div>
                    ) : (
                      <div className='compensation-recurring-wraper'>
                        <p className='taxes-compensation-subline'>Every Settlement</p>
                        <div className='setting-icon-container taxes-setting-icon-container'>
                          <img
                            src={setting}
                            alt=''
                            onClick={() => {
                              setRecurringModalShow(true);
                              setRecurringModalData({ ...item, index });
                            }}
                          />
                        </div>
                        <div className=''>
                          <img src={cross} alt='' onClick={() => remove(index, 'benefits')} />
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
              <div className='add-another compensation-add-another-feild'>
                <img src={add} className='add-employee-icon' alt='add' />
                <button
                  className='add-employer-button'
                  onClick={() =>
                    Benefitformik.setFieldValue('benefits', [
                      ...Benefitformik.values.benefits,
                      {
                        driver_id: driverId,
                        date: new Date(convertToCustomerTime()),
                        time: '',
                        benefit_type_id: null,
                        quantity: 1,
                        per_quantity_amount: '',
                        amount: 0,
                        every_settlement: 0,
                        month_week_settlement: 1,
                        day: 'Monday',
                        month_week: 'Week',
                        benefit_id: 'new',
                        type: 'benefits',
                      },
                    ])
                  }
                >
                  Add Another
                </button>
              </div>
            </div>
            <DeductionsSettings open={openSettings === 'deductions'} onClose={onSettingsClose} />
            <TaxesSettings open={openSettings === 'taxes'} onClose={onSettingsClose} />
            <BenefitsSettings open={openSettings === 'benefits'} onClose={onSettingsClose} />
            {recurringModalShow && (
              <RecurringModal
                show={setRecurringModalShow}
                onHide={() => setRecurringModalShow(false)}
                recurringModalData={recurringModalData}
                updateReccuringData={setReccuring}
              />
            )}
          </>
        ) : null}
        <AdditionsSettings open={openSettings === 'additions'} onClose={onSettingsClose} />
      </div>
      {!!balanceToDeleteId && (
        <ConfirmationModal
          width='510px'
          open={!!balanceToDeleteId}
          onClose={() => setBalanceToDeleteId(null)}
          headerTitle='Delete Balance'
          text='Are you sure you want to delete this balance?'
          onConfirm={deleteBalance}
          disabled={loadingDeleteBalance}
        />
      )}
      {!!openAddBalance && (
        <AddBalance
          open={openAddBalance}
          onClose={() => setOpenAddBalance(false)}
          onSuccess={() => {
            getBalances();
            getDeductions();
          }}
          userId={driver_id || driverId}
        />
      )}
    </div>
  );
};
