import React, { useEffect, useState } from 'react';
import { ModalBody } from 'react-bootstrap';
import axios from 'axios';
import authHeader from 'services/auth-header';
import Select from 'react-select';
import { useQuery } from 'react-query';
import { useFormik } from 'formik';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import './addhook.css';

const AddHook = ({
  handleClose,
  id,
  SuccessShow,
  SuccessClose,
  HookedErrShow,
  HookedErrClose,
  GetHookedError,
  onSuccess = () => null,
}) => {
  const [resErr, setresErr] = useState();
  const { use } = useTheme();

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      height: 32,
      minHeight: 32,
      minWidth: 250,
      borderRadius: '4px',
      borderColor: 'hsl(0, 0%, 80%)',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '14px',
    }),
    menuList: (base) => ({
      ...base,

      backgroundColor: use(palette.white, palette.dark800),
      color: use(palette.dark800, palette.gray200),
      cursor: 'pointer',
    }),
  };

  useEffect(() => {
    GetHookedError(resErr);
  }, [resErr]);

  const formik = useFormik({
    initialValues: {},
    onSubmit: async (values) => {
      try {
        await axios
          .put(`${API_URL}/equipment/${id}`, values, {
            headers: authHeader(),
          })
          .then((res) => {
            if (res?.status === 200) {
              handleClose();
              SuccessShow(res.data?.data);
              onSuccess();
              setTimeout(() => {
                SuccessClose();
              }, 3000);
            }
          });
      } catch (err) {
        if (err?.response?.data?.response?.hooked_to_id) {
          setresErr(err?.response?.data?.response?.hooked_to_id.join(' '));
          handleClose();
          HookedErrShow();
        }
        setTimeout(() => {
          HookedErrClose();
        }, 3000);
      }
    },
  });
  const API_URL = process.env.REACT_APP_API_URL;

  async function Trailer() {
    const { data } = await axios.get(`${API_URL}/equipment/hook-to/${id}`, {
      headers: authHeader(),
    });
    return data;
  }

  const { data, isError, isLoading, error } = useQuery('hooked', Trailer);
  if (isLoading) {
    return <span className='text-style'>Loading...</span>;
  }
  if (isError) {
    return <span className='text-style'>Error: {error.message}</span>;
  }
  const NotAssignedTrailer = data?.data?.filter((v) => v?.equipment_status?.equipment_status === 'Not Assigned');
  const arr = NotAssignedTrailer?.length
    ? NotAssignedTrailer.map((t) => ({
        label: `${t.equipment_id} ( ${t.make ? t.make : ''} ${t.model_id ? t.model_id : ''} )`,
        value: t.id,
      }))
    : [];
  const handleChange = (e) => {
    formik.setFieldValue('hooked_to_id', e?.value);
  };

  return (
    <div className='equipment-list-add-hook' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
      <ModalBody className='p-0'>
        <form onSubmit={formik.handleSubmit}>
          <div className='hook-trailer-modal-header'>
            <h6
              className='hook-trailer-modal-heading mt-3 ms-3'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              Hook Trailer
            </h6>
          </div>
          <div className='hook-trailer-modal-body' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
            <p className='hook-trailer-modal-text' style={{ color: use(palette.dark800, palette.gray200) }}>
              {' '}
              Trailer*
            </p>
            <div className='select-trailer-list mt-0'>
              <Select options={arr} type='checkbox' name='arr' onChange={handleChange} styles={customStyles} />
            </div>
          </div>
          <div className='add-driver-modal-footer d-flex justify-content-end'>
            <button
              type='button'
              className='hook-trailer-modal-cancel-button'
              onClick={handleClose}
              style={{
                color: use(palette.dark800, palette.gray200),
                backgroundColor: use(palette.white, palette.dark800),
              }}
            >
              Cancel
            </button>
            <button type='submit' className='hook-trailer-modal-hook-trailer-button'>
              Hook Trailer
            </button>
          </div>
        </form>
      </ModalBody>
    </div>
  );
};

export default AddHook;
