import React from 'react';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import Chip from 'common/Chip';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { LoginStatusColor, palette } from 'utils/constants';

export const initialFilters = { page: 1, itemsPerPage: 25 };

export const initialDateRange = {
  start: startOfDay(addDays(new Date(), -31)),
  end: endOfDay(new Date()),
};

export const useLoginLogColumns = ({ sort, sortingQuery }) => {
  const { convertToCustomerTime } = useDateFormat();

  return [
    {
      field: 'device_name',
      title: <ColumnHeader title='DEVICE' field='device_name' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {Number(row.device_name) === 0 ? '' : row.device_name} {row.device_type}
          </Typography>
        );
      },
    },
    {
      field: 'location',
      title: <ColumnHeader title='LOCATION' field='location' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row.location || '-'}
          </Typography>
        );
      },
    },
    {
      field: 'login_attempt_status_id',
      title: <ColumnHeader title='TYPE' field='login_attempt_status_id' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Chip
            bgColor={LoginStatusColor[row?.login_attempt_status?.status]?.bgColor}
            labelColor={LoginStatusColor[row?.login_attempt_status?.status]?.color}
            label={row.login_attempt_status?.status}
          />
        );
      },
    },
    {
      field: 'created_at',
      title: <ColumnHeader title='DATE' field='created_at' onClick={sortingQuery} sort={sort} />,
      render: (row) => {
        return (
          <Typography variant='b2' style={{ color: palette.gray700 }}>
            {row?.created_at ? convertToCustomerTime(row?.created_at) : '-'}
          </Typography>
        );
      },
    },
  ];
};
