export const getInitialValues = (document, shipment) => {
  const { reference_id, path, doc_type, shipment_stop, slave_shipment_id, customer } = document || {};

  return {
    doc: path || null,
    reference_id: reference_id || '',
    type: doc_type || null,
    shipment:
      document && shipment?.shipment_type === 'ltl' && slave_shipment_id
        ? { shipment_id: slave_shipment_id, shipment_billing: [{ billing_customer: { company_name: customer } }] }
        : null,
    shipment_stop: shipment_stop || null,
    shipment_type: shipment?.shipment_type,
  };
};
