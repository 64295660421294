import React from 'react';
import Chip from 'common/Chip';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import useDateFormat from 'hooks/useDateFormat';
import { formatPhoneNumber } from 'utils/helpers';
import { formatNumber, palette } from 'utils/constants';
import { STable } from '../ViewApplicantModal.styles';

const PreviousEmployments = ({ data }) => {
  const { formatDate } = useDateFormat();

  if (!data?.length) {
    return null;
  }

  return (
    <div className='info-section'>
      <div className='mb-3'>
        <Typography variant='h5' style={{ color: palette.gray900 }}>
          Previous Employment
        </Typography>
      </div>
      <div className='overflow-x-auto'>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>EMPLOYER NAME</th>
              <th>MAY CONTACT</th>
              <th>EMAIL</th>
              <th>PHONE NUMBER</th>
              <th>FAX NUMBER</th>
              <th>START-END DATE</th>
              <th>ADDRESS</th>
              <th>POSITION HELD</th>
              <th>REASON FOR LEAVING</th>
              <th>TERMINATED / DISCHARGED / LAID OFF</th>
              <th>CURRENT EMPLOYER</th>
              <th>OPERATED A COMMERCIAL MOTOR VEHICLE</th>
              <th>WERE SUBJECT TO THE FEDERAL MOTOR CARRIER OR TRANSPORT CANADA SAFETY REGULATIONS</th>
              <th>PERFORMED SAFETY SENSITIVE FUNCTIONS</th>
              <th>AREAS DRIVEN</th>
              <th>MILES DRIVEN</th>
              <th>PAY RANGE</th>
              <th>MOST COMMON TRUCK DRIVEN</th>
              <th>MOST COMMON TRAILER NAME/SIZE</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr className='body-row' key={item.id}>
                <td>
                  <Typography variant='b2' className='nowrap'>
                    {item.employer_name}
                  </Typography>
                </td>
                <td>
                  <div>
                    <Chip
                      label={item.can_contact ? 'Yes' : 'No'}
                      labelColor={item.can_contact ? palette.green500 : palette.red500}
                      bgColor={item.can_contact ? palette.green0 : palette.red0}
                    />
                  </div>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.email || '-'}
                  </Typography>
                </td>
                <td className='nowrap'>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    {formatPhoneNumber(`+${item.phone_no}`)}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.fax ? formatPhoneNumber(`+${item.fax}`) : '-'}
                  </Typography>
                </td>
                <td>
                  <div style={{ color: palette.gray400 }} className='d-flex align-items-center gap-2'>
                    <Chip label={formatDate(item.start_date)} size='small' /> -{' '}
                    <Chip label={item.present ? 'Present' : formatDate(item.end_date)} size='small' />
                  </div>
                </td>
                <td>
                  <div className='d-flex flex-column gap-1'>
                    <Typography variant='b2' style={{ color: palette.gray900 }} className='nowrap'>
                      {item.address ? `${item.address}` : '-'}
                    </Typography>
                    <Typography variant='b2' style={{ color: palette.gray900 }} className='nowrap'>
                      {item.city ? `${item.city.name}` : ''}
                      {item.state ? `, ${item.state.short_name}` : ''}
                      {item.zipcode ? ` ${item.zipcode}` : ''}
                    </Typography>
                  </div>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.position || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.reason || '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.terminated ? 'Yes' : 'No'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.current_employer ? 'Yes' : 'No'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.operated_commercial_vehicle ? 'Yes' : 'No'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.subject_to_safety_regulations ? 'Yes' : 'No'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.perform_safety_functions ? 'Yes' : 'No'}
                  </Typography>
                </td>
                <td>
                  <div style={{ width: '200px' }}>
                    <Typography variant='b2' style={{ color: palette.gray900 }}>
                      {item.areas_driven || '-'}
                    </Typography>
                  </div>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.miles_driven ? Math.round(item.miles_driven) : '-'}
                  </Typography>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.pay_range ? `$${formatNumber(item.pay_range)} /mile` : '-'}
                  </Typography>
                </td>
                <td>
                  <div style={{ width: '200px' }}>
                    <Typography variant='b2' style={{ color: palette.gray900 }}>
                      {item.truck_name || '-'}
                    </Typography>
                  </div>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray900 }}>
                    {item.trailer_name_size || '-'}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </STable>
        {!data?.length && <NoRecords />}
      </div>
    </div>
  );
};

export default PreviousEmployments;
