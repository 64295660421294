import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { GetEquipmentAtStop, GetGeofence, GetStopPointsItem, UpdateStopPoint } from 'Api/StopPoint';
import AverageWaitTime from 'pages/Reports/ReportDetails/AverageWaitTime';
import { STOP_POINT_TAB } from 'utils/constants';
import { CHANGE_LOG_TYPE } from 'componentsV2/ChangeLog';
import LogsSection from 'componentsV2/ChangeLog/LogsSection';
import styles from './Walmart.module.css';
import Inventory from './steps/Inventory';
import WalmartHeader from './WalmartHeader';
import General from './steps/General/General';
import EquipmentHistory from './steps/EquipmentHistory';
import CustomModal from '../../CustomModal/CustomModal';
import IdChangeModal from './IdChangeModal/IdChangeModal';
import ContactBook from './steps/ContactBook/ContactBook';
import WorkingHours from './steps/WorkingHours/WorkingHours';
import TypeChangeModal from './TypeChangeModal/TypeChangeModal';

const Walmart = () => {
  const [equipmentAtStop, setEquipmentAtStop] = useState(null);
  const [showIdModal, setShowIdModal] = useState(false);
  const [updateIndex, setUpdateIndex] = useState(Date.now());
  const [showTypeModal, setShowTypeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { id, tab } = useParams();
  const { inventoryEquipmentType, historyEquipmentType, dateRange, inventoryDateRange } = useSelector(
    (state) => state?.stopPoints
  );
  const [geofenceData, setGeofenceData] = useState(null);
  const [stopPointsItem, setStopPointsItem] = useState(null);
  const [showStatuses, setShowStatuses] = useState(false);

  const getStopPointItem = (id) => {
    return GetStopPointsItem(id).then((res) => {
      setStopPointsItem(res.data);
    });
  };
  const getGeofence = (id) => {
    return GetGeofence(id).then((res) => {
      setGeofenceData(res.data);
    });
  };

  const getEquipmentAtStop = (id) => {
    const average_equipment_type =
      tab === STOP_POINT_TAB.EQUIPMENT_HISTORY
        ? historyEquipmentType === 2
          ? 'trailer'
          : 'vehicle'
        : tab === STOP_POINT_TAB.INVENTORY
        ? inventoryEquipmentType === 2
          ? 'trailer'
          : 'vehicle'
        : 'vehicle';

    const actualDateRange =
      tab === STOP_POINT_TAB.EQUIPMENT_HISTORY
        ? dateRange
        : tab === STOP_POINT_TAB.INVENTORY
        ? inventoryDateRange
        : dateRange;

    const average_from = actualDateRange.start ? moment(actualDateRange.start).format('YYYY-MM-DD') : undefined;
    const average_to = actualDateRange.end ? moment(actualDateRange.end).format('YYYY-MM-DD') : undefined;

    setLoading(true);
    return GetEquipmentAtStop(id, {
      average_equipment_type,
      average_from,
      average_to,
      history: tab === STOP_POINT_TAB.EQUIPMENT_HISTORY ? 1 : undefined,
    })
      .then((res) => {
        setEquipmentAtStop(res.data);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getStopPointItem(id).then();
    getGeofence(id).then();
  }, [updateIndex]);

  useEffect(() => {
    getEquipmentAtStop(id).then();
  }, [tab, inventoryEquipmentType, historyEquipmentType, dateRange, inventoryDateRange]);

  const goBack = () => {
    navigate('/stop-point');
  };

  function headerOverLayChange(status) {
    UpdateStopPoint(id, {
      status_id: status.id,
    }).then((res) => {
      if (res) {
        setShowStatuses(false);
        getStopPointItem(id).then();
        getGeofence(id).then();
        getEquipmentAtStop(id).then();
      }
    });
  }

  function headerOnClickContact() {}

  function headerOnClickMail() {}

  function headerOnEditContact() {}

  const currentTab = useMemo(() => {
    switch (tab) {
      case 'general':
        return (
          <General
            stopPointsItem={stopPointsItem}
            setStopPointsItem={setStopPointsItem}
            geofenceData={geofenceData}
            setGeofenceData={setGeofenceData}
            getGeofence={getGeofence}
            getStopPointItem={getStopPointItem}
            updateIndex={updateIndex}
            setUpdateIndex={setUpdateIndex}
          />
        );
      case 'contact-book':
        return <ContactBook />;
      case 'working-hours':
        return <WorkingHours />;
      case 'equipment-history':
        return <EquipmentHistory />;
      case 'inventory':
        return <Inventory />;
      case 'average-wait-time':
        return <AverageWaitTime stopPoint={id} />;
      case 'log':
        return <LogsSection type={CHANGE_LOG_TYPE.STOP_POINT} params={{ stop_point_id: id }} />;
      default:
        return (
          <General
            stopPointsItem={stopPointsItem}
            setStopPointsItem={setStopPointsItem}
            geofenceData={geofenceData}
            setGeofenceData={setGeofenceData}
            getGeofence={getGeofence}
            getStopPointItem={getStopPointItem}
            updateIndex={updateIndex}
            setUpdateIndex={setUpdateIndex}
          />
        );
    }
  }, [stopPointsItem, geofenceData, tab]);

  return (
    <div>
      <WalmartHeader
        showStatuses={showStatuses}
        setShowStatuses={setShowStatuses}
        setShowIdModal={setShowIdModal}
        setShowTypeModal={setShowTypeModal}
        rowData={stopPointsItem}
        equipmentAtStop={equipmentAtStop}
        onBackPlanner={goBack}
        overLayChange={headerOverLayChange}
        onClickContact={headerOnClickContact}
        onClickMail={headerOnClickMail}
        onEditContact={headerOnEditContact}
        loading={loading}
      />
      <div className={classNames(styles.line, styles.details)} />
      {currentTab}
      <CustomModal
        showModal={showIdModal}
        styleBody={{ width: 440, backgroundColor: '#F7F9FC' }}
        onHide={() => setShowIdModal(false)}
        headerTitle='Stop point ID'
        styleButtons={{ padding: '6px 12px' }}
        footer={false}
      >
        <IdChangeModal
          getStopPointItem={getStopPointItem}
          stopPointsItem={stopPointsItem}
          setShowIdModal={setShowIdModal}
        />
      </CustomModal>
      <CustomModal
        showModal={showTypeModal}
        styleBody={{ width: 440, backgroundColor: '#F7F9FC' }}
        onHide={() => setShowTypeModal(false)}
        headerTitle='Type'
        styleButtons={{ padding: '6px 12px' }}
        footer={false}
      >
        <TypeChangeModal setShowTypeModal={setShowTypeModal} />
      </CustomModal>
    </div>
  );
};

export default Walmart;
