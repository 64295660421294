import React, { useCallback, useEffect, useState } from 'react';

import { Collapse } from '@mui/material';
import { palette } from 'utils/constants';
import { useAuth } from 'context/auth.context';
import { useTheme } from 'context/themeContext';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';

import { ReactComponent as PlusIcon } from 'assets/icons/pluseIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minus.svg';
import { ReactComponent as ArrowDown } from 'assets/icons/arrowDown.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteThin.svg';
import { ReactComponent as FilterDark } from 'assets/icons/filterIconDark.svg';
import { ReactComponent as DataTableIcon } from 'assets/icons/dataTable.svg';
import Input from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import DarkOverlay from 'components/DarkOverlay';
import CustomButton from 'components/CustomButton/CustomButton';
import PopoverSettings from 'components/Equipment/TableSetting/TableSettingPopover';
import { getVendorsList } from 'Api/Vendors';
import { OwnerOperator } from 'Api/OwnerOperator';
import { equipmentStatuses, getAllTrailers } from 'Api/Map';
import { getEquipmentRequiredVehicles, Groups } from 'Api/Equipment';
import { convertOwnerOperatorData, convertVendorsData } from 'components/Equipment/TableEquipment/VehicleTable/helpers';
import uuid from 'react-uuid';
import { MOTION_DATA, OWNERSHIP_OPTIONS, TRUCK_DAY_CAB_OPTION, TRUCK_SLEEPER_OPTION } from '../constat';

import classes from './VehicleTable.module.scss';

const VehicleHeader = ({
  onSearch,
  searchBy,
  filter,
  setFilter,
  loadingDrivers,
  driverSelectData,
  // for table settings
  TYPES,
  dragItem,
  onChangeOrder,
  tablePopoverFilter,
  OnTableSettingChange,
  updateTablePopoverFilter,
}) => {
  const { use } = useTheme();
  const { value } = useAuth();
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [errorLocation, setErrorLocation] = useState('');
  const [badgeData, setBadgeData] = useState([]);
  /** DATA ADVANCED * */
  const [statusData, setStatusData] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const [ownersList, setOwnersList] = useState([]);
  const [vehicleData, setVehicleData] = useState([]);
  const [trailerData, setTrailerData] = useState([]);
  const [trailerLoading, setTrailerLoading] = useState(false);
  const [ownersListLoading, setOwnersListLoading] = useState(false);
  /** DATA ADVANCED * */
  /** ENDPOINTS ADVANCED* */
  const GetStatuses = async () => {
    try {
      const { data } = await equipmentStatuses('vehicle');
      setStatusData(data);
    } catch (e) {
      // Do noting
    }
  };
  const GetGroups = async () => {
    try {
      const { data } = await Groups();
      setGroupsData(data);
    } catch (e) {
      // Do noting
    }
  };
  const GetVehicleType = async () => {
    try {
      const { data } = await getEquipmentRequiredVehicles();
      if (Array.isArray(data)) {
        const filteredData = data.filter((el) => +el.id !== 1);
        filteredData.push(TRUCK_DAY_CAB_OPTION, TRUCK_SLEEPER_OPTION);
        setVehicleData(filteredData);
      }
    } catch (e) {
      // Do noting
    }
  };
  const GetAllTrailers = async () => {
    try {
      setTrailerLoading(true);
      const trailers = await getAllTrailers(null, null, null, null);
      setTrailerData(trailers.data);
    } catch (e) {
      // Do nothing
    } finally {
      setTrailerLoading(false);
    }
  };

  const getOwners = async () => {
    try {
      setOwnersListLoading(true);
      const { data } = await getVendorsList();
      const { data: ownerData } = await OwnerOperator();
      const vendors = convertVendorsData(data);
      const ownerOperators = convertOwnerOperatorData(ownerData);
      setOwnersList([
        {
          id: 1,
          key: uuid(),
          ownership_type: 1,
          title: value.user.customer.company_name,
        },
        ...vendors,
        ...ownerOperators,
      ]);
    } catch (e) {
      // Do nothing
    } finally {
      setOwnersListLoading(false);
    }
  };

  /** ENDPOINTS ADVANCED* */

  const onChangeMultiSelectValues = (key, value) => {
    const newFilter = { ...filter };
    if (Array.isArray(value) && value.length === 0) {
      delete newFilter[key];
      if (key === 'equipment_type_id') {
        delete newFilter.truck_type;
      }
    } else {
      newFilter[key] = value;
      if (key === 'equipment_type_id') {
        newFilter.truck_type = value.map((el) => ({ id: el.truckType }));
      }
    }
    return setFilter(newFilter);
  };

  const normalizeBadgeData = (item, nameKey, idKey, options) => {
    return {
      name: nameKey === 'hook_to' ? `${item.equipment_id} ${item.equipment_type?.title || ''}` : item?.[nameKey] || '',
      id: item?.[idKey] || '',
      ...options,
    };
  };

  const onSearchFilter = () => {
    if (
      Number(searchBy?.id) === 2 &&
      (((!filter.city || !filter.state) && !filter.postCode) || (!filter.city && !filter.state && !filter.postCode))
    ) {
      setErrorLocation('State and City or ZipCode is required');
      return;
    }
    setBadgeData(convertedBadgeData(filter));
    onSearch();
  };

  const onChangeLocationValues = (e) => {
    if (errorLocation) {
      setErrorLocation('');
    }
    setFilter((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handleDeleteBadgeItem = (child) => {
    const { key, id } = child || {};
    const newFilter = { ...filter };
    const itemValue = newFilter[key];

    if (Array.isArray(itemValue)) {
      const newItemValue = itemValue.filter((el) => el.id !== id);

      if (newItemValue.length === 0) {
        delete newFilter[key];
        if (key === 'equipment_type_id') {
          delete newFilter.truck_type;
        }
      } else {
        newFilter[key] = newItemValue;
        if (key === 'equipment_type_id') {
          newFilter.truck_type = newItemValue.map((el) => ({ id: el.truckType }));
        }
      }
    } else if (typeof itemValue === 'string') {
      if (key === 'city' || key === 'state') {
        delete newFilter.city;
        delete newFilter.state;
      } else {
        delete newFilter[key];
      }
    }

    setFilter(newFilter);
    onSearch(newFilter);
    setBadgeData(convertedBadgeData(newFilter));
  };

  const handleClearAll = () => {
    setBadgeData([]);
    setFilter({});
    onSearch({});
  };

  const getIcon = useCallback(
    (name) => {
      switch (name) {
        case 'arrow':
          return (
            <ArrowDown style={{ width: 10, height: 10, marginTop: 2 }} fill={use(palette.gray700, palette.gray200)} />
          );
        case 'filter':
          return (
            <FilterDark
              style={{ width: 12, height: 12, marginRight: 2 }}
              fill={use(palette.gray700, palette.gray200)}
            />
          );
        case 'plusMinus':
          return advancedOpen ? (
            <MinusIcon fill='#fff' style={{ margin: 8 }} />
          ) : (
            <PlusIcon fill='#fff' style={{ margin: 8 }} />
          );
        default:
          return (
            <ArrowDown style={{ width: 10, height: 10, marginTop: 2 }} fill={use(palette.gray700, palette.gray200)} />
          );
      }
    },
    [advancedOpen]
  );

  const convertedBadgeData = useCallback((filter) => {
    return [
      ...(!!filter?.city || !!filter?.state || !!filter?.postCode || !!filter?.radius
        ? [
            {
              title: 'Location',
              children: [
                ...(filter?.city ? [{ name: filter.city, id: 'city', key: 'city' }] : []),
                ...(filter?.state ? [{ name: filter.state, id: 'state', key: 'state' }] : []),
                ...(filter?.postCode ? [{ name: filter.postCode, id: 'postCode', key: 'postCode' }] : []),
                ...(filter?.radius ? [{ name: `radius(${filter.radius})`, id: 'radius', key: 'radius' }] : []),
              ],
            },
          ]
        : []),
      ...(filter.status_id
        ? [
            {
              title: 'Status',
              children: filter.status_id.map((item) =>
                normalizeBadgeData(item, 'equipment_status', 'id', { key: 'status_id' })
              ),
            },
          ]
        : []),
      ...(filter.group_id
        ? [
            {
              title: 'Tag',
              children: filter.group_id.map((item) =>
                normalizeBadgeData(item, 'group_name', 'id', { key: 'group_id' })
              ),
            },
          ]
        : []),
      ...(filter.equipment_type_id
        ? [
            {
              title: 'Vehicle Type',
              children: filter.equipment_type_id.map((item) =>
                normalizeBadgeData(item, 'title', 'id', { key: 'equipment_type_id' })
              ),
            },
          ]
        : []),
      ...(filter.motion
        ? [
            {
              title: 'Motion',
              children: filter.motion.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'motion' })),
            },
          ]
        : []),
      ...(filter?.equipment_id
        ? [
            {
              title: 'Equipment ID',
              children: [{ name: filter.equipment_id, id: 'equipment_id', key: 'equipment_id' }],
            },
          ]
        : []),
      ...(filter?.retired
        ? [{ title: 'Retired', children: [{ name: filter.retired, id: 'retired', key: 'retired' }] }]
        : []),
      ...(filter?.driver_id
        ? [
            {
              title: 'Driver',
              children: filter.driver_id.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'driver_id' })),
            },
          ]
        : []),
      ...(filter?.hook_to
        ? [
            {
              title: 'Hooked To',
              children: filter.hook_to.map((item) => normalizeBadgeData(item, 'hook_to', 'id', { key: 'hook_to' })),
            },
          ]
        : []),
      ...(filter?.owned_by
        ? [
            {
              title: 'Ownership Type',
              children: filter.owned_by.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'owned_by' })),
            },
          ]
        : []),
      ...(filter?.ownership
        ? [
            {
              title: 'Ownership',
              children: filter.ownership.map((item) => normalizeBadgeData(item, 'title', 'id', { key: 'ownership' })),
            },
          ]
        : []),
      ...(filter?.vin
        ? [
            {
              title: 'VIN',
              children: [{ name: filter.vin, id: 'vin', key: 'vin' }],
            },
          ]
        : []),
      ...(filter?.plate_number
        ? [
            {
              title: 'Plate Number',
              children: [{ name: filter.plate_number, id: 'plate_number', key: 'plate_number' }],
            },
          ]
        : []),
    ];
  }, []);

  useEffect(() => {
    getOwners();
    GetAllTrailers();
    GetStatuses().catch();
    GetGroups().catch();
    GetVehicleType().catch();
  }, []);

  return (
    <div className='d-flex flex-column'>
      <div className='equipment-table-header mt-3'>
        <div style={{ display: 'flex' }} />
        <div className='top_popover_search-wrapper'>
          <PopoverSettingsWrapper
            TYPES={TYPES}
            dragItem={dragItem}
            onChangeOrder={onChangeOrder}
            tablePopoverFilter={tablePopoverFilter}
            OnTableSettingChange={OnTableSettingChange}
            updateTablePopoverFilter={updateTablePopoverFilter}
          />
          <div className={classes.filterWrapper}>
            <div className={classes.fieldsWrapper}>
              <Input
                width='300px'
                name='equipment_id'
                placeholder='Search'
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onSearchFilter();
                  }
                }}
                value={filter?.equipment_id || ''}
                onChange={(e) => setFilter((p) => ({ ...p, equipment_id: e.target.value }))}
              />
            </div>
            <CustomButton
              type='primary'
              title='Search'
              onClick={onSearchFilter}
              styleTitle={{ fontSize: 14 }}
              styleButton={{ padding: '5px 12px', margin: '0 0 0 16px' }}
            />
            <CustomButton
              type='primary'
              leftIcon={getIcon('plusMinus')}
              styleButton={{ height: 32, margin: '0 0 0 16px' }}
              onClick={() => setAdvancedOpen(!advancedOpen)}
            />
          </div>
        </div>
      </div>
      {/* AdvancedFilter Collapse */}
      <Collapse in={advancedOpen}>
        <div className={classes.collapseWrapper}>
          <div className={classes.collapseFields}>
            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.status_id || []}
                options={statusData}
                label='Status'
                onChange={(e, value) => onChangeMultiSelectValues('status_id', value)}
                getTagLabel={(option) => (option ? option?.equipment_status : '')}
                getOptionLabel={(option) => (option ? option?.equipment_status : '')}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                value={filter?.group_id || []}
                options={groupsData}
                label='Tags'
                onChange={(e, value) => onChangeMultiSelectValues('group_id', value)}
                getTagLabel={(option) => (option ? option?.group_name : '')}
                getOptionLabel={(option) => (option ? option?.group_name : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                label='Vehicle Type'
                value={filter?.equipment_type_id || []}
                options={vehicleData}
                onChange={(e, value) => onChangeMultiSelectValues('equipment_type_id', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                label='Motion'
                value={filter?.motion || []}
                options={MOTION_DATA}
                onChange={(e, value) => onChangeMultiSelectValues('motion', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                label='Hooked To'
                limitTags={2}
                loading={trailerLoading}
                options={trailerData}
                value={filter?.hook_to || []}
                getOptionLabel={(option) => {
                  return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                }}
                getTagLabel={(option) => {
                  return option ? `${option.equipment_id} ${option.equipment_type.title}` : '';
                }}
                onChange={(e, value) => onChangeMultiSelectValues('hook_to', value)}
                isOptionEqualToValue={(option, value) => option.id === value?.id || option.id === value}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                label='Driver'
                limitTags={2}
                labelKey='title'
                loading={loadingDrivers}
                value={filter.driver_id || []}
                options={driverSelectData}
                onChange={(e, value) => onChangeMultiSelectValues('driver_id', value)}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                label='Ownership Type'
                value={filter?.owned_by || []}
                options={OWNERSHIP_OPTIONS}
                onChange={(e, value) => onChangeMultiSelectValues('owned_by', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
              />
            </div>

            <div style={{ width: 250 }}>
              <Autocomplete
                multiple
                width='100%'
                limitTags={2}
                label='Ownership'
                value={filter?.ownership || []}
                options={ownersList}
                loading={ownersListLoading}
                onChange={(e, value) => onChangeMultiSelectValues('ownership', value)}
                getTagLabel={(option) => (option ? option?.title : '')}
                getOptionLabel={(option) => (option ? option?.title : '')}
                isOptionEqualToValue={(option, value) => option?.key === value?.key}
              />
            </div>

            <div className={classes.fieldsWrapper}>
              <div className={classes.locationWrapper}>
                <Input
                  label='Location'
                  name='city'
                  placeholder='City'
                  value={filter?.city || ''}
                  classNameInput={classes.fieldCity}
                  onChange={(e) => onChangeLocationValues(e)}
                  error={errorLocation}
                />
                <Input
                  name='state'
                  placeholder='State'
                  value={filter?.state || ''}
                  classNameInput={classes.fieldInputState}
                  onChange={(e) => onChangeLocationValues(e)}
                />

                <div className={classes.fieldWithTextsWrapper}>
                  <Input
                    name='postCode'
                    placeholder='Zip'
                    value={filter?.postCode || ''}
                    classNameInput={classes.fieldWithText}
                    onChange={(e) => onChangeLocationValues(e)}
                  />
                  <div className={classes.fieldText}>
                    <span>Zip Code</span>
                  </div>
                </div>

                <div className={classes.fieldWithTextsWrapper}>
                  <Input
                    name='radius'
                    placeholder='Miles'
                    value={filter?.radius || ''}
                    classNameInput={classes.fieldWithText}
                    onChange={(e) => onChangeLocationValues(e)}
                  />
                  <div className={classes.fieldText}>
                    <span>Miles</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Input
                label='VIN'
                width='250px'
                value={filter?.vin || ''}
                onChange={(e) => onChangeMultiSelectValues('vin', e.target.value)}
              />
            </div>
            <div>
              <Input
                label='Plate Number'
                width='250px'
                value={filter?.plate_number || ''}
                onChange={(e) => onChangeMultiSelectValues('plate_number', e.target.value)}
              />
            </div>
          </div>

          <footer className={classes.footer}>
            <Button className='cancel-button' onClick={handleClearAll}>
              Clear All
            </Button>
            <Button className='next-step' onClick={onSearchFilter}>
              Search
            </Button>
          </footer>
        </div>
      </Collapse>

      {badgeData.length > 0 && (
        <div className={classes.badgesWrapper}>
          <span onClick={handleClearAll} className={classes.clearAll}>
            Clear All
          </span>
          {badgeData.map((badge) => {
            if (badge.children.length <= 0) return;
            return (
              <div key={badge.title} className={classes.badge}>
                <span className={classes.badgeTitle}>{badge.title}:</span>
                <div className={classes.badgeChildrenWrapper}>
                  {badge.children.map((child, index) => {
                    return (
                      <div key={child.key} className={classes.badgeChild}>
                        <span className={classes.badgeChildTitle}>{child?.name}</span>
                        <div className={classes.badgeChildIcon} onClick={() => handleDeleteBadgeItem(child)}>
                          <DeleteIcon />
                        </div>
                        {badge.children.length - 1 !== index ? <span className={classes.comma}>,</span> : ''}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default VehicleHeader;

const PopoverSettingsWrapper = ({
  TYPES,
  dragItem,
  onChangeOrder,
  tablePopoverFilter,
  OnTableSettingChange,
  updateTablePopoverFilter,
}) => {
  const { use } = useTheme();
  const [showDarkOverLay, setShowDarkOverLay] = useState(false);

  const popOverContent = (
    <Popover
      id='popover-basic'
      className={classes.tableSettingsWrapper}
      style={{ background: use(palette.white, palette.dark800) }}
    >
      <Popover.Body>
        <PopoverSettings
          types={TYPES}
          dragItem={dragItem}
          filter={tablePopoverFilter}
          onChangeOrder={onChangeOrder}
          updateFilter={updateTablePopoverFilter}
          OnTableSettingChange={OnTableSettingChange}
          setShowPopover={(isOpen) => setShowDarkOverLay(isOpen)}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <OverlayTrigger
        trigger='click'
        placement='bottom'
        show={showDarkOverLay}
        overlay={popOverContent}
        rootClose={showDarkOverLay}
        onToggle={setShowDarkOverLay}
      >
        <div className='top_popover' style={{ backgroundColor: use(palette.white, palette.dark800) }}>
          <DataTableIcon width={14} height={14} fill={use(palette.gray700, palette.white)} />
        </div>
      </OverlayTrigger>
      <DarkOverlay visible={showDarkOverLay} styles={{ zIndex: 11 }} />
    </>
  );
};
