import styled from 'styled-components';
import { palette } from 'utils/constants';

export const SInfoSection = styled.div`
  background-color: ${palette.white};
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(152, 161, 178, 0.1), 0 30px 70px -10px rgba(17, 24, 38, 0.25),
    0 10px 30px 0 rgba(0, 0, 0, 0.2);
  padding: 26px 20px;
`;
