import axios from '../services/axios';

export async function getBrokerageSettings(dot, token, type) {
  const { data } = await axios.get(`onboard/brokerage-settings`, {
    headers: type
      ? {
          dot,
          token,
          type,
        }
      : {
          dot,
          token,
        },
  });
  return data;
}

export async function getCarrierByToken(dot, token) {
  const { data } = await axios.get(`onboard/carrier`, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function getDocumentTypesByToken(dot, token) {
  const { data } = await axios.get(`carrier/shipment/document-types`, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function checkCarrierToken(dot, token) {
  const { data } = await axios.get(`onboard/check-token`, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function getPaymentTerms(dot, token) {
  const { data } = await axios.get(`onboard/customer/payment-terms`, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function getContactBookTypes(dot, token, type) {
  const { data } = await axios.get(`onboard/customer/contact-book-type`, {
    headers: type
      ? {
          dot,
          token,
          type,
        }
      : {
          dot,
          token,
        },
  });
  return data;
}

// Contact Book
export async function getCarrierContactBookByToken(dot, token, type) {
  const { data } = await axios.get(`onboard/carrier/contact-book`, {
    headers: type
      ? {
          dot,
          token,
          type,
        }
      : {
          dot,
          token,
        },
  });
  return data;
}

export async function createContactBookByToken(dot, token, params, type) {
  const { data } = await axios.post(`onboard/carriers/carrier-contact-book`, params, {
    headers: type
      ? {
          dot,
          token,
          type,
        }
      : {
          dot,
          token,
        },
  });
  return data;
}

export async function updateContactBook(dot, token, id, body, type) {
  const { data } = await axios.put(`onboard/carriers/carrier-contact-book/${id}`, body, {
    headers: type
      ? {
          dot,
          token,
          type,
        }
      : {
          dot,
          token,
        },
  });
  return data;
}

export async function deleteContactBook(dot, token, id, type) {
  const { data } = await axios.delete(`onboard/carriers/carrier-contact-book/${id}`, {
    headers: type
      ? {
          dot,
          token,
          type,
        }
      : {
          dot,
          token,
        },
  });
  return data;
}

export async function getCarrierPayTermsByToken(dot, token, id) {
  const { data } = await axios.get(`onboard/carriers/${id}/carrier-payment-term`, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function getCarrierStaticDocumentsByToken(dot, token, id) {
  const { data } = await axios.get(`onboard/carriers/${id}/carrier-static-documents`, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function getCarrierDocumentsByToken(dot, token, id) {
  const { data } = await axios.get(`onboard/carriers/${id}/carrier-documents`, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function createSingleDocument(dot, token, body) {
  const { data } = await axios.post(`onboard/carriers/carrier-document`, body, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function updateSingleDocument(dot, token, id, body) {
  const { data } = await axios.post(`onboard/carriers/carriers-document/${id}`, body, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function deleteSingleDocument(dot, token, id) {
  const { data } = await axios.delete(`onboard/carriers/carriers-document/${id}`, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function updateCarrierByToken(dot, token, params) {
  const { data } = await axios.post(`onboard/carrier`, params, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function createCarrierPayTermByToken(dot, token, params) {
  const { data } = await axios.post(`onboard/carriers/carrier-payment-term`, params, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function addOrUpdateStaticDocsByToken(dot, token, params) {
  const { data } = await axios.post(`onboard/carriers/carrier-documents`, params, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function uploadDocumentsCarrierShipment(dot, token, params) {
  const { data } = await axios.post(`carrier/shipment/upload-documents`, params, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function updateCarrierPayTermByToken(dot, token, params, id) {
  const { data } = await axios.put(`onboard/carriers/carrier-payment-term/${id}`, params, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function getCarriersList(params) {
  const { data } = await axios.get(`customer/carriers`, { params });
  return data;
}

export async function getCarrier({ id }) {
  const { data } = await axios.get(`/customer/carriers/${id}`);
  return data;
}

export async function getCarrierStaticDocs(id) {
  const { data } = await axios.get(`/customer/carriers/${id}/carrier-static-documents`);
  return data;
}

export async function getCarrierDocs(id) {
  const { data } = await axios.get(`/customer/carriers/${id}/carrier-documents`);
  return data;
}

export async function updateCarrierGeneralInfo(dataJson, id) {
  const { data } = await axios.post(`/customer/carrier/${id}`, dataJson);
  return data;
}

export async function carrierTabs() {
  const { data } = await axios.get('tabs?type=carrier');
  return data;
}

export async function carrierStatuses() {
  const { data } = await axios.get('customer/carrier-statuses');
  return data;
}

export async function createCarrier(params) {
  const { data } = await axios.post(`customer/carriers`, params);
  return data;
}

export async function createCarrierContactBook(params) {
  const { data } = await axios.post(`customer/carriers/carrier-contact-book`, params);
  return data;
}

export async function deleteCarrierContactBook(id) {
  const { data } = await axios.delete(`customer/carriers/carrier-contact-book/${id}`);
  return data;
}

export async function getCarrierContactBook({ id }) {
  const { data } = await axios.get(`customer/carriers/${id}/carrier-contact-book`);
  return data;
}

export async function updateCarrierContactBook(dataJson, id) {
  const { data } = await axios.put(`customer/carriers/carrier-contact-book/${id}`, dataJson);
  return data;
}

export async function createCarrierPaymentTerms(params) {
  const { data } = await axios.post(`customer/carriers/carrier-payment-term`, params);
  return data;
}

export async function reviseConfirmation(params) {
  const { data } = await axios.post(`customer/shipments/carrier/revise-confirmation`, params);
  return data;
}

export async function createCarrierDocuments(body) {
  const { data } = await axios.post(`customer/carriers/carrier-documents`, body);
  return data;
}

export async function createCarrierDocument(params) {
  const { data } = await axios.post(`customer/carriers/carrier-document`, params);
  return data;
}

export async function updateCarrierDocument(params, id) {
  const { data } = await axios.post(`customer/carriers/carriers-document/${id}`, params);
  return data;
}

export async function deleteCarrierDocument(id) {
  const { data } = await axios.delete(`customer/carriers/carriers-document/${id}`);
  return data;
}

export async function resendOnboardLink(body) {
  const { data } = await axios.post(`customer/carrier/resend-onboard-link`, body);
  return data;
}

export async function getCarrierPayTerms({ id }) {
  const { data } = await axios.get(`customer/carriers/${id}/carrier-payment-term`);
  return data;
}

export async function updateCarrierPayTerm(dataJson, id) {
  const { data } = await axios.put(`/customer/carriers/carrier-payment-term/${id}`, dataJson);
  return data;
}

export async function updateCarrierStatus(dataJson, id) {
  const { data } = await axios.post(`customer/carrier/${id}`, dataJson);
  return data;
}

export async function updateCarrierOperations(dataJson, id) {
  const { data } = await axios.post(`customer/carrier/${id}`, dataJson);
  return data;
}

export async function getCarrierLogs(params, signal) {
  const { data } = await axios.get(`carrier/change-log`, { params, signal });
  return data;
}

export async function addNote(body) {
  const { data } = await axios.post('/notes/carrier', body);
  return data;
}

export async function deleteNote(id) {
  const { data } = await axios.delete(`/notes/carrier/${id}`);
  return data;
}

export async function getCarrierNotes({ id, page, itemsPerPage, sort }) {
  const params = { page, itemsPerPage, sort };
  const { data } = await axios.get(`notes/carrier/${id}`, { params });
  return data;
}

export async function checkDot(dot) {
  const { data } = await axios.get(`carrier/registration/dot-check/${dot}`);
  return data;
}

export async function getStats(params) {
  const { data } = await axios.get(`search-states`, { params });
  return data;
}

export async function uploadDocument(body) {
  const { data } = await axios.post('customer/carrier/upload-document', body);
  return data;
}

export async function uploadDocumentPublic(body, headers) {
  const { data } = await axios.post('onboard/customer/carrier/upload-document', body, { headers });
  return data;
}

export async function getCarrierGraphData(id, params) {
  const { data } = await axios.get(`customer/carrier/${id}/graph-data`, { params });
  return data;
}

export async function getCarriersReportedStates(id, params) {
  const { data } = await axios.get(`customer/carrier/${id}/report-states`, { params });
  return data;
}

export async function getCarriersInspections(id, params) {
  const { data } = await axios.get(`customer/carrier/${id}/inspections`, { params });
  return data;
}

export async function getCarriersViolations(id, params) {
  const { data } = await axios.get(`customer/carrier/${id}/violations`, { params });
  return data;
}

export async function getCarriersCrashes(id, params) {
  const { data } = await axios.get(`customer/carrier/${id}/crashes`, { params });
  return data;
}

export async function getEquipmentTypes(dot) {
  const { data } = await axios.get('equipment-types', {
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function generateAccountToken(dot, token, body) {
  const { data } = await axios.post(`onboard/carriers/account-token`, body, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function carrierConfirmShipment(dot, token, body) {
  const { data } = await axios.post(`carrier/shipments/confirm`, body, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function saveBankAccount(dot, token, body) {
  const { data } = await axios.post(`onboard/carriers/account-save`, body, {
    headers: {
      dot,
      token,
    },
  });
  return data;
}

export async function getCarrierShipment(dot, token, params) {
  const { data } = await axios.get('carrier/shipments/info', {
    headers: {
      dot,
      token,
    },
    params,
  });
  return data;
}

export async function getCarrierShipmentCost(params) {
  const { data } = await axios.get(`customer/shipments/brokerage/cost`, { params });
  return data;
}

export async function getSafetyTabsCounts(id) {
  const { data } = await axios.get(`customer/carrier/${id}/safety/tabs-count`);
  return data;
}

export async function importCarrierCsv(body) {
  const { data } = await axios.post(`customer/carrier/import`, body);
  return data;
}

export async function getCarrierShipments(id, params) {
  const { data } = await axios.get(`customer/carrier/${id}/shipments`, { params });
  return data;
}

export async function getVendor(id) {
  const { data } = await axios.get(`account/vendor/${id}`);
  return data;
}

export async function getCarriers() {
  const { data } = await axios.get(`customer/carriers-list`);
  return data;
}
