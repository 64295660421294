import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import Modal from 'common/Modal';
import UserInfo from 'components/UserInfo';
import { InputWithIcon } from 'common/Input';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { blockNonPositiveNumbers } from 'utils/helpers';
import { getVehicles } from 'Api/Equipment';
import { getAvailableDrivers, swapDriverVehicle } from 'Api/Shipment';
import { getInitialValues } from './ReplaceDriver.data';
import { validationSchema } from './validationSchema';

const ReplaceDriver = ({ open, onClose, shipment, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const { assigned_to_1, assigned_to_2 } = shipment || {};

  const onSubmit = async () => {
    try {
      setLoading(true);
      const body = {
        shipment_id: shipment.shipment_id,
        assigned_to1: values.replace1 && values.assigned_to1 ? values.assigned_to1.id : undefined,
        assigned_to2: values.replace2 && values.assigned_to2 ? values.assigned_to2.id : undefined,
        driver1_amount: values.replace1 && values.amount1 ? values.amount1 : undefined,
        driver2_amount: values.replace2 && values.amount2 ? values.amount2 : undefined,
        previous_driver1_amount:
          values.replace1 && values.previous_driver1_amount ? values.previous_driver1_amount : undefined,
        previous_driver2_amount:
          values.replace2 && values.previous_driver2_amount ? values.previous_driver2_amount : undefined,
        equipment_id: values.replace_vehicle ? values.equipment.id : undefined,
      };

      await swapDriverVehicle(body);
      showToaster({ type: 'success', message: 'Success!' });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(shipment),
    onSubmit,
    validationSchema,
  });

  const getVehiclesList = async () => {
    try {
      const { data } = await getVehicles({ status_id: 4 });
      setVehicles(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDrivers = async () => {
    try {
      const { data } = await getAvailableDrivers();
      setDrivers(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getVehiclesList();
    getDrivers();
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <div className='d-flex flex-column'>
          <Typography variant='h6'>Replace Driver</Typography>
          <Typography variant='c3' style={{ color: palette.gray500 }}>
            Shipment {shipment.shipment_id} {shipment.shipment_billing?.[0]?.billing_customer?.company_name}
          </Typography>
        </div>
      }
      $bgColor={palette.gray0}
      $maxWidth='524px'
      $minWidth='524px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'Upload',
          type: 'primary',
          title: 'Swap',
          onClick: handleSubmit,
          disabled: loading || (!values.replace1 && !values.replace2 && !values.replace_vehicle),
        },
      ]}
    >
      <div className='mb-4'>
        <div className='mb-4'>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='replace1'
              checked={!!values.replace1}
              onChange={(checked) => handleChange('replace1', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Replace Driver 1
            </Typography>
          </div>
          {values.replace1 && (
            <div className='ms-4'>
              <div className='d-flex align-items-center gap-3 mb-2'>
                <div className='d-flex align-items-center gap-2 w-50'>
                  <UserInfo
                    size='26px'
                    statusSize='6px'
                    data={{
                      id: assigned_to_1.id,
                      image: assigned_to_1.image,
                      name: `${assigned_to_1.fname} ${assigned_to_1.lname}`,
                      email: assigned_to_1.email,
                      phone_number: assigned_to_1.phone_number,
                      status: assigned_to_1.status_data?.driver_status,
                      user_type: 'driver',
                    }}
                  />
                  <ArrowRight />
                </div>
                <div className='w-50'>
                  <Autocomplete
                    name='assigned_to1'
                    placeholder='Select Driver'
                    options={drivers}
                    value={values.assigned_to1}
                    onChange={(e, val) => handleChange('assigned_to1', val)}
                    getOptionLabel={(option) => `${option.fname} ${option.lname}`}
                    error={touchedErrors.assigned_to1}
                  />
                </div>
              </div>
              <div className='d-flex gap-3'>
                <InputWithIcon
                  type='number'
                  required
                  className='w-100'
                  name='previous_driver1_amount'
                  placeholder='Pay amount for old driver'
                  onChange={(event) => handleChange(event, `previous_driver1_amount`)}
                  error={touchedErrors.previous_driver1_amount}
                  onKeyDown={blockNonPositiveNumbers}
                />
                <InputWithIcon
                  type='number'
                  required
                  className='w-100'
                  name='amount1'
                  value={values.amount1}
                  onChange={handleChange}
                  placeholder='Pay amount for new driver'
                  error={touchedErrors.amount1}
                  onKeyDown={blockNonPositiveNumbers}
                />
              </div>
            </div>
          )}
        </div>
        {shipment.assigned_to2 && (
          <div className='mb-4'>
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                type='switch'
                name='replace2'
                checked={!!values.replace2}
                onChange={(checked) => handleChange('replace2', checked)}
                smail={false}
              />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Replace Driver 2
              </Typography>
            </div>
            {values.replace2 && (
              <div className='ms-4'>
                <div className='d-flex align-items-center gap-3 mb-2'>
                  <div className='d-flex align-items-center gap-2 w-50'>
                    <UserInfo
                      size='26px'
                      statusSize='6px'
                      data={{
                        id: assigned_to_2.id,
                        image: assigned_to_2.image,
                        name: `${assigned_to_2.fname} ${assigned_to_2.lname}`,
                        email: assigned_to_2.email,
                        phone_number: assigned_to_2.phone_number,
                        status: assigned_to_2.status_data?.driver_status,
                        user_type: 'driver',
                      }}
                    />
                    <ArrowRight />
                  </div>
                  <div className='w-50'>
                    <Autocomplete
                      name='assigned_to2'
                      placeholder='Select Driver'
                      options={drivers}
                      value={values.assigned_to2}
                      onChange={(e, val) => handleChange('assigned_to2', val)}
                      getOptionLabel={(option) => `${option.fname} ${option.lname}`}
                      error={touchedErrors.assigned_to2}
                    />
                  </div>
                </div>
                <div className='d-flex gap-3'>
                  <InputWithIcon
                    type='number'
                    required
                    className='w-100'
                    placeholder='Pay amount for old driver'
                    name='previous_driver2_amount'
                    onChange={(event) => handleChange(event, `previous_driver2_amount`)}
                    error={touchedErrors.previous_driver2_amount}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                  <InputWithIcon
                    type='number'
                    required
                    className='w-100'
                    name='amount2'
                    value={values.amount2}
                    onChange={handleChange}
                    placeholder='Pay amount for new driver'
                    error={touchedErrors.amount2}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div>
          <div className='d-flex align-items-center gap-2'>
            <CustomCheckbox
              type='switch'
              name='replace_vehicle'
              checked={!!values.replace_vehicle}
              onChange={(checked) => handleChange('replace_vehicle', checked)}
              smail={false}
            />
            <Typography variant='s2' style={{ color: palette.gray700 }}>
              Replace Vehicle
            </Typography>
          </div>
          {values.replace_vehicle && (
            <div className='ms-4'>
              <div className='d-flex align-items-center'>
                <div className='d-flex align-items-center gap-1 w-50'>
                  <div>
                    <Typography variant='s2'>{shipment.equipment?.equipment_id} - </Typography>
                    {shipment?.equipment?.equipment_type && (
                      <Typography variant='s2'>{shipment?.equipment.equipment_type.title}</Typography>
                    )}
                    {!!shipment?.equipment?.hooked_to && (
                      <div className='d-flex align-items-center gap-2 ms-2'>
                        <ArrowIcon />
                        <div>
                          <Typography variant='s2'>{shipment.equipment?.hooked_to?.equipment_id} - </Typography>
                          {shipment?.equipment?.hooked_to?.equipment_type && (
                            <Typography variant='s2'>
                              {shipment?.equipment?.hooked_to?.equipment_type?.title}
                            </Typography>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <ArrowRight className='ms-3' />
                </div>
                <div className='w-50'>
                  <Autocomplete
                    name='equipment'
                    options={vehicles}
                    value={values.equipment}
                    onChange={(e, val) => handleChange('equipment', val)}
                    getOptionLabel={(option) =>
                      `${option.equipment_id} ${option.equipment_type?.title || ''}${
                        option.hook_to
                          ? ` (${option.hook_to.equipment_id} ${option.hook_to.equipment_type?.title || ''})`
                          : ''
                      }`
                    }
                    error={touchedErrors.equipment}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ReplaceDriver;
