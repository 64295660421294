import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import uuid from 'react-uuid';
import searchIcon from 'assets/icons/header/Search.png';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import Autocomplete from 'common/Autocomplete';
import styles from './DocumentFilters.module.css';

const DocumentFilters = ({
  search,
  onChange,
  onDayChange,
  documentTypeList,
  dockTypeFilter,
  onCheckMultiselectData,
}) => {
  const hasBrokerage = useHasBrokerage();
  const [date, setDate] = useState('all');
  const [filterByGroupsData, setFilterByGroupsData] = useState([]);
  const multiSelectOptions = useMemo(() => {
    return {
      title: 'Document Types',
      id: 999,
      value: 'Document Types',
      key: 9999,
      submenu: [...filterByGroupsData],
    };
  }, [filterByGroupsData, documentTypeList]);

  const getDocumentTypes = () => {
    const filterByData = documentTypeList
      .filter((el) => (hasBrokerage ? true : !el.hidden))
      .map((el) => {
        return {
          title: el.name,
          id: el.id,
          value: 'groups',
          type: 'checkbox',
          key: uuid(),
        };
      });
    setFilterByGroupsData(filterByData);
  };

  const handleClick = (date) => {
    setDate(date);
    const now = new Date();
    const start = moment(new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0));

    switch (date) {
      case 'all':
        onDayChange({
          from_date: '',
          to_date: '',
        });
        break;
      case 'today':
        onDayChange({
          from_date: moment(start).format('DD-MM-YYYY'),
          to_date: moment(start).format('DD-MM-YYYY'),
        });
        break;
      case 'yesterday':
        onDayChange({
          from_date: moment(start).subtract(1, 'days').format('DD-MM-YYYY'),
          to_date: moment(start).subtract(1, 'days').format('DD-MM-YYYY'),
        });
        break;
      case 'week':
        onDayChange({
          from_date: moment().subtract(1, 'weeks').startOf('week').format('DD-MM-YYYY'),
          to_date: moment().subtract(1, 'weeks').endOf('week').format('DD-MM-YYYY'),
        });
        break;
      case 'month':
        onDayChange({
          from_date: moment().subtract(1, 'months').startOf('month').format('DD-MM-YYYY'),
          to_date: moment().subtract(1, 'months').endOf('month').format('DD-MM-YYYY'),
        });
        break;
      default:
        onDayChange({
          from_date: '',
          to_date: '',
        });
    }
  };

  const activeColor = {
    color: 'var(--indigo-500)',
  };

  useEffect(() => {
    getDocumentTypes();
  }, [documentTypeList]);

  return (
    <div className={styles.filtersWrapper}>
      <div className='header-search'>
        <div className='header-search-container d-flex'>
          <div className='me-2'>
            <Autocomplete
              multiple
              width='250px'
              labelKey='title'
              disableClearable={false}
              options={multiSelectOptions?.submenu || []}
              value={dockTypeFilter}
              placeholder={dockTypeFilter?.length ? '' : 'Document Type'}
              onChange={(e, val) => onCheckMultiselectData(val)}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              limitTags={1}
            />
          </div>
          <div>
            <input
              className={styles.searchInput}
              type='text'
              value={search}
              onChange={(e) => onChange(e.target.value)}
              placeholder='Search'
            />
            <div className='input-group-addon-search'>
              <img src={searchIcon} alt='search' />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.dateWrapper}>
        <div
          className={styles.dateFilter}
          style={date === 'all' ? activeColor : null}
          onClick={() => handleClick('all')}
        >
          All Time
        </div>
        <div
          className={styles.dateFilter}
          style={date === 'today' ? activeColor : null}
          onClick={() => handleClick('today')}
        >
          Today
        </div>
        <div
          className={styles.dateFilter}
          style={date === 'yesterday' ? activeColor : null}
          onClick={() => handleClick('yesterday')}
        >
          Yesterday
        </div>
        <div
          className={styles.dateFilter}
          style={date === 'week' ? activeColor : null}
          onClick={() => handleClick('week')}
        >
          Last Week
        </div>
        <div
          className={styles.dateFilter}
          style={date === 'month' ? activeColor : null}
          onClick={() => handleClick('month')}
        >
          Last Month
        </div>
      </div>
    </div>
  );
};
export default DocumentFilters;
