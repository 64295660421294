import { date, object, string } from 'yup';
import { validator } from 'utils/helpers';

export const validationSchema = object().shape({
  physical_insurance_policy_id: string().when('physical_insurance_policy_active', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  physical_insurance_expire_date: date().when(['physical_insurance_policy_active', 'physical_insurance_no_expire'], {
    is: (physical_insurance_policy_active, physical_insurance_no_expire) =>
      !!physical_insurance_policy_active && !physical_insurance_no_expire,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  physical_insurance_policy_attach: string().when('physical_insurance_policy_active', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),

  liability_insurance_policy_id: string().when('liability_insurance_policy_active', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  liability_insurance_expire_date: date().when(['liability_insurance_policy_active', 'liability_insurance_no_expire'], {
    is: (liability_insurance_policy_active, liability_insurance_no_expire) =>
      !!liability_insurance_policy_active && !liability_insurance_no_expire,
    then: validator.futureRequiredDate(),
    otherwise: validator.date(),
  }),
  liability_insurance_policy_attach: string().when('liability_insurance_policy_active', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),

  motor_cargo_insurance_policy_id: string().when('motor_cargo_insurance_policy_active', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  motor_cargo_insurance_expire_date: date().when(
    ['motor_cargo_insurance_policy_active', 'motor_cargo_insurance_no_expire'],
    {
      is: (motor_cargo_insurance_policy_active, motor_cargo_insurance_no_expire) =>
        !!motor_cargo_insurance_policy_active && !motor_cargo_insurance_no_expire,
      then: validator.futureRequiredDate(),
      otherwise: validator.date(),
    }
  ),
  motor_cargo_insurance_policy_attach: string().when('motor_cargo_insurance_policy_active', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),

  general_liability_insurance_policy_id: string().when('general_liability_insurance_policy_active', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  general_liability_insurance_expire_date: date().when(
    ['general_liability_insurance_policy_active', 'general_liability_insurance_no_expire'],
    {
      is: (general_liability_insurance_policy_active, general_liability_insurance_no_expire) =>
        !!general_liability_insurance_policy_active && !general_liability_insurance_no_expire,
      then: validator.futureRequiredDate(),
      otherwise: validator.date(),
    }
  ),
  general_liability_insurance_policy_attach: string().when('general_liability_insurance_policy_active', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
});
