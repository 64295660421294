import axios from 'axios';
import axiosInstance from '../services/axios';
import authHeader from '../services/auth-header';

const API_URL = process.env.REACT_APP_API_URL;

export async function Equipments({ page, itemsPerPage, query, allFilterData, QueryAllData, statusFilter, signal }) {
  let url = '/equipment/vehicle';
  if (query && typeof query === 'string') {
    url += `?${query}`;
  }
  const params = {
    page,
    itemsPerPage,
  };
  if (QueryAllData) {
    if (QueryAllData?.query_all) {
      url += `?query_all=${QueryAllData?.query_all}`;
    }
    if (QueryAllData?.equipment_id) {
      url += `?equipment_id=${QueryAllData?.equipment_id}`;
    }
    if (QueryAllData?.equipment_id_query) {
      url += `?equipment_id_query=${QueryAllData?.equipment_id_query}`;
    }
    if (QueryAllData?.equipment_id_exact) {
      url += `?equipment_id_exact=${QueryAllData?.equipment_id_exact}`;
    }
    if (QueryAllData?.driver) {
      url += `?driver_id=${QueryAllData?.driver}`;
    }
  }
  if (allFilterData) {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      if (value !== undefined && value !== '') {
        params[`${key}`] = typeof allFilterData[key] === 'object' ? allFilterData[key].toString() : allFilterData[key];
      }
    });
  }

  if (statusFilter) {
    params.filter = [statusFilter];
  }

  const { data } = await axios.get(API_URL + url, {
    headers: authHeader(),
    params,
    signal,
  });

  return data;
}

export async function EquipmentsMap({
  page,
  itemsPerPage,
  query,
  allFilterData,
  QueryAllData,
  radius,
  state,
  city,
  postCode,
  statusFilter,
}) {
  let url = '/equipment/vehicle';
  if (query && typeof query === 'string') {
    url += `?${query}`;
  }
  const params = {
    page,
    itemsPerPage,
    radius,
    state,
    city,
    postCode,
  };
  if (QueryAllData) {
    if (QueryAllData?.query_all) {
      url += `?query_all=${QueryAllData?.query_all}`;
    }
    if (QueryAllData?.equipment_id) {
      url += `?equipment_id=${QueryAllData?.equipment_id}`;
    }
    if (QueryAllData?.driver) {
      url += `?driver_id=${QueryAllData?.driver}`;
    }
  }
  if (allFilterData) {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      if (value !== undefined && value !== '') {
        params[`${key}`] = typeof allFilterData[key] === 'object' ? allFilterData[key].toString() : allFilterData[key];
      }
    });
  }
  if (statusFilter) {
    params.filter = [statusFilter];
  }

  const { data } = await axios.get(API_URL + url, {
    headers: authHeader(),
    params,
  });

  return data;
}

export async function EquipmentProfile({ id, value }) {
  if (value) {
    const { data } = await axios.get(`${API_URL}/equipment/vehicle?equipment_id=${value}`, {
      headers: authHeader(),
    });
    return data;
  }
  const { data } = await axios.get(`${API_URL}/equipment/vehicle?equipment_id=${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function Groups() {
  const { data } = await axios.get(`${API_URL}/group`, {
    headers: authHeader(),
  });
  return data;
}

export async function DriversList() {
  const { data } = await axios.get(`${API_URL}/drivers/not-assigned-equipment`, { headers: authHeader() });
  return data;
}

export async function Trailer({ page, itemsPerPage, query, allFilterData, statusFilter, signal }) {
  let url = '/equipment/trailer';
  if (query) {
    url += `?${query}`;
  }

  const params = {
    page,
    itemsPerPage,
  };
  if (allFilterData) {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      if (value !== undefined && value !== '') {
        params[`${key}`] = typeof allFilterData[key] === 'object' ? allFilterData[key].toString() : allFilterData[key];
      }
    });
  }

  if (statusFilter) {
    params.filter = [statusFilter];
  }

  const { data } = await axios.get(API_URL + url, {
    headers: authHeader(),
    params,
    signal,
  });
  return data;
}

export async function TrailerMap({
  page,
  itemsPerPage,
  query,
  allFilterData,
  radius,
  state,
  city,
  postCode,
  statusFilter,
}) {
  let url = '/equipment/trailer';
  if (query) {
    url += `?${query}`;
  }

  const params = {
    page,
    itemsPerPage,
    radius,
    state,
    city,
    postCode,
  };
  if (allFilterData) {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      if (value !== undefined && value !== '') {
        params[`${key}`] = typeof allFilterData[key] === 'object' ? allFilterData[key].toString() : allFilterData[key];
      }
    });
  }
  if (statusFilter) {
    params.filter = [statusFilter];
  }
  const { data } = await axios.get(API_URL + url, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function MaintanceCost(id) {
  const { data } = await axios.get(`${API_URL}/equipment/maintenance-cost?equipment_id=${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function ConnectedServices() {
  const { data } = await axios.get(`${API_URL}/customer/customer-connected-services?type=vehicle`, {
    headers: authHeader(),
  });
  return data;
}

export async function EquipmentMaintenanceRecord(id, { query }) {
  let url = `/equipment/maintenance-record?equipment_id=${id}`;

  if (query) {
    url += `&${query}`;
  }
  const { data } = await axios.get(API_URL + url, {
    headers: authHeader(),
  });
  return data;
}

export async function getEquipmentRequiredVehicles() {
  const { data } = await axios.get(`${API_URL}/vehicles`, {
    headers: authHeader(),
  });
  return data;
}

export async function getEquipmentRequiredTrailers() {
  const { data } = await axios.get(`${API_URL}/trailers`, {
    headers: authHeader(),
  });
  return data;
}

export async function trailerCount() {
  const { data } = await axios.get(`${API_URL}/equipment/trailerCount`, {
    headers: authHeader(),
  });
  return data;
}

export async function OwnerOperator() {
  const { data } = await axios.get(`${API_URL}/operators/list`, {
    headers: authHeader(),
  });
  return data;
}

export async function EquipmentMaintenanceSchedule(id, { query }) {
  let url = `/equipment/maintenance-schedule?equipment_id=${id}`;

  if (query) {
    url += `&${query}`;
  }
  const { data } = await axios.get(API_URL + url, {
    headers: authHeader(),
  });
  return data;
}

export async function ViewMainTenanceRecord({ id }) {
  const { data } = await axios.get(`${API_URL}/equipment/maintenance-record/${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function EquipmentConnectedService(id) {
  const { data } = await axios.get(`${API_URL}/equipment/connected-services/${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function checkAddOnStatus(id, equipment_id) {
  const { data } = await axios.get(`${API_URL}/customer/addon/check-add-on-assign-to-status`, {
    headers: authHeader(),
    params: {
      addon_id: id,
      equipment_id,
    },
  });
  return data;
}

export async function EquipmentGrossRevenue(id, date) {
  const { data } = await axios.get(`${API_URL}/equipment/${id}/shipment-stats?month_of_year=${date}`, {
    headers: authHeader(),
  });
  return data;
}

export async function ManageType() {
  const { data } = await axios.get(`${API_URL}/equipment/maintenance-type`, {
    headers: authHeader(),
  });
  return data;
}

export async function EquipmentCities(id) {
  const { data } = await axios.get(`${API_URL}/search-cities?state_id=${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function EquipmentState(id) {
  const { data } = await axios.get(`${API_URL}/search-states?country_id=${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function LicenseState(id) {
  return axios.get(`${API_URL}/search-states?country_id=${id}`, {
    headers: authHeader(),
  });
}

export async function EquipmentDetectedHardware(id) {
  const { data } = await axios.get(`${API_URL}/equipment-profile/driver-gps-details/${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function Dimension(id, length) {
  const params = {
    equipment_type_id: id,
    length: length || undefined,
  };

  const { data } = await axios.get(`${API_URL}/equipment-dimensions`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function DimensionArray(id) {
  const params = {
    equipment_type_id: id,
  };

  const { data } = await axios.get(`${API_URL}/equipment-dimensions`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function ShipmentId() {
  const { data } = await axios.get(`${API_URL}/equipment-shipment`, {
    headers: authHeader(),
  });
  return data;
}

export async function StopPointId(id) {
  const { data } = await axios.get(`${API_URL}/shipment-stoppoint/${id}`, {
    headers: authHeader(),
  });
  return data;
}

export async function getVehicleConnectedServices() {
  const { data } = await axios.get(`${API_URL}/customer/customer-connected-services?type=vehicle`, {
    headers: authHeader(),
  });
  return data;
}

export async function getTrailerConnectedServices() {
  const { data } = await axios.get(`${API_URL}/customer/customer-connected-services?type=trailer`, {
    headers: authHeader(),
  });
  return data;
}

export async function TrackingHistoryData(id, start, end) {
  const { data } = await axios.get(
    `${API_URL}/equipment-tracking-history-list/${id}?from_date=${start}&to_date=${end}`,
    {
      headers: authHeader(),
    }
  );
  return data;
}

export async function hardwareCart() {
  const { data } = await axios.get(`${API_URL}/hardware-cart`, {
    headers: authHeader(),
  });
  return data;
}

export async function StopPoints(params) {
  const { data } = await axios.get(`${API_URL}/customer/stop-point`, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function MyHardwareList({ query, itemsPerPage, page }) {
  let url = '/my-hardware';
  if (query) {
    url += `?${query}`;
  }
  const params = {
    page,
    itemsPerPage,
  };
  const { data } = await axios.get(API_URL + url, {
    headers: authHeader(),
    params,
  });
  return data;
}

export async function mapFlag({ body }) {
  const { data } = await axios.post(`${API_URL}/equipment/map/flag`, body, {
    headers: authHeader(),
  });
  return data;
}

export async function getAlertCenter({
  page,
  itemsPerPage,
  query,
  allFilterData,
  alert_status,
  preventive_action,
  sortField,
  sortBy,
  signal,
}) {
  const url = '/customer/alert-center';

  const params = {
    page,
    itemsPerPage,
    preventive_action,
    [sortField]: sortBy,
  };

  if (alert_status) {
    params.alert_status = alert_status;
  }
  if (query) {
    params.query = query;
  }

  if (allFilterData) {
    Object.keys(allFilterData).forEach((key) => {
      const value = allFilterData[key];
      if (value !== undefined) {
        params[`${key}`] = typeof allFilterData[key] === 'object' ? allFilterData[key].toString() : allFilterData[key];
      }
    });
  }
  const { data } = await axios.get(API_URL + url, {
    headers: authHeader(),
    params,
    signal,
  });
  return data;
}

export async function getFuelTypes() {
  const { data } = await axiosInstance.get(`fuel-types`);
  return data;
}

export async function getMileageHistory({ id, params }) {
  const { data } = await axiosInstance.get(`equipment/${id}/shipment-history`, { params });
  return data;
}

export async function updateEquipment(equipmentId, body) {
  const { data } = await axiosInstance.put(`/equipment/${equipmentId}`, body);
  return data;
}

export async function updateEquipmentStatus(id, body) {
  const { data } = await axiosInstance.put(`/equipment/equipment-status/${id}`, body);
  return data;
}

export async function getVehicleInventory(params) {
  const { data } = await axiosInstance.get(`/equipment/inventory`, { params });
  return data;
}

export async function createVehicleInventory(body) {
  const { data } = await axiosInstance.post(`/equipment/inventory`, body);
  return data;
}

export async function updateVehicleInventory(id, body) {
  const { data } = await axiosInstance.post(`/equipment/inventory/${id}`, body);
  return data;
}

export async function getHookToTrailers(vehicleId, params) {
  const { data } = await axiosInstance.get(`/equipment/hook-to/${vehicleId}`, { params });
  return data;
}

export async function createEquipment(body) {
  const { data } = await axiosInstance.post(`/equipment`, body);
  return data;
}

export async function getEquipmentDocumentTypes() {
  const { data } = await axiosInstance.get(`/equipment-document/types`);
  return data;
}

export async function getEquipmentDocuments(equipmentId, params) {
  const { data } = await axiosInstance.get(`equipment/${equipmentId}/documents`, { params });
  return data;
}

export async function getEquipmentTypes(params, dot) {
  const { data } = await axiosInstance.get(`/equipment-types`, {
    params,
    headers: {
      'x-dot': dot,
    },
  });
  return data;
}

export async function getVehicleTypes(params) {
  const { data } = await axiosInstance.get(`/vehicles`, { params });
  return data;
}

export async function getTrailerTypes(params) {
  const { data } = await axiosInstance.get(`/trailers`, { params });
  return data;
}

export async function createEquipmentDocument(body) {
  const { data } = await axiosInstance.post(`/equipment-documents`, body);
  return data;
}

export async function updateEquipmentDocument(body) {
  const { data } = await axiosInstance.post(`/equipment-documents-update`, body);
  return data;
}

export async function deleteEquipmentDocument(id) {
  const { data } = await axiosInstance.delete(`/equipment-documents/${id}`);
  return data;
}

export async function uploadEquipmentDocument(body) {
  const { data } = await axiosInstance.post(`/equipment-documents/upload`, body);
  return data;
}

export async function bulkCreateEquipmentDocuments(body) {
  const { data } = await axiosInstance.post(`/equipment-documents/bulk`, body);
  return data;
}

export async function updateDetentionDate(id, body) {
  const { data } = await axiosInstance.put(`/equipment/detention-data/${id}`, body);
  return data;
}

export async function updateNextAvailability(body) {
  const { data } = await axiosInstance.put(`/equipment/next-available-location`, body);
  return data;
}

export async function getEquipment(id) {
  const { data } = await axiosInstance.get(`/equipment/${id}`);
  return data;
}

export async function getVehicles(params) {
  const { data } = await axiosInstance.get(`/equipment/vehicles`, { params });
  return data;
}
