import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router-dom';
import LogsSection from 'componentsV2/ChangeLog/LogsSection';
import OwnerActivity from 'components/OwnerActivity/OwnerActivity';
import OwnerDocument from 'components/OwnerDocument/OwnerDocument';
import OwnerEquipment from 'components/OwnerEuipment/OwnerEquipment';
import OwnerContractor from 'components/OwnerContractor/OwnerContractor';
import OperatorNoteTable from 'components/OperatorNoteTable/OperatorNoteTable';
import OwnerShipmentTabTable from 'components/OwnerShipmentTabTable/OwnerShipmentTabTable';
import OwnerGeneralInformation from 'components/OwnerGeneralInformation/OwnerGeneralInformation';
import OwnerOperatorCompensation from 'components/OwnerOperatorCompensation/OwnerOperatorCompensation';
import { palette } from 'utils/constants';
import './OwnerProfileTab.css';
import { CHANGE_LOG_TYPE } from 'componentsV2/ChangeLog';

export default function DriverProfileTab({
  email_id,
  staffInfo,
  updateCompensation,
  showError,
  updateProfile,
  onSuccess,
  loading,
  staffStatsData,
  operatorCompansation,
  operator_name,
}) {
  const { id } = useParams();
  const [key, setKey] = useState('General');

  return (
    <div className='modal-container-tab driver-style-wrap owner-profile-tab'>
      <Tabs
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className='mb-3 profile-tab'
        style={{ borderBottomColor: palette.gray50 }}
      >
        <Tab eventKey='General' className='profile-tab-content' unmountOnExit title='General'>
          <div className='Driver-general' style={{ backgroundColor: palette.white }}>
            <OwnerGeneralInformation
              staffInfo={staffInfo}
              updateGeneralProfile={updateProfile}
              onSuccess={onSuccess}
              loading={loading}
            />
            <div className='activity-container'>
              <div>
                <OwnerActivity staffStatsData={staffStatsData} />
              </div>
              <div className='activity-container-bottom'>
                <div className='contractor'>
                  <OwnerContractor />
                </div>
                <div className='equipment'>
                  <OwnerEquipment />
                </div>
              </div>
            </div>
          </div>
        </Tab>
        <Tab
          eventKey='Compensation'
          unmountOnExit
          className='profile-tab-content'
          title='Compensation'
          style={{ backgroundColor: palette.white }}
        >
          <div>
            <OwnerOperatorCompensation
              email_id={email_id}
              showError={showError}
              updateCompensation={updateCompensation}
              operatorCompansation={operatorCompansation}
              onSuccess={onSuccess}
              loading={loading}
            />
          </div>
        </Tab>
        <Tab eventKey='Documents' unmountOnExit className='profile-tab-content' title='Documents'>
          <OwnerDocument />
        </Tab>
        <Tab
          eventKey='Shipments'
          unmountOnExit
          className='profile-tab-content'
          title='Shipments'
          style={{ backgroundColor: palette.white }}
        >
          <div>
            <OwnerShipmentTabTable />
          </div>
        </Tab>
        <Tab eventKey='log' unmountOnExit className='profile-tab-content' title='Log'>
          <LogsSection type={CHANGE_LOG_TYPE.OWNER_OPERATOR} params={{ operator_id: id }} />
        </Tab>

        <Tab eventKey='notes' unmountOnExit className='profile-tab-content' title='Notes'>
          <div style={{ backgroundColor: palette.white }}>
            <OperatorNoteTable operator_name={operator_name} />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}
