import PDFMerger from 'pdf-merger-js';
import { getDocsFiles } from 'Api/Shipment';
import { generatePDF } from './helpers';

const urlToBlob = (fileUrl) => {
  return fetch(fileUrl).then((response) => response.blob());
};

const getShipmentDocuments = async ({ shipmentData }) => {
  try {
    const { data } = await getDocsFiles(shipmentData.shipment.shipment_id);
    const docsToMerge = data.filter(
      (doc) =>
        !(doc.doc_type?.name === 'Carrier Confirmation' && !doc.doc_type?.is_delete) && doc.path?.endsWith('.pdf')
    );
    const documentPaths = [];
    for (const item of docsToMerge) {
      const blob = await urlToBlob(item.path);
      if (blob.type !== 'application/xml') {
        documentPaths.push(blob);
      }
    }
    return documentPaths;
  } catch (e) {
    return [];
  }
};

export const getMergedUrl = async (docs, invoiceId = '', shipmentId = '') => {
  try {
    const merger = new PDFMerger();

    for (const file of docs) {
      await merger.add(file);
    }

    await merger.setMetadata({
      title: `Invoice ${invoiceId}`,
    });
    const mergedPdf = await merger.saveAsBlob();
    return URL.createObjectURL(mergedPdf);
  } catch (e) {
    if (e.message?.includes('but got instance of undefined')) {
      throw new Error(
        `There is an issue with one of your uploaded documents (Shipment ID - ${shipmentId}). E-signature and secure documents are not permitted.`
      );
    }
    throw e;
  }
};

const viewPdf = async ({
  companyProfile,
  chargeTypes,
  shipmentData,
  accountingSettings,
  instructions,
  billDate = null,
  dueDate = null,
  payable_to = null,
  email_to = null,
  send_email = null,
  payment_method = null,
  stripePaymentLink,
  currency,
  formatDate,
  formatDateTime,
  existingInvoiceId,
}) => {
  let invoiceId;
  if (existingInvoiceId) {
    invoiceId = existingInvoiceId;
  } else {
    invoiceId = shipmentData.invoiceId;
  }
  return {
    pdf: generatePDF({
      accountingSettings,
      companyProfile,
      instructions,
      shipmentData,
      invoiceId,
      shipmentCharges: shipmentData.billing_charges,
      chargeTypes,
      billDate,
      dueDate,
      payable_to,
      email_to,
      send_email,
      payment_method,
      stripePaymentLink,
      currency,
      formatDate,
      formatDateTime,
    }),
    invoiceId,
  };
};

export const generateShipmentInvoice = async ({
  companyProfile,
  chargeTypes,
  shipmentData,
  accountingSettings,
  instructions,
  billDate = null,
  dueDate = null,
  payable_to = null,
  email_to = null,
  send_email = null,
  payment_method = null,
  attach_noa_letter,
  stripePaymentLink,
  currency,
  formatDate,
  formatDateTime,
  existingInvoiceId = '',
}) => {
  const { pdf, invoiceId } = await viewPdf({
    companyProfile,
    chargeTypes,
    shipmentData,
    accountingSettings,
    instructions,
    billDate,
    dueDate,
    payable_to,
    email_to,
    send_email,
    payment_method,
    stripePaymentLink,
    currency,
    formatDate,
    formatDateTime,
    existingInvoiceId,
  });
  const shipmentDocumentsPdf = await getShipmentDocuments({ shipmentData });

  const docs = attach_noa_letter
    ? [pdf.blob, accountingSettings.notice_letter, ...shipmentDocumentsPdf]
    : [pdf.blob, ...shipmentDocumentsPdf];

  return getMergedUrl(docs, invoiceId, shipmentData.shipment.shipment_id);
};
