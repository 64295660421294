import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ErrorMessage, Field, FieldArray, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import MaterialTable from 'material-table';
import { palette } from 'utils/constants';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import moreInfo from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as TickIcon } from 'assets/icons/tickIndigo.svg';
import Input from 'common/Input';
import Tooltip from 'common/Tooltip';
import { blockNonPositiveNumbers, blockNotNumberChars, phoneRegex } from 'utils/helpers';
import useShowToaster from 'hooks/useShowToaster';
import {
  createCustomerContactBook,
  deleteCustomersContactBook,
  getCustomerContactBook,
  getCustomerContactBookType,
  updateCustomersContactBook,
} from 'Api/Customers';
import { AppendToFormData } from '../CreateShipment/ShipmentStops/helpers/constants';
import CustomButton from '../CustomButton/CustomButton';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import CustomSelect from '../CreateShipment/ShipmentStops/helpers/CustomSelect';
import CustomPhoneInput from '../CustomPhoneInput';
import CustomInput from '../CreateShipment/helpers/CustomInput';
import { Typography } from '../Typography';
import { useColumns } from './ContactBookTab.data';
import sm from './ContactBookTab.module.css';

const ContactBookTab = ({ isModalView, customer, onSuccess, setHasChanges }) => {
  const { id } = useParams();
  const showToaster = useShowToaster();
  const [toggle, setToggle] = React.useState(!!isModalView);
  const [contactBooK, setContactBook] = React.useState();
  const [refetchIndex, setRefetchIndex] = useState(Date.now());
  const [changedIndexes, setChangedIndexes] = useState([]);
  const formRef = useRef();
  const styles = useMemo(() => {
    return {
      labelStyle: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: 0,
      },
      error: {
        color: palette.red500,
      },
      type: {
        width: '150px',
        height: 32,
      },
      fullWidthPercent: {
        width: '100%',
      },
      addAnotherTitle: {
        fontSize: 14,
        color: palette.indigo500,
        marginLeft: 8,
      },
      addAnotherButton: {
        padding: '7px 12px',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        paddingLeft: 0,
      },
      deleteIcon: {
        margin: '12px 8px 0',
        cursor: 'pointer',
      },
    };
  }, [palette]);
  const defaultValue = {
    contact_name: '',
    contact_email: '',
    phone_number: '',
    extension: '',
    contact_type_id: '',
    invoicing: false,
    portal_access: false,
    email_updates: false,
    sms_updates: false,
  };

  const [typeData, setTypeData] = useState([]);

  const getTypeData = () => {
    getCustomerContactBookType()
      .then((res) => {
        if (res && res?.data) {
          const selectData = res.data.map((el) => {
            return {
              ...el,
              key: el.id,
              label: el.title,
              labelSelected: null,
            };
          });
          setTypeData(selectData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const validateStopPointInput = (value) => {
    let error;
    if (value !== undefined && value === '' && value?.trim() === '') {
      error = 'Required';
    }
    return error;
  };

  const validateEmail = (value) => {
    let error;
    const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!isValidEmail.test(value) && value) {
      error = 'Invalid email address';
    }
    // else if (!value) {
    //   error = 'Email address is required';
    // }
    return error;
  };

  const validatePhoneNumber = (value) => {
    let error;

    if (!value) {
      error = 'Required';
    } else if (!phoneRegex.test(value) && value) {
      error = 'Invalid phone number';
    }

    return error;
  };

  const onDelete = (el, index, arrayHelpers) => {
    if (el?.id) {
      deleteCustomersContactBook(el.id).then(() => {
        arrayHelpers.remove(index);
        showToaster({ type: 'success', message: 'Contact deleted successfully' });
      });
    } else {
      arrayHelpers.remove(index);
      if (changedIndexes.includes(index)) {
        setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
      }
    }
  };

  const onUpdate = (
    el,
    index,
    values,
    errors,
    getFieldHelpers,
    arrayHelpers,
    validateForm,
    validateField,
    setFieldValue
  ) => {
    const fieldNames = [
      `contact_books[${index}][contact_name]`,
      `contact_books[${index}][contact_email]`,
      `contact_books[${index}][phone_number]`,
      `contact_books[${index}][extension]`,
      `contact_books[${index}][contact_type_id]`,
    ];
    const promises = fieldNames.map((fieldName) => {
      const fieldHelper = getFieldHelpers(fieldName);
      fieldHelper.setTouched();
      return validateField(fieldName);
    });

    Promise.all(promises).then((errors) => {
      const isValid = errors.every((error) => !error);
      if (isValid) {
        if (changedIndexes.includes(index)) {
          setChangedIndexes((prevIndexes) => prevIndexes.filter((i) => i !== index));
        }
        if (el?.id) {
          updateContactUpdate(el, values);
        } else {
          createContactUpdate(el, values, index, setFieldValue);
        }
      }
    });
  };

  const updateContactUpdate = (el) => {
    const updateData = {
      customer_id: el.customer_id,
      contact_name: el.contact_name,
      phone_number: el.phone_number,
      extension: el.extension,
      contact_type_id: el.contact_type_id,
      invoicing: el.invoicing ? 1 : 0,
      portal_access: el.portal_access ? 1 : 0,
      email_updates: el.email_updates ? 1 : 0,
      sms_updates: el.sms_updates ? 1 : 0,
      contact_email: el.contact_email !== '' ? el.contact_email : null,
    };

    updateCustomersContactBook(updateData, el.id)
      .then((res) => {
        if (res && res.data) {
          showToaster({ type: 'success', message: 'Contact updated successfully' });
          if (isModalView) {
            setRefetchIndex(Date.now());
          }
        }
      })
      .catch(() => {
        // Do nothing
      });
  };

  const createContactUpdate = (el, values, index, setFieldValue) => {
    const formData = new FormData();
    const updateData = AppendToFormData({}, values, index);
    const map = mapper(updateData, index);
    Object.keys(map).map((key) => formData.append(key, map[key]));
    createCustomerContactBook(formData).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.data?.[0]) {
          setFieldValue(`contact_books[${index}][id]`, res.data.data[0].id);
        }
        showToaster({ type: 'success', message: 'Contact created successfully!' });
        if (isModalView) {
          setRefetchIndex(Date.now());
        }
      }
    });
  };

  const mapper = (obj, index) => {
    const newData = {
      customer_id: id || customer?.id,
    };
    const map = {
      [`contact_books[${index}]invoicing`]: obj[`contact_books[${index}]invoicing`] ? '1' : '0',
      [`contact_books[${index}]email_updates`]: obj[`contact_books[${index}]email_updates`] ? '1' : '0',
      [`contact_books[${index}]portal_access`]: obj[`contact_books[${index}]portal_access`] ? '1' : '0',
      [`contact_books[${index}]sms_updates`]: obj[`contact_books[${index}]sms_updates`] ? '1' : '0',
    };
    const namesMap = {
      [`contact_books[${index}]sms_updates`]: [`contact_books[${index}][sms_updates]`],
      [`contact_books[${index}]email_updates`]: [`contact_books[${index}][email_updates]`],
      [`contact_books[${index}]invoicing`]: [`contact_books[${index}][invoicing]`],
      [`contact_books[${index}]portal_access`]: [`contact_books[${index}][portal_access]`],
      [`contact_books[${index}]contact_name`]: [`contact_books[${index}][contact_name]`],
      [`contact_books[${index}]contact_email`]: [`contact_books[${index}][contact_email]`],
      [`contact_books[${index}]phone_number`]: [`contact_books[${index}][phone_number]`],
      [`contact_books[${index}]extension`]: [`contact_books[${index}][extension]`],
      [`contact_books[${index}]contact_type_id`]: [`contact_books[${index}][contact_type_id]`],
    };

    Object.keys(obj).forEach((key) => {
      const name = namesMap[key];
      if (map[key]) {
        newData[name] = map[key];
      } else {
        newData[name] = obj[key];
      }
    });

    return newData;
  };

  const onCancel = () => {
    setToggle(!toggle);
    setRefetchIndex(Date.now());
    setChangedIndexes([]);
  };

  const initialValue = isModalView
    ? {
        contact_books: customer.customer_contact_book.map((el) => {
          return {
            ...el,
            invoicing: el?.invoicing === 1,
            portal_access: el?.portal_access === 1,
            email_updates: el?.email_updates === 1,
            sms_updates: el?.sms_updates === 1,
          };
        }),
      }
    : contactBooK?.contactBook?.data?.length
    ? {
        contact_books: contactBooK.contactBook.data.map((el) => {
          return {
            ...el,
            invoicing: el?.invoicing === 1,
            portal_access: el?.portal_access === 1,
            email_updates: el?.email_updates === 1,
            sms_updates: el?.sms_updates === 1,
          };
        }),
      }
    : { contact_books: [{ ...defaultValue }] };

  useEffect(() => {
    getCustomerContactBook({ id: id || customer?.id }).then((res) => {
      setContactBook({ contactBook: res });
      if (onSuccess) {
        onSuccess(id || customer?.id, res.data);
      }
    });
    getTypeData();
  }, [refetchIndex]);

  useEffect(() => {
    if (isModalView) {
      setHasChanges(!!changedIndexes.length);
    }
  }, [changedIndexes]);

  const handleSelectChange = (index) => {
    if (!changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    }
  };

  const handleFieldChange = (index, fieldName, values, setChangedIndexes, changedIndexes) => (event) => {
    const newValue = event.target.value;
    const oldValue = values.contact_books[index][fieldName];
    const isChanged = newValue !== oldValue;

    if (isChanged && !changedIndexes.includes(index)) {
      setChangedIndexes((prevIndexes) => [...prevIndexes, index]);
    }
  };

  const columns = useColumns();

  return (
    <div
      className={sm.contactBookTab}
      style={{
        backgroundColor: palette.gray0,
        borderColor: palette.gray50,
      }}
    >
      {!isModalView && (
        <div
          className={sm['contactBookTab-header']}
          style={{
            backgroundColor: palette.white,
            borderBottomColor: palette.gray50,
          }}
        >
          <p className={sm.heading} style={{ color: palette.gray900 }}>
            Contact Book
          </p>
          {!toggle ? (
            <div>
              <img src={moreInfo} alt='' onClick={() => setToggle(!toggle)} />
            </div>
          ) : (
            <div>
              <button onClick={onCancel} className='driver-compansation-cancel-btn'>
                Close
              </button>
            </div>
          )}
        </div>
      )}
      {toggle ? (
        <div className={sm.body}>
          <Formik initialValues={initialValue} innerRef={formRef}>
            {({ values, getFieldHelpers, errors, validateForm, validateField, setFieldValue }) => (
              <Form style={{ width: '100%' }}>
                <FieldArray
                  name='contact_books'
                  render={(arrayHelpers) => {
                    return (
                      <div className={sm.fieldArrayWrapper}>
                        {values.contact_books.map((el, index) => {
                          const namePrefix = `contact_books[${index}]`;
                          return (
                            <div
                              key={namePrefix}
                              className={[sm.row, sm.line, sm.fieldsWrapper].join(' ')}
                              style={{ marginTop: 0 }}
                            >
                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Name <span className={sm.required_system}>*</span>
                                  </Typography>
                                )}
                                <Field
                                  name={`${namePrefix}[contact_name]`}
                                  component={CustomInput}
                                  style={{ paddingLeft: 10, width: '250px' }}
                                  labelStyle={styles.labelStyle}
                                  validate={validateStopPointInput}
                                  className={sm.input_basic_details}
                                  onChange={handleFieldChange(
                                    index,
                                    'contact_name',
                                    values,
                                    setChangedIndexes,
                                    changedIndexes
                                  )}
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[contact_name]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>
                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Email
                                  </Typography>
                                )}
                                <Field
                                  name={`${namePrefix}[contact_email]`}
                                  component={CustomInput}
                                  style={{ paddingLeft: 10, width: '250px' }}
                                  labelStyle={styles.labelStyle}
                                  validate={validateEmail}
                                  className={sm.input_basic_details}
                                  onChange={handleFieldChange(
                                    index,
                                    'contact_email',
                                    values,
                                    setChangedIndexes,
                                    changedIndexes
                                  )}
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[contact_email]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>
                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Phone Number <span className={sm.required_system}>*</span>
                                  </Typography>
                                )}
                                <Field
                                  name={`${namePrefix}[phone_number]`}
                                  inputStyle={{ width: '230px' }}
                                  labelStyle={{ marginTop: 0 }}
                                  countryCodeEditable={false}
                                  component={CustomPhoneInput}
                                  validate={validatePhoneNumber}
                                  onChange={(value) =>
                                    handleFieldChange(
                                      index,
                                      'phone_number',
                                      values,
                                      setChangedIndexes,
                                      changedIndexes
                                    )({ target: { value } })
                                  }
                                />
                                <ErrorMessage
                                  name={`${namePrefix}[phone_number]`}
                                  render={(error) => (
                                    <Typography variant='c2' style={styles.error}>
                                      {error}
                                    </Typography>
                                  )}
                                />
                              </div>
                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    EXT
                                  </Typography>
                                )}
                                <Input
                                  width='70px'
                                  type='number'
                                  name={`${namePrefix}[extension]`}
                                  value={values.contact_books?.[index]?.extension}
                                  onChange={(e) => {
                                    if (Number(e.target.value) > 999) {
                                      return;
                                    }
                                    setFieldValue(`${namePrefix}[extension]`, e.target.value);
                                    handleFieldChange(index, 'extension', values, setChangedIndexes, changedIndexes)(e);
                                  }}
                                  onKeyDown={(e) => {
                                    blockNotNumberChars(e);
                                    blockNonPositiveNumbers(e);
                                  }}
                                />
                              </div>
                              <div className={sm.field_wrapper}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Type <span className={sm.required_system}>*</span>
                                  </Typography>
                                )}
                                <div>
                                  <Field
                                    name={`${namePrefix}[contact_type_id]`}
                                    options={typeData}
                                    styles={styles.type}
                                    component={CustomSelect}
                                    menuStyles={styles.fullWidthPercent}
                                    validate={validateStopPointInput}
                                    onChange={() => {
                                      handleSelectChange(index);
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`${namePrefix}[contact_type_id]`}
                                    render={(error) => (
                                      <Typography variant='c2' style={styles.error}>
                                        {error}
                                      </Typography>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className={sm.field_wrapper2}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Invoicing
                                  </Typography>
                                )}
                                <Field name={`${namePrefix}[invoicing]`}>
                                  {({ field }) => (
                                    <CustomCheckbox
                                      name={`${namePrefix}[invoicing]`}
                                      field={field}
                                      type='switch'
                                      withinForm
                                      checked={!!field.value}
                                      onChange={() => {
                                        handleSelectChange(index);
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className={sm.field_wrapper2}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Portal Access
                                  </Typography>
                                )}
                                <Tooltip title='This feature is not available'>
                                  <span>
                                    <Field name={`${namePrefix}[portal_access]`}>
                                      {({ field }) => (
                                        <CustomCheckbox
                                          name={`${namePrefix}[portal_access]`}
                                          field={field}
                                          type='switch'
                                          withinForm
                                          checked={!!field.value}
                                          onChange={() => {
                                            handleSelectChange(index);
                                          }}
                                          disabled
                                        />
                                      )}
                                    </Field>
                                  </span>
                                </Tooltip>
                              </div>
                              <div className={sm.field_wrapper2}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    Email Updates
                                  </Typography>
                                )}
                                <Field name={`${namePrefix}[email_updates]`}>
                                  {({ field }) => (
                                    <CustomCheckbox
                                      name={`${namePrefix}[email_updates]`}
                                      field={field}
                                      type='switch'
                                      withinForm
                                      checked={!!field.value}
                                      onChange={() => {
                                        handleSelectChange(index);
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className={sm.field_wrapper2}>
                                {index === 0 && (
                                  <Typography className={sm.filedTitle} variant='s2' style={{ width: el.width }}>
                                    SMS Updates
                                  </Typography>
                                )}
                                <Field name={`${namePrefix}[sms_updates]`}>
                                  {({ field }) => (
                                    <CustomCheckbox
                                      name={`${namePrefix}[sms_updates]`}
                                      field={field}
                                      type='switch'
                                      withinForm
                                      checked={!!field.value}
                                      onChange={() => {
                                        handleSelectChange(index);
                                      }}
                                    />
                                  )}
                                </Field>
                              </div>
                              <div className={sm.rowSettingsWrapper}>
                                {!isModalView || !el?.id ? (
                                  <DeleteIcon
                                    width={12}
                                    height={12}
                                    fill={palette.indigo500}
                                    style={styles.deleteIcon}
                                    onClick={() => onDelete(el, index, arrayHelpers)}
                                  />
                                ) : (
                                  <div style={{ width: 28 }} />
                                )}
                                {!changedIndexes.includes(index) ? (
                                  <TickIcon fill={palette.green500} style={{ width: 33, margin: '12px 0 0 0' }} />
                                ) : (
                                  <CustomButton
                                    type='primary'
                                    title=''
                                    styleButton={{
                                      padding: '6px 12px',
                                      margin: '6px 0 0 0',
                                    }}
                                    styleTitle={{ fontSize: 14 }}
                                    onClick={() =>
                                      onUpdate(
                                        el,
                                        index,
                                        values,
                                        errors,
                                        getFieldHelpers,
                                        arrayHelpers,
                                        validateForm,
                                        validateField,
                                        setFieldValue
                                      )
                                    }
                                    leftIcon={<TickIcon fill={palette.white} style={{ width: 9, height: 9 }} />}
                                    buttonProps={{
                                      type: 'submit',
                                    }}
                                  />
                                )}
                              </div>
                            </div>
                          );
                        })}

                        <CustomButton
                          type='secondary'
                          title='Add Another'
                          styleTitle={styles.addAnotherTitle}
                          styleButton={styles.addAnotherButton}
                          onClick={() => arrayHelpers.push({ ...defaultValue })}
                          leftIcon={<PlusIcon fill={palette.indigo500} />}
                        />
                      </div>
                    );
                  }}
                />
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <div className={sm['table-wrap']}>
          <div className='sub-table-container'>
            <div className='planner_table_wrapper'>
              <MaterialTable
                data={contactBooK?.contactBook?.data}
                columns={columns}
                style={{
                  backgroundColor: palette.white,
                  color: palette.gray700,
                  borderColor: palette.gray50,
                }}
                options={{
                  sorting: false,
                  toolbar: false,
                  paging: false,
                  draggable: false,
                  rowStyle: {
                    borderBottom: 'none',
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactBookTab;
