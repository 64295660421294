import React, { useState } from 'react';
import Modal from 'common/Modal';
import HookTrailerAutocomplete from 'componentsV2/Equipment/HookTrailerAutocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { updateEquipment } from 'Api/Equipment';
import { validationSchema } from './validationSchema';

const HookTrailer = ({ open, onClose, vehicleId, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const body = { hooked_to_id: values.hooked_to.id };
      const { data } = await updateEquipment(vehicleId, body);
      showToaster({ type: 'success', message: 'Trailer hooked successfully!' });
      onSuccess(data);
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) || 'Something went wrong!' });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: { hooked_to: null },
    onSubmit,
    validationSchema,
  });

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Hook Trailer'
      $bgColor={palette.gray0}
      $maxWidth='450px'
      $minWidth='450px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
          onClick: onClose,
        },
        {
          key: 'submit',
          type: 'primary',
          title: 'Hook Trailer',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div className='mt-3 mb-3'>
        <HookTrailerAutocomplete
          vehicleId={vehicleId}
          required
          label='Trailer'
          value={values.hooked_to}
          onChange={(e, val) => handleChange('hooked_to', val)}
          error={touchedErrors.hooked_to}
        />
      </div>
    </Modal>
  );
};

export default HookTrailer;
