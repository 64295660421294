import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  assigned_to1: object().when('replace1', {
    is: true,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  amount1: string().when('replace1', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  previous_driver1_amount: string().when('replace1', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  assigned_to2: object().when('replace2', {
    is: true,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
  amount2: string().when('replace2', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  previous_driver2_amount: string().when('replace2', {
    is: true,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  equipment: object().when('replace_vehicle', {
    is: true,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});
