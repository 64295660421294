import styled from 'styled-components';
import { palette } from 'utils/constants';

export const STable = styled.table`
  width: 100%;

  th {
    border-bottom: 1px solid ${palette.gray50};
    font-size: 12px;
    color: ${palette.gray700};
    font-weight: 500;
    padding: 8px 16px;
  }

  td {
    padding: 12px 16px;
    border: 1px solid ${palette.gray50};
    border-left: none;
    border-right: none;

    :first-child {
      border-left: none;
    }

    :last-child {
      border-right: none;
    }
  }

  tr {
    &.no-border td {
      border: none;
    }
  }
`;

export const STableRow = styled.tr`
  transition: background-color 0.2s;

  .actions-cell {
    width: 100px;
  }

  .MuiChip-root {
    max-width: 400px;

    .MuiChip-label {
      white-space: normal;
    }
  }
`;

export const SSection = styled.div`
  border-radius: 10px;
  border: 1px solid ${palette.gray50};
  background-color: ${palette.white};
  margin-bottom: 24px;

  .section-header {
    display: flex;
    justify-content: space-between;
    background-color: ${palette.white};
    border-radius: 10px 10px 0 0;
    margin-bottom: 8px;
    padding: 16px 20px;
    border-bottom: 1px solid ${palette.gray50};
  }

  .section-body {
    padding: 16px 20px;
    background-color: ${palette.white};
    border-radius: 10px;
  }
`;
