import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  CancelShipment,
  splitShipment,
  updateCarrierCurrentLocation,
  updateCurrentLocation,
  revertLastAction,
} from 'Api/Planner';
import { handleToaster } from 'store/reducers/root.reducer';
import {
  applyDryRun,
  carrierHookOrDropTrailer,
  changeDockStatus,
  checkIn,
  checkOut,
  copyShipment,
  deleteRevertShipment,
  hookOrDropTrailer,
  layover,
  swapDriverVehicle,
} from 'Api/Shipment';
import useShowToaster from 'hooks/useShowToaster';
import { getErrorMessage } from 'utils/error';
import { customerTimeToUtc } from 'utils/helpers';
import SplitShipment from 'componentsV2/Shipment/SplitShipment';
import ReplaceDriver from 'componentsV2/Shipment/ReplaceDriver';
import DropTrailer from './components/DropTrailer';
import DryRun from '../TableRowEditModalTypes/DryRun';
import Layover from '../TableRowEditModalTypes/Layover';
import Detention from '../TableRowEditModalTypes/Detention';
import ShareUpdate from '../TableRowEditModalTypes/ShareUpdate';
import CancelModal from '../TableRowEditModalTypes/CancelModal';
import RevertModal from '../TableRowEditModalTypes/RevertModal';
import CopyShipment from '../TableRowEditModalTypes/CopyShipment';
import CancelPlanner from '../TableRowEditModalTypes/CancelPlanner';
import CheckInOutModal from '../TableRowEditModalTypes/CheckInOutModal';
import ReserveShipment from '../TableRowEditModalTypes/ReserveShipment';
import RevertLastAction from '../TableRowEditModalTypes/RevertLastAction';
import UpdateDockStatus from '../TableRowEditModalTypes/UpdateDockStatus';
import UpdateCurrentLocation from '../TableRowEditModalTypes/UpdateCurrnetLocation';
import UploadShipmentDocuments from '../TableRowEditModalTypes/UploadShipmentDocumnets';

const TableShipmentRowEditChildes = ({ data, onSubmit, getShipment }) => {
  const showToaster = useShowToaster();
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(null);
  const dispatch = useDispatch();
  const [dropTrailerOpen, setDropTrailerOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  function getStopInCheckInOutModal() {
    const index = data.rowData?.shipment_stops.findIndex((el) => el?.departure_date === null);
    return data.rowData?.shipment_stops?.[index]
      ? {
          ...data.rowData?.shipment_stops?.[index],
          index,
          shipment_billing: data.rowData?.shipment_billing,
          shipment_stops: data.rowData?.shipment_stops,
        }
      : {};
  }

  function createCopyFormData(input) {
    const formData = new FormData();
    formData.append('copy_count', input.copy_count);
    formData.append('shipment_id', data.rowData.shipment_id);
    formData.append('reference_id', input.reference_id);
    input.stops.forEach((item, index) => {
      if (item.scheduled_date) {
        formData.append(`scheduled_dates[${index}][scheduled_date]`, moment(item.scheduled_date).format('YYYY-MM-DD'));
        formData.append(`scheduled_dates[${index}][from]`, moment(item.scheduled_date).format('HH:mm'));
        formData.append(
          `scheduled_dates[${index}][scheduled_date_to]`,
          moment(item.scheduled_date_to || item.scheduled_date).format('YYYY-MM-DD')
        );
        formData.append(
          `scheduled_dates[${index}][to]`,
          moment(item.scheduled_date_to || item.scheduled_date).format('HH:mm')
        );
      }
    });
    return formData;
  }

  const onAdd = async (validateForm, resetForm, submitForm, values) => {
    if (submitForm) {
      submitForm();
      return validateForm().then((errors) => {
        const isValid = Object.keys(errors).length === 0;
        if (isValid) {
          return onSubmitHandler(Number(data?.item?.id), values)
            .then((res) => {
              typeof onSubmit === 'function' && onSubmit({ lastData: data, res });
              setActiveId(null);
              resetForm();
            })
            .catch(null);
        }
        setLoading(false);
        return Promise.resolve();
      });
    }
    return onSubmitHandler(Number(data?.item?.id), values)
      .then((res) => {
        typeof onSubmit === 'function' && onSubmit({ lastData: data, res });
        setActiveId(null);
      })
      .catch(null);
  };

  function createFormData(shipments) {
    const formData = new FormData();

    formData.append('shipment_id', data.rowData.shipment_id);

    for (let i = 0; i < shipments.length; i++) {
      for (let j = 0; j < shipments[i].length; j++) {
        if ('id' in shipments[i][j]) {
          formData.append(`shipments[${i}][${j}]`, shipments[i][j].id);
        }
      }
    }

    return formData;
  }

  async function performTrailerActions(val, passStop) {
    try {
      getStopInCheckInOutModal(val);
      const hookParams = { hooked_to_id: val.equipment_id };

      if (passStop) {
        hookParams.stop_point_id = val.stop_point_id;
      }

      if (val.equipment_id && data.rowData.equipment.id) {
        await hookOrDropTrailer(data.rowData.equipment.id, hookParams);
      }

      if (data.rowData?.brokerage_dispatch?.carrier_id) {
        await performTrailerActionsForCarrier(val);
      }
    } catch (err) {
      showToaster({ type: 'error', message: getErrorMessage(err) });
      throw err;
    }
  }

  async function performTrailerActionsForCarrier(val) {
    try {
      const hookParams = {
        status: val.equipment_id ? 'hook' : 'drop',
        shipment_id: data?.rowData.shipment_id,
        stop_point_id: val.stop_point_id,
      };

      if (val.equipment_id) {
        await carrierHookOrDropTrailer(val.equipment_id, hookParams);
      }
    } catch (err) {
      showToaster({ type: 'error', message: getErrorMessage(err) });
    }
  }

  async function onSubmitHandler(id, values) {
    switch (+id) {
      case 2:
        if (!values.check_in) {
          if (values.equipment_action === '3') {
            try {
              await performTrailerActions(values);
            } catch (e) {
              return;
            }
            if (data.rowData?.equipment?.hooked_to) {
              setDropTrailerOpen(data.rowData.equipment.hooked_to.id);
            }
          }

          if (values.stop_point_type === '1' && values.equipment_action === '2') {
            try {
              await performTrailerActions(values, true);
            } catch (e) {
              return;
            }
          }

          if (values.equipment_action === '2' && (values.stop_point_type === '2' || values.stop_point_type === '3')) {
            if (values.drop_id) {
              setDropTrailerOpen(values.drop_id);
            }
          }

          const checkoutData = new FormData();
          const totalQuantity = values.cargos?.reduce((acc, cur) => acc + Number(cur.reported_quantity || 0), 0);
          const totalWeight = values.cargos?.reduce((acc, cur) => acc + Number(cur.reported_weight || 0), 0);

          checkoutData.append('shipment_id', data.rowData.shipment_id);
          checkoutData.append('stop_point_id', values.stop_point_id);
          checkoutData.append('departure_date', moment(values.departure_date).format('YYYY-MM-DD HH:mm:ss'));
          checkoutData.append(
            'utc_departure_date',
            customerTimeToUtc(moment(values.departure_date).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
          );
          values.actual_quantity && checkoutData.append('reported_pieces', totalQuantity);
          values.actual_weight && checkoutData.append('reported_weight', totalWeight);
          values.bill_lading_id && checkoutData.append('bill_of_lading_id', values.bill_lading_id);
          values.signature_name && checkoutData.append('signature_name', values.signature_name);
          values.proof_of_delivery_name && checkoutData.append('proof_of_delivery_name', values.proof_of_delivery_name);
          values.seal_id && checkoutData.append('reported_seal_id', values.seal_id);
          values.signature && checkoutData.append('signature', values.signature);
          values.bill_of_lading && checkoutData.append('bill_of_lading', values.bill_of_lading);
          values.bill_of_lading_ref && checkoutData.append('bill_of_lading_ref', values.bill_of_lading_ref);
          values.equipment_id && checkoutData.append('equipment_id', values.equipment_id);
          checkoutData.append('reported_weight_type', values.reported_weight_type);
          checkoutData.append('type', values.stop_point_type);
          (values.cargos || []).forEach((item, index) => {
            checkoutData.append(`cargos[${index}][id]`, item.id);
            if (item.reported_quantity) {
              checkoutData.append(`cargos[${index}][reported_quantity]`, item.reported_quantity);
              checkoutData.append(`cargos[${index}][reported_quantity_type]`, item.expected_quantity_type || '');
            }
            if (item.reported_weight) {
              checkoutData.append(`cargos[${index}][reported_weight]`, item.reported_weight);
              checkoutData.append(`cargos[${index}][reported_weight_type]`, item.expected_weight_type || '');
              if (item.expected_weight_unit) {
                checkoutData.append(`cargos[${index}][reported_weight_unit]`, item.expected_weight_unit);
              }
            }
          });
          if (values.should_provide_eta && values.next_stop_id) {
            checkoutData.append('next_stop_id', values.next_stop_id);
            checkoutData.append(
              'eta',
              customerTimeToUtc(moment(values.eta).format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DDTHH:mm:ss.SSSSS[Z]')
            );
            if (values.delay_reason) {
              checkoutData.append('delay_reason_id', values.delay_reason?.id);
            }
          }

          return checkOut(checkoutData)
            .then((res) => {
              showToaster({ message: 'Checked Out Successfully', type: 'success' });
              setActiveId(null);
              return res;
            })
            .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
        }
        const checkInData = new FormData();
        checkInData.append('shipment_id', data.rowData.shipment_id);
        checkInData.append('stop_point_id', values.stop_point_id);
        checkInData.append('arrival_date', moment(values.arrival_date).format('YYYY-MM-DD HH:mm:ss'));
        checkInData.append(
          'utc_arrival_date',
          customerTimeToUtc(moment(values.arrival_date).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
        );
        return checkIn(checkInData)
          .then(async (res) => {
            showToaster({ message: 'Checked In Successfully', type: 'success' });
            setActiveId(null);
            const activeStop = getStopInCheckInOutModal();
            const params = {
              dock_status_id: 1,
              shipment_id: data.rowData.shipment_id,
            };
            await changeDockStatus({ params, id: activeStop.id }).then(() => {});
            return res;
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));

      case 4:
      case 5:
        const params = {
          shipment_id: values.shipment_id ? values.shipment_id : data.rowData.shipment_id,
          billing_amount: values.amount,
          driver1_amount: values.pay_driver && Number(values.driver1_amount) ? values.driver1_amount : undefined,
          driver2_amount: values.pay_driver && Number(values.driver2_amount) ? values.driver2_amount : undefined,
        };
        return applyDryRun(params)
          .then(() => {
            showToaster({ message: 'Success!', type: 'success' });
            setActiveId(null);
            navigate(`/shipments`, { replace: true });
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
      case 6:
        setLoading(true);
        const requestBody = {
          shipment_id: data.rowData.shipment_id,
          shipment_stop: values.shipment_stop,
          amount: values.amount,
          driver_pay: values.pay_driver
            ? values.driver_pays.map((item) => {
                return { driver_id: item.driver_id, driver_amount: item.driver_number };
              })
            : undefined,
          scheduled_date: values.date_to ? moment(values.date_to).format('YYYY-MM-DD') : undefined,
          from: values.date_to ? moment(values.date_to).format('HH:mm') : undefined,
          to: values.date_to ? moment(values.date_to).format('HH:mm') : undefined,
        };

        return layover(requestBody)
          .then(() => {
            showToaster({ message: 'Layover created successfully!', type: 'success' });
            setActiveId(null);
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }))
          .finally(() => setLoading(false));

      case 8:
        setLoading(true);
        const splitParams = createFormData(values);
        return splitShipment(splitParams)
          .then(() => {
            showToaster({ message: 'Success!', type: 'success' });
            setActiveId(null);
            navigate('/planner', { state: { allValue: data.rowData.shipment_id?.split('-S')?.[0] } });
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }))
          .finally(() => setLoading(false));

      case 61:
        const activeStop = getStopInCheckInOutModal();
        const dockParams = {
          dock_status_id: values.atTheDock ? 2 : 1,
          shipment_id: data.rowData.shipment_id,
        };
        return changeDockStatus({ params: dockParams, id: activeStop.id })
          .then((res) => {
            return res;
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
      case 11:
        const copyFormData = createCopyFormData(values);
        return copyShipment(copyFormData)
          .then((res) => {
            showToaster({ message: 'Success!', type: 'success' });
            setActiveId(null);
            return res;
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
      case 12:
        // eslint-disable-next-line no-promise-executor-return
        return new Promise((resolve) => setTimeout(resolve, 1000, values));
      case 14:
        const replaceParams = {
          shipment_id: data.rowData.shipment_id,
          assigned_to1: values.replace1 && values.assigned_to1 ? values.assigned_to1 : undefined,
          assigned_to2: values.replace2 && values.assigned_to2 ? values.assigned_to2 : undefined,
          driver1_amount: values.replace1 && values.amount1 ? values.amount1 : undefined,
          driver2_amount: values.replace2 && values.amount2 ? values.amount2 : undefined,
          previous_driver1_amount:
            values.replace1 && values.previous_driver1_amount ? values.previous_driver1_amount : undefined,
          previous_driver2_amount:
            values.replace2 && values.previous_driver2_amount ? values.previous_driver2_amount : undefined,
          equipment_id: values.vehicle?.equipment_id ? values.vehicle.equipment_id : undefined,
        };
        return swapDriverVehicle(replaceParams)
          .then(() => {
            showToaster({ message: 'Success!', type: 'success' });
            setActiveId(null);
          })
          .catch((err) => showToaster({ type: 'error', message: getErrorMessage(err) }));
      case 17:
        return CancelShipment([...values])
          .then(() => {
            const message = `Shipment has been canceled`;
            dispatch(handleToaster({ visible: true, type: 'success', message }));
            setActiveId(null);
          })
          .catch(() => {});
      case 43:
        const { equipment, shipment_id, brokerage_dispatch } = data.rowData || {};
        const { carrier } = brokerage_dispatch || {};
        const body = {
          city: values.city?.name,
          state: values.state?.short_name,
          zipcode: values.zip,
          address: values.address1,
          country: values.country?.sortname,
          shipment_id,
        };
        if (carrier) {
          return updateCarrierCurrentLocation(shipment_id, body)
            .then((res) => {
              showToaster({ type: 'success', message: 'Current location was successfully updated' });
              setActiveId(null);
              return res;
            })
            .catch((err) => {
              showToaster({ type: 'error', message: getErrorMessage(err) });
            });
        }
        return updateCurrentLocation(equipment.id, body)
          .then((res) => {
            showToaster({ type: 'success', message: 'Current location was successfully updated' });
            setActiveId(null);
            return res;
          })
          .catch((err) => {
            showToaster({ type: 'error', message: getErrorMessage(err) });
          });
      case 7:
      case 97:
        return revertLastAction([data.rowData?.shipment_id]).then(() => {
          dispatch(handleToaster({ message: 'Last action has been reverted ', visible: true, type: 'success' }));
        });
      case 19:
        return deleteRevertShipment({ shipment_ids: [data.rowData[0].shipment_id] })
          .then((res) => {
            if (res?.data) {
              showToaster({
                message: 'Dispatch has been reverted and shipment is returned to planner page.',
                type: 'success',
              });
            }
          })
          .catch(() => {
            // Do nothing
          });
      default:
    }
  }

  const currentModal = useMemo(() => {
    switch (activeId) {
      case 2:
        return (
          <CheckInOutModal
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
            shipment={data.rowData}
            onRevertSuccess={() => onSubmit({ lastData: data })}
          />
        );
      case 4:
        return (
          <Detention
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={data.rowData}
            activeStop={getStopInCheckInOutModal()}
          />
        );
      case 5:
        return <DryRun isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} />;
      case 6:
        return (
          <Layover
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={data.rowData}
            activeStop={getStopInCheckInOutModal()}
            loading={loading}
          />
        );
      case 7:
        return <ShareUpdate isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} />;
      case 8:
        return (
          <SplitShipment
            open={!!activeId}
            onClose={() => {
              setActiveId(null);
              if (getShipment) {
                getShipment();
              }
            }}
            shipment={getStopInCheckInOutModal()}
          />
        );
      case 11:
        return (
          <CopyShipment
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
          />
        );
      case 12:
        return (
          <ReserveShipment
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
          />
        );
      case 13:
        return (
          <UploadShipmentDocuments open={!!activeId} onClose={() => setActiveId(null)} shipmentData={data.rowData} />
        );
      case 14:
        return (
          <ReplaceDriver
            open={!!activeId}
            onClose={() => setActiveId(null)}
            shipment={data.rowData}
            onSuccess={() => onSubmit({ lastData: data })}
          />
        );
      case 17:
        return <CancelModal isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} />;
      case 19:
        return <RevertModal isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} />;
      case 43:
        return (
          <UpdateCurrentLocation isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} />
        );
      case 61:
        return (
          <UpdateDockStatus
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
          />
        );
      case 77:
        return (
          <CancelPlanner
            isOpen={!!activeId}
            setIsOpen={setActiveId}
            onAddBtn={onAdd}
            data={getStopInCheckInOutModal()}
          />
        );
      case 97:
        return <RevertLastAction isOpen={!!activeId} setIsOpen={setActiveId} onAddBtn={onAdd} data={data.rowData} />;
      default:
    }
  }, [activeId, loading]);

  useEffect(() => {
    setActiveId(Number(data?.item?.id));
  }, [data]);

  if ((activeId === null || activeId === undefined) && !dropTrailerOpen) return null;
  return (
    <div>
      {currentModal}
      {!!dropTrailerOpen && (
        <DropTrailer
          open={dropTrailerOpen}
          onClose={() => setDropTrailerOpen(false)}
          shipment={data?.rowData}
          stop={getStopInCheckInOutModal()}
        />
      )}
    </div>
  );
};

export default TableShipmentRowEditChildes;
