import axios from '../services/axios';

export async function customerStatuses() {
  const { data } = await axios.get('customer/customer-statuses');
  return data;
}

export async function customerPayTerms() {
  const { data } = await axios.get('customer/payment-terms');
  return data;
}

export async function getCustomerPopoverSettings({ type }) {
  const { data } = await axios.get('/table-settings', {
    params: {
      type,
    },
  });
  return data;
}

export async function UpdateCustomersPopoverSettings(dataJson) {
  const { data } = await axios.put('/table-settings', dataJson);
  return data;
}

export async function getFullPrices() {
  const { data } = await axios.get('/fuel-price/fuel-prices');
  return data;
}

export async function getCommodity() {
  const { data } = await axios.get('/customer/shipment-commodity-types');
  return data;
}

export async function getCustomerPayTerms() {
  const { data } = await axios.get('/customer/payment-terms');
  return data;
}

export async function updateCustomPayTerm({ id, body }) {
  const { data } = await axios.put(`customer/payment-terms/${id}`, body);
  return data;
}

export async function deleteCustomPayTerm(id) {
  const { data } = await axios.delete(`customer/payment-terms/${id}`);
  return data;
}

export async function createCustomer(postData) {
  const formData = new FormData();
  postData.customer_id && formData.append('customer_id', postData.customer_id);
  postData.general_ledger_id && formData.append('general_ledger_id', postData.general_ledger_id);
  formData.append('company_name', postData.company_name);
  formData.append('telephone_number', postData.telephone_number);
  formData.append('address1', postData.address1);
  postData.address2 && formData.append('address2', postData.address2);
  formData.append('country_id', postData.country_id);
  formData.append('state_id', postData.state_id);
  formData.append('city_id', postData.city_id);
  formData.append('zipcode', postData.zipcode);
  postData.company_dot_mc && formData.append('company_dot_mc', postData.company_dot_mc);
  formData.append('status_id', 1);
  formData.append('account_id', postData.account_id);
  postData.group_id && formData.append('group_id', postData.group_id);
  const { data } = await axios.post('/customer/customer', formData);
  return data;
}

export async function updateCustomer(id, dataJson) {
  const { data } = await axios.put(`/customer/customer/${id}`, dataJson);
  return data;
}

export async function getCustomerContactBookType() {
  const { data } = await axios.get('/customer/contact-book-type');
  return data;
}

export function createCustomerContactBook(data) {
  const config = {
    method: 'post',
    url: `/customer/customer/customer-contact-book`,
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

export async function createCustomPayTerm(title, discount, net) {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('discount', discount);
  formData.append('net_day', net);

  const { data } = await axios.post('customer/payment-terms', formData);
  return data;
}

export async function createCustomerPayTerms(postData, id) {
  const formData = new FormData();
  formData.append('customer_id', id);
  postData.invoice_note && formData.append('invoice_note', postData.invoice_note);
  formData.append('require_signature_on_stop', postData.require_signature_on_stop ? 1 : 0);
  formData.append('require_cargo_scan_on_pickup', postData.require_cargo_scan_on_pickup ? 1 : 0);
  formData.append('require_bill_of_lading', postData.require_bill_of_lading ? 1 : 0);
  formData.append('require_confirmations', postData.require_confirmations ? 1 : 0);
  formData.append('send_invoice_to_factoring', postData.send_invoice_to_factoring ? 1 : 0);
  formData.append('require_staff_provide_reason', postData.require_staff_provide_reason ? 1 : 0);
  formData.append('require_driver_provide_reason', postData.require_driver_provide_reason ? 1 : 0);
  formData.append('is_brokerage_customer', postData.is_brokerage_customer ? 1 : 0);
  postData.detention && formData.append('detention_starts_after_hour', postData.detention_starts_after_hour);
  postData.detention && formData.append('billed_at', postData.billed_at);

  postData.detentionTrailer &&
    formData.append('detention_trailer_dropped_starts_after', postData.detention_trailer_dropped_starts_after);
  postData.detentionTrailer &&
    formData.append('detention_trailer_dropped_start_after_per', postData.detention_trailer_dropped_start_after_per);
  postData.detentionTrailer &&
    formData.append('detention_trailer_dropped_billed_at', postData.detention_trailer_dropped_billed_at);
  postData.credit_line_maximum && formData.append('credit_line_maximum', postData.credit_line_maximum);
  formData.append('invoice_pay_term', postData.invoice_pay_term);
  postData.account_id && formData.append('account_id', postData.account_id);
  postData.required_document_type_id &&
    formData.append('required_document_type_id', postData.required_document_type_id);

  const { data } = await axios.post('customer/customer/customer-payment-term', formData);
  return data;
}

export function createCustomerDelayCode(data) {
  const config = {
    method: 'post',
    url: `/customer/customer/customer-delay-code`,
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

export function createCustomerFuelMatrix(data) {
  const config = {
    method: 'post',
    url: `/customer/customer/customer-fuel-surcharge-matrix`,
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

export function createCustomerLinehaulRateMatrix(data) {
  const config = {
    method: 'post',
    url: `/customer/customer/customer-linehaul-rate-matrix`,
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

export async function getDepartments() {
  const { data } = await axios.get('/customer/departments');
  return data;
}

export async function getGroupsList() {
  const { data } = await axios.get('/group');
  return data;
}

export async function UpdateUserProfile(values) {
  const { data } = await axios.post('/user/update-profile', values);
  return data;
}

export async function changePassword(dataPasswords) {
  const { data } = await axios.put('/user/change-user-password', dataPasswords);
  return data;
}

export async function CustomerGeneral({ id }) {
  const { data } = await axios.get(`/customer/customer/${id}`);
  return data;
}

export async function getCustomerContactBook({ id }) {
  const { data } = await axios.get(`customer/customer/${id}/customer-contact-book`);
  return data;
}

export async function CustomerPayTerms({ id }) {
  const { data } = await axios.get(`customer/customer/${id}/customer-payment-term`);
  return data;
}

export async function getCustomerDelayCode({ id }) {
  const { data } = await axios.get(`customer/customer/${id}/customer-delay-code`);
  return data;
}

export async function getCustomerFuelSurchargeMatrix({ id }) {
  const { data } = await axios.get(`customer/customer/${id}/customer-fuel-surcharge-matrix`);
  return data;
}

export async function getCustomerLinehaulRateMatrix({ id }) {
  const { data } = await axios.get(`customer/customer/${id}/customer-linehaul-rate-matrix`);
  return data;
}

export async function UpdateCustomersGeneralProfile(dataJson, id) {
  const { data } = await axios.put(`/customer/customer/${id}`, dataJson);
  return data;
}

export async function UpdateCustomersPayTerm(dataJson, id) {
  const { data } = await axios.put(`/customer/customer/customer-payment-term/${id}`, dataJson);
  return data;
}

export async function updateCustomersContactBook(dataJson, id) {
  const { data } = await axios.put(`customer/customer/customer-contact-book/${id}`, dataJson);
  return data;
}

export async function deleteCustomersContactBook(id) {
  const { data } = await axios.delete(`customer/customer/customer-contact-book/${id}`);
  return data;
}

export async function updateCustomersDelayCode(dataJson, id) {
  const { data } = await axios.put(`customer/customer/customer-delay-code/${id}`, dataJson);
  return data;
}

export async function deleteCustomersDelayCode(id) {
  const { data } = await axios.delete(`customer/customer/customer-delay-code/${id}`);
  return data;
}

export async function updateCustomersFuelSurchargeMatrix(dataJson, id) {
  const { data } = await axios.put(`customer/customer/customer-fuel-surcharge-matrix/${id}`, dataJson);
  return data;
}

export async function deleteCustomersFuelSurchargeMatrix(id) {
  const { data } = await axios.delete(`customer/customer/customer-fuel-surcharge-matrix/${id}`);
  return data;
}

export async function updateCustomersLinehaulRateMatrix(dataJson, id) {
  const { data } = await axios.put(`customer/customer/customer-linehaul-rate-matrix/${id}`, dataJson);
  return data;
}

export async function deleteCustomersLinehaulRateMatrix(id) {
  const { data } = await axios.delete(`customer/customer/customer-linehaul-rate-matrix/${id}`);
  return data;
}

export async function getCustomerShipments(id, params) {
  const { data } = await axios.get(`/customer/customer/${id}/shipments-tab`, { params });
  return data;
}

export async function getCustomerLogs(params, signal) {
  const { data } = await axios.get(`/customer/change-log`, { params, signal });
  return data;
}

export async function updateCustomerStatus(dataJson, id) {
  const { data } = await axios.put(`customer/customer/${id}`, dataJson);
  return data;
}

export async function getHolidays(params) {
  const { data } = await axios.get('customer/holiday', { params });
  return data;
}

export async function getCustomerHolidays(id, params) {
  const { data } = await axios.get(`customer/customer-holiday/${id}`, { params });
  return data;
}

export async function createCustomerHoliday(postData, stopPoints, id) {
  const formData = new FormData();
  formData.append('holiday_id', postData.holiday.id);
  if (stopPoints && stopPoints.length) {
    stopPoints.forEach((item, index) => {
      formData.append(`stop_point_ids[${index}]`, item.id);
    });
  }
  const { data } = await axios.post(`customer/customer-holiday/${id}`, formData);
  return data;
}

export async function updateCustomerHoliday(id, body) {
  const { data } = await axios.put(`customer/customer-holiday/${id}`, body);
  return data;
}

export async function deleteCustomHoliday(id) {
  const { data } = await axios.delete(`customer/customer-holiday/${id}`);
  return data;
}

export async function createHoliday(postData) {
  const formData = new FormData();
  formData.append('country_id', postData.country_id);
  formData.append('holiday_name', postData.holiday_name);
  formData.append('month_of_year', postData.month_of_year);
  formData.append('day_of_year', postData.day_of_year);

  const { data } = await axios.post(`customer/holiday`, formData);
  return data;
}

export async function updateHoliday(id, body) {
  const { data } = await axios.put(`/customer/holiday/${id}`, body);
  return data;
}

export async function deleteHoliday(id) {
  const { data } = await axios.delete(`customer/holiday/${id}`);
  return data;
}

export async function validateCustomers({ body }) {
  const { data } = await axios.post(`customer/import-customer-validation`, body);
  return data;
}

export async function importCustomersCsv(body) {
  const { data } = await axios.post(`customer/import-customer`, body);
  return data;
}

export async function getCustomersList(params) {
  const { data } = await axios.get(`customer/customer`, { params });
  return data;
}

export async function getCustomersWithDetention() {
  const { data } = await axios.get(`customer/customer-with-detentions`);
  return data;
}

export async function getCustomerMoney(id, params) {
  const { data } = await axios.get(`customer/customer/${id}/money-in-last-year`, { params });
  return data;
}

export async function getCustomerCredits(id, params) {
  const { data } = await axios.get(`customer/customer/${id}/credits`, { params });
  return data;
}

export async function getCustomerSalesCommissions(id, params) {
  const { data } = await axios.get(`customer/customer/${id}/sales-commission`, { params });
  return data;
}

export async function getAllCustomers(params) {
  const { data } = await axios.get(`user/staff/compensation/sales-commissions/get-customers`, { params });
  return data;
}

export async function getEdiSettings(customerId, params) {
  const { data } = await axios.get(`/customer/customer/${customerId}/customer-edi-settings`, { params });
  return data;
}

export async function updateEdiSettings(customerId, body) {
  const { data } = await axios.post(`/customer/customer/customer-edi-settings/${customerId}`, body);
  return data;
}

export async function getCustomerRejectReasons(customerId) {
  const { data } = await axios.get(`/customer/customer/${customerId}/customer-rejection-reason`);
  return data;
}

export async function createCustomerRejectReason(customerId, body) {
  const { data } = await axios.post(`/customer/customer/customer-rejection-reason`, body);
  return data;
}

export async function updateCustomerRejectReason(reasonId, body) {
  const { data } = await axios.put(`/customer/customer/customer-rejection-reason/${reasonId}`, body);
  return data;
}

export async function deleteCustomerRejectReason(reasonId) {
  const { data } = await axios.delete(`/customer/customer/customer-rejection-reason/${reasonId}`);
  return data;
}

export async function createDelayCode(body) {
  const { data } = await axios.post(`/customer/customer/customer-delay-code`, body);
  return data;
}
