import React, { useState } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Modal, Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import { Toast } from 'react-bootstrap';
import Popover from '@mui/material/Popover';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import subRight from 'assets/icons/drivers/sub-right.png';
import { Icons } from 'assets/icons';
import ReserveEquipmentMsg from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/ReservedEquipmentMsg';
import ReserveEquipment from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/ReserveEquipment';
import AlreadyReserved from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AlreadyReserved';
import UnresevedEquipment from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/UnreservedEquipment';
import DriverInfo from 'pages/landing/Equipment/DialogueModals/DriverInfo/DriverInfo';
import RemoveDriver from 'pages/landing/Equipment/DialogueModals/DriverInfo/RemoveDriver';
import RemovedDriverMsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/RemoveDriverSuccess';
import AddDriver from 'pages/landing/Equipment/DialogueModals/AddDriver/AddDriver';
import AddDriverSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddDriverMsg';
import AddHook from 'pages/landing/Equipment/DialogueModals/AddHook/AddhookTrailer';
import AddHookedError from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddHookedError';
import AddHookedSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/HookedTrailerSuccuss';
import UnHooked from 'pages/landing/Equipment/DialogueModals/AddHook/UnhookedVehicle';
import UnhookedMsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/UnhookedTrailerMsg';
import useDateFormat from 'hooks/useDateFormat';
import styles from './VehicleContainer.module.css';
import CustomState from './MapComponents/CustomState';
import VehicleSkeleton from './MapComponents/VehicleSkeleton';
import Chip from '../../common/Chip';
import { formatNumber, motionColors, palette } from '../../utils/constants';
import { formatSeconds } from '../../utils/helpers';
import { Typography } from '../Typography';
import { getUTCTimeDifference } from '../TableShipments/helpers/constants';

const TrailerMap = ({
  vehicleData,
  setVehicleData,
  loading,
  AddStopPointData,
  stopPointsData,
  routeCoords,
  moreDataTrailer,
  page,
  loadingTab,
  stopPointCoords,
  firstCoords,
}) => {
  const { formatDate } = useDateFormat();
  const [reserveEquipment, setReserveEquipment] = useState(false);
  const [id, setId] = useState();
  const navigate = useNavigate();
  const [showA, setShowA] = useState(false);
  const [reserveMsgData, setReserveMsgData] = useState();
  const [alreadyReserveMsg, setAlreadyReserveMsg] = useState(false);
  const [alreadyReserveId] = useState();
  const [unReseveredEqId] = useState({ data: [] });
  const [unReseveredEq, setUnReserveredEq] = useState(false);
  const [, setUnReserveSuccess] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [driverPopupData] = useState({ data: [] });
  const [delDriver, setDeleteDriver] = useState(false);
  const [removeDriverData, setRemoveDriverData] = useState({ data: [] });
  const [removeDrivermsg, setRemovedDrivermsg] = useState(false);
  const [addDriver, setAddDriver] = useState(false);
  const [driverAddedmsg, setDriverAddedmsg] = useState(false);
  const [hook, setHook] = useState(false);
  const [success, setSuccess] = useState(false);
  const [Hookederr, setHookederr] = useState(false);
  const [getHookederr, setGetHookedErr] = useState();
  const [unHookedTrailer, setUnHookedTrailer] = useState(false);
  const [unHookedData, setUnhookedData] = useState({ data: [] });
  const [unHookedMsg, setUnhookedMsg] = useState(false);

  const ReserveEquipmentModalClose = () => {
    setReserveEquipment(false);
  };
  const toggleShowA = () => {
    setShowA(true);
  };
  const toggleCloseA = () => setShowA(false);
  const GetreserveMsgData = (val) => {
    setReserveMsgData(val);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const AlreadyReserveMsgModalClose = () => setAlreadyReserveMsg(false);
  const UnReservedModalClose = () => {
    setUnReserveredEq(false);
  };
  const UnReserveSuccessShow = () => {
    setUnReserveSuccess(true);
  };
  const UnReserveSuccessClose = () => {
    setUnReserveSuccess(false);
  };
  const UnHookedModalShow = (id, eq_id, length, unit, title) => {
    setUnhookedData([id, eq_id, length, unit, title]);
    setUnHookedTrailer(true);
  };
  const UnHookedModalClose = () => {
    setUnHookedTrailer(false);
  };
  const deleteDriverModal = (id, rowId, fname, lname, Equipment_id, Equipment_title) => {
    handleClose();
    setDeleteDriver(true);
    setRemoveDriverData([id, rowId, fname, lname, Equipment_id, Equipment_title]);
  };
  const deleteDriverModalClose = () => {
    setDeleteDriver(false);
  };
  const RemoveDriverModal = () => {
    setRemovedDrivermsg(true);
  };
  const addDriverModalClose = () => setAddDriver(false);

  const DriversAddedmsgModal = () => {
    setDriverAddedmsg(true);
    setTimeout(() => {
      DriversAddedmsgModalClose();
    }, 2000);
  };
  const hookModalClose = () => setHook(false);
  const hookModalShow = (id) => {
    setId(id);
    setHook(true);
  };
  const SuccessShow = (res) => {
    if (vehicleData?.data?.length && res?.id) {
      const updatedVehicleData = vehicleData.data.map((item) => (item.id === res.id ? { ...res } : item));

      setVehicleData((prevState) => ({
        ...prevState,
        data: updatedVehicleData,
      }));
    }
    setSuccess(true);
  };
  const HookedErrShow = () => {
    setHookederr(true);
  };
  const HookedErrClose = () => {
    setHookederr(false);
  };
  const GetHookedError = (err) => {
    setGetHookedErr(err);
  };
  const UnHoookedMsgShow = (res) => {
    if (vehicleData && vehicleData.data && vehicleData.data.length && res.id) {
      const updatedVehicleData = vehicleData.data.map((item) => {
        if (item.id === res.id) {
          return {
            ...res,
          };
        }
        return item;
      });
      setVehicleData((prevState) => ({
        ...prevState,
        data: updatedVehicleData,
      }));
    }
    setUnhookedMsg(true);
  };

  const UnhookedMsgClose = () => {
    setUnhookedMsg(false);
  };
  const SuccessClose = () => setSuccess(false);
  const DriversAddedmsgModalClose = () => setDriverAddedmsg(false);
  const RemoveDriverModalClose = () => setRemovedDrivermsg(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const open = Boolean(anchorEl);

  const Popup = open ? 'simple-popover' : undefined;

  const EquipmentStatusTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow:
        '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
  }));
  const EquipmentStatusContentToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow:
        '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      backgroundColor: '#fff',
      color: 'black',
      padding: '10px',
    },
  }));

  return (
    <>
      <div className='mt-1' style={{ position: 'relative' }} id='scrollableDiv1'>
        <InfiniteScroll
          className={styles.InfiniteScrollDiv}
          dataLength={vehicleData?.data?.length}
          next={moreDataTrailer}
          hasMore={page <= vehicleData?.last_page}
          scrollableTarget='scrollableDiv1'
          height='calc(100vh - 350px)'
          pullDownToRefreshThreshold={50}
          loader={
            vehicleData?.data?.length === 0 || loadingTab ? (
              ''
            ) : (
              <div>
                {Array.from({ length: 5 }).map((_, index) => (
                  <VehicleSkeleton key={_ || index} />
                ))}
              </div>
            )
          }
        >
          {loadingTab ? (
            <div>
              {Array.from({ length: 10 }).map((_, index) => (
                <VehicleSkeleton key={_ || index} />
              ))}
            </div>
          ) : vehicleData?.data?.length ? (
            vehicleData?.data?.map((data) => {
              const flag =
                data?.drivers?.length === 2
                  ? data?.drivers?.filter((ele) => ele.status_data?.driver_status === 'Not Available').length >= 2
                  : '' || data?.drivers?.filter((ele) => ele.status_data?.driver_status === 'Not Available').length > 0;

              const statusColor = data?.equipment_status?.color;
              const title = data?.equipment_status?.equipment_status;
              return (
                <div className={styles.MapCardMainDiv} key={data.id}>
                  {data?.location_distance && (
                    <span className='d-flex'>
                      <span className='location-distance me-1'>{Number(data?.location_distance || 0).toFixed()}</span>
                      <span className='location-distance'> miles away </span>
                    </span>
                  )}
                  <div
                    className={styles.MapVehicalContentCard}
                    onClick={() => {
                      navigate(`/equipment-profile/trailer/${data.id}`);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className='d-flex justify-content-between align-item-center'>
                      <div className='d-flex justify-content-center'>
                        <div className='flex-row d-flex align-items-center'>
                          <div className='d-flex mt-0 p-0'>
                            <span className={styles.MapCardContentText}>
                              {data?.equipment_id} {data?.length?.length}
                              {data?.length?.unit} {data?.equipment_type?.title}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex me-2'>
                        {data?.location_target === 'Vehicle' ? (
                          <span className='d-flex align-items-center me-2'>
                            <img src={Icons.EquipmentProfileIcon} alt='' />
                          </span>
                        ) : (
                          ''
                        )}
                        {data?.location_target === 'Equipment' ? (
                          <div className='d-flex me-2'>
                            <svg
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M9.20022 2.25556C9.26833 2.11572 9.40323 2.01964 9.55849 2.00042C9.5731 1.9998 9.58789 1.99989 9.60261 2.00051C11.8881 2.00051 13.75 3.85041 13.75 6.1212C13.7616 6.24928 13.7185 6.37624 13.6312 6.4712C13.5438 6.56606 13.4203 6.62013 13.2909 6.62013C13.1615 6.62013 13.038 6.56606 12.9507 6.4712C12.8633 6.37624 12.8202 6.24928 12.8317 6.1212C12.8317 4.34522 11.39 2.91273 9.60252 2.91273C9.44623 2.90854 9.3027 2.82574 9.22141 2.69296C9.14013 2.56018 9.13211 2.3955 9.20022 2.25556Z'
                                fill='#868FA0'
                              />
                              <path
                                d='M9.20301 4.05788C9.28543 3.916 9.4377 3.8286 9.60252 3.8286C10.8699 3.82868 11.91 4.86194 11.9099 6.12107C11.9099 6.28482 11.822 6.43611 11.6792 6.51799C11.5365 6.59986 11.3606 6.59986 11.2179 6.51799C11.075 6.43611 10.9872 6.28482 10.9872 6.12107C10.9872 5.35688 10.3717 4.74541 9.60252 4.74541C9.4377 4.74541 9.28542 4.65812 9.20301 4.51624C9.1206 4.37446 9.1206 4.19966 9.20301 4.05788Z'
                                fill='#868FA0'
                              />
                              <path
                                d='M9.83429 6.9169C9.83429 7.50062 9.358 7.97382 8.77046 7.97382C8.18292 7.97382 7.70663 7.50062 7.70663 6.9169C7.70663 6.33318 8.18292 5.85999 8.77046 5.85999C9.358 5.85999 9.83429 6.33318 9.83429 6.9169Z'
                                fill='#868FA0'
                              />
                              <path
                                d='M3.44775 6.29748C3.63558 6.11117 3.89107 6.00436 4.15588 6.00052C4.43149 5.99668 4.69648 6.10398 4.89136 6.29752L9.21744 10.5955C9.61285 10.9884 9.61269 11.6364 9.21744 12.0297C8.45321 12.7901 7.4155 13.2193 6.33293 13.2196C5.86689 13.2198 5.41829 13.1411 5 12.9963V13.2504C5 13.5945 4.76832 13.8844 4.45247 13.9728C4.39798 13.9907 4.33975 14.0004 4.27925 14.0004L4.26574 14.0003L4.25 14.0004C3.83579 14.0004 3.5 13.6646 3.5 13.2504V12.077C2.73023 11.3378 2.24961 10.303 2.25 9.16323C2.25031 8.0877 2.6823 7.05674 3.44775 6.29748Z'
                                fill='#868FA0'
                              />
                            </svg>
                          </div>
                        ) : data?.location_target === null ? (
                          <svg
                            width='16'
                            height='16'
                            viewBox='0 0 16 16'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M4.79114 3.72156C4.49577 3.42619 4.01689 3.42619 3.72152 3.72156C3.42616 4.01693 3.42616 4.49581 3.72152 4.79117L6.93037 8.00002L3.72155 11.2088C3.42619 11.5042 3.42619 11.9831 3.72155 12.2784C4.01692 12.5738 4.4958 12.5738 4.79117 12.2784L7.99998 9.06963L11.2088 12.2785C11.5042 12.5738 11.9831 12.5738 12.2784 12.2785C12.5738 11.9831 12.5738 11.5042 12.2784 11.2089L9.06959 8.00002L12.2785 4.79114C12.5738 4.49577 12.5738 4.01689 12.2785 3.72152C11.9831 3.42616 11.5042 3.42616 11.2089 3.72152L7.99998 6.9304L4.79114 3.72156Z'
                              fill='#DC4067'
                            />
                          </svg>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <div className='d-flex ms-1 me-3'>
                      {!flag ? (
                        <span className='equipment_status me-2'>
                          {
                            //   data && data?.equipment_status?.equipment_status === "Not Assigned"
                            //  ?
                            //  <EquipmentStatusContentToolTip
                            //  title="Not Assigned"
                            //  placement="bottom"
                            //  >
                            //  <img src={Icons.NotAssigned} alt=''/>
                            //  </EquipmentStatusContentToolTip>
                            //  :data?.equipment_status?.equipment_status === "Available"
                            //  ?
                            //  <EquipmentStatusContentToolTip
                            //  title="Available"
                            //  placement="bottom"
                            //  >
                            //  <img src={Icons?.Available} alt=''/>
                            //  </EquipmentStatusContentToolTip>
                            //  : data?.equipment_status?.equipment_status === "On Shipment"
                            //  ?
                            //  <EquipmentStatusContentToolTip
                            //  title="On Shipment"
                            //  placement="bottom"
                            //  >
                            //   <img src={Icons?.OnShipment} alt=''/>
                            //   </EquipmentStatusContentToolTip>
                            //  : data?.equipment_status?.equipment_status === "Out of Service"
                            //  ?
                            <EquipmentStatusContentToolTip title={title} placement='bottom'>
                              <div style={{ width: 8, height: 8, backgroundColor: `${statusColor}` }} />
                            </EquipmentStatusContentToolTip>
                            //  : <img src={Icons.NotAssigned} alt=''/>
                          }
                        </span>
                      ) : (
                        <span style={{ backgroundColor: '#F0F2F7', color: '#5A6376' }} className='equipment_status'>
                          <span>
                            <EquipmentStatusTooltip
                              title={`Driver is Not available Until ${formatDate(
                                data?.drivers?.map((v) => v.driver_not_available_until_date)[0]
                              )} ${data?.drivers?.map((v) => v.driver_not_available_until_time)[0]}. Note: ${
                                data?.drivers?.map((v) => v.note_not_available)[0]
                              }`}
                              placement='right'
                            >
                              <img src={Icons?.NotAssigned} alt='' />
                            </EquipmentStatusTooltip>
                          </span>
                        </span>
                      )}
                      {/* <span className={styles.MapCardBlueRactangle}></span> */}
                      <img className={styles.MapCardContentRightImg} src={subRight} alt='' />
                      <div className='hooked-to-column-wrapper fade-in-row'>
                        {data?.hook_to ? (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              UnHookedModalShow(
                                data?.id,
                                data?.hook_to?.id,
                                data?.hook_to?.equipment_type?.title,
                                data?.hook_to.equipment_id
                              );
                            }}
                          >
                            <span
                              className='hooked-numbers'
                              // style={{ color: use(palette.dark800, palette.gray200) }}
                            >
                              {data?.hook_to?.equipment_id}
                            </span>
                            <span
                              className='hooked-numbers'
                              // style={{ color: use(palette.dark800, palette.gray200) }}
                            >
                              {data?.hook_to?.equipment_type?.title}
                            </span>
                          </div>
                        ) : (
                          ''
                        )}
                        {!data.hook_to ? (
                          <div className='row-style'>
                            <div className='add-driver'>
                              <div
                                // className=" add-driver-wrapper "
                                onClick={(e) => {
                                  e.stopPropagation();
                                  hookModalShow(data?.id);
                                }}
                              >
                                <svg
                                  width='12'
                                  height='12'
                                  viewBox='0 0 12 12'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                                    fill='#4f5aed'
                                  />
                                </svg>

                                <span className='add-driver-content'>Hook</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    {!!data?.length && (
                      <div className='d-flex flex-column gap-1'>
                        <Typography variant='s3' style={{ color: palette.dark800 }}>
                          {data.length.length * 12} x {data.width || '-'} x {data.height || '-'} in
                        </Typography>
                        {data.cargo_maximum_capacity_weight && (
                          <Typography variant='s3' style={{ color: palette.dark800 }}>
                            Payload {formatNumber(data.cargo_maximum_capacity_weight, 0)}{' '}
                            {data.cargo_capacity_measuring_unit}
                          </Typography>
                        )}
                      </div>
                    )}
                    {data?.equipment_gps ||
                    (data?.hooked_to && data?.hooked_to?.equipment_gps) ||
                    (data?.hooked_to && data?.hooked_to?.drivers && data?.hooked_to?.drivers[0]?.driver_gps) ||
                    (data?.hooked_to && data?.hooked_to?.last_known_location) ||
                    data?.last_known_location ? (
                      <div className='d-flex'>
                        <div className='d-flex justify-content-center'>
                          <img
                            className={styles.MapCardContentMainImg}
                            style={{ marginTop: '10px' }}
                            src={Icons.Location}
                            alt=''
                          />
                          <div>
                            {data?.location_target === 'Vehicle' ? (
                              data?.hooked_to?.location_target === 'Equipment' ? (
                                <div>
                                  {data?.hooked_to?.equipment_gps?.location ? (
                                    <span
                                      className={styles['current-location']}
                                      style={{
                                        margin: 0,
                                      }}
                                    >
                                      {data?.hooked_to?.equipment_gps?.location}
                                    </span>
                                  ) : (
                                    <span
                                      className={styles['current-location']}
                                      style={{
                                        margin: 0,
                                      }}
                                    >
                                      {' '}
                                      {data?.hooked_to?.last_known_location}
                                    </span>
                                  )}
                                </div>
                              ) : data?.hooked_to?.location_target === 'Driver' ? (
                                <div>
                                  {data?.hooked_to?.drivers ? (
                                    <span
                                      className={styles['current-location']}
                                      style={{
                                        margin: 0,
                                      }}
                                    >
                                      {data?.hooked_to?.drivers.map((v) => v?.driver_gps?.location)[0]}
                                    </span>
                                  ) : (
                                    <span
                                      className={styles['current-location']}
                                      style={{
                                        margin: 0,
                                      }}
                                    >
                                      {data?.hooked_to?.last_known_location}
                                    </span>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <span
                                    className={styles['current-location']}
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    {data?.hooked_to?.last_known_location}
                                  </span>
                                </div>
                              )
                            ) : data?.location_target === 'Equipment' ? (
                              <span
                                className={styles['current-location']}
                                style={{
                                  margin: '0px',
                                }}
                              >
                                {data?.equipment_gps?.location
                                  ? data?.equipment_gps?.location
                                  : data?.last_known_location}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {data?.location_target === 'Vehicle' &&
                      data?.hooked_to &&
                      data?.hooked_to?.location_target === 'Equipment' &&
                      data?.hooked_to?.equipment_gps?.motion?.motion && (
                        <>
                          <div>
                            <Chip
                              fontWeight={500}
                              fontSize='11px'
                              bgColor={motionColors[data?.hooked_to?.equipment_gps.motion.motion]?.bgColor}
                              labelColor={motionColors[data?.hooked_to?.equipment_gps.motion.motion]?.color}
                              style={{ textTransform: 'uppercase' }}
                              label={`${data?.hooked_to?.equipment_gps?.motion?.motion} ${formatSeconds(
                                data?.hooked_to?.equipment_gps?.motion.duration
                              )}`}
                            />
                          </div>
                          <Typography variant='c3'>
                            Updated {getUTCTimeDifference(data?.hooked_to?.equipment_gps?.motion?.updated_at)} ago
                          </Typography>
                        </>
                      )}

                    {data?.location_target === 'Vehicle' &&
                      data?.hooked_to &&
                      data?.hooked_to?.location_target === 'Driver' &&
                      data?.hooked_to?.drivers?.length && (
                        <>
                          <div>
                            <Chip
                              fontWeight={500}
                              fontSize='11px'
                              bgColor={motionColors[data?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.motion]?.bgColor}
                              labelColor={
                                motionColors[data?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.motion]?.color
                              }
                              style={{ textTransform: 'uppercase' }}
                              label={`${data?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.motion} ${formatSeconds(
                                data?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.duration
                              )}`}
                            />
                          </div>
                          <Typography variant='c3'>
                            Updated{' '}
                            {getUTCTimeDifference(data?.hooked_to?.drivers?.[0]?.driver_gps?.motion?.updated_at)} ago
                          </Typography>
                        </>
                      )}

                    {data?.location_target === 'Equipment' && data?.equipment_gps?.motion?.motion && (
                      <>
                        <div>
                          <Chip
                            fontWeight={500}
                            fontSize='11px'
                            bgColor={motionColors[data?.equipment_gps.motion.motion].bgColor}
                            labelColor={motionColors[data?.equipment_gps.motion.motion].color}
                            style={{ textTransform: 'uppercase' }}
                            label={`${data?.equipment_gps?.motion?.motion} ${formatSeconds(
                              data?.equipment_gps?.motion.duration
                            )}`}
                          />
                        </div>
                        <Typography variant='c3'>
                          Updated {getUTCTimeDifference(data?.equipment_gps?.motion?.updated_at)} ago
                        </Typography>
                      </>
                    )}
                    {data?.location_target === 'Driver' && data?.drivers?.length && (
                      <>
                        <div>
                          <Chip
                            fontWeight={500}
                            fontSize='11px'
                            bgColor={motionColors[data?.drivers?.[0]?.driver_gps?.motion?.motion].bgColor}
                            labelColor={motionColors[data?.drivers?.[0]?.driver_gps?.motion?.motion].color}
                            style={{ textTransform: 'uppercase' }}
                            label={`${data?.drivers?.[0]?.driver_gps?.motion?.motion} ${formatSeconds(
                              data?.drivers?.[0]?.driver_gps?.motion?.duration
                            )}`}
                          />
                        </div>
                        <Typography variant='c3'>
                          Updated {getUTCTimeDifference(data?.drivers?.[0]?.driver_gps?.motion?.updated_at)} ago
                        </Typography>
                      </>
                    )}
                  </div>
                  <span className={styles.MapCardBorderLine} />
                </div>
              );
            })
          ) : (
            <div>No records to display.</div>
          )}
        </InfiniteScroll>
        <Modal
          show={reserveEquipment}
          open={reserveEquipment}
          onClose={ReserveEquipmentModalClose}
          style={{ outline: 0 }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Fade in={reserveEquipment}>
            <Box sx={style}>
              <ReserveEquipment
                id={id}
                ReserveEquipmentModalClose={ReserveEquipmentModalClose}
                toggleShowA={toggleShowA}
                toggleCloseA={toggleCloseA}
                GetreserveMsgData={GetreserveMsgData}
              />
            </Box>
          </Fade>
        </Modal>
        <Toast show={showA} onClose={toggleCloseA} className='reserve-equipment-toast'>
          <ReserveEquipmentMsg
            handleClose={toggleCloseA}
            reserveMsgData={reserveMsgData}
            userFname={user.first_name}
            userLname={user.last_name}
          />
        </Toast>
        <Toast
          show={alreadyReserveMsg}
          onClose={AlreadyReserveMsgModalClose}
          className='already-reserve-equipment-toast'
        >
          <div>
            <AlreadyReserved
              alreadyReserveid={alreadyReserveId}
              AlreadyReserveMsgModalClose={AlreadyReserveMsgModalClose}
            />
          </div>
        </Toast>
        <Modal
          open={unReseveredEq}
          onClose={UnReservedModalClose}
          style={{ outline: 0 }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Fade in={unReseveredEq}>
            <Box sx={style}>
              <UnresevedEquipment
                handleClose={UnReservedModalClose}
                unReseverdEqId={unReseveredEqId}
                alreadyReserveid={alreadyReserveId}
                UnReserveSuccessShow={(id) => UnReserveSuccessShow(id)}
                UnReserveSuccessClose={UnReserveSuccessClose}
              />
            </Box>
          </Fade>
        </Modal>
        <Popover
          id={Popup}
          anchorPosition={{ top: 200, left: 400 }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DriverInfo
            driverData={driverPopupData}
            PopupHandleClose={handleClose}
            deleteDriverModal={deleteDriverModal}
          />
        </Popover>
        <Modal
          open={delDriver}
          onClose={deleteDriverModalClose}
          style={{ outline: 0 }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Fade in={delDriver}>
            <Box sx={style}>
              <RemoveDriver
                RemoveDriverData={removeDriverData}
                deleteDriverModalClose={deleteDriverModalClose}
                RemoveDriverModal={(id) => RemoveDriverModal(id)}
                RemoveDriverModalClose={RemoveDriverModalClose}
              />
            </Box>
          </Fade>
        </Modal>
        <Toast show={removeDrivermsg} onClose={RemoveDriverModalClose} className='equipment-success-msg'>
          <div>
            <RemovedDriverMsg RemoveDriverModalClose={RemoveDriverModalClose} />
          </div>
        </Toast>
        <Modal
          open={addDriver}
          onClose={addDriverModalClose}
          style={{ outline: 0 }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Fade in={addDriver}>
            <Box sx={style}>
              <AddDriver
                id={id}
                handleClose={addDriverModalClose}
                DriversAddedmsgModal={(id) => DriversAddedmsgModal(id)}
              />
            </Box>
          </Fade>
        </Modal>
        <Toast show={driverAddedmsg} onClose={DriversAddedmsgModalClose} className='equipment-success-msg'>
          <div>
            <AddDriverSuccess DriversAddedmsgModalClose={DriversAddedmsgModalClose} />
          </div>
        </Toast>
        <Modal
          open={hook}
          onClose={hookModalClose}
          style={{ outline: 0 }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Fade in={hook}>
            <Box sx={style}>
              <AddHook
                id={id}
                handleClose={hookModalClose}
                SuccessShow={SuccessShow}
                SuccessClose={SuccessClose}
                HookedErrClose={HookedErrClose}
                HookedErrShow={HookedErrShow}
                GetHookedError={GetHookedError}
              />
            </Box>
          </Fade>
        </Modal>
        <Toast show={Hookederr} onClose={HookedErrClose} className='equipment-err-msg'>
          {/* <div className="errText">{getHookederr}</div> */}.
          <AddHookedError getHookederr={getHookederr} handleClose={HookedErrClose} />
        </Toast>
        <Toast show={success} onClose={SuccessClose} className='equipment-success-msg'>
          <AddHookedSuccess handleClose={SuccessClose} />
        </Toast>
        <Modal
          open={unHookedTrailer}
          onClose={UnHookedModalClose}
          style={{ outline: 0 }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Fade in={unHookedTrailer}>
            <Box sx={style}>
              <UnHooked
                handleClose={UnHookedModalClose}
                unHookedData={unHookedData}
                UnhookedMsgClose={UnhookedMsgClose}
                UnHoookedMsgShow={(id) => UnHoookedMsgShow(id)}
                HookedErrClose={HookedErrClose}
                HookedErrShow={HookedErrShow}
                GetHookedError={GetHookedError}
              />
            </Box>
          </Fade>
        </Modal>

        <Toast show={unHookedMsg} onClose={UnhookedMsgClose} className='equipment-success-msg'>
          <div>
            <UnhookedMsg
              handleClose={UnhookedMsgClose}
              UnhookedMsgClose={UnhookedMsgClose}
              UnHoookedMsgShow={UnHoookedMsgShow}
            />
          </div>
        </Toast>
      </div>
      {loading ? (
        ''
      ) : AddStopPointData?.equipment_id || AddStopPointData?.radius ? (
        <CustomState
          stopPointsData={stopPointsData}
          routeCoords={routeCoords}
          AddStopPointData={AddStopPointData}
          stopPointCoords={stopPointCoords}
          firstCoords={firstCoords}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default TrailerMap;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  // backgroundColor: use(palette.white, palette.dark800),
  outline: 0,
};
