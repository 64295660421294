import axios from 'services/axios';

export async function saveFinishLater(id, body) {
  const { data } = await axios.post(`owner-operator/draft-step-number/${id}`, body);
  return data;
}

export async function getOperatorChangeLog(params, signal) {
  const { data } = await axios.get('/operator/change-log', { params, signal });
  return data;
}
