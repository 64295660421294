import { number, object, string } from 'yup';

export const validationSchema = object().shape({
  reason: object()
    .shape({ id: number().required('Required') })
    .required('Required')
    .nullable(),
  edi_api_code: string().trim().required('Required').nullable(),
  note_text: string().when('create_note', {
    is: (create_note) => !!create_note,
    then: string().trim().required('Required').nullable(),
    otherwise: string().nullable(),
  }),
  contact: object().when('create_note', {
    is: (create_note) => !!create_note,
    then: object()
      .shape({ id: number().required('Required') })
      .required('Required')
      .nullable(),
    otherwise: object().nullable(),
  }),
});
