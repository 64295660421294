import axios from '../services/axios';

export async function getAllStopPoints() {
  const { data } = await axios.get(`customer/stop-point`);
  return data;
}

export async function getStopPoints({ page, query, page_size, status_id, stop_point_id, ...rest }) {
  const params = {
    page,
    page_size,
    ...rest,
  };
  if (query) params.query = query;
  if (Array.isArray(status_id) && status_id?.length !== 0 && status_id[0] !== 0) params.status_id = status_id;
  const { data } = await axios.get('customer/stop-point', {
    params,
  });
  return data;
}

export async function getStopPointTableSettings({ type }) {
  const { data } = await axios.get('/table-settings', {
    params: {
      type,
    },
  });
  return data;
}

export async function GetStopPointsItem(stop_point_id) {
  const { data } = await axios.get(`customer/stop-point/${stop_point_id}`);
  return data;
}

export async function UpdateStopPoint(id, dataJson) {
  const { data } = await axios.put(`/customer/stop-point/${id}`, dataJson);
  return data;
}

export async function getStopPointsContactBook(stop_point_id) {
  const { data } = await axios.get(`customer/${stop_point_id}/stop-point-contact-book/`);
  return data;
}

export async function createStopPointContactBook(data) {
  const config = {
    method: 'post',
    url: `/customer/stop-point-contact-book`,
    data,
  };

  return axios(config).then((response) => {
    return response;
  });
}

export async function DeleteStopPointContactBook(contact_book_id) {
  const { data } = await axios.delete(`customer/stop-point-contact-book/${contact_book_id}`);
  return data;
}

export async function updateStopPointContactBook(dataJson, id) {
  const { data } = await axios.put(`/customer/stop-point-contact-book/${id}`, dataJson);
  return data;
}

export async function GetStopPointsWorkingHours(stop_point_id) {
  const { data } = await axios.get(`customer/${stop_point_id}/stop-point-working-hours`);
  return data;
}

export async function UpdateStopPointWorkingHours(dataJson, id) {
  const { data } = await axios.put(`/customer/stop-point-working-hours/${id}`, dataJson);
  return data;
}

export async function GetGeofence(stop_point_id) {
  const { data } = await axios.get(`customer/stop-points/geofence`, {
    params: {
      stop_point_id,
    },
  });
  return data;
}

export async function UpdateGeofence(dataJson) {
  const { data } = await axios.put(`/customer/stop-points/geofence`, dataJson);
  return data;
}

export async function CreateGeofence(geoJson) {
  const { data } = await axios.post('customer/stop-points/geofence', geoJson);
  return data;
}

export async function DeleteGeofence(stop_point_id) {
  const { data } = await axios.delete(`customer/stop-points/geofence?${stop_point_id}`, {
    params: {
      stop_point_id,
    },
  });
  return data;
}

export async function GetEquipmentAtStop(stop_point_id, params) {
  const { data } = await axios.get(`equipment/equipment-at-stop-point/stop-point/${stop_point_id}`, {
    params: {
      ...params,
      stop_point_id,
    },
  });
  return data;
}

export async function GetEquipmentVehicle(params, cancelToken, signal) {
  const { data } = await axios.get(`equipment/equipment-at-stop-point/vehicles`, {
    params,
    cancelToken,
    signal,
  });
  return data;
}

export async function getVehicleAndTrailersForGeofence(params) {
  const { data } = await axios.get(`equipment/equipment-at-stop-point/all`, { params });
  return data;
}

export async function GetEquipmentTrailer(params, cancelToken, signal) {
  const { data } = await axios.get(`equipment/equipment-at-stop-point/trailers`, {
    params,
    cancelToken,
    signal,
  });
  return data;
}

export async function UpdateEquipmentAtStopCargo(id, dataJson) {
  const { data } = await axios.put(`/equipment/equipment-at-stop-point/${id}`, dataJson);
  return data;
}

export async function UpdateEquipmentAtStopBillDetention(id, dataJson) {
  const { data } = await axios.put(`/customer/shipments/detention/${id}`, dataJson);
  return data;
}

export async function GetEquipmentCargoOnboardTypes() {
  const { data } = await axios.get(`equipment/cargo-onboard-types`);
  return data;
}

export async function GetShipmentList(params) {
  const { data } = await axios.get(`customer/shipments-list`, {
    params,
  });
  return data;
}

export async function GetStopPoints() {
  const { data } = await axios.get(`customer/stop-point`);
  return data;
}

export async function GetEquipmentStops() {
  const { data } = await axios.get(`equipment/stops`);
  return data;
}

export async function CreateEquipmentAtStop(jsonData) {
  const { data } = await axios.post('equipment/stop', jsonData);
  return data;
}

export async function DeleteEquipmentStops(id) {
  const { data } = await axios.delete(`equipment/stops/${id}`);
  return data;
}

export async function getCustomerPaymentTerms({ customer_id }) {
  const { data } = await axios.get(`customer/customer/${customer_id}/customer-payment-term`);
  return data;
}

export async function addInventoryTab(jsonData) {
  const { data } = await axios.post('inventory/tabs', jsonData);
  return data;
}

export async function getInventoryTab(params) {
  const { data } = await axios.get('inventory/tabs', { params });
  return data;
}

export async function deleteTab(id) {
  const { data } = await axios.delete(`inventory/tabs/${id}`);
  return data;
}

export async function updateTab({ id, dataJson }) {
  const { data } = await axios.put(`inventory/tabs/${id}`, dataJson);
  return data;
}

export async function getStopPointsWithDetention() {
  const { data } = await axios.get(`customer/customer-stop-points`);
  return data;
}

export async function getTrailerStatusesWithCounts(params) {
  const { data } = await axios.get('equipment/equipment-at-stop-point/trailers/status', { params });
  return data;
}

export async function resetGeofence(stopId) {
  const { data } = await axios.post(`/customer/stop-points/${stopId}/reset-geofence`);
  return data;
}

export async function getStopPointLogs(params, signal) {
  const { data } = await axios.get('/stop-point/change-log', { params, signal });
  return data;
}
