import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Tab from '@mui/material/Tab';
import { ReactComponent as RevertIcon } from 'assets/icons/reload.svg';
import { ReactComponent as TwoWaysIcon } from 'assets/icons/twoWays.svg';
import { ReactComponent as CheckIcon } from 'assets/icons/check-rounded.svg';
import Search from 'common/Search';
import ViewPdf from 'components/ViewPdf';
import Autocomplete from 'common/Autocomplete';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import ThreeDotActions from 'common/ThreeDotActions';
import CustomRadioButton from 'components/CustomRadioButton';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import { useHasBrokerage } from 'hooks/useHasBrokerage';
import { getBrokerageSetting } from 'Api/CompanySettings';
import { getOpenInvoicesTableData, getOpenStandaloneInvoices } from 'Api/AccountingReceivables';
import MarkPaid from '../shared/MarkPaid';
import AddNotes from '../shared/AddNotes';
import RevertInvoice from '../shared/RevertInvoice';
import UploadDocument from '../shared/UploadDocument';
import { onDownload } from '../shared/SendInvoice/SendInvoice.data';
import StandaloneInvoiceNotes from '../shared/StandaloneInvoiceNotes';
import BulkActions from './components/BulkActions';
import OtherRevenueTable from './components/OtherRevenueTable';
import ShipmentInvoiceTable from './components/ShipmentInvoiceTable';
import { filterOptions, initialFilters, otherRevenueFilterOptions } from '../TableSection.data';
import { useColumns, useOtherColumns } from './OpenInvoices.data';
import { SFiltersWrapper, SSearchWrapper, STabs } from '../../Receivables.styles';

const OpenInvoices = ({ getCounts, isPastDue, stopPoints, customers, groups, tableRefreshIndex }) => {
  const hasBrokerage = useHasBrokerage();
  const controller = useRef(new AbortController());
  const controllerOther = useRef(new AbortController());
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('shipment');
  const [data, setData] = useState({ data: [] });
  const [otherData, setOtherData] = useState({ data: [] });
  const [searchParams] = useSearchParams();
  const processedInvoiceBatchId = searchParams.get('batchId');
  const processedInvoiceId = searchParams.get('invoiceId');
  const [modalData, setModalData] = useState({});
  const [pdfUrl, setPdfUrl] = useState('');
  const [openUploadDocument, setOpenUploadDocument] = useState(false);
  const [openMarkPaid, setOpenMarkPaid] = useState(false);
  const [openRevertInvoice, setOpenRevertInvoice] = useState(false);
  const [openAddNotes, setOpenAddNotes] = useState(false);
  const [openStandaloneNotes, setOpenStandaloneNotes] = useState(false);
  const [openViewPDF, setOpenViewPDF] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState(null);
  const [search, setSearch] = useState(processedInvoiceBatchId || processedInvoiceId || '');
  const [otherSearch, setOtherSearch] = useState('');
  const [sortByDate, setSortByDate] = useState(null);
  const [sort, setSort] = useState({ field: 'customer_billed_date', sortBy: 'desc' });
  const [otherSort, setOtherSort] = useState({ field: 'age', sortBy: 'desc' });
  const [loading, setLoading] = useState(false);
  const [otherLoading, setOtherLoading] = useState(false);
  const [isBrokered, setIsBrokered] = useState(false);
  const [filterType, setFilterType] = useState(
    processedInvoiceBatchId
      ? filterOptions.find((option) => option.value === 'batch_id')
      : processedInvoiceId
      ? filterOptions.find((option) => option.value === 'invoice_id')
      : filterOptions[0]
  );
  const [otherFilterType, setOtherFilterType] = useState(otherRevenueFilterOptions[0]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [otherSelectedFilters, setOtherSelectedFilters] = useState(initialFilters);
  const [brokerageSettings, setBrokerageSettings] = useState(null);
  const debouncedSearch = useDebounce(search, 500);
  const otherDebouncedSearch = useDebounce(otherSearch, 500);
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateRange, setDateRange] = useState({
    start: null,
    end: null,
  });

  const getOpenShipmentInvoices = async () => {
    if (controller?.current) {
      controller.current.abort();
      controller.current = new AbortController();
    }

    try {
      setLoading(true);
      const { page, itemsPerPage, customers, groups, stopPoints } = selectedFilters || {};
      const filter = `filters[${filterType.value}]`;
      const condFilter = filterType.id !== 10 && filterType.id !== 11 && filterType.id !== 12;
      const sortField = `sort[][${sort.field}]`;
      const params = {
        page,
        itemsPerPage,
        ...(condFilter && !!debouncedSearch && { [filter]: debouncedSearch }),
        'sort[][end_date_time]': sortByDate || undefined,
        'filters[from]': dateRange.start ? moment(dateRange.start.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[to]': dateRange.end ? moment(dateRange.end.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[tab]': isPastDue ? 'past_due_invoice' : undefined,
        'filters[type]': 'shipment',
        'filters[is_brokered]': isBrokered ? 1 : undefined,
        'filters[customer_id]': customers?.length ? customers.map((i) => i.id) : undefined,
        'filters[group_id]': groups?.length ? groups.map((i) => i.id) : undefined,
        'filters[stop_point_id]': stopPoints?.length ? stopPoints.map((i) => i.id) : undefined,
        [sortField]: sort.sortBy,
      };

      const response = await getOpenInvoicesTableData(params, controller?.current?.signal);
      setData(response);
      getCounts();
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const getOpenOtherInvoices = async () => {
    if (controllerOther?.current) {
      controllerOther.current.abort();
      controllerOther.current = new AbortController();
    }

    try {
      setOtherLoading(true);
      const { page, itemsPerPage, other_customers, groups, stopPoints } = otherSelectedFilters || {};
      const filter = `filters[${otherFilterType.value}]`;
      const condFilter = filterType.id !== 2;
      const sortField = `sort[][${otherSort.field}]`;
      const params = {
        page,
        itemsPerPage,
        ...(condFilter && !!otherDebouncedSearch && { [filter]: otherDebouncedSearch }),
        'filters[from]': dateRange.start ? moment(dateRange.start.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[to]': dateRange.end ? moment(dateRange.end.toUTCString()).format('DD-MM-YYYY') : undefined,
        'filters[tab]': isPastDue ? 'past_due_invoice' : undefined,
        'filters[is_brokered]': isBrokered ? 1 : undefined,
        'filters[customer_id]': other_customers?.length ? other_customers.map((i) => i.id) : undefined,
        'filters[group_id]': groups?.length ? groups.map((i) => i.id) : undefined,
        'filters[stop_point_id]': stopPoints?.length ? stopPoints.map((i) => i.id) : undefined,
        [sortField]: otherSort.sortBy,
      };

      const response = await getOpenStandaloneInvoices(params, controllerOther?.current?.signal);
      setOtherData(response);
      getCounts();
    } catch (e) {
      // Do nothing
    } finally {
      setOtherLoading(false);
    }
  };

  const onMarkPaid = (row) => {
    setSelectedRows([row]);
    setOpenMarkPaid(true);
  };

  const onRevertInvoice = (row) => {
    setSelectedRows([row]);
    setOpenRevertInvoice(true);
  };

  const onUploadDocuments = (row) => {
    setModalData(row);
    setOpenUploadDocument(true);
  };

  const onViewPDF = (row) => {
    setPdfUrl(row.pdf_file_link);
    setOpenViewPDF(true);
  };

  const onViewNotes = (row) => {
    setModalData(row);
    if (activeTab === 'shipment') {
      setOpenAddNotes(true);
    } else {
      setOpenStandaloneNotes(true);
    }
  };

  const onMarkPaidSuccess = () => {
    if (activeTab === 'shipment') {
      getOpenShipmentInvoices();
    } else {
      getOpenOtherInvoices();
    }
    setSelectedRows([]);
  };

  const onDownloadClick = () => {
    selectedRows.forEach((row) => {
      onDownload(row.pdf_file_link, `Invoice-${row.invoice_id}`);
    });
  };

  const handleSelectAll = () => {
    if (activeTab === 'shipment') {
      setSelectedRows(selectedRows.length !== data?.data?.length ? data?.data : []);
    } else {
      setSelectedRows(selectedRows.length !== otherData?.data?.length ? otherData?.data : []);
    }
  };

  const handleSelectRow = (checked, row) => {
    if (checked) {
      setSelectedRows((prevState) => [...prevState, row]);
    } else {
      const updatedSelectedRows = selectedRows.filter((item) => item.id !== row.id);
      setSelectedRows(updatedSelectedRows);
    }
  };

  const sortingQuery = (field) => {
    const direction = sort?.sortBy === 'asc' ? 'desc' : 'asc';
    const otherDirection = otherSort?.sortBy === 'asc' ? 'desc' : 'asc';
    if (activeTab === 'shipment') {
      setSort({ field, sortBy: direction });
    } else {
      setOtherSort({ field, sortBy: otherDirection });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const getBrokerSettings = async () => {
    try {
      const { data } = await getBrokerageSetting();
      setBrokerageSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    if (processedInvoiceBatchId || processedInvoiceId) {
      navigate('/accounting/receivables?tab=open_invoices', { replace: true });
    }
    getOpenShipmentInvoices();
  }, [dateRange, selectedFilters, debouncedSearch, sortByDate, isPastDue, sort, tableRefreshIndex, isBrokered]);

  useEffect(() => {
    getOpenOtherInvoices();
  }, [otherSelectedFilters, otherDebouncedSearch, isPastDue, otherSort, tableRefreshIndex, isBrokered]);

  const columns = useColumns({
    onMarkPaid,
    onRevertInvoice,
    onUploadDocuments,
    onViewNotes,
    onViewPDF,
    handleSelectRow,
    handleSelectAll,
    selectedRows,
    data: data?.data,
    sort,
    sortingQuery,
  });

  const otherColumns = useOtherColumns({
    onViewPDF,
    onRevertInvoice,
    onMarkPaid,
    handleSelectRow,
    handleSelectAll,
    selectedRows,
    data: otherData?.data,
    sort: otherSort,
    sortingQuery,
    onViewNotes,
  });

  useEffect(() => {
    setActiveTab('shipment');
    setSelectedRows([]);
  }, [isPastDue]);

  useEffect(() => {
    setSelectedRows([]);
  }, [activeTab]);

  useEffect(() => {
    if (hasBrokerage) {
      getBrokerSettings();
    }
  }, []);

  return (
    <>
      {selectedRows.length ? (
        activeTab === 'shipment' ? (
          <BulkActions
            data={selectedRows}
            setOpenMarkPaid={setOpenMarkPaid}
            setOpenRevertInvoice={setOpenRevertInvoice}
            onDownloadClick={onDownloadClick}
          />
        ) : (
          <SFiltersWrapper>
            <CustomButton
              type='primary'
              title='Mark Paid'
              onClick={() => setOpenMarkPaid(true)}
              leftIcon={<CheckIcon fill={palette.white} style={{ marginRight: 10 }} />}
              styleButton={{ padding: '5px 12px', marginTop: 0 }}
            />
            <CustomButton
              type='secondary'
              title='Revert'
              leftIcon={<RevertIcon fill={palette.gray700} style={{ marginRight: 10, transform: 'rotate(90deg)' }} />}
              styleButton={{ padding: '5px 12px', marginTop: 0 }}
              onClick={() => setOpenRevertInvoice(true)}
            />
          </SFiltersWrapper>
        )
      ) : (
        <SFiltersWrapper>
          {activeTab === 'shipment' && (
            <>
              <ThreeDotActions
                anchorEl={filterAnchorEl}
                setAnchorEl={setFilterAnchorEl}
                actionButton={
                  <CustomButton
                    type='secondary'
                    title=''
                    leftIcon={
                      <TwoWaysIcon
                        width={16}
                        height={16}
                        fill={palette.gray700}
                        style={{ transform: 'rotate(90deg)' }}
                      />
                    }
                    styleButton={{ padding: '8px 12px', margin: 0 }}
                    onClick={() => null}
                  />
                }
                popoverStyles={{ margin: '10px 0 0 70px' }}
              >
                <div style={{ padding: '0 8px' }}>
                  <CustomRadioButton
                    field={{
                      name: 'sort_by_date',
                      value: 'desc',
                      onChange: () => setSortByDate('desc'),
                      checked: sortByDate === 'desc',
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Recent
                    </Typography>
                  </CustomRadioButton>
                  <CustomRadioButton
                    field={{
                      name: 'sort_by_date',
                      value: 'asc',
                      onChange: () => setSortByDate('asc'),
                      checked: sortByDate === 'asc',
                    }}
                  >
                    <Typography variant='s2' style={{ marginLeft: 8, color: palette.gray700 }}>
                      Oldest
                    </Typography>
                  </CustomRadioButton>
                </div>
              </ThreeDotActions>
              <DateRangePicker
                top='-168px'
                dateRange={dateRange}
                initialRangeName='All Time'
                setDateRange={setDateRange}
                type='allTime'
              />
            </>
          )}
          {activeTab === 'shipment' ? (
            <SSearchWrapper>
              <div>
                <Autocomplete
                  width='150px'
                  labelKey='label'
                  options={filterOptions}
                  value={filterType}
                  onChange={(e, val) => {
                    setSearch('');
                    setFilterType(val);
                    setSelectedFilters(initialFilters);
                  }}
                />
              </div>
              {filterType?.value === 'customer' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='company_name'
                  disableClearable={false}
                  options={customers}
                  value={selectedFilters.customers}
                  placeholder={selectedFilters.customers?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, customers: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : filterType?.value === 'stop_point' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='location_name'
                  disableClearable={false}
                  options={stopPoints}
                  value={selectedFilters.stopPoints}
                  placeholder={selectedFilters.stopPoints?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, stopPoints: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : filterType?.value === 'group' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='group_name'
                  disableClearable={false}
                  options={groups}
                  value={selectedFilters.groups}
                  placeholder={selectedFilters.groups?.length ? '' : 'Select..'}
                  onChange={(e, val) => setSelectedFilters((prevState) => ({ ...prevState, groups: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : (
                <Search search={search} onChange={setSearch} className='search-input' />
              )}
            </SSearchWrapper>
          ) : (
            <SSearchWrapper>
              <div>
                <Autocomplete
                  width='150px'
                  labelKey='label'
                  options={otherRevenueFilterOptions}
                  value={otherFilterType}
                  onChange={(e, val) => {
                    setOtherSearch('');
                    setOtherFilterType(val);
                    setOtherSelectedFilters(initialFilters);
                  }}
                />
              </div>
              {otherFilterType?.value === 'customer' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='company_name'
                  disableClearable={false}
                  options={customers}
                  value={otherSelectedFilters.other_customers}
                  placeholder={otherSelectedFilters.other_customers?.length ? '' : 'Select..'}
                  onChange={(e, val) =>
                    setOtherSelectedFilters((prevState) => ({ ...prevState, other_customers: val }))
                  }
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : otherFilterType?.value === 'group' ? (
                <Autocomplete
                  multiple
                  width='380px'
                  labelKey='group_name'
                  disableClearable={false}
                  options={groups}
                  value={otherSelectedFilters.groups}
                  placeholder={otherSelectedFilters.groups?.length ? '' : 'Select..'}
                  onChange={(e, val) => setOtherSelectedFilters((prevState) => ({ ...prevState, groups: val }))}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  className='filter-dropdown'
                  limitTags={2}
                />
              ) : (
                <Search search={otherSearch} onChange={setOtherSearch} className='search-input' />
              )}
            </SSearchWrapper>
          )}
          {!!brokerageSettings?.company_name && (
            <div className='d-flex align-items-center gap-2'>
              <CustomCheckbox
                type='switch'
                name='is_brokered'
                checked={isBrokered}
                onChange={(checked) => setIsBrokered(checked)}
                smail={false}
              />
              <Typography variant='s2' style={{ color: palette.gray700 }}>
                Sent as {`${brokerageSettings.company_name}`}
              </Typography>
            </div>
          )}
        </SFiltersWrapper>
      )}
      <div>
        <STabs value={activeTab} onChange={handleTabChange}>
          <Tab
            id='tab-1'
            value='shipment'
            label='Shipment'
            iconPosition='end'
            icon={<span>{data?.total}</span>}
            disableRipple
          />
          <Tab
            id='tab-2'
            value='other'
            label='Other'
            iconPosition='end'
            icon={<span>{otherData?.total}</span>}
            disableRipple
          />
        </STabs>
        {activeTab === 'shipment' ? (
          <ShipmentInvoiceTable
            loading={loading}
            data={data}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            setSelectedRows={setSelectedRows}
            setOpenMarkPaid={setOpenMarkPaid}
            columns={columns}
          />
        ) : (
          <OtherRevenueTable
            data={otherData}
            loading={otherLoading}
            columns={otherColumns}
            setSelectedRows={setSelectedRows}
            setOpenMarkPaid={setOpenMarkPaid}
            selectedFilters={otherSelectedFilters}
            setSelectedFilters={setOtherSelectedFilters}
          />
        )}
      </div>
      {openUploadDocument && (
        <UploadDocument
          open={openUploadDocument}
          onClose={() => setOpenUploadDocument(false)}
          shipmentData={modalData.billing}
        />
      )}
      {openAddNotes && (
        <AddNotes
          open={openAddNotes}
          onClose={() => setOpenAddNotes(false)}
          data={modalData.billing}
          onSuccess={getOpenShipmentInvoices}
        />
      )}
      {openStandaloneNotes && (
        <StandaloneInvoiceNotes
          open={openStandaloneNotes}
          onClose={() => setOpenStandaloneNotes(false)}
          invoice={modalData}
          onSuccess={getOpenOtherInvoices}
        />
      )}
      {openMarkPaid && (
        <MarkPaid
          open={openMarkPaid}
          onClose={() => setOpenMarkPaid(false)}
          invoices={selectedRows}
          onSuccess={onMarkPaidSuccess}
        />
      )}
      {openRevertInvoice && (
        <RevertInvoice
          open={openRevertInvoice}
          onClose={() => setOpenRevertInvoice(false)}
          type='open'
          invoices={selectedRows}
          tab={activeTab}
          onSuccess={onMarkPaidSuccess}
        />
      )}
      {openViewPDF && (
        <ViewPdf title='View Invoice(s)' open={openViewPDF} pdfUrl={pdfUrl} onClose={() => setOpenViewPDF(false)} />
      )}
    </>
  );
};

export default OpenInvoices;
