import React from 'react';
import moment from 'moment';
import Select from 'react-select';
import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import CircularProgress from '@mui/material/CircularProgress';
import down from 'assets/icons/drivers/down.svg';
import equipment_save from 'assets/icons/equipment/equipment_save.svg';
import equipment_close from 'assets/icons/equipment/equipment_close.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/drivers/calendar.svg';

import Radio from 'common/Radio';
import Autocomplete from 'common/Autocomplete';
import ErrorMessage from 'common/ErrorMessage';
import CustomDatePicker from 'common/DatePicker';
import Input, { InputWithIcon, InputWithIconAndText } from 'common/Input';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import { palette } from 'utils/constants';
import { useTheme } from 'context/themeContext';
import { blockNonPositiveNumbers, blockNotNumberChars } from 'utils/helpers';
import { SYearMonthPicker } from 'common/DatePicker/DatePicker.styles';
import { validationSchema } from 'pages/landing/Equipment/EquipmentProfile/EquipmentProfileComponents/EquipmentMain/validationSchema';
import VendorAutocomplete from '../VendorAutocomplete';
import { SInput } from '../../EquipmentProfile.styles';
import style from '../../EquipmentProfile.module.css';

const customStyles = {
  control: (base) => ({
    ...base,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: palette.white,
    color: palette.dark800,
    height: 30,
    minHeight: 30,
    minWidth: 149,
    borderColor: 'hsl(0, 0%, 80%)',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 0px 0px 1px rgba(104, 113, 130, 0.16)',
    border: 'none !important',
    borderRadius: '4px',
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: palette.white,
    color: palette.dark800,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
  }),
  menuList: (base) => ({
    ...base,
    backgroundColor: palette.white,
    color: palette.dark800,
  }),
};

const EditDetails = ({
  profileData,
  registration,
  details,
  setDetails,
  onSubmit,
  userData,
  trailerList,
  vehicleList,
  equipmentOwner,
  vendors,
  getVendors,
  leasingCompanyError,
  setLeasingCompanyError,
  ownerOperator,
  ownerOperatorError,
  setOwnerOperatorError,
  setEquipmentOnwer,
  type,
  plateState,
  fuelTypes,
  lengthData,
  openEditModal,
  expand,
  expandComponent,
  loading,
}) => {
  const { use } = useTheme();

  const formik = useFormik({
    initialValues: {
      truck_type: details?.equipment_type_id === 1 ? details?.truck_type || 2 : null,
      state: details?.equipment_ownership?.state_data?.id,
      owner: details?.equipment_ownership?.owner,
      make: details?.make,
      year: details?.year,
      purchase_cost: details?.equipment_ownership?.purchase_cost || '',
      model_id: details?.model_id,
      color: details?.color,
      tire_size: details?.tire_size,
      axles: details?.axles,
      lift_gate: !!details?.lift_gate,
      vin: details?.vin,
      created_at: moment(details?.created_at).format('MM/DD/YYYY'),
      start_odometer: details?.start_odometer,
      odometer_unit: details?.odometer_unit,
      gross_vehicle_weight: details?.gross_vehicle_weight,
      gross_vehicle_measuring_unit: details?.gross_vehicle_measuring_unit,
      fuel_type_id: details?.fuel_type?.id ? details?.fuel_type?.id : '',
      fuel_gallon_capacity: details?.fuel_gallon_capacity || '',
      license_plate_number: registration?.reference_id || '',
      license_plate_expire_date: details?.license_plate_expire_date
        ? moment(details?.equipment_ownership?.license_plate_expire_date).format('MM/DD/YYYY')
        : '',
      cargo_maximum_capacity_weight: details?.cargo_maximum_capacity_weight,
      height: details?.height,
      width: details?.width,
      length: details?.length
        ? { label: `${details.length?.length} ${details.length?.unit}`, id: details.length?.id }
        : null,
      owner_operator: details?.equipment_ownership?.owner_operator,
      equipment_type_id: details?.equipment_type_id,
      leasing_company_name: details?.equipment_ownership?.leasing_company_name || '',
      lease_or_credit_payment: details?.equipment_ownership?.lease_or_credit_payment || '',
      is_retired: details?.equipment_status?.id === 5,
      retired_date: details?.retired_date ? moment(details?.retired_date).toDate() : new Date(),
      transmission_type: details?.transmission_type ? Number(details?.transmission_type) : null,
      transmission_speed: details?.transmission_speed ? Number(details?.transmission_speed) : null,
    },
    onSubmit,
    validationSchema,
  });

  return (
    <>
      <div className='d-flex justify-content-between  align-item-center equipment-profile-group-box'>
        <span className={style.details} style={{ color: use(palette.dark800, palette.gray200) }}>
          DETAILS <img className='ms-2' src={down} alt='' onClick={expandComponent} />
        </span>
        <div className='mt-2'>{expand && <div />}</div>

        <div>
          <button onClick={openEditModal} className={style.equ_close}>
            <img src={equipment_close} alt='close_icon' />
          </button>
          {loading ? (
            <CircularProgress
              style={{
                width: '20px',
                height: '20px',
                marginLeft: '10px',
              }}
            />
          ) : (
            <button className={style.equ_save} onClick={formik.handleSubmit}>
              <img src={equipment_save} alt='save_icon' />
            </button>
          )}
        </div>
      </div>
      <div>
        <div className='d-flex mt-2 equipment-profile-group-box' style={{ color: palette.dark800 }}>
          <span className={style['equipment-profile-content']}>Type </span>
          <div className={style['equipment-trailer-selector']}>
            {profileData && profileData?.equipment_type?.vehicle_type_id === 1 ? (
              <div className={style['select-owner-operator']}>
                <Select
                  options={vehicleList}
                  styles={customStyles}
                  className='basic-single'
                  classNamePrefix='select'
                  value={vehicleList?.find((i) => Number(i.value) === Number(formik.values.equipment_type_id)) || ''}
                  name='equipment_type_id'
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      equipment_type_id: e?.value,
                    });
                    formik.setFieldValue('equipment_type_id', e?.value);
                  }}
                />
                <span className='d-flex justify-content-end me-1'>
                  {formik.errors.equipment_type_id && formik.touched.equipment_type_id ? (
                    <div
                      className={style['add-equipment-validation']}
                      style={{
                        color: '#D12953',
                        fontSize: '12px',
                        marginTop: '12px',
                      }}
                    >
                      {formik.errors.equipment_type_id}
                    </div>
                  ) : null}
                </span>
              </div>
            ) : (
              <div className={style['select-owner-operator']}>
                <Select
                  options={trailerList}
                  name='type'
                  value={trailerList?.find((i) => Number(i.value) === Number(formik.values.equipment_type_id)) || ''}
                  onChange={(e) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      equipment_type_id: e?.value,
                    }));
                    formik.setFieldValue('equipment_type_id', e?.value);
                  }}
                  onBlur={formik.handleBlur}
                  styles={customStyles}
                />
                <span className='d-flex justify-content-end me-1'>
                  {formik.errors.equipment_type_id && formik.touched.equipment_type_id ? (
                    <div
                      className={style['add-equipment-validation']}
                      style={{
                        color: '#D12953',
                        fontSize: '12px',
                        marginTop: '12px',
                      }}
                    >
                      {formik.errors.equipment_type_id}
                    </div>
                  ) : null}
                </span>
              </div>
            )}
          </div>
        </div>
        {formik.values.equipment_type_id === 1 && (
          <div
            className='d-flex mt-2 equipment-profile-group-box'
            style={{ color: use(palette.dark800, palette.gray200) }}
          >
            <span className={style['equipment-profile-content']}>Truck Type</span>
            <div className={style['equipment-trailer-selector']}>
              <div className='d-flex gap-3 mt-2 nowrap'>
                <Radio
                  label='Day-Cab'
                  value={1}
                  checked={formik.values.truck_type === 1}
                  onChange={() => formik.setFieldValue('truck_type', 1)}
                />
                <Radio
                  label='Sleeper'
                  value={2}
                  checked={formik.values.truck_type === 2}
                  onChange={() => formik.setFieldValue('truck_type', 2)}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>Owned By</span>
          {profileData && equipmentOwner === 'Company Owned' && (
            <input
              type='text'
              style={{
                color: use(palette.dark200, palette.gray800),
                backgroundColor: use(palette.white, palette.gray50),
                width: '300px',
              }}
              className={style.equipment_edit_details}
              name='owner'
              value={userData?.customer?.company_name || ''}
              disabled
            />
          )}
          {profileData && equipmentOwner === 'Leasing Owned' && (
            <div>
              <VendorAutocomplete
                value={vendors.find((i) => i.name === formik.values.leasing_company_name) || null}
                options={vendors || []}
                onChange={(e, val) => {
                  setDetails({
                    ...details,
                    equipment_ownership: {
                      ...details?.equipment_ownership,
                      leasing_company_name: val?.name,
                    },
                  });
                  formik.setFieldValue('leasing_company_name', val?.name);
                  setLeasingCompanyError(false);
                }}
                onAddNewSuccess={(newVendor) => {
                  getVendors();
                  setDetails({
                    ...details,
                    equipment_ownership: {
                      ...details?.equipment_ownership,
                      leasing_company_name: newVendor?.name || '',
                    },
                  });
                  formik.setFieldValue('leasing_company_name', newVendor?.name || '');
                }}
              />
              <ErrorMessage error={leasingCompanyError && 'Required'} />
            </div>
          )}
          {profileData && equipmentOwner === 'Owner Operator' && (
            <div>
              <Select
                options={ownerOperator}
                type='checkbox'
                name='owner_operator'
                styles={customStyles}
                defaultValue={
                  profileData?.equipment_ownership?.owner_operator === '3' &&
                  ownerOperator?.some((i) => i.label === formik.values.owner)
                    ? { label: profileData?.equipment_ownership?.owner }
                    : ''
                }
                onChange={(e) => {
                  setDetails({
                    ...details,
                    equipment_ownership: {
                      ...details?.equipment_ownership,
                      owner: e?.label,
                    },
                  });
                  formik.setFieldValue('owner', e?.label);
                  setOwnerOperatorError(false);
                }}
              />
              <ErrorMessage error={ownerOperatorError && 'Required'} />
            </div>
          )}
        </div>
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>Owner of Equipment</span>
          <div style={{ minWidth: '149px' }}>
            <div>
              <span>
                <input
                  type='radio'
                  name='equipmentOwner'
                  style={style.equipmentType}
                  value='vehicle'
                  onClick={() => {
                    setEquipmentOnwer('Company Owned');
                    setDetails({
                      ...details,
                      equipment_ownership: {
                        ...details?.equipment_ownership,
                        owner: userData?.customer?.company_name,
                      },
                    });
                    formik.setFieldValue('owner', userData?.customer?.company_name);
                  }}
                  defaultChecked={
                    profileData?.equipment_ownership?.owner_operator
                      ? profileData?.equipment_ownership?.owner_operator === '1'
                      : ''
                  }
                />
                <span className={style['equipment-profile-content-value-radio']}>Company Owned</span>
              </span>
            </div>
            <div className=''>
              <span>
                <input
                  type='radio'
                  name='equipmentOwner'
                  value='vehicle'
                  onClick={() => {
                    setLeasingCompanyError(false);
                    setEquipmentOnwer('Leasing Owned');
                  }}
                  defaultChecked={
                    profileData?.equipment_ownership?.owner_operator
                      ? profileData?.equipment_ownership?.owner_operator === '2'
                      : ''
                  }
                />
                <span className={style['equipment-profile-content-value-radio']}>Leasing Owned</span>
              </span>
            </div>
            <div className=''>
              <span>
                <input
                  type='radio'
                  name='equipmentOwner'
                  value='vehicle'
                  onClick={() => {
                    setOwnerOperatorError(false);
                    setEquipmentOnwer('Owner Operator');
                  }}
                  defaultChecked={
                    profileData?.equipment_ownership?.owner_operator
                      ? profileData?.equipment_ownership?.owner_operator === '3'
                      : ''
                  }
                />
                <span className={style['equipment-profile-content-value-radio']}>Owner Operator</span>
              </span>
            </div>

            <div className=''>
              <span>
                <input
                  type='radio'
                  name='equipmentOwner'
                  value='vehicle'
                  onClick={() => {
                    setOwnerOperatorError(false);
                    setEquipmentOnwer('3rd Party');
                  }}
                  defaultChecked={
                    profileData?.equipment_ownership?.owner_operator
                      ? profileData?.equipment_ownership?.owner_operator === '4'
                      : ''
                  }
                />
                <span className={style['equipment-profile-content-value-radio']}>3rd Party</span>
              </span>
            </div>
          </div>
        </div>
        {profileData && equipmentOwner === 'Company Owned' ? (
          <div className='d-flex mt-2' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content']}>Purchase Cost</span>
            <InputWithIcon
              size='small'
              width='150px'
              type='number'
              name='purchase_cost'
              value={formik.values.purchase_cost}
              onChange={(e) => {
                setDetails((prevState) => ({
                  ...prevState,
                  equipment_ownership: {
                    ...prevState?.equipment_ownership,
                    purchase_cost: e.target.value,
                  },
                }));
                formik.setFieldValue('purchase_cost', e.target.value);
              }}
              onKeyDown={blockNonPositiveNumbers}
            />
          </div>
        ) : profileData && equipmentOwner === 'Leasing Owned' ? (
          <div className='d-flex mt-2' style={{ color: use(palette.dark800, palette.gray200) }}>
            <span className={style['equipment-profile-content']}>Lease Cost</span>
            <InputWithIconAndText
              size='small'
              width='86px'
              type='number'
              text='/month'
              name='lease_or_credit_payment'
              value={formik.values.lease_or_credit_payment}
              onChange={(e) => {
                setDetails({
                  ...details,
                  equipment_ownership: {
                    ...details?.equipment_ownership,
                    lease_or_credit_payment: e.target.value,
                  },
                });
                formik.setFieldValue('lease_or_credit_payment', e.target.value);
              }}
              onKeyDown={blockNonPositiveNumbers}
            />
          </div>
        ) : null}
        <div className='d-flex mt-2' style={{ color: use(palette.dark800, palette.gray200) }}>
          <span className={style['equipment-profile-content']}>{type ? 'Manufactured Date' : 'Year'}</span>
          {type ? (
            <SYearMonthPicker $width='150px'>
              <CalendarIcon />
              <DatePicker
                dateFormat='MM/yyyy'
                placeholderText='MM/YYYY'
                showYearDropdown
                showMonthYearPicker
                customInput={<Input />}
                selected={
                  moment(formik.values.year, 'MM/YYYY').isValid()
                    ? moment(formik.values.year, 'MM/YYYY').toDate()
                    : null
                }
                onChange={(date) => {
                  setDetails({
                    ...details,
                    year: date,
                  });
                  formik.setFieldValue('year', date);
                }}
                maxDate={new Date()}
              />
            </SYearMonthPicker>
          ) : (
            <Input
              size='small'
              width='149px'
              type='number'
              name='year'
              value={formik.values.year}
              onChange={(e) => {
                setDetails({
                  ...details,
                  year: e.target.value,
                });
                formik.setFieldValue('year', e.target.value);
              }}
              onKeyDown={(e) => {
                blockNonPositiveNumbers(e);
                blockNotNumberChars(e);
              }}
            />
          )}
        </div>
        <div className='d-flex mt-2' style={{ color: use(palette.dark800, palette.gray200) }}>
          <span className={style['equipment-profile-content']}>Make </span>
          <input
            style={{
              color: use(palette.dark200, palette.gray800),
              backgroundColor: use(palette.white, palette.gray50),
            }}
            type='text'
            name='make'
            value={formik.values.make}
            className={style.equipment_edit_details}
            onChange={(e) => {
              setDetails({
                ...details,
                make: e.target.value,
              });
              formik.setFieldValue('make', e.target.value);
            }}
          />
        </div>

        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>Model </span>
          <input
            style={{
              color: use(palette.dark200, palette.gray800),
              backgroundColor: use(palette.white, palette.gray50),
            }}
            type='text'
            name='model_id'
            value={formik.values.model_id}
            className={style.equipment_edit_details}
            onChange={(e) => {
              setDetails({
                ...details,
                model_id: e.target.value,
              });
              formik.setFieldValue('model_id', e.target.value);
            }}
          />
        </div>
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>Color </span>
          <input
            style={{
              color: use(palette.dark200, palette.gray800),
              backgroundColor: use(palette.white, palette.gray50),
            }}
            type='text'
            name='color'
            value={formik.values.color}
            className={style.equipment_edit_details}
            onChange={(e) => {
              setDetails({
                ...details,
                color: e.target.value,
              });
              formik.setFieldValue('color', e.target.value);
            }}
          />
        </div>
        <div className='d-flex mt-2 equipment-profile-group-box' style={{ color: palette.dark800 }}>
          <span className={style['equipment-profile-content']}>Tire Size </span>
          <input
            style={{
              color: palette.dark200,
              backgroundColor: palette.white,
            }}
            type='number'
            name='tire_size'
            value={formik.values.tire_size}
            className={style.equipment_edit_details}
            onChange={(e) => {
              setDetails({
                ...details,
                tire_size: e.target.value,
              });
              formik.setFieldValue('tire_size', e.target.value);
            }}
          />
        </div>
        {!type && (
          <div
            className='d-flex mt-2 equipment-profile-group-box'
            style={{ color: use(palette.dark800, palette.gray200) }}
          >
            <span className={style['equipment-profile-content']}>Transmission Type</span>
            <div className={style['equipment-trailer-selector']}>
              <div className='d-flex align-items-center gap-3 nowrap'>
                <Radio
                  id='automatic'
                  label='Automatic'
                  name='transmission_type'
                  value={1}
                  checked={formik.values.transmission_type === 1}
                  onChange={() => formik.setFieldValue('transmission_type', 1)}
                />
                <Radio
                  id='manual'
                  label='Manual'
                  name='transmission_type'
                  value={2}
                  checked={formik.values.transmission_type === 2}
                  onChange={() => formik.setFieldValue('transmission_type', 2)}
                />
                {formik.values.transmission_type === 2 && (
                  <Input
                    size='small'
                    type='number'
                    name='transmission_speed'
                    placeholder='Speed'
                    value={formik.values.transmission_speed}
                    onChange={(e) => formik.setFieldValue('transmission_speed', e.target.value)}
                    onKeyDown={blockNonPositiveNumbers}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        <div className='d-flex mt-2 equipment-profile-group-box' style={{ color: palette.dark800 }}>
          <span className={style['equipment-profile-content']}>Axles </span>
          <input
            style={{
              color: palette.dark200,
              backgroundColor: palette.white,
            }}
            type='number'
            name='axles'
            value={formik.values.axles}
            className={style.equipment_edit_details}
            onChange={(e) => {
              setDetails({
                ...details,
                axles: e.target.value,
              });
              formik.setFieldValue('axles', e.target.value);
            }}
          />
        </div>
        <div className='d-flex mt-2' style={{ color: palette.dark800 }}>
          <span className={style['equipment-profile-content']}>Lift Gate </span>
          <CustomCheckbox
            name='lift_gate'
            checked={formik.values.lift_gate}
            onChange={(checked) => formik.setFieldValue('lift_gate', checked)}
          />
        </div>
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>VIN </span>
          <input
            style={{
              color: use(palette.dark200, palette.gray800),
              backgroundColor: use(palette.white, palette.gray50),
            }}
            type='text'
            name='vin'
            value={formik.values.vin}
            className={style.equipment_edit_details}
            onChange={(e) => {
              setDetails({
                ...details,
                vin: e.target.value,
              });
              formik.setFieldValue('vin', e.target.value);
            }}
          />
        </div>
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{
            color: use(palette.dark800, palette.gray200),
            minWidth: '149px !important',
          }}
        >
          <span className={style['equipment-profile-content']}>Join Date </span>
          <span className={style['equipment-profile-Date-input']}>
            <DatePicker
              // style={{ color: use(palette.dark200, palette.gray800), backgroundColor: use(palette.white, palette.gray50) }}
              dateFormat='MM-DD-yyyy'
              selected={details?.created_at ? new Date(details?.created_at) : ''}
              //    showTimeInput
              value={formik.values.created_at}
              onChange={(date) => {
                setDetails({
                  ...details,
                  created_at: moment(date).format('L'),
                });
                formik.setFieldValue('created_at', moment(date).format('L'));
              }}
              disabled
              className={style['equipment-profile-content-value-join-date']}
              style={{
                marginLeft: '8px',
                color: use(palette.dark200, palette.gray800),
                backgroundColor: use(palette.white, palette.gray50),
              }}
            />
          </span>
        </div>
        {!type && (
          <>
            <div
              className='d-flex mt-2 equipment-profile-group-box'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              <span className={style['equipment-profile-content']}>Start Odometer</span>
              <input
                style={{
                  color: use(palette.dark200, palette.gray800),
                  backgroundColor: use(palette.white, palette.gray50),
                }}
                type='text'
                name='start_odometer'
                value={formik.values.start_odometer}
                className={style.equipment_edit_details}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    start_odometer: e.target.value,
                  });
                  formik.setFieldValue('start_odometer', e.target.value);
                }}
              />
            </div>
            <div
              className='d-flex mt-2 equipment-profile-group-box'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              <span className={style['equipment-profile-content']}>Odometer Unit</span>
              <input
                style={{
                  color: use(palette.dark200, palette.gray800),
                  backgroundColor: use(palette.white, palette.gray50),
                }}
                type='text'
                name='odometer_unit'
                value={formik.values.odometer_unit}
                className={style.equipment_edit_details}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    odometer_unit: e.target.value,
                  });
                  formik.setFieldValue('odometer_unit', e.target.value);
                }}
              />
            </div>{' '}
          </>
        )}
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>Gross Vehicle Weight </span>
          <div className='d-flex'>
            <input
              type='text'
              name='gross_vehicle_weight'
              className={style.equipment_edit_details}
              value={formik.values.gross_vehicle_weight}
              onChange={(e) => {
                formik.setFieldValue('gross_vehicle_weight', e.target.value);
              }}
              style={{
                color: palette.dark200,
                backgroundColor: palette.white,
                borderRadius: '6px 0 0 6px',
                width: '100px',
              }}
            />
            <select
              name='gross_vehicle_measuring_unit'
              className={style.equipment_edit_details}
              defaultValue={formik.values.gross_vehicle_measuring_unit}
              onChange={(e) => {
                formik.setFieldValue('gross_vehicle_measuring_unit', e.target.value);
              }}
              style={{
                borderRadius: '0 6px 6px 0',
                width: '49px',
              }}
            >
              <option className={style['select-kgs']} value='LBS'>
                LBS
              </option>
              <option value='KGS'>KGS</option>
            </select>
          </div>
        </div>
        {!type && (
          <>
            <div
              className='d-flex mt-2 equipment-profile-group-box'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              <span className={style['equipment-profile-content']}>Fuel Type</span>
              <div className={style['select-equipment-class']}>
                <select
                  className={style['equipment-headings']}
                  style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    paddingLeft: '8px',
                    background: '#fff',
                  }}
                  name='fuel_type'
                  value={formik.values.fuel_type_id}
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      fuel_type: {
                        ...details?.fuel_type,
                        id: e.target.value,
                      },
                    });
                    formik.setFieldValue('fuel_type_id', e.target.value);
                  }}
                >
                  {fuelTypes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.type}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div
              className='d-flex mt-2 equipment-profile-group-box'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              <span className={style['equipment-profile-content']}>Fuel Capacity</span>
              <div className='position-relative'>
                <SInput
                  type='number'
                  style={{
                    color: use(palette.dark200, palette.gray800),
                    backgroundColor: use(palette.white, palette.gray50),
                  }}
                  name='fuel_gallon_capacity'
                  value={formik.values?.fuel_gallon_capacity || ''}
                  onChange={(e) => {
                    if (e.target?.value?.length > 3) {
                      return;
                    }
                    formik.setFieldValue('fuel_gallon_capacity', e.target.value);
                  }}
                  onKeyDown={(e) => {
                    blockNonPositiveNumbers(e);
                    blockNotNumberChars(e);
                  }}
                  className={style.equipment_edit_details}
                />
                <span className={style['fuel-capacity-wrapper']} />
              </div>
            </div>
          </>
        )}
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>Plate Number</span>
          <input
            type='text'
            style={{
              color: use(palette.dark200, palette.gray800),
              backgroundColor: use(palette.white, palette.gray50),
            }}
            name='license_plate_number'
            // onChange={formik.handleChange}
            value={formik.values?.license_plate_number}
            onChange={(e) => {
              setDetails({
                ...details,
                equipment_ownership: {
                  ...details?.equipment_ownership,
                  license_plate_number: e.target.value,
                },
              });
              formik.setFieldValue('license_plate_number', e.target.value);
            }}
            // disabled
            className={style.equipment_edit_details}
          />
        </div>
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>Plate State </span>
          <div>
            {plateState.length && profileData ? (
              <Select
                options={plateState}
                type='checkbox'
                name='state'
                defaultValue={{
                  label: profileData?.equipment_ownership?.state_data?.name,
                  value: profileData?.equipment_ownership?.state_data?.id,
                }}
                onBlur={formik.handleBlur}
                styles={customStyles}
                onChange={(e) => {
                  formik.setFieldValue('state', e?.value);
                }}
              />
            ) : (
              ''
            )}
            <span className='d-flex justify-content-end me-1'>
              {formik.errors.state && formik.touched.state ? (
                <div
                  className={style['add-equipment-validation']}
                  style={{
                    color: '#D12953',
                    fontSize: '12px',
                    marginTop: '12px',
                  }}
                >
                  {formik.errors.state}
                </div>
              ) : null}
            </span>
          </div>
        </div>
        {type ||
        details?.equipment_type_id === 3 ||
        details?.equipment_type_id === 4 ||
        details?.equipment_type_id === 5 ||
        details?.equipment_type_id === 9 ||
        details?.equipment_type_id === 11 ? (
          <>
            <div
              className='d-flex mt-2 equipment-profile-group-box'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              <span className={style['equipment-profile-content']}>Cargo Payload</span>
              <input
                style={{
                  color: use(palette.dark200, palette.gray800),
                  backgroundColor: use(palette.white, palette.gray50),
                }}
                type='text'
                name='cargo_maximum_capacity_weight'
                value={formik.values.cargo_maximum_capacity_weight}
                className={style.equipment_edit_details}
                onChange={(e) => {
                  setDetails({
                    ...details,
                    cargo_maximum_capacity_weight: e.target.value,
                  });
                  formik.setFieldValue('cargo_maximum_capacity_weight', e.target.value);
                }}
              />
            </div>
            <div
              className='d-flex mt-2 equipment-profile-group-box'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              <div className={style['equipment-profile-content']}>
                <span>Length </span>
              </div>
              <div className={style['equipment-trailer-selector']}>
                <Autocomplete
                  size='small'
                  width='150px'
                  labelKey='label'
                  name='length'
                  options={lengthData || []}
                  value={formik.values.length || null}
                  onChange={(e, val) => formik.setFieldValue('length', val)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  error={formik.touched.length && formik.errors.length}
                />
              </div>
            </div>
            <div
              className='d-flex mt-2 equipment-profile-group-box'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              <span className={style['equipment-profile-content']}>Height </span>
              <div>
                <input
                  style={{
                    color: use(palette.dark200, palette.gray800),
                    backgroundColor: use(palette.white, palette.gray50),
                  }}
                  type='text'
                  name='height'
                  value={formik.values.height}
                  className={style.equipment_edit_details}
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      height: e.target.value,
                    });
                    formik.setFieldValue('height', e.target.value);
                  }}
                />
                <ErrorMessage error={formik.touched.height && formik.errors.height} />
              </div>
            </div>
            <div
              className='d-flex mt-2 equipment-profile-group-box'
              style={{ color: use(palette.dark800, palette.gray200) }}
            >
              <span className={style['equipment-profile-content']}>Width </span>
              <div>
                <input
                  style={{
                    color: use(palette.dark200, palette.gray800),
                    backgroundColor: use(palette.white, palette.gray50),
                  }}
                  type='text'
                  name='Width'
                  value={formik.values.width}
                  className={style.equipment_edit_details}
                  onChange={(e) => {
                    setDetails({
                      ...details,
                      width: e.target.value,
                    });
                    formik.setFieldValue('width', e.target.value);
                  }}
                />
                <ErrorMessage error={formik.touched.width && formik.errors.width} />
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        <div
          className='d-flex mt-2 equipment-profile-group-box'
          style={{ color: use(palette.dark800, palette.gray200) }}
        >
          <span className={style['equipment-profile-content']}>Retired Date</span>
          <div className={style['equipment-trailer-selector']}>
            <div className='d-flex align-items-center gap-3 nowrap'>
              <Radio
                label='No'
                name='is_retired'
                value={false}
                checked={!formik.values.is_retired}
                onChange={() => formik.setFieldValue('is_retired', false)}
              />
              <Radio
                label='Yes'
                name='is_retired'
                value
                checked={!!formik.values.is_retired}
                onChange={() => formik.setFieldValue('is_retired', true)}
              />
              {!!formik.values.is_retired && (
                <CustomDatePicker
                  size='small'
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={20}
                  name='retired_date'
                  value={formik.values.retired_date}
                  maxDate={new Date()}
                  onChange={(value) => formik.setFieldValue('retired_date', value)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDetails;
