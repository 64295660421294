import React, { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as MoreInfo } from 'assets/icons/drivers/moreInfo.svg';
import { ReactComponent as SettingIcon } from 'assets/sidemanu/settings-20px.svg';
import NoRecords from 'common/NoRecords';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import { palette, PERMISSIONS } from 'utils/constants';
import { getDelayReasonsList } from 'Api/CompanySettings';
import { CustomerPayTerms, getCustomerContactBook, getCustomerDelayCode } from 'Api/Customers';
import DelayReason from './DelayReason';
import { initialValues } from './DelayReasonCodes.data';
import { SSection, STable, SAddMore } from '../EdiApi.styles';

const DelayReasonCodes = ({ customerId, onlyEdit }) => {
  const navigate = useNavigate();
  const { permissions } = useSelector((state) => state?.root);
  const [editMode, setEditMode] = useState(!!onlyEdit || false);
  const [delayCodes, setDelayCodes] = useState([]);
  const [delayReasons, setDelayReasons] = useState([]);
  const [customerContacts, setCustomerContacts] = useState([]);
  const [customerPaymentTerms, setCustomerPaymentTerms] = useState(null);

  const readOnly =
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) ||
    permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_READ_ONLY.name);

  const getDelayCodes = async () => {
    try {
      const { data } = await getCustomerDelayCode({ id: customerId });
      setDelayCodes(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getDelayReasons = async () => {
    try {
      const { data } = await getDelayReasonsList();
      setDelayReasons(data || []);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomerContacts = async () => {
    try {
      const { data } = await getCustomerContactBook({ id: customerId });
      setCustomerContacts(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getCustomerPaymentTerms = async () => {
    try {
      const { data } = await CustomerPayTerms({ id: customerId });
      setCustomerPaymentTerms(data);
    } catch (e) {
      // Do nothing
    }
  };

  const onAddMore = () => {
    setDelayCodes((prevState) => [...prevState, { ...initialValues, id: uuid(), isNew: true }]);
  };

  useEffect(() => {
    getDelayCodes();
    getDelayReasons();
    getCustomerContacts();
    getCustomerPaymentTerms();
  }, []);

  if (
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) &&
    !permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_READ_ONLY.name)
  ) {
    return null;
  }

  return (
    <SSection>
      {!onlyEdit && (
        <div className='section-header'>
          <Typography variant='h4' style={{ color: palette.gray900 }}>
            Delay Reason Codes
          </Typography>
          {permissions.includes(PERMISSIONS.EDI_CUSTOMER_PROFILES_EDIT.name) &&
            (editMode ? (
              <CustomButton
                styleTitle={{ fontSize: 14 }}
                styleButton={{ padding: '3px 12px', margin: 0 }}
                type='secondary'
                title='Close'
                onClick={() => {
                  setEditMode(false);
                  if (delayCodes.some((i) => i.isNew)) {
                    setDelayCodes((prevState) => prevState.filter((i) => !i.isNew));
                  }
                }}
              />
            ) : (
              <MoreInfo className='pointer' onClick={() => setEditMode(true)} />
            ))}
        </div>
      )}
      <div className='section-body'>
        <STable>
          <thead>
            <tr className='header-row'>
              <th>
                REASON
                {!!editMode && (
                  <SettingIcon
                    className='ms-2'
                    onClick={() =>
                      navigate('/company-settings', {
                        state: {
                          tabId: 7,
                          subTabName: 'DelayReasons',
                        },
                      })
                    }
                  />
                )}
              </th>
              <th>EDI/API CODE</th>
              <th>ADD NOTE</th>
              <th style={{ width: '100px' }} />
            </tr>
          </thead>
          <tbody>
            {delayCodes.map((item, index) => (
              <DelayReason
                key={item.id}
                index={index}
                delayCode={item}
                editMode={editMode}
                customerId={customerId}
                delayReasons={delayReasons}
                existingDelayCodes={delayCodes.filter((i) => !i.isNew)}
                customerContacts={customerContacts}
                customerPaymentTerms={customerPaymentTerms}
                onDeleteSuccess={(id) => setDelayCodes((prevState) => prevState.filter((i) => i.id !== id))}
                onCreateSuccess={(newData) =>
                  setDelayCodes((prevState) => prevState.map((el, i) => (i === index ? newData : el)))
                }
              />
            ))}
          </tbody>
        </STable>
        {!editMode && !delayCodes.length && <NoRecords />}
        {!readOnly && !!editMode && (
          <SAddMore onClick={onAddMore}>
            <PlusIcon />
            <Typography variant='s2' style={{ color: palette.indigo500 }}>
              Add Another...
            </Typography>
          </SAddMore>
        )}
      </div>
    </SSection>
  );
};

export default DelayReasonCodes;
