import axios from '../services/axios';

export async function GetCompanyProfile() {
  const { data } = await axios.get('company-profile');
  return data;
}

export async function getBrokerageSetting() {
  const { data } = await axios.get('customer-setting/brokerage');
  return data;
}

export async function updateBrokerageSetting(dataJson) {
  const { data } = await axios.post(`customer-setting/brokerage`, dataJson);
  return data;
}

export async function GetTimeZoneList() {
  const { data } = await axios.get('time-zones');
  return data;
}

export async function GetFMCSACompliance() {
  const { data } = await axios.get('customer/settings/fmcsa-compliance');
  return data;
}

export async function CreateFMCSACompliance(dataJson) {
  const { data } = await axios.post(`customer/settings/fmcsa-compliance`, dataJson);
  return data;
}

export async function UpdateCompanyProfile(dataJson) {
  const { data } = await axios.put(`update-company-profile`, dataJson);
  return data;
}

export async function GetSMSNumberList() {
  const { data } = await axios.get('twilio/get-phone-number');
  return data;
}

export async function getDataRetention(id) {
  const { data } = await axios.get(`customers/${id}`);
  return data;
}

export async function UpdateDataRetention(id, dataJson) {
  const { data } = await axios.put(`customers/${id}`, dataJson);
  return data;
}

export async function GetCompanyResources() {
  const { data } = await axios.get('company-resources');
  return data;
}

export async function CreateCompanyResource(dataJson) {
  const { data } = await axios.post(`company-resources`, dataJson);
  return data;
}

export async function GetCategories() {
  const { data } = await axios.get('/company-resources/categories');
  return data;
}

export async function getRulesAndConduct() {
  const { data } = await axios.get('customer/settings/rules-conduct');
  return data;
}

export async function createRule(dataJson) {
  const { data } = await axios.post(`customer/settings/rules-conduct`, dataJson);
  return data;
}

export async function updateRule(id, dataJson) {
  const { data } = await axios.post(`customer/settings/rules-conduct/${id}`, dataJson);
  return data;
}

export async function deleteRule(id) {
  const { data } = await axios.delete(`customer/settings/rules-conduct/${id}`);
  return data;
}

export async function GetDepartments() {
  const { data } = await axios.get('/customer/departments');
  return data;
}

export async function CreateCategory(dataJson) {
  const { data } = await axios.post(`company-resources/category`, dataJson);
  return data;
}

export async function DeleteCompanyResources(id) {
  const { data } = await axios.delete(`company-resources/${id}`);
  return data;
}

export async function UpdateCompanyResources(id, dataJson) {
  const { data } = await axios.post(`company-resources/${id}`, dataJson);
  return data;
}

export async function DeleteCategory(id) {
  const { data } = await axios.delete(`company-resources/category/${id}`);
  return data;
}

export async function getSettingsApiKey() {
  const { data } = await axios.get('/customer/api-key');
  return data;
}

export async function getSecondaryApiKeys(params) {
  const { data } = await axios.get('/customer/secondary-api-key', { params });
  return data;
}

export async function resetSettingsApiKey() {
  const { data } = await axios.post('/customer/api-key-reset');
  return data;
}

export async function generateSecondaryApiKey(body) {
  const { data } = await axios.post('/customer/secondary-api-key', body);
  return data;
}

export async function resetSecondaryApiKey(id) {
  const { data } = await axios.put(`/customer/secondary-api-key/${id}`);
  return data;
}

export async function deleteSecondaryApiKey(id) {
  const { data } = await axios.delete(`/customer/secondary-api-key/${id}`);
  return data;
}

export async function GetSettingsAccounting() {
  const { data } = await axios.get('/customer/settings/account');
  return data;
}

export async function CreateAccountingSettings(body) {
  const { data } = await axios.post(`customer/settings/account`, body);
  return data;
}

export async function getFundsTransferInstructions() {
  const { data } = await axios.get('customer/settings/fund-transfer-instruction');
  return data;
}

export async function updateFundsTransferInstructions(body) {
  const { data } = await axios.post('customer/settings/fund-transfer-instruction', body);
  return data;
}

export async function getFundsTransferInstructionsBrokerage() {
  const { data } = await axios.get('customer/settings/brokerage/fund-transfer-instruction');
  return data;
}

export async function updateFundsTransferInstructionsBrokerage(body) {
  const { data } = await axios.post('customer/settings/brokerage/fund-transfer-instruction', body);
  return data;
}

export async function GetAccountTypes() {
  const { data } = await axios.get('customer/settings/account/type');
  return data;
}

export async function CreateAccountType(dataJson) {
  const { data } = await axios.post(`customer/settings/account/type`, dataJson);
  return data;
}

export async function GetSettingsAlerts() {
  const { data } = await axios.get('/customer/settings/alerts');
  return data;
}

export async function UpdateSettingsAlert({ id, body }) {
  const { data } = await axios.put(`/customer/settings/alert/${id}`, body);
  return data;
}

export async function GetSettingsDepartments() {
  const { data } = await axios.get('/customer/departments');
  return data;
}

export async function deleteDepartment(id) {
  const { data } = await axios.delete(`/customer/department/${id}`);
  return data;
}

export async function UpdateSettingsDepartment({ id, body }) {
  const { data } = await axios.put(`/customer/department/${id}`, body);
  return data;
}

export async function CreateDepartment(dataJson) {
  const { data } = await axios.post(`/customer/department`, dataJson);
  return data;
}

export async function GetDepartmentsPermissions() {
  const { data } = await axios.get('/customer/department/permission');
  return data;
}

export async function GetSettingsGroups(params) {
  const { data } = await axios.get(`group`, {
    params,
  });
  return data;
}

export async function getVehicles() {
  const { data } = await axios.get(`/vehicles`);
  return data;
}

export async function updateMph(id, body) {
  const { data } = await axios.put(`/vehicles-types/${id}`, body);
  return data;
}

export async function updateGroup(id, body) {
  const { data } = await axios.put(`/group/${id}`, body);
  return data;
}

export async function deleteSettingsGroup(id) {
  const { data } = await axios.delete(`/group/${id}`);
  return data;
}

export async function createGroup(body) {
  const { data } = await axios.post(`group`, body);
  return data;
}

export async function GetSettingsShipments() {
  const { data } = await axios.get('/customer/settings/shipment');
  return data;
}

export async function CreateSettingsShipments(dataJson) {
  const { data } = await axios.post(`customer/settings/shipment`, dataJson);
  return data;
}

export async function GetChargeTypes(params) {
  const { data } = await axios.get('/customer/shipment-charge-types', { params });
  return data;
}

export async function UpdateChargeType(id, dataJson) {
  const { data } = await axios.put(`customer/shipment-charge-type/${id}`, dataJson);
  return data;
}

export async function CreateChargeType(dataJson) {
  const { data } = await axios.post(`customer/shipment-charge-type`, dataJson);
  return data;
}

export async function DeleteChargeType(id) {
  const { data } = await axios.delete(`customer/shipment-charge-type/${id}`);
  return data;
}

export async function GetSettingMobileApps() {
  const { data } = await axios.get('/customer/settings/driver-app');
  return data;
}

export async function CreateSettingMobileApps(dataJson) {
  const { data } = await axios.post(`/customer/settings/driver-app`, dataJson);
  return data;
}

export async function GetCommodityTypes() {
  const { data } = await axios.get('/customer/shipment-commodity-types');
  return data;
}

export async function UpdateCommodityType(id, dataJson) {
  const { data } = await axios.put(`customer/shipment-commodity-type/${id}`, dataJson);
  return data;
}

export async function CreateCommodityType(dataJson) {
  const { data } = await axios.post(`customer/shipment-commodity-type`, dataJson);
  return data;
}

export async function DeleteCommodityType(id) {
  const { data } = await axios.delete(`customer/shipment-commodity-type/${id}`);
  return data;
}

export async function GetHandbook() {
  const { data } = await axios.get('customer/settings/get-handbook');
  return data;
}

export async function getDelayReasonsList() {
  const { data } = await axios.get('customer/shipment-delay-reasons');
  return data;
}

export async function createDelayReason(body) {
  const { data } = await axios.post(`customer/shipment-delay-reason`, body);
  return data;
}

export async function deleteDelayReason(id) {
  const { data } = await axios.delete(`customer/shipment-delay-reason/${id}`);
  return data;
}

export async function getDriverCompensation() {
  const { data } = await axios.get('customer/settings/driver-compensation');
  return data;
}

export async function updateDriverCompensation(body) {
  const { data } = await axios.post(`customer/settings/driver-compensation`, body);
  return data;
}

export async function getOutOfServiceStatus(id) {
  const { data } = await axios.get(`customers/${id}`);
  return data;
}

export async function updateOutOfServiceStatus(id, body) {
  const { data } = await axios.put(`customers/${id}`, body);
  return data;
}

export async function uploadCompanyLogo(body) {
  const { data } = await axios.post(`company-logo`, body);
  return data;
}

export async function createCityStateCountry(body) {
  const { data } = await axios.post(`countries-states-cities`, body);
  return data;
}

export async function getSelectOptions(params) {
  const { data } = await axios.get(`select-options`, { params });
  return data;
}

export async function getIncidentTypes() {
  const { data } = await axios.get('customer/settings/incident-type');
  return data;
}

export async function updateIncidentType(id, body) {
  const { data } = await axios.put(`customer/settings/incident-type/${id}`, body);
  return data;
}

export async function createIncidentType(body) {
  const { data } = await axios.post(`customer/settings/incident-type`, body);
  return data;
}

export async function deleteIncidentType(id, body) {
  const { data } = await axios.delete(`customer/settings/incident-type/${id}`, body);
  return data;
}

// Locations
export async function getTimeZoneList() {
  const { data } = await axios.get('time-zones');
  return data;
}

export async function getLocationPins(params) {
  const { data } = await axios.get('company-settings/locations', { params });
  return data;
}

export async function createLocationPin(body) {
  const { data } = await axios.post('company-settings/locations', body);
  return data;
}

export async function updateLocationPin(id, body) {
  const { data } = await axios.put(`company-settings/locations/${id}`, body);
  return data;
}

export async function deleteLocationPin(id) {
  const { data } = await axios.delete(`company-settings/locations/${id}`);
  return data;
}

// Currency and Metrics
export async function getCurrencyAndMetrics(params) {
  const { data } = await axios.get('customer/settings/currency-metrics', { params });
  return data;
}

export async function createCurrencyAndMetrics(body) {
  const { data } = await axios.post('customer/settings/currency-metrics', body);
  return data;
}

// SMS Communications
export async function getSMSCommunicationSettings(params) {
  const { data } = await axios.get('customer/settings/sms-communication', { params });
  return data;
}

export async function createSMSCommunicationSettings(body) {
  const { data } = await axios.post('customer/settings/sms-communication', body);
  return data;
}

export async function getShipmentNoteDepartments() {
  const { data } = await axios.get('company-settings/driver-notes/departments');
  return data;
}

export async function updateShipmentNoteDepartments(body) {
  const { data } = await axios.post('company-settings/driver-notes/departments', body);
  return data;
}

// Date and Time Format
export async function getDateTimeSettings(params) {
  const { data } = await axios.get('customer/settings/general/date-time-format', { params });
  return data;
}

export async function createDateTimeSettings(body) {
  const { data } = await axios.post('customer/settings/general/date-time-format', body);
  return data;
}

// Change Log
export async function getChangeLog(params, signal) {
  const { data } = await axios.get('company-setting/change-log', { params, signal });
  return data;
}

// KYU
export async function getKyuSettings() {
  const { data } = await axios.get('customer/kyu');
  return data;
}

export async function updateKyuSettings(body) {
  const { data } = await axios.post('customer/update-kyu', body);
  return data;
}

// Out of Service Triggers
export async function getOutOfServiceTrigger() {
  const { data } = await axios.get('customer/settings/out-of-service-trigger');
  return data;
}

export async function updateOutOfServiceTrigger(id, body) {
  const { data } = await axios.put(`customer/settings/out-of-service-trigger/${id}`, body);
  return data;
}

// Offers
export async function getShipmentOfferSettings() {
  const { data } = await axios.get('customer/settings/shipment-offer');
  return data;
}

export async function updateShipmentOfferSettings(body) {
  const { data } = await axios.post(`customer/settings/shipment-offer`, body);
  return data;
}

// Dispatch
export async function getShipmentDispatchSettings() {
  const { data } = await axios.get('customer/settings/shipment-dispatch');
  return data;
}

export async function updateShipmentDispatchSettings(body) {
  const { data } = await axios.post(`customer/settings/shipment-dispatch`, body);
  return data;
}

// Documents
export async function getDocumentSettings() {
  const { data } = await axios.get('customer/settings/shipment-document');
  return data;
}

export async function updateDocumentSettings(body) {
  const { data } = await axios.post(`customer/settings/shipment-document`, body);
  return data;
}

// Absence Policy

export async function getDriverAbsence() {
  const { data } = await axios.get('customer/settings/driver-absence');
  return data;
}

export async function createDriverAbsence(body) {
  const { data } = await axios.post(`customer/settings/driver-absence`, body);
  return data;
}

export async function getStaffAbsence() {
  const { data } = await axios.get('customer/settings/staff-absence');
  return data;
}

export async function createStaffAbsence(body) {
  const { data } = await axios.post(`customer/settings/staff-absence`, body);
  return data;
}

// favorites
export async function getProductivityEssentialsSetting() {
  const { data } = await axios.get('customer/settings/get-productivity-essentials');
  return data;
}

// favorites
export async function getLogbookDataRetention() {
  const { data } = await axios.get('company-settings/data-retention');
  return data;
}

export async function updateHostDataRetention(body) {
  const { data } = await axios.put(`company-settings/data-retention`, body);
  return data;
}

export async function updateProductivityEssentialsSetting(dataJson) {
  const { data } = await axios.post(`customer/settings/update-productivity-essentials`, dataJson);
  return data;
}

// Staff onboarding
export async function getStaffOnboarding() {
  const { data } = await axios.get('/customer/settings/staff-onboarding');
  return data;
}

export async function updateStaffOnboarding(body) {
  const { data } = await axios.post(`/customer/settings/staff-onboarding`, body);
  return data;
}

// Stop point inventory
export async function getInventorySettings() {
  const { data } = await axios.get('/customer/settings/stop-point-inventory');
  return data;
}

export async function updateInventorySettings(body) {
  const { data } = await axios.post(`/customer/settings/stop-point-inventory`, body);
  return data;
}

// EDI Logs
export async function getEDILogs(params, signal) {
  const { data } = await axios.get('/customer/edi-shipment/logs', { params, signal });
  return data;
}

export async function updateLogAcknowledgement(id, body) {
  const { data } = await axios.put(`/customer/edi-shipment/logs/${id}`, body);
  return data;
}

// Driver next availability
export async function getNextAvailabilitySettings() {
  const { data } = await axios.get('/customer-setting/next-available-settings');
  return data;
}

export async function updateNextAvailabilitySettings(body) {
  const { data } = await axios.post(`/customer-setting/next-available-settings`, body);
  return data;
}
