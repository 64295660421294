import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import moment from 'moment';
import locationIcon from 'assets/icons/location.png';
import globeIcon from 'assets/icons/globe.png';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';
import { getPaymentTermTitle } from 'components/CustomerProfile/CustomerProfile.data';
import { formatAmount } from 'utils/helpers';
import { generatePaymentMethods } from './paymentMethods';
import { generateShipmentCharges } from './shipmentCharges';
import { generateShipmentDetails } from './shipmentDetails';

const currencies = {
  $: 'USD',
  C$: 'CAD',
  '₱': 'MXN',
};

const getDashedLineY = (methodsCount) => {
  if (methodsCount === 1) {
    return 217;
  }

  if (methodsCount === 2) {
    return 207;
  }

  if (methodsCount === 5) {
    return 196;
  }

  return 203;
};

const getBarcode = (id, displayValue = true) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, id, { displayValue });

  return canvas.toDataURL('image/jpeg');
};

const createFooter = ({
  doc,
  accountingSettings,
  companyProfile,
  instructions,
  customer_company_name,
  invoiceId,
  payable_to,
  payment_method,
  stripePaymentLink,
  page = 1,
  totalPages = 1,
  total_amount,
  dueDate = null,
  currency,
  formatDate,
}) => {
  if (!accountingSettings || !companyProfile) {
    return null;
  }

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const {
    website,
    show_website,
    show_billing,
    receivable_email,
    address_line1,
    address_line2,
    city: cityA,
    state: stateA,
    country,
    zip: zipA,
    factoring_company,
  } = accountingSettings || {};
  const { company_name, address1, address2, city_id, state_id, country_id, zip, phone_number } = companyProfile || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  // background
  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 22, pageWidth, 26, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 5.5, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(pageWidth / 4, pageHeight - 20, pageWidth / 4, pageHeight - 10);
    doc.line(pageWidth / 2 + 1, pageHeight - 20, pageWidth / 2 + 1, pageHeight - 10);
    doc.line(pageWidth - pageWidth / 4, pageHeight - 20, pageWidth - pageWidth / 4, pageHeight - 10);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 8, pageHeight - 20, 3, 3, undefined, 'FAST');
    doc.addImage(globeIcon, 'png', pageWidth / 4 + 5, pageHeight - 20, 3, 3, undefined, 'FAST');
    doc.addImage(mailIcon, 'png', pageWidth / 2 + 6, pageHeight - 20, 4, 3, undefined, 'FAST');
    doc.addImage(phoneIcon, 'png', pageWidth - pageWidth / 4 + 6, pageHeight - 20, 3, 3, undefined, 'FAST');
    doc.text('Address', 13, pageHeight - 17.5);
    doc.text('Online', pageWidth / 4 + 10, pageHeight - 17.5);
    doc.text('Email', pageWidth / 2 + 12, pageHeight - 17.5);
    doc.text('Phone', pageWidth - pageWidth / 4 + 11, pageHeight - 17.5);

    doc.setFontSize(8);
    doc.setTextColor('#171C26');
    doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 8, pageHeight - 13);
    doc.text(`${city_id?.name}, ${state_id?.name} ${zip}`, 8, pageHeight - 9, { maxWidth: 45 });

    if (show_website === 1) {
      doc.text(`${website || '-'}`, pageWidth / 4 + 5, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 4 + 5, pageHeight - 13);
    }
    if (show_billing) {
      doc.text(`${receivable_email || '-'}`, pageWidth / 2 + 6, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 2 + 6, pageHeight - 13);
    }
    doc.text(`${phone || '-'}`, pageWidth - pageWidth / 4 + 6, pageHeight - 13);

    // Summary section
    if (payment_method.length > 2 && page === 1) {
      doc.setFont('Inter', 'normal', 700);
      doc.text('Payable to:', 125, page !== 1 ? 16 : pageHeight - 72);

      doc.setFont('Inter', 'normal', 400);
      if (payable_to === 'factoring_company') {
        doc.text(`${factoring_company}`, 145, page !== 1 ? 16 : pageHeight - 72);
        doc.text(
          `${address_line1}${address_line2 ? `, ${address_line2}` : ''}`,
          145,
          page !== 1 ? 20 : pageHeight - 68
        );
        doc.text(`${cityA?.name}, ${stateA?.name}, ${zipA}`, 145, page !== 1 ? 24 : pageHeight - 64);
        doc.text(`${country?.sortname === 'US' ? 'USA' : country?.sortname}`, 145, page !== 1 ? 145 : pageHeight - 60);
      } else {
        doc.text(`${company_name}`, 145, page !== 1 ? 16 : pageHeight - 72);
        doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 145, page !== 1 ? 20 : pageHeight - 68);
        doc.text(`${city_id?.name}, ${state_id?.name}, ${zip}`, 145, page !== 1 ? 24 : pageHeight - 64);
        doc.text(
          `${country_id?.sortname === 'US' ? 'USA' : country_id?.sortname}`,
          145,
          page !== 1 ? 145 : pageHeight - 60
        );
      }
    } else if (payment_method.length === 2 && page === 1) {
      doc.setFont('Inter', 'normal', 700);
      doc.text('Payable to:', 8, page !== 1 ? 16 : pageHeight - 66);

      doc.setFont('Inter', 'normal', 400);
      if (payable_to === 'factoring_company') {
        doc.text(`${factoring_company}`, 28, page !== 1 ? 16 : pageHeight - 66);
        doc.text(`${address_line1}${address_line2 ? `, ${address_line2}` : ''}`, 28, page !== 1 ? 20 : pageHeight - 62);
        doc.text(`${cityA?.name}, ${stateA?.name}, ${zipA}`, 28, page !== 1 ? 24 : pageHeight - 58);
        doc.text(`${country?.sortname === 'US' ? 'USA' : country?.sortname}`, 28, page !== 1 ? 28 : pageHeight - 54);
      } else {
        doc.text(`${company_name}`, 28, page !== 1 ? 16 : pageHeight - 66);
        doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 28, page !== 1 ? 20 : pageHeight - 62);
        doc.text(`${city_id?.name}, ${state_id?.name}, ${zip}`, 28, page !== 1 ? 24 : pageHeight - 58);
        doc.text(
          `${country_id?.sortname === 'US' ? 'USA' : country_id?.sortname}`,
          28,
          page !== 1 ? 28 : pageHeight - 54
        );
      }
    } else {
      doc.setFont('Inter', 'normal', 700);
      doc.text('Payable to:', 8, page !== 1 ? 16 : pageHeight - 55);

      doc.setFont('Inter', 'normal', 400);
      if (payable_to === 'factoring_company') {
        doc.text(`${factoring_company}`, 28, page !== 1 ? 16 : pageHeight - 55);
        doc.text(`${address_line1}${address_line2 ? `, ${address_line2}` : ''}`, 28, page !== 1 ? 20 : pageHeight - 51);
        doc.text(`${cityA?.name}, ${stateA?.name}, ${zipA}`, 28, page !== 1 ? 24 : pageHeight - 47);
        doc.text(`${country?.sortname === 'US' ? 'USA' : country?.sortname}`, 28, page !== 1 ? 28 : pageHeight - 43);
      } else {
        doc.text(`${company_name}`, 28, page !== 1 ? 16 : pageHeight - 55);
        doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 28, page !== 1 ? 20 : pageHeight - 51);
        doc.text(`${city_id?.name}, ${state_id?.name}, ${zip}`, 28, page !== 1 ? 24 : pageHeight - 47);
        doc.text(
          `${country_id?.sortname === 'US' ? 'USA' : country_id?.sortname}`,
          28,
          page !== 1 ? 28 : pageHeight - 43
        );
      }
    }

    // Right side
    doc.setFillColor(209, 41, 83);
    doc.rect(114, page !== 1 ? 14 : pageHeight - 59, 94, 6, 'F');

    doc.setFontSize(8);
    doc.setFont('Inter', 'normal', 700);
    doc.setTextColor('#FFFFFF');
    doc.text('Total Due', 125, page !== 1 ? 18 : pageHeight - 55);
    doc.text('Payment Due Date', 175, page !== 1 ? 18 : pageHeight - 55);

    doc.setTextColor('#000000');
    doc.text(`${currency}${formatAmount(total_amount)}`, 125, page !== 1 ? 24.5 : pageHeight - 48.5);
    doc.text(`${dueDate ? formatDate(dueDate) : '-'}`, 180, page !== 1 ? 24.5 : pageHeight - 48.5);

    doc.setDrawColor(188, 194, 206);
    doc.setLineDash(0);
    doc.line(125, page !== 1 ? 26 : pageHeight - 47, pageWidth - 8, page !== 1 ? 26 : pageHeight - 47);
    doc.setDrawColor(0, 0, 0);

    doc.text('Customer', 125, page !== 1 ? 30 : pageHeight - 43);
    doc.text('Invoice Number', 125, page !== 1 ? 34 : pageHeight - 39);
    doc.text('Amount Enclosed', 125, page !== 1 ? 38 : pageHeight - 35);

    doc.setFont('Inter', 'normal', 400);
    doc.text(customer_company_name, 160, page !== 1 ? 30 : pageHeight - 43);
    doc.text(invoiceId, 160, page !== 1 ? 34 : pageHeight - 39);
    doc.text('(if by Check)', 125, page !== 1 ? 42 : pageHeight - 31);
    doc.text('Enter the amount you are paying above', 155, page !== 1 ? 43 : pageHeight - 30);
    doc.line(154, page !== 1 ? 39.5 : pageHeight - 33.5, pageWidth - 8, page !== 1 ? 39.5 : pageHeight - 33.5);

    doc.setDrawColor(0, 0, 0);
    doc.setLineDash([3, 3], 0);

    doc.line(
      8,
      page !== 1 ? 10 : getDashedLineY(payment_method.length),
      pageWidth - 8,
      page !== 1 ? 10 : getDashedLineY(payment_method.length)
    );
    doc.line(
      8,
      page !== 1 ? 10.1 : getDashedLineY(payment_method.length) + 0.1,
      pageWidth - 8,
      page !== 1 ? 10.1 : getDashedLineY(payment_method.length) + 0.1
    );

    // Left side
    generatePaymentMethods({
      doc,
      page,
      pageHeight,
      instructions,
      payment_method,
      stripePaymentLink,
    });
  }
};

export const generatePDF = ({
  accountingSettings,
  companyProfile,
  instructions,
  shipmentData,
  invoiceId,
  shipmentCharges,
  chargeTypes,
  billDate = null,
  dueDate = null,
  payable_to = null,
  email_to = null,
  send_email = null,
  payment_method = null,
  stripePaymentLink,
  currency,
  formatDate,
  formatDateTime,
}) => {
  if (!accountingSettings || !companyProfile) {
    return null;
  }

  const { address1, address2, city_id, state_id, country_id, zip, company_logo, company_name } = companyProfile || {};

  const {
    company_name: customer_company_name,
    address1: customer_address1,
    address2: customer_address2,
    city: customer_city,
    state: customer_state,
    country: customer_country,
    zipcode: customer_zip,
    customer_payment_term,
  } = shipmentData.billing_customer || {};

  const { shipment_id, shipment_stops, shipment_notes } = shipmentData.shipment || {};

  const shippersAndConsignees = shipment_stops
    .filter(
      (stop) =>
        Number(stop.bill_type) === 1 && (Number(stop.stop_point_type) === 1 || Number(stop.stop_point_type) === 2)
    )
    .map((el) => {
      if (Number(el.stop_point_type) === 1) {
        return { ...el, type: 'Shipper' };
      }
      return { ...el, type: 'Consignee' };
    });

  const pickupStops = shippersAndConsignees.filter((stop) => Number(stop.stop_point_type) === 1);

  const doc = new jsPDF({ format: 'letter', compress: true });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.addImage(`${company_logo}?cache=${Math.random()}`, 'JPEG', 8, 8, 60, 12, 'Logo', 'FAST');

  doc.setFontSize(22);
  doc.setTextColor('#000000');
  doc.setFont('Inter', 'normal', 700);
  doc.text('Invoice', 8, 30);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);

  doc.text(`${customer_company_name}`, 8, 38);
  if (send_email && email_to?.length && payable_to !== 'factoring_company') {
    const contactNames = email_to.map((contact) => (contact.contact_name ? ` ${contact.contact_name}` : ' '));

    doc.text(`Attention: ${contactNames}`, 8, 42);
  }
  doc.text(
    `${customer_address1}${customer_address2 ? `, ${customer_address2}` : ''}`,
    8,
    send_email && email_to?.length && payable_to !== 'factoring_company' ? 46 : 42
  );
  doc.text(
    `${customer_city?.name}, ${customer_state?.name || ''} ${customer_zip}`,
    8,
    send_email && email_to?.length && payable_to !== 'factoring_company' ? 50 : 46
  );
  doc.text(
    `${customer_country?.sortname === 'US' ? 'USA' : customer_country?.sortname || ''}`,
    8,
    send_email && email_to?.length && payable_to !== 'factoring_company' ? 54 : 50
  );

  doc.setFont('Inter', 'normal', 700);
  doc.text('Invoice Number', pageWidth - 80, 16);
  doc.text('Invoice Date', pageWidth - 80, 24);
  doc.text('Shipment ID', pageWidth - 80, 32);
  doc.text('Reference', pageWidth - 80, 40);
  doc.text('Terms', pageWidth - 80, shipmentData.reference_id?.length > 18 ? 52 : 48);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${shipmentData?.invoiceId || invoiceId}`, pageWidth - 80, 19.5);
  doc.text(`${billDate ? moment(billDate).format('MMM D, YYYY') : '-'}`, pageWidth - 80, 27.5);
  doc.text(`${shipment_id}`, pageWidth - 80, 35.5);
  doc.text(`${shipmentData.reference_id}`, pageWidth - 80, 43.5, { maxWidth: 25 });
  doc.text(
    getPaymentTermTitle(customer_payment_term?.pay_term_types),
    pageWidth - 80,
    shipmentData.reference_id?.length > 18 ? 55.5 : 51.5
  );

  doc.addImage(
    getBarcode(`${shipmentData?.invoiceId || invoiceId}`, true),
    'JPEG',
    pageWidth - 42,
    8,
    35,
    15,
    undefined,
    'FAST'
  );

  doc.text(`${company_name}`, pageWidth - 42, 30);
  doc.text(`${address1}${address2 ? ',' : ''}`, pageWidth - 42, 33);
  doc.text(`${address2 ? `${address2}` : ''}`, pageWidth - 42, 36);
  doc.text(
    `${city_id?.name}, ${state_id?.short_name || state_id?.name || ''} ${zip}`,
    pageWidth - 42,
    address2 ? 39 : 36,
    { maxWidth: 40 }
  );

  const addressLineLength =
    Number(city_id?.name.length) +
    Number(state_id?.short_name?.length || state_id?.name?.length || 0) +
    Number(zip?.length);

  if (address2) {
    doc.text(
      `${country_id.sortname === 'US' ? 'USA' : country_id.sortname}`,
      pageWidth - 42,
      addressLineLength > 24 ? 45 : 42
    );
  } else {
    doc.text(
      `${country_id.sortname === 'US' ? 'USA' : country_id.sortname}`,
      pageWidth - 42,
      addressLineLength > 24 ? 42 : 39
    );
  }

  const detailsEnd = generateShipmentDetails(doc, pageWidth, shippersAndConsignees, formatDateTime);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 700);

  doc.text('Description', 8, detailsEnd + 5);
  doc.text('Quantity', 132, detailsEnd + 5);
  doc.text('Rate', 162, detailsEnd + 5);
  doc.text(`Amount ${currencies[currency]}`, 188, detailsEnd + 5);
  doc.line(8, detailsEnd + 6.5, pageWidth - 8, detailsEnd + 6.5);

  const { total_amount, chargesEnd } = generateShipmentCharges(
    doc,
    pageWidth,
    detailsEnd + 6,
    shipmentCharges,
    chargeTypes,
    pickupStops,
    shipment_notes,
    customer_payment_term?.invoice_note,
    accountingSettings,
    currency
  );

  if (chargesEnd > getDashedLineY(payment_method.length) + 25) {
    doc.addPage();
  }

  const totalPages = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < totalPages; i++) {
    doc.setPage(i);
    const page = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter({
      doc,
      accountingSettings,
      companyProfile,
      instructions,
      dueDate,
      payable_to,
      payment_method,
      stripePaymentLink,
      customer_company_name,
      invoiceId: shipmentData?.invoiceId || invoiceId,
      page,
      totalPages,
      total_amount,
      currency,
      formatDate,
    });
  }

  const url = doc.output('bloburl');
  const blob = doc.output('blob');

  return { blob, url };
};
