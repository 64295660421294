import React, { useState, useEffect, Fragment } from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Modal, Box } from '@mui/material';
import uuid from 'react-uuid';
import Fade from '@mui/material/Fade';
import { Toast, Modal as BootstrapModal } from 'react-bootstrap';
import Popover from '@mui/material/Popover';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import subRight from 'assets/icons/drivers/sub-right.png';
import { Icons, PetrolIcon } from 'assets/icons';

import { ReactComponent as IdleIcon } from 'assets/icons/idle.svg';
import { ReactComponent as ShipmentIcon } from 'assets/sidemanu/shipment.svg';
import { ReactComponent as FaultCodeIcon } from 'assets/icons/vehicles/fault-code.svg';
import { ReactComponent as ProfileAlert } from 'assets/icons/vehicles/profile-alert.svg';
import Chip from 'common/Chip';
import StopsWay from 'components/StopsWay';
import UserInfo from 'components/UserInfo';
import { Typography } from 'components/Typography';
import Hos from 'components/TableShipments/helpers/Hos';
import { getUTCTimeDifference } from 'components/TableShipments/helpers/constants';
import UnHooked from 'pages/landing/Equipment/DialogueModals/AddHook/UnHooked';
import AddDriver from 'pages/landing/Equipment/DialogueModals/AddDriver/AddDriver';
import AlertMsg from 'pages/landing/Equipment/DialogueModals/ProfileAlert/AlertIcon';
import FaultCode from 'pages/landing/Equipment/DialogueModals/ProfileAlert/FaultCode';
import DriverInfo from 'pages/landing/Equipment/DialogueModals/DriverInfo/DriverInfo';
import RemoveDriver from 'pages/landing/Equipment/DialogueModals/DriverInfo/RemoveDriver';
import AddDriverSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddDriverMsg';
import AddHookedError from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddHookedError';
import UnhookedMsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/UnhookedTrailerMsg';
import AlreadyReserved from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AlreadyReserved';
import RemovedDriverMsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/RemoveDriverSuccess';
import AddHookedSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/HookedTrailerSuccuss';
import ReserveEquipmentMsg from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/ReservedEquipmentMsg';
import { formatSeconds } from 'utils/helpers';
import useDateFormat from 'hooks/useDateFormat';
import { formatNumber, motionColors, palette, TRUCK_TYPES } from 'utils/constants';
import HookTrailer from 'componentsV2/Equipment/HookTrailer';
import { PusherJs } from 'common/pusher';
import styles from './VehicleContainer.module.css';
import CustomState from './MapComponents/CustomState';
import VehicleSkeleton from './MapComponents/VehicleSkeleton';

const VehicleContainer = ({
  filterStatus,
  vehicleData,
  setVehicleData,
  AddStopPointData,
  stopPointsData,
  routeCoords,
  moreData,
  page,
  vehicleLoading,
  stopPointCoords,
  firstCoords,
}) => {
  const { formatDate, formatDateTime, formatTime, convertToCustomerTime } = useDateFormat();
  const [id, setId] = useState();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [showA, setShowA] = useState(false);
  const [alreadyReserveMsg, setAlreadyReserveMsg] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [driverPopupData] = useState({ data: [] });
  const [delDriver, setDeleteDriver] = useState(false);
  const [removeDriverData, setRemoveDriverData] = useState({ data: [] });
  const [removeDrivermsg, setRemovedDrivermsg] = useState(false);
  const [addDriver, setAddDriver] = useState(false);
  const [driverAddedmsg, setDriverAddedmsg] = useState(false);
  const [hook, setHook] = useState(false);
  const [success, setSuccess] = useState(false);
  const [Hookederr, setHookederr] = useState(false);
  const [getHookederr, setGetHookedErr] = useState();
  const [unHookedTrailer, setUnHookedTrailer] = useState(false);
  const [unHookedData, setUnhookedData] = useState({ data: [] });
  const [unHookedMsg, setUnhookedMsg] = useState(false);
  const [profileAlert, setProfileAlert] = useState(null);
  const [faultCode, setFaultCode] = useState(null);
  const [faultCodeData, setFaultCodeData] = useState();
  const [alertData, setAlertData] = useState();
  const [alertID, setAlertID] = useState();
  const ProfileAlertOpen = Boolean(profileAlert);
  const faultcodeOpen = Boolean(faultCode);
  const FaultCodeContent = faultcodeOpen ? 'simple-popover' : undefined;
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;
  const navigate = useNavigate();

  const FaultCodehandleClick = (e, faultCode) => {
    setFaultCodeData(faultCode);
    setFaultCode(e.currentTarget);
  };
  const FaultCodehandleClose = () => {
    setFaultCode(null);
  };
  const ProfileAlerthandleClick = (e, alerts, id) => {
    setProfileAlert(e.currentTarget);
    setAlertData(alerts);
    setAlertID(id);
  };

  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };

  const toggleCloseA = () => setShowA(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const AlreadyReserveMsgModalClose = () => setAlreadyReserveMsg(false);

  const UnHookedModalShow = (id, eq_id, length, unit, title) => {
    setUnhookedData([id, eq_id, length, unit, title]);
    setUnHookedTrailer(true);
  };

  const UnHookedModalClose = () => {
    setUnHookedTrailer(false);
  };

  const deleteDriverModal = (id, rowId, fname, lname, Equipment_id, Equipment_title, status) => {
    handleClose();
    setDeleteDriver(true);
    setRemoveDriverData([id, rowId, fname, lname, Equipment_id, Equipment_title, status]);
  };

  const deleteDriverModalClose = () => {
    setDeleteDriver(false);
  };

  const RemoveDriverModal = (id, res) => {
    if (vehicleData && vehicleData.data && vehicleData.data.length && res.id) {
      const updatedVehicleData = vehicleData.data.map((item) => {
        if (item.id === res.id) {
          return {
            ...res,
          };
        }
        return item;
      });
      setVehicleData((prevState) => ({
        ...prevState,
        data: updatedVehicleData,
      }));
    }
    setRemovedDrivermsg(true);
  };

  const addDriverModalClose = () => setAddDriver(false);

  const addDriverModalShow = (vehicle) => {
    setSelectedVehicle(vehicle);
    setAddDriver(true);
  };

  const onAddDriverSuccess = (res) => {
    if (vehicleData?.data?.length && res?.id) {
      const updatedVehicleData = vehicleData.data.map((item) => (item.id === res.id ? { ...res } : item));

      setVehicleData((prevState) => ({
        ...prevState,
        data: updatedVehicleData,
      }));
    }
  };

  const DriversAddedmsgModal = () => {
    setDriverAddedmsg(true);
    setTimeout(() => {
      DriversAddedmsgModalClose();
    }, 2000);
  };

  const hookModalShow = (id) => {
    setId(id);
    setHook(true);
  };

  const SuccessShow = (res) => {
    if (vehicleData?.data?.length && res?.id) {
      const updatedVehicleData = vehicleData.data.map((item) => (item.id === res.id ? { ...res } : item));

      setVehicleData((prevState) => ({
        ...prevState,
        data: updatedVehicleData,
      }));
    }
  };

  const HookedErrShow = () => {
    setHookederr(true);
  };

  const HookedErrClose = () => {
    setHookederr(false);
  };

  const GetHookedError = (err) => {
    setGetHookedErr(err);
  };

  const UnHoookedMsgShow = (res) => {
    if (vehicleData && vehicleData.data && vehicleData.data.length && res.id) {
      const updatedVehicleData = vehicleData.data.map((item) => {
        if (item.id === res.id) {
          return {
            ...res,
          };
        }
        return item;
      });
      setVehicleData((prevState) => ({
        ...prevState,
        data: updatedVehicleData,
      }));
    }
    setUnhookedMsg(true);
  };

  const UnhookedMsgClose = () => {
    setUnhookedMsg(false);
  };

  const SuccessClose = () => setSuccess(false);
  const DriversAddedmsgModalClose = () => setDriverAddedmsg(false);
  const RemoveDriverModalClose = () => setRemovedDrivermsg(false);
  const user = JSON.parse(localStorage.getItem('user'));
  const open = Boolean(anchorEl);

  const Popup = open ? 'simple-popover' : undefined;

  const EquipmentStatusTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow:
        '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
  }));
  const EquipmentStatusContentToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow:
        '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      backgroundColor: '#fff',
      color: 'black',
      padding: '10px',
    },
  }));

  useEffect(() => {
    const dataRaw = localStorage.getItem('user');
    const data = JSON.parse(dataRaw);
    const channel = PusherJs.subscribe(`private-equipmentList-Update.${data?.customer?.dot}`);

    channel.bind('equipment-event', (data) => {
      setVehicleData((prevState) => {
        if (prevState && prevState.data && prevState.data.length) {
          const updatedVehicleData = prevState.data.map((item) => {
            if (item.id === data.message.data.id) {
              return {
                ...item,
                ...data.message.data,
                drivers: data?.message?.data?.drivers ? data.message.data.drivers : null,
              };
            }
            return item;
          });

          return {
            ...prevState,
            data: updatedVehicleData,
          };
        }

        return prevState;
      });
    });

    return () => {
      channel.unbind('equipment-event');
    };
  }, []);

  return (
    <>
      <div className='mt-1' style={{ position: 'relative' }} id='scrollableDiv'>
        <InfiniteScroll
          className={styles.InfiniteScrollDiv}
          dataLength={vehicleData?.data?.length}
          next={moreData}
          hasMore={page <= vehicleData?.last_page}
          height='calc(100vh - 350px)'
          pullDownToRefreshThreshold={50}
          loader={
            vehicleData?.data?.length === 0 || vehicleLoading ? (
              ''
            ) : (
              <div>
                {Array.from({ length: 5 }).map((_, index) => (
                  <VehicleSkeleton key={_ || index} />
                ))}
              </div>
            )
          }
        >
          {vehicleLoading ? (
            <div>
              {Array.from({ length: 5 }).map((_, index) => (
                <VehicleSkeleton key={_ || index} />
              ))}
            </div>
          ) : vehicleData?.data?.length ? (
            vehicleData?.data?.map((data) => {
              const flag =
                data?.drivers?.length === 2
                  ? data?.drivers?.filter((ele) => ele.status_data?.driver_status === 'Not Available').length >= 2
                  : '' || data?.drivers?.filter((ele) => ele.status_data?.driver_status === 'Not Available').length > 0;

              const statusColor = data?.equipment_status?.color;
              const title = data?.equipment_status?.equipment_status;

              const {
                driver_from_city: city,
                driver_from_state: state,
                created_at,
                loaded_miles,
                empty_miles,
                total_miles_remain,
                shipment_stops,
                status,
              } = data?.shipment || {};
              const stops = shipment_stops || [];
              const statusId = status?.id;
              const miles = +loaded_miles + +empty_miles;
              let formula = 100 - (+total_miles_remain * 100) / miles;
              if (formula < 0) {
                formula = 0;
              }

              let milesLength = empty_miles !== undefined && empty_miles !== null ? +empty_miles : 0;

              for (let i = 0; i < stops.length; i++) {
                const stop = stops[i];
                const { distance_to_next_stop } = stop || {};

                if (stops.length - 1 !== i) {
                  if (distance_to_next_stop === null) {
                    milesLength = null;
                    break;
                  }
                  milesLength += +distance_to_next_stop;
                }
              }

              const dataList = stops.map((el, index) => {
                const {
                  stop_point,
                  arrival_date,
                  scheduled_date,
                  distance_to_next_stop,
                  from,
                  to,
                  reported_dock_status,
                  departure_date,
                } = el || {};
                const date = arrival_date !== null ? arrival_date : scheduled_date;

                const datePlusHour =
                  from && to
                    ? `${formatTime(`${date} ${from}`)} - ${formatTime(`${date} ${to}`)}`
                    : from
                    ? formatTime(`${date} ${from}`)
                    : date;

                const title = arrival_date
                  ? `${stop_point.city?.name},${stop_point.state?.short_name} 
                                      (${formatDateTime(arrival_date)} )`
                  : `${stop_point.city?.name},${stop_point.state?.short_name} 
                                      (${formatDate(date)}  , ${datePlusHour})`;

                const color = +statusId === 4 ? palette.red500 : +statusId === 1 ? palette.gray500 : palette.indigo500;
                const deadHeadResult = (100 / milesLength) * empty_miles;
                return index === stops.length - 1
                  ? {
                      id: el?.id,
                      percent: 100,
                      color,
                      title,
                      tick: !!el?.arrival_date,
                      reported_dock_status,
                      arrival_date,
                      departure_date,
                    }
                  : {
                      id: el?.id,
                      percent:
                        milesLength === null
                          ? (100 / stops.length) * (index + 1)
                          : index === 0
                          ? deadHeadResult < 1
                            ? 1
                            : deadHeadResult
                          : (100 / milesLength) * distance_to_next_stop,
                      color,
                      title,
                      tick: !!el?.arrival_date,
                      reported_dock_status,
                      departure_date,
                      arrival_date,
                    };
              });

              dataList.unshift({
                id: uuid(),
                percent: 0,
                tick: !![3, 4, 5, 7].includes(Number(statusId)),
                title: `${city}, ${state} (${convertToCustomerTime(created_at)})`,
              });

              return (
                <div className={styles.MapCardMainDiv} key={data.id}>
                  {data?.location_distance && (
                    <span className='d-flex'>
                      <span className='location-distance me-1'>{Number(data?.location_distance || 0).toFixed()}</span>
                      <span className='location-distance'> miles away </span>
                    </span>
                  )}
                  <div
                    className={styles.MapVehicalContentCard}
                    onClick={() => navigate(`/equipment-profile/vehicle/${data.id}`)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className='d-flex justify-content-between align-item-center'>
                      <div className='d-flex justify-content-center'>
                        <div className='flex-row d-flex align-items-center'>
                          <div className='d-flex mt-0 p-0'>
                            <span className={styles.MapCardContentText}>
                              {data?.equipment_id} {data?.equipment_type?.title}{' '}
                              {!!data?.truck_type && <span> ({TRUCK_TYPES[data.truck_type]})</span>}
                            </span>
                            {data?.expiring_equipment_alerts ? (
                              <div
                                className='ms-1 me-1'
                                onClick={(e) => e.stopPropagation()}
                                style={{ position: 'relative' }}
                              >
                                <ProfileAlert
                                  onClick={(e) => ProfileAlerthandleClick(e, data?.expiring_equipment_alerts, data?.id)}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                            <div className='ms-1 me-1'>
                              {!!data?.equipment_connected_services_faultcode && (
                                <FaultCodeIcon
                                  onClick={(e) => {
                                    FaultCodehandleClick(e, data?.equipment_connected_services_faultcode);
                                    e.stopPropagation();
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='d-flex align-items-center'>
                        {data?.equipment_connected_services_device?.fuel_percent ? (
                          <div className='me-2'>
                            <div className='d-flex align-item-flex-start'>
                              <div className=' me-2'>{PetrolIcon}</div>
                              <div className='petrolPersent'>
                                <div
                                  style={{
                                    width: `${data?.equipment_connected_services_device?.fuel_percent}%`,
                                    backgroundColor: '#81D6AB',
                                    borderRadius: '4px',
                                  }}
                                >
                                  <span style={{ textAlign: 'center' }} className='ms-2'>
                                    {data?.equipment_connected_services_device?.fuel_percent}%
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : data?.location_target === 'Driver' ? (
                          <div className='mb-2 ms-2 me-2'>
                            <span />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className='ms-1 me-2'>
                          {data?.equipment_connected_services_device?.engine_state === 'Idle' ? (
                            <IdleIcon />
                          ) : data?.equipment_connected_services_device?.engine_state === 'On' ? (
                            <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                                fill='#38A06C'
                              />
                            </svg>
                          ) : data?.equipment_connected_services_device?.engine_state === 'Off' ? (
                            <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                                fill='#5A6376'
                              />
                            </svg>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='d-flex me-2'>
                          {data?.location_target === 'Driver' ? (
                            <span className='d-flex align-items-center me-2'>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  fillRule='evenodd'
                                  clipRule='evenodd'
                                  d='M13.0001 3C13 1.89544 12.1046 1.00001 11.0001 1.00001L5.00004 1C3.89547 1 3.00004 1.89543 3.00003 3L3 13C3 14.1046 3.89543 15 5 15H11.0001C12.1047 15 13.0001 14.1046 13.0001 13L13.0001 3ZM11.5 11.5C11.5 11.7761 11.2761 12 11 12H4.99999C4.72385 12 4.49999 11.7761 4.49999 11.5L4.5 3C4.5 2.72386 4.72386 2.5 5 2.5L6 2.5L6.15532 3.12127C6.21096 3.34385 6.41095 3.5 6.64039 3.5H9.35961C9.58905 3.5 9.78904 3.34385 9.84468 3.12127L10 2.5L11 2.5C11.2761 2.5 11.5 2.72386 11.5 3L11.5 11.5ZM7.24462 13.5C7.24462 13.9074 7.58698 14.25 7.99462 14.25C8.402 14.25 8.74435 13.9238 8.74462 13.5C8.74462 13.0924 8.402 12.75 7.99462 12.75C7.58698 12.75 7.24462 13.0924 7.24462 13.5Z'
                                  fill='#38A06C'
                                />
                              </svg>
                            </span>
                          ) : (
                            ''
                          )}
                          {data?.location_target === 'Equipment' ? (
                            <div className='d-flex me-2'>
                              <svg
                                width='16'
                                height='16'
                                viewBox='0 0 16 16'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M9.20022 2.25556C9.26833 2.11572 9.40323 2.01964 9.55849 2.00042C9.5731 1.9998 9.58789 1.99989 9.60261 2.00051C11.8881 2.00051 13.75 3.85041 13.75 6.1212C13.7616 6.24928 13.7185 6.37624 13.6312 6.4712C13.5438 6.56606 13.4203 6.62013 13.2909 6.62013C13.1615 6.62013 13.038 6.56606 12.9507 6.4712C12.8633 6.37624 12.8202 6.24928 12.8317 6.1212C12.8317 4.34522 11.39 2.91273 9.60252 2.91273C9.44623 2.90854 9.3027 2.82574 9.22141 2.69296C9.14013 2.56018 9.13211 2.3955 9.20022 2.25556Z'
                                  fill='#868FA0'
                                />
                                <path
                                  d='M9.20301 4.05788C9.28543 3.916 9.4377 3.8286 9.60252 3.8286C10.8699 3.82868 11.91 4.86194 11.9099 6.12107C11.9099 6.28482 11.822 6.43611 11.6792 6.51799C11.5365 6.59986 11.3606 6.59986 11.2179 6.51799C11.075 6.43611 10.9872 6.28482 10.9872 6.12107C10.9872 5.35688 10.3717 4.74541 9.60252 4.74541C9.4377 4.74541 9.28542 4.65812 9.20301 4.51624C9.1206 4.37446 9.1206 4.19966 9.20301 4.05788Z'
                                  fill='#868FA0'
                                />
                                <path
                                  d='M9.83429 6.9169C9.83429 7.50062 9.358 7.97382 8.77046 7.97382C8.18292 7.97382 7.70663 7.50062 7.70663 6.9169C7.70663 6.33318 8.18292 5.85999 8.77046 5.85999C9.358 5.85999 9.83429 6.33318 9.83429 6.9169Z'
                                  fill='#868FA0'
                                />
                                <path
                                  d='M3.44775 6.29748C3.63558 6.11117 3.89107 6.00436 4.15588 6.00052C4.43149 5.99668 4.69648 6.10398 4.89136 6.29752L9.21744 10.5955C9.61285 10.9884 9.61269 11.6364 9.21744 12.0297C8.45321 12.7901 7.4155 13.2193 6.33293 13.2196C5.86689 13.2198 5.41829 13.1411 5 12.9963V13.2504C5 13.5945 4.76832 13.8844 4.45247 13.9728C4.39798 13.9907 4.33975 14.0004 4.27925 14.0004L4.26574 14.0003L4.25 14.0004C3.83579 14.0004 3.5 13.6646 3.5 13.2504V12.077C2.73023 11.3378 2.24961 10.303 2.25 9.16323C2.25031 8.0877 2.6823 7.05674 3.44775 6.29748Z'
                                  fill='#868FA0'
                                />
                              </svg>
                            </div>
                          ) : data?.location_target === null ? (
                            <svg
                              width='16'
                              height='16'
                              viewBox='0 0 16 16'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <path
                                d='M4.79114 3.72156C4.49577 3.42619 4.01689 3.42619 3.72152 3.72156C3.42616 4.01693 3.42616 4.49581 3.72152 4.79117L6.93037 8.00002L3.72155 11.2088C3.42619 11.5042 3.42619 11.9831 3.72155 12.2784C4.01692 12.5738 4.4958 12.5738 4.79117 12.2784L7.99998 9.06963L11.2088 12.2785C11.5042 12.5738 11.9831 12.5738 12.2784 12.2785C12.5738 11.9831 12.5738 11.5042 12.2784 11.2089L9.06959 8.00002L12.2785 4.79114C12.5738 4.49577 12.5738 4.01689 12.2785 3.72152C11.9831 3.42616 11.5042 3.42616 11.2089 3.72152L7.99998 6.9304L4.79114 3.72156Z'
                                fill='#DC4067'
                              />
                            </svg>
                          ) : (
                            ''
                          )}
                        </div>
                        <div>
                          {data?.equipment_connected_services_device?.speed_mph ? (
                            <div className='equipment-vehicle-columns'>
                              <p
                                className='equipment-vehicle-columns-text m-0'
                                // style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                <span
                                  className={
                                    data?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] !==
                                    '0'
                                      ? 'speedMPH'
                                      : 'speedMPHgray'
                                  }
                                >
                                  {Number(data?.equipment_connected_services_device?.speed_mph).toFixed(1) <= 0
                                    ? 0
                                    : Number(data?.equipment_connected_services_device?.speed_mph).toFixed()}

                                  <span className='ms-1'>MPH </span>
                                </span>
                              </p>
                            </div>
                          ) : data?.location_target === 'Driver' ? (
                            data?.drivers &&
                            data?.drivers[0]?.driver_gps &&
                            data?.drivers[0]?.driver_gps?.mph !== null ? (
                              <div
                                className={
                                  data?.drivers[0]?.driver_gps?.mph.toString().split('.')[0] !== '0'
                                    ? 'speedMPH'
                                    : 'speedMPHgray'
                                }
                              >
                                {data?.drivers[0]?.driver_gps?.mph.toString().split('.')[0]}
                                <span className='ms-1'>MPH </span>
                              </div>
                            ) : (
                              <div className='mb-2'>
                                <span />
                              </div>
                            )
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex ms-1 me-3'>
                      {!flag ? (
                        <span className='equipment_status me-2'>
                          <EquipmentStatusContentToolTip title={title} placement='bottom'>
                            <div style={{ width: 8, height: 8, backgroundColor: `${statusColor}` }} />
                          </EquipmentStatusContentToolTip>
                        </span>
                      ) : (
                        <span style={{ backgroundColor: '#F0F2F7', color: '#5A6376' }} className='equipment_status'>
                          <span>
                            <EquipmentStatusTooltip
                              title={`Driver is Not available Until ${formatDate(
                                data?.drivers?.map((v) => v.driver_not_available_until_date)[0]
                              )} ${data?.drivers?.map((v) => v.driver_not_available_until_time)[0]}. Note: ${
                                data?.drivers?.map((v) => v.note_not_available)[0]
                              }`}
                              placement='right'
                            >
                              <img src={Icons?.NotAssigned} alt='' />
                            </EquipmentStatusTooltip>
                          </span>
                        </span>
                      )}
                      {/* <span className={styles.MapCardBlueRactangle}></span> */}
                      <img className={styles.MapCardContentRightImg} src={subRight} alt='' />
                      <div className='hooked-to-column-wrapper fade-in-row ms-2'>
                        {data?.hook_to?.equipment_id ? (
                          <div
                            onClick={($event) => {
                              UnHookedModalShow(
                                data?.id,
                                data?.hook_to?.equipment_id,
                                data?.hook_to?.length?.length,
                                data?.hook_to?.length?.unit,
                                data?.hook_to?.equipment_type?.title
                              );
                              $event.stopPropagation();
                            }}
                          >
                            <span
                            // style={{ color: use(palette.dark800, palette.gray200) }}
                            >
                              {data?.hook_to?.equipment_id}
                            </span>
                            <span
                              className='hooked-numbers-sub ms-1'
                              // style={{ color: use(palette.dark800, palette.gray200) }}
                            >
                              {data?.hook_to?.length?.length}
                              <span
                              // className="ms-1"
                              // style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                {data?.hook_to?.length?.unit}
                              </span>
                              <span
                                className='ms-1'
                                // style={{ color: use(palette.dark800, palette.gray200) }}
                              >
                                {data?.hook_to?.equipment_type?.title}
                              </span>
                            </span>
                          </div>
                        ) : data.equipment_type?.vehicle_type_id !== 1 || +data.equipment_type.id === 1 ? (
                          <div className='row-style'>
                            <div className='add-driver'>
                              <div
                                // className=" add-driver-wrapper "
                                onClick={($event) => {
                                  $event.stopPropagation();
                                  hookModalShow(data?.id);
                                }}
                              >
                                <svg
                                  width='12'
                                  height='12'
                                  viewBox='0 0 12 12'
                                  fill='none'
                                  xmlns='http://www.w3.org/2000/svg'
                                >
                                  <path
                                    d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                                    fill='#4f5aed'
                                  />
                                </svg>
                                <span className='add-driver-content'>Hook</span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>-</>
                        )}
                      </div>
                    </div>

                    <div className='d-flex' />

                    <div className='d-flex'>
                      <div className='d-flex justify-content-center align-items-center'>
                        {data?.drivers &&
                          data?.drivers.map((d) => {
                            return (
                              <div className='d-flex flex-column' key={d.id}>
                                <div className='d-flex'>
                                  <UserInfo
                                    size='25px'
                                    statusSize='6px'
                                    data={{
                                      ...d,
                                      name: `${d.fname} ${d.lname}`,
                                      status: d.status_data?.driver_status,
                                      user_type: 'driver',
                                    }}
                                  />
                                  <span className='me-1'>
                                    {d?.certifications?.can_travel_usa === 1 && (
                                      <img src={Icons.US} alt='' style={{ height: '14px' }} />
                                    )}
                                  </span>
                                  <span className='me-1'>
                                    {d?.certifications?.can_travel_mexico === 1 && (
                                      <img src={Icons.Mexico} alt='' style={{ width: '20px' }} />
                                    )}
                                  </span>
                                  <span>
                                    {d?.certifications?.can_travel_canada === 1 && (
                                      <img src={Icons.Canada} alt='' style={{ height: '14px' }} />
                                    )}
                                  </span>
                                </div>
                                <Hos driver={d} />
                              </div>
                            );
                          })}
                        <div className='row-style'>
                          {!data?.drivers ? (
                            <div className='add-drivers'>
                              <div
                                className='d-flex ms-4'
                                onClick={($event) => {
                                  addDriverModalShow(data);
                                  $event.stopPropagation();
                                }}
                              >
                                <div className='add-driver-profile' style={{ border: '1px dashed #4F5AED' }}>
                                  <svg
                                    width='12'
                                    height='12'
                                    viewBox='0 0 12 12'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                  >
                                    <path
                                      d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                                      fill='#4f5aed'
                                    />
                                  </svg>
                                </div>
                                <span className='add-driver-content'>Add</span>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    {!!data?.length && (
                      <div className='d-flex flex-column gap-1'>
                        <Typography variant='s3' style={{ color: palette.dark800 }}>
                          {data.length.length * 12} x {data.width || '-'} x {data.height || '-'} in
                        </Typography>
                        {data.cargo_maximum_capacity_weight && (
                          <Typography variant='s3' style={{ color: palette.dark800 }}>
                            Payload {formatNumber(data.cargo_maximum_capacity_weight, 0)}{' '}
                            {data.cargo_capacity_measuring_unit}
                          </Typography>
                        )}
                      </div>
                    )}
                    {(data?.drivers && data?.drivers[0]?.driver_gps) ||
                    data?.equipment_gps?.location ||
                    data?.last_known_location ? (
                      <div className='d-flex'>
                        <div className='d-flex justify-content-center'>
                          <img
                            className={styles.MapCardContentMainImg}
                            style={{ marginTop: '10px' }}
                            src={Icons.Location}
                            alt=''
                          />
                          <div>
                            {data?.location_target === 'Driver' ? (
                              data?.location_target === 'Driver' && data?.drivers ? (
                                <div>
                                  <span
                                    className={styles['current-location']}
                                    style={{
                                      margin: 0,
                                    }}
                                  >
                                    {data?.drivers[0]?.driver_gps?.location
                                      ? data?.drivers[0]?.driver_gps?.location
                                      : data?.last_known_location}
                                  </span>
                                </div>
                              ) : (
                                <span
                                  className={styles['current-location']}
                                  style={{
                                    margin: '0px',
                                  }}
                                >
                                  {data?.last_known_location}
                                </span>
                              )
                            ) : data?.location_target === 'Equipment' ? (
                              <span
                                className={styles['current-location']}
                                style={{
                                  margin: '0px',
                                }}
                              >
                                {data?.equipment_gps?.location
                                  ? data?.equipment_gps?.location
                                  : data?.last_known_location}
                              </span>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <div>
                      {!!data?.next_available_location && (
                        <div className='equipment-vehicle-columns'>
                          <p className='current-location mb-0'>
                            <img className={styles.MapCardContentRightImg} src={subRight} alt='' />
                            <span
                              className='equipment-vehicle-columns-text me-2'
                              style={{ width: '60px', whiteSpace: 'normal' }}
                            >
                              {data.next_available_location}
                            </span>
                            <span
                              className='equipment-vehicle-columns-text'
                              style={{ width: '60px', whiteSpace: 'normal' }}
                            >
                              {formatDateTime(data?.next_available_date)}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className='d-flex align-items-center gap-2 mb-2'>
                      {data.shipment && data.shipment.shipment_id && (
                        <div
                          className='d-flex gap-2 align-items-center'
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(`/shipment/${data.shipment.shipment_id}/dispatch`);
                          }}
                        >
                          <ShipmentIcon />
                          <span className='equipment-vehicle-columns-text'>{data.shipment.shipment_id}</span>
                        </div>
                      )}
                      {data?.location_target === 'Equipment' && data?.equipment_gps?.motion?.motion ? (
                        <Chip
                          fontWeight={500}
                          fontSize='11px'
                          bgColor={motionColors[data?.equipment_gps.motion.motion]?.bgColor}
                          labelColor={motionColors[data?.equipment_gps.motion.motion]?.color}
                          style={{ textTransform: 'uppercase' }}
                          label={`${data?.equipment_gps?.motion?.motion} ${formatSeconds(
                            data?.equipment_gps?.motion?.duration
                          )}`}
                        />
                      ) : data?.location_target === 'Driver' &&
                        data?.drivers?.length &&
                        data?.drivers?.[0]?.driver_gps?.motion?.motion ? (
                        <Chip
                          fontWeight={500}
                          fontSize='11px'
                          bgColor={motionColors[data?.drivers?.[0]?.driver_gps?.motion?.motion]?.bgColor}
                          labelColor={motionColors[data?.drivers?.[0]?.driver_gps?.motion?.motion]?.color}
                          style={{ textTransform: 'uppercase' }}
                          label={`${data?.drivers?.[0]?.driver_gps?.motion?.motion} ${formatSeconds(
                            data?.drivers?.[0]?.driver_gps?.motion?.duration
                          )}`}
                        />
                      ) : (
                        <span>-</span>
                      )}
                    </div>

                    {data?.location_target === 'Equipment' && data?.equipment_gps?.motion?.motion ? (
                      <>
                        {!!data.shipment && filterStatus === 'on_shipment' && (
                          <div className='mb-3' style={{ marginTop: 12 }}>
                            <StopsWay
                              status_id={data.shipment?.status?.id}
                              mainColor={palette.indigo500}
                              dataList={dataList}
                              currentData={{
                                id: 14,
                                percent: +formula > 100 ? 100 : formula,
                                title: data?.equipment_gps?.location,
                                lastUpdate: data?.equipment_gps?.updated_at,
                                custom: data?.equipment_gps?.custom,
                              }}
                              styleContainer={{ width: `100%`, padding: '0 12px' }}
                            />
                          </div>
                        )}
                        <Typography variant='c3'>
                          Updated {getUTCTimeDifference(data?.equipment_gps?.motion?.updated_at)} ago
                        </Typography>
                      </>
                    ) : data?.location_target === 'Driver' &&
                      data?.drivers?.length &&
                      data?.drivers?.[0]?.driver_gps?.motion?.motion ? (
                      <>
                        {!!data.shipment && filterStatus === 'on_shipment' && (
                          <div className='mb-3' style={{ marginTop: 12 }}>
                            <StopsWay
                              status_id={data.shipment?.status?.id}
                              mainColor={palette.indigo500}
                              dataList={dataList}
                              currentData={{
                                id: 14,
                                percent: +formula > 100 ? 100 : formula,
                                title: data?.drivers?.[0]?.driver_gps?.location,
                                lastUpdate: data?.drivers?.[0]?.driver_gps?.updated_at,
                                custom: data?.drivers?.[0]?.driver_gps?.custom,
                              }}
                              styleContainer={{ width: `100%`, padding: '0 12px' }}
                            />
                          </div>
                        )}

                        <Typography variant='c3'>
                          Updated {getUTCTimeDifference(data?.drivers?.[0]?.driver_gps?.motion?.updated_at)} ago
                        </Typography>
                      </>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <span className={styles.MapCardBorderLine} />
                </div>
              );
            })
          ) : (
            <div>No records to display.</div>
          )}
        </InfiniteScroll>
        <Toast show={showA} onClose={toggleCloseA} className='reserve-equipment-toast'>
          <ReserveEquipmentMsg handleClose={toggleCloseA} userFname={user.first_name} userLname={user.last_name} />
        </Toast>
        <Toast
          show={alreadyReserveMsg}
          onClose={AlreadyReserveMsgModalClose}
          className='already-reserve-equipment-toast'
        >
          <div>
            <AlreadyReserved AlreadyReserveMsgModalClose={AlreadyReserveMsgModalClose} />
          </div>
        </Toast>
        <Popover
          id={Popup}
          anchorPosition={{ top: 200, left: 400 }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <DriverInfo
            driverData={driverPopupData}
            PopupHandleClose={handleClose}
            deleteDriverModal={deleteDriverModal}
          />
        </Popover>
        <Popover
          id={ProfileAlertContent}
          open={ProfileAlertOpen}
          anchorEl={profileAlert}
          onClose={ProfileAlerthandleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          style={{ marginLeft: '20px' }}
        >
          <AlertMsg alertData={alertData} alertID={alertID} />
        </Popover>
        <Popover
          id={FaultCodeContent}
          anchorPosition={{ top: 299, left: 669 }}
          open={faultcodeOpen}
          anchorEl={faultCode}
          onClose={FaultCodehandleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          style={{ marginLeft: '20px' }}
        >
          <div style={{ width: '360px' }}>
            <FaultCode faultCodeData={faultCodeData} />
          </div>
        </Popover>
        <Modal
          open={delDriver}
          onClose={deleteDriverModalClose}
          style={{ outline: 0 }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Fade in={delDriver}>
            <Box sx={style}>
              <RemoveDriver
                RemoveDriverData={removeDriverData}
                deleteDriverModalClose={deleteDriverModalClose}
                RemoveDriverModal={(id, data) => RemoveDriverModal(id, data)}
                RemoveDriverModalClose={RemoveDriverModalClose}
              />
            </Box>
          </Fade>
        </Modal>
        <Toast show={removeDrivermsg} onClose={RemoveDriverModalClose} className='equipment-success-msg'>
          <div>
            <RemovedDriverMsg RemoveDriverModalClose={RemoveDriverModalClose} />
          </div>
        </Toast>
        <BootstrapModal enforceFocus={false} show={addDriver} onHide={addDriverModalClose} centered>
          <AddDriver
            vehicle={selectedVehicle}
            handleClose={addDriverModalClose}
            onSuccess={onAddDriverSuccess}
            DriversAddedmsgModal={(id) => DriversAddedmsgModal(id)}
          />
        </BootstrapModal>
        <Toast show={driverAddedmsg} onClose={DriversAddedmsgModalClose} className='equipment-success-msg'>
          <div>
            <AddDriverSuccess DriversAddedmsgModalClose={DriversAddedmsgModalClose} />
          </div>
        </Toast>
        {!!hook && <HookTrailer open={!!hook} onClose={() => setHook(false)} vehicleId={id} onSuccess={SuccessShow} />}
        {/* <Modal */}
        {/*  open={hook} */}
        {/*  onClose={hookModalClose} */}
        {/*  style={{ outline: 0 }} */}
        {/*  aria-labelledby='modal-modal-title' */}
        {/*  aria-describedby='modal-modal-description' */}
        {/* > */}
        {/*  <Fade in={hook}> */}
        {/*    <Box sx={style}> */}
        {/*      <AddHook */}
        {/*        id={id} */}
        {/*        handleClose={hookModalClose} */}
        {/*        SuccessShow={SuccessShow} */}
        {/*        SuccessClose={SuccessClose} */}
        {/*        HookedErrClose={HookedErrClose} */}
        {/*        HookedErrShow={HookedErrShow} */}
        {/*        GetHookedError={GetHookedError} */}
        {/*      /> */}
        {/*    </Box> */}
        {/*  </Fade> */}
        {/* </Modal> */}
        <Toast show={Hookederr} onClose={HookedErrClose} className='equipment-err-msg'>
          <AddHookedError getHookederr={getHookederr} handleClose={HookedErrClose} />
        </Toast>
        <Toast show={success} onClose={SuccessClose} className='equipment-success-msg'>
          <AddHookedSuccess handleClose={SuccessClose} />
        </Toast>
        <Modal
          open={unHookedTrailer}
          onClose={UnHookedModalClose}
          style={{ outline: 0 }}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Fade in={unHookedTrailer}>
            <Box sx={style}>
              <UnHooked
                handleClose={UnHookedModalClose}
                unHookedData={unHookedData}
                UnhookedMsgClose={UnhookedMsgClose}
                UnHoookedMsgShow={(id) => UnHoookedMsgShow(id)}
                HookedErrClose={HookedErrClose}
                HookedErrShow={HookedErrShow}
                GetHookedError={GetHookedError}
                // mapPage={true}
              />
            </Box>
          </Fade>
        </Modal>
        <Toast show={unHookedMsg} onClose={UnhookedMsgClose} className='equipment-success-msg'>
          <div>
            <UnhookedMsg
              handleClose={UnhookedMsgClose}
              UnhookedMsgClose={UnhookedMsgClose}
              UnHoookedMsgShow={UnHoookedMsgShow}
            />
          </div>
        </Toast>
      </div>
      {vehicleLoading ? (
        ''
      ) : AddStopPointData?.equipment_id || AddStopPointData?.radius ? (
        <CustomState
          stopPointsData={stopPointsData}
          routeCoords={routeCoords}
          AddStopPointData={AddStopPointData}
          stopPointCoords={stopPointCoords}
          firstCoords={firstCoords}
        />
      ) : (
        ''
      )}
    </>
  );
};
export default VehicleContainer;
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 500,
  outline: 0,
};
