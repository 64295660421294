import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow_right.svg';
import Chip from 'common/Chip';
import Search from 'common/Search';
import NoRecords from 'common/NoRecords';
import ViewPdf from 'components/ViewPdf';
import Pagination from 'common/Pagination';
import TableSkeleton from 'common/TableSkeleton';
import { Typography } from 'components/Typography';
import DateRangePicker from 'common/DateRangePicker';
import { palette } from 'utils/constants';
import useDebounce from 'hooks/useDebounce';
import useDateFormat from 'hooks/useDateFormat';
import { getCarrierLogs } from 'Api/Carriers';
import { getStaffChangeLog } from 'Api/Staff';
import { getCustomerLogs } from 'Api/Customers';
import { getStopPointLogs } from 'Api/StopPoint';
import { getDriverChangeLog } from 'Api/DriverV2';
import { getChangeLog } from 'Api/CompanySettings';
import { getEquipmentLogs } from 'Api/EquipmentProfile';
import { getOperatorChangeLog } from 'Api/OwnerOperatorV2';
import { CHANGE_LOG_TYPE } from './ChangeLog.data';
import { STable, STableRow } from './ChangeLog.styles';

const ChangeLog = ({ type, additionalParams, hideFilters }) => {
  const { convertToCustomerTime, formatDate, formatDateTime } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [changeLog, setChangeLog] = useState({ data: [] });
  const [pdfUrl, setPdfUrl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({ page: 1, itemsPerPage: 25 });
  const [search, setSearch] = useState('');
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const debouncedSearch = useDebounce(search, 500);
  const controller = useRef(new AbortController());

  const getChangeLogData = async () => {
    try {
      if (controller.current) {
        controller.current.abort();
        controller.current = new AbortController();
      }

      setLoading(true);

      const params = {
        page: selectedFilters.page,
        itemsPerPage: selectedFilters.itemsPerPage,
        'sort[][created_at]': 'desc',
        query: debouncedSearch || undefined,
        from_date: dateRange.start ? moment(dateRange.start).format('MM/DD/YYYY') : undefined,
        to_date: dateRange.end ? moment(dateRange.end).format('MM/DD/YYYY') : undefined,
        ...additionalParams,
      };

      let response;

      switch (type) {
        case CHANGE_LOG_TYPE.COMPANY_SETTINGS:
          response = await getChangeLog(params, controller.current.signal);
          break;
        case CHANGE_LOG_TYPE.OWNER_OPERATOR:
          response = await getOperatorChangeLog(params, controller.current.signal);
          break;
        case CHANGE_LOG_TYPE.DRIVER:
          response = await getDriverChangeLog(params, controller.current.signal);
          break;
        case CHANGE_LOG_TYPE.CUSTOMER:
          response = await getCustomerLogs(params, controller.current.signal);
          break;
        case CHANGE_LOG_TYPE.CARRIER:
          response = await getCarrierLogs(params, controller.current.signal);
          break;
        case CHANGE_LOG_TYPE.STAFF:
          response = await getStaffChangeLog(params, controller.current.signal);
          break;
        case CHANGE_LOG_TYPE.STOP_POINT:
          response = await getStopPointLogs(params, controller.current.signal);
          break;
        case CHANGE_LOG_TYPE.EQUIPMENT:
          response = await getEquipmentLogs(params, controller.current.signal);
          break;
        default:
      }

      setChangeLog(response || { data: [] });
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  const onChangeRowPerPage = (rowPage) => {
    setSelectedFilters((prevState) => ({ ...prevState, page: 1, itemsPerPage: rowPage }));
  };

  const onPageChange = (page) => {
    setSelectedFilters((prevState) => ({ ...prevState, page }));
  };

  useEffect(() => {
    if (!type) {
      return;
    }

    getChangeLogData();
  }, [type, selectedFilters, debouncedSearch, dateRange, additionalParams]);

  return (
    <div>
      {!hideFilters && (
        <div className='d-flex align-items-center gap-3 mb-3'>
          <DateRangePicker top='-108px' dateRange={dateRange} initialRangeName='All Time' setDateRange={setDateRange} />
          <Search search={search} onChange={setSearch} className='search-input' />
        </div>
      )}
      {loading ? (
        <TableSkeleton />
      ) : (
        <STable>
          <thead>
            <tr>
              <th>CHANGED SECTION</th>
              <th>CHANGED FROM / TO</th>
              <th>CHANGED BY</th>
            </tr>
          </thead>
          <tbody>
            {changeLog.data.map((item) => (
              <STableRow key={item.id}>
                <td>
                  <Typography
                    variant='b2'
                    className='d-inline-block'
                    style={{ color: palette.gray700, maxWidth: '250px' }}
                  >
                    {item.change_description || '-'}
                  </Typography>
                </td>
                <td>
                  <div className='d-flex align-items-center gap-3' style={{ color: palette.gray700 }}>
                    <Chip
                      disableRipple
                      onClick={
                        item.change_type === 'image' && item.change_from ? () => setPdfUrl(item.change_from) : undefined
                      }
                      label={
                        item.change_type === 'date' && item.change_from
                          ? item.change_from.includes(' ')
                            ? formatDateTime(item.change_from)
                            : formatDate(item.change_from)
                          : item.change_type === 'image' && item.change_from
                          ? 'VIEW OLD ATTACHMENT'
                          : item.change_from || '-'
                      }
                    />{' '}
                    <ArrowRight fill={palette.gray500} />{' '}
                    <Chip
                      disableRipple
                      onClick={
                        item.change_type === 'image' && item.change_to ? () => setPdfUrl(item.change_to) : undefined
                      }
                      label={
                        item.change_type === 'date' && item.change_to
                          ? item.change_to.includes(' ')
                            ? formatDateTime(item.change_to)
                            : formatDate(item.change_to)
                          : item.change_type === 'image' && item.change_to
                          ? 'VIEW NEW ATTACHMENT'
                          : item.change_to || '-'
                      }
                    />
                  </div>
                </td>
                <td>
                  <Typography variant='b2' style={{ color: palette.gray700 }}>
                    By {item?.change_by_user_details?.first_name} {item?.change_by_user_details?.last_name} on{' '}
                    {convertToCustomerTime(item.created_at)}
                  </Typography>
                </td>
              </STableRow>
            ))}
          </tbody>
        </STable>
      )}
      {!loading && !changeLog.data?.length && <NoRecords />}
      <Pagination
        data={changeLog}
        rowPerPage={selectedFilters.itemsPerPage}
        onPageChange={onPageChange}
        onChangeRowPerPage={onChangeRowPerPage}
        rowsPerPageOptions={[25, 50, 100, 150]}
      />
      <ViewPdf title='View Attachment' open={!!pdfUrl} pdfUrl={pdfUrl} onClose={() => setPdfUrl(null)} />
    </div>
  );
};

export default ChangeLog;
export { CHANGE_LOG_TYPE };
