import React, { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import hexRgb from 'hex-rgb';
import uuid from 'react-uuid';
import Fade from '@mui/material/Fade';
import { Box, Modal } from '@mui/material';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import { Spinner, Toast, Modal as BootstrapModal } from 'react-bootstrap';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { Icons, PetrolIcon, satelight, Speed } from 'assets/icons';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import Chip from 'common/Chip';
import Garlax from 'common/Tooltip';
import UserInfo from 'components/UserInfo';
import ColumnHeader from 'common/ColumnHeader';
import { Typography } from 'components/Typography';
import Hos from 'components/TableShipments/helpers/Hos';
import TableFooter from 'components/Pagination/EquipmentTableFooter';
import PlannedLoads from 'components/TableShipments/components/PlannedLoads';
import { getUTCTimeDifference } from 'components/TableShipments/helpers/constants';
import { getMapTabs } from 'Api/Map';
import { formatSeconds } from 'utils/helpers';
import authHeader from 'services/auth-header';
import { useAuth } from 'context/auth.context';
import useDateFormat from 'hooks/useDateFormat';
import { getDrivers } from 'Api/EquipmentProfile';
import { formatNumber, motionColors, palette, TRUCK_TYPES } from 'utils/constants';
import { Equipments, getEquipmentRequiredVehicles, Groups } from 'Api/Equipment';

import AddHook from 'pages/landing/Equipment/DialogueModals/AddHook/AddHook';
import UnHooked from 'pages/landing/Equipment/DialogueModals/AddHook/UnHooked';
import AddGroup from 'pages/landing/Equipment/DialogueModals/AddGroup/AddGroup';
import AddDriver from 'pages/landing/Equipment/DialogueModals/AddDriver/AddDriver';
import AlertMsg from 'pages/landing/Equipment/DialogueModals/ProfileAlert/AlertIcon';
import FaultCode from 'pages/landing/Equipment/DialogueModals/ProfileAlert/FaultCode';
import DriverInfo from 'pages/landing/Equipment/DialogueModals/DriverInfo/DriverInfo';
import RemoveGroup from 'pages/landing/Equipment/DialogueModals/AddGroup/RemoveGroup';
import RemoveDriver from 'pages/landing/Equipment/DialogueModals/DriverInfo/RemoveDriver';
import AddHookedError from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddHookedError';
import AddDriverSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddDriverMsg';
import UnhookedMsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/UnhookedTrailerMsg';
import AlreadyReserved from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AlreadyReserved';
import AddGroupSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/AddGroupSuccess';
import RemovedDriverMsg from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/RemoveDriverSuccess';
import ReserveEquipment from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/ReserveEquipment';
import AddHookedSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/HookedTrailerSuccuss';
import RemoveGroupSuccess from 'pages/landing/Equipment/DialogueModals/Equipmentsmsg/RemoveGroupSuccess';
import UnresevedEquipment from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/UnreservedEquipment';
import ReserveEquipmentMsg from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/ReservedEquipmentMsg';
import UnreservedEquipmentmsg from 'pages/landing/Equipment/DialogueModals/ReserveEquipment/UnreserveEquipmentmsg';

import SubTabs from '../../EquipmentTab/SubTab';
import { NavLinkWrapper } from '../NavLinkWrapper';
import SwitchRadio from '../../EquipmentTab/SwitchRadio';
import VehicleHeader from './VehicleHeader';
import CustomModal from '../../EquipmentTab/EquipmentAddTabModal';
import EquipmentTableModal from '../../EquipmentTab/EquipmentTabModal';
import { LOCATION_INITIAL_VALUE } from '../../EquipmentFilters/constant';
import Preloader from '../../EquipmentPreloader/EquipmentPreloaderSkeleton';
import MaterialTableWrapper, { EquipmentTableSort } from '../../EquipmentTableWrapper/EquipmentTableWrapper';
import '../EquipmentTable.css';
import { FILTER_SEARCH_BY_VEHICLE } from '../constat';

export const TableEquipmentContext = createContext();

const VehicleTable = ({ setTabName, setCurrentPageIds = () => null, setMainTab, createdEquipmentId }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const { convertToCustomerTime, formatDate, dateFormat, timeFormat } = useDateFormat();
  const [loading, setLoading] = useState(false);
  const [loadingDrivers, setLoadingDrivers] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tab, setTab] = useState(1);
  const [rowPerPage, setRowPerPage] = useState(25);

  const [unreserveSuccess, setUnReserveSuccess] = useState(false);
  const [Hookederr, setHookederr] = useState(false);
  const [getHookederr, setGetHookedErr] = useState();
  const [unHookedMsg, setUnhookedMsg] = useState(false);
  const [showA, setShowA] = useState(false);
  const [reserveMsgData, setReserveMsgData] = useState();
  const [unHookedTrailer, setUnHookedTrailer] = useState(false);
  const [unHookedData, setUnhookedData] = useState({ data: [] });
  const [, setQueryParameterData] = useState();
  const [vehicleTabData, setVehicleTabData] = useState([]);
  const [reserveEquipment, setReserveEquipment] = useState(false);
  const [driverPopupData] = useState({ data: [] });
  const [id, setId] = useState();
  const [tableSettingSwitch, setTableSettingSwitch] = useState({});
  const [hook, setHook] = useState(false);
  const [addDriver, setAddDriver] = useState(false);
  const [delDriver, setDeleteDriver] = useState(false);
  const [removeDriverData, setRemoveDriverData] = useState({ data: [] });
  const [TYPES, SET_TYPES] = useState();
  const [columnOrder, setColumnOrder] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [vehicleData, setVehicleData] = useState({ data: [] });
  const [anchorEl, setAnchorEl] = useState(null);
  const [addGroup, setAddGroup] = useState(false);
  const [unReseveredEq, setUnReserveredEq] = useState(false);
  const [unReseveredEqId, setUnReserveredEqId] = useState({ data: [] });
  const [removeDrivermsg, setRemovedDrivermsg] = useState(false);
  const [driverAddedmsg, setDriverAddedmsg] = useState(false);
  const [alreadyReserveMsg, setAlreadyReserveMsg] = useState(false);
  const [alreadyReserveId, setAlreadyReserveId] = useState();
  const [faultCode, setFaultCode] = useState(null);
  const [faultCodeData, setFaultCodeData] = useState();
  const [alertData, setAlertData] = useState();
  const [alertID, setAlertID] = useState(null);
  const [addGroupSuccess, setAddGroupSuccess] = useState(false);
  const [removeGroup, setRemoveGroup] = useState(false);
  const [RemoveGroupData, setRemoveGroupData] = useState({ data: [] });
  const [removeGroupSuccess, setRemoveGroupSuccess] = useState(false);
  const [profileAlert, setProfileAlert] = useState(null);
  const [, setFilterByGroupsData] = useState([]);
  const [filterByDriversData, setFilterByDriversData] = useState([]);
  const [, setFilterByEquipmentTypeData] = useState([]);
  const [, setShowHeaderFilters] = useState([]);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('sort[][equipment_id]=asc');
  const [tableIndex, setTableIndex] = useState(Date.now());
  const didMountPageRef = useRef(false);
  const [addActionOpen, setAddActionOpen] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const { value: userData } = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const vehicles = useRef([]);

  const [switcherData, setSwitcherData] = useState([]);

  const [filter, setFilter] = useState({
    searchBy: { id: 1, title: 'Equipment', value: 'equipment_id', key: 9999 },
    allValue: '',
    searchValue: '',
    selectedValues: [],
    tableColumn: TYPES,
    drivers: [],
    ColumnOrder: columnOrder,
    location: LOCATION_INITIAL_VALUE,
    switchRadioButtons: tableSettingSwitch,
    applyAllUsers: { value: '0' },
    filterTableTop: [
      { key: 'vehicle', label: `Vehicles`, value: 1 },
      { key: 'trailer', label: `Trailers`, value: 2 },
    ],
  });
  const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    () => ({
      [`& .${tooltipClasses.tooltip}`]: {
        boxShadow:
          '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
        borderRadius: '8px',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: '12px',
        lineHeight: '18px',
        backgroundColor: palette.white,
        color: palette.dark800,
      },
    })
  );
  const EquipmentStatusTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      boxShadow:
        '0px 0px 0px 1px rgba(152, 161, 178, 0.1), 0px 30px 70px -10px rgba(17, 24, 38, 0.25), 0px 10px 30px rgba(0, 0, 0, 0.2)',
      borderRadius: '8px',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: '500',
      fontSize: '12px',
      lineHeight: '18px',
      textTransform: 'uppercase',
      backgroundColor: palette.white,
      color: palette.dark800,
    },
  }));
  const [multiSelectOptions] = useState({});
  const RemoveGroupSuccessModal = (id) => {
    setLoadingStatus(id, true);
    setRemoveGroupSuccess(true);
    setTimeout(() => {
      RemoveGroupSuccessModalClose();
    }, 2000);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 3000);
  };
  const RemoveGroupSuccessModalClose = () => {
    setRemoveGroupSuccess(false);
  };

  const DriversAddedmsgModal = (id) => {
    setLoadingStatus(id, true);
    setDriverAddedmsg(true);
    setTimeout(() => {
      DriversAddedmsgModalClose();
    }, 2000);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 3000);
  };
  const DriversAddedmsgModalClose = () => setDriverAddedmsg(false);
  const RemoveDriverModal = (id) => {
    setTableIndex(Date.now());
    setLoadingStatus(id, true);
    setRemovedDrivermsg(true);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 3000);
  };

  const RemoveDriverModalClose = () => setRemovedDrivermsg(false);
  const navigate = useNavigate();

  const AlreadyReserveMsgModal = (id) => {
    setAlreadyReserveId(id);
    setAlreadyReserveMsg(true);
    setTimeout(() => {
      AlreadyReserveMsgModalClose();
    }, 3000);
  };

  const onClickPlanned = (e, rowData, count) => {
    e.stopPropagation();
    if (count) {
      setSelectedEquipment(rowData);
      setAddActionOpen(true);
    }
  };

  const setLoadingStatus = (id, status) => {
    const vehicleDataCopy = { ...{}, ...vehicleData };
    const index = vehicleDataCopy.data.findIndex((i) => i.id === id);
    const row = document.getElementsByClassName('MuiTableRow-root')[index + 1];

    row?.classList?.add('flash-in-row');
    if (!status) {
      row?.classList?.remove('flash-in-row');
    }
  };

  const RemoveGroupModal = (id, eq_id, row_id, title, group_name) => {
    setRemoveGroup(true);
    setRemoveGroupData([id, eq_id, row_id, title, group_name]);
  };
  const RemoveGroupModalClose = () => {
    setRemoveGroup(false);
  };
  const AddGroupSuccessModal = (id) => {
    setLoadingStatus(id, true);
    setAddGroupSuccess(true);
    setTimeout(() => {
      AddGroupSuccessClose();
    }, 2000);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 3000);
  };
  const AddGroupSuccessClose = () => {
    setAddGroupSuccess(false);
  };
  const AlreadyReserveMsgModalClose = () => setAlreadyReserveMsg(false);
  const GetHookedError = (err) => {
    setGetHookedErr(err);
  };
  const HookedErrShow = () => {
    setHookederr(true);
  };
  const HookedErrClose = () => {
    setHookederr(false);
  };
  const UnReserveSuccessShow = (id) => {
    // alert(id)
    setLoadingStatus(id, true);
    setUnReserveSuccess(true);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 3000);
  };
  const UnReserveSuccessClose = () => {
    setUnReserveSuccess(false);
  };
  const UnHoookedMsgShow = (id) => {
    setLoadingStatus(id, true);
    setUnhookedMsg(true);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 3000);
  };
  const UnhookedMsgClose = () => {
    setUnhookedMsg(false);
  };
  const SuccessShow = (id) => {
    setLoadingStatus(id, true);
    setSuccess(true);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 3000);
  };
  const SuccessClose = () => setSuccess(false);
  const UnHookedModalShow = (id, eq_id, length, unit, title) => {
    setUnhookedData([id, eq_id, length, unit, title]);
    setUnHookedTrailer(true);
  };
  const UnHookedModalClose = () => {
    setUnHookedTrailer(false);
  };
  const toggleShowA = (id) => {
    setLoadingStatus(id, true);
    setShowA(true);
    setTimeout(() => {
      setLoadingStatus(id, false);
    }, 3000);
  };
  const GetreserveMsgData = (val) => {
    setReserveMsgData(val);
  };
  const toggleCloseA = () => setShowA(false);
  const ReserveEquipmentModal = () => {
    setReserveEquipment(true);
  };
  const ReserveEquipmentModalClose = () => {
    setReserveEquipment(false);
  };
  const UnReservedEqModal = (id, row_id, title, equipment_id) => {
    setUnReserveredEqId([id, row_id, title, equipment_id]);
    setUnReserveredEq(true);
  };
  const UnReservedModalClose = () => {
    setUnReserveredEq(false);
  };

  const addGroupModal = (id) => {
    setId(id);
    setAddGroup(true);
  };
  const addGroupModalClose = () => setAddGroup(false);

  const hookModalClose = () => setHook(false);
  const hookModalShow = (id) => {
    setId(id);
    setHook(true);
  };
  const getId = (id) => {
    setId(id);
    ReserveEquipmentModal(true);
  };

  const deleteDriverModal = (id, rowId, fname, lname, Equipment_id, Equipment_title, status, equipment_id) => {
    handleClose();
    setDeleteDriver(true);
    setRemoveDriverData([id, rowId, fname, lname, Equipment_id, Equipment_title, status, equipment_id]);
  };

  const deleteDriverModalClose = () => {
    setDeleteDriver(false);
  };

  const addDriverModalClose = () => setAddDriver(false);
  const addDriverModalShow = (vehicle) => {
    setSelectedEquipment(vehicle);
    setAddDriver(true);
  };

  const FaultCodehandleClick = (e, faultCode) => {
    setFaultCodeData(faultCode);
    setFaultCode(e.currentTarget);
  };
  const FaultCodehandleClose = () => {
    setFaultCode(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const ProfileAlerthandleClick = (e, alerts, id) => {
    setProfileAlert(e.currentTarget);
    setAlertData(alerts);
    setAlertID(id);
  };
  const ProfileAlerthandleClose = () => {
    setProfileAlert(null);
  };

  const open = Boolean(anchorEl);
  const faultcodeOpen = Boolean(faultCode);
  const ProfileAlertOpen = Boolean(profileAlert);

  const FaultCodeContent = faultcodeOpen ? 'simple-popover' : undefined;
  const ProfileAlertContent = ProfileAlertOpen ? 'simple-popover' : undefined;

  const Popup = open ? 'simple-popover' : undefined;
  const columns = useMemo(() => {
    const state = { tab: 'vehicles' };
    const navLinkInnerClickableElement = { position: 'relative', zIndex: 1, width: 'fit-content' };
    const getDefaultTo = (rowData) => `/equipment-profile/vehicle/${rowData?.id}`;
    return [
      {
        field: 'equipment_id',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='EQUIPMENT ID' field='equipment_id' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          const { truck_type } = rowData || {};
          return (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {rowData.loading && <Spinner size='sm' animation='grow' variant='primary' className='me-2' />}
                <div
                  className='d-flex flex-column equipment-id-column fade-in-row row-hover'
                  key={rowData.equipment_id}
                >
                  {rowData?.location_distance && (
                    <span className='d-flex'>
                      <span className='location-distance me-1'>
                        {rowData?.location_distance.toString().split('.')[0]}
                      </span>
                      <span className='location-distance'> miles away </span>
                    </span>
                  )}
                  <span className='equipment-id-text-style' style={{ color: palette.dark800 }}>
                    {rowData.equipment_id} {rowData?.equipment_type?.title}{' '}
                    {!!truck_type && <span>({TRUCK_TYPES[truck_type]})</span>}
                  </span>

                  <div className='flex-row d-flex align-items-center'>
                    <div className='d-flex mt-0 p-0'>
                      {rowData?.equipment_reserve?.id ? (
                        <div
                          style={navLinkInnerClickableElement}
                          onClick={($event) => {
                            $event.stopPropagation();
                            user?.department?.department_name === 'Management' ||
                            user.id === rowData?.equipment_reserve?.user?.id
                              ? UnReservedEqModal(
                                  rowData?.equipment_reserve?.id,

                                  rowData?.id,
                                  rowData?.equipment_type?.title,
                                  rowData.equipment_id
                                )
                              : AlreadyReserveMsgModal(rowData?.equipment_reserve?.id);
                          }}
                        >
                          <svg
                            className='ms-1 me-1'
                            width='12'
                            height='14'
                            viewBox='0 0 12 14'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M0.5 2C0.5 0.895431 1.39543 0 2.5 0H9.5C10.6046 0 11.5 0.895431 11.5 2V13.5993C11.5 13.8965 11.1873 14.0899 10.9213 13.9572L6.4467 11.723C6.16546 11.5826 5.83454 11.5826 5.55329 11.723L1.07868 13.9572C0.812734 14.0899 0.5 13.8965 0.5 13.5993V2Z'
                              fill='#767FFB'
                            />
                          </svg>
                        </div>
                      ) : (
                        <div className='reserve-equipment' style={navLinkInnerClickableElement}>
                          <img
                            onClick={(e) => {
                              e.stopPropagation();
                              getId(rowData.id);
                            }}
                            src={Icons.buttonIcon}
                            alt='button-icon'
                            className='bookmark'
                          />
                        </div>
                      )}
                      {rowData?.expiring_equipment_alerts ? (
                        <div
                          className='ms-1 me-1'
                          onClick={(e) => e.stopPropagation()}
                          style={{ position: 'relative' }}
                        >
                          <svg
                            width='16'
                            height='16'
                            fill='none'
                            viewBox='0 0 16 16'
                            xmlns='http://www.w3.org/2000/svg'
                            style={navLinkInnerClickableElement}
                            onClick={(e) => ProfileAlerthandleClick(e, rowData?.expiring_equipment_alerts, rowData?.id)}
                          >
                            <path
                              d='M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z'
                              fill='#C97A20'
                            />
                            <path
                              d='M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z'
                              fill='#C97A20'
                            />
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z'
                              fill='#C97A20'
                            />
                          </svg>
                        </div>
                      ) : (
                        ''
                      )}
                      <div className='ms-1 me-1'>
                        {rowData?.equipment_connected_services_faultcode ? (
                          <svg
                            width='16'
                            height='16'
                            fill='none'
                            viewBox='0 0 16 16'
                            style={navLinkInnerClickableElement}
                            xmlns='http://www.w3.org/2000/svg'
                            onClick={(e) => {
                              FaultCodehandleClick(e, rowData?.equipment_connected_services_faultcode);
                              e.stopPropagation();
                            }}
                          >
                            <path
                              d='M8 9C7.58579 9 7.25 8.66421 7.25 8.25V6.25C7.25 5.83579 7.58579 5.5 8 5.5C8.41421 5.5 8.75 5.83579 8.75 6.25V8.25C8.75 8.66421 8.41421 9 8 9Z'
                              fill='#DC4067'
                            />
                            <path
                              d='M8 11.5C7.58579 11.5 7.25 11.1642 7.25 10.75C7.25 10.3358 7.58579 10 8 10C8.41421 10 8.75 10.3358 8.75 10.75C8.75 11.1642 8.41421 11.5 8 11.5Z'
                              fill='#DC4067'
                            />
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M6.2779 2.95046C7.0624 1.68318 8.9376 1.68318 9.72211 2.95046L14.7043 10.9986C15.5195 12.3154 14.5529 14 12.9822 14H3.0178C1.4471 14 0.48054 12.3154 1.2957 10.9986L6.2779 2.95046ZM7.55329 3.73999C7.75138 3.42 8.24862 3.42 8.44671 3.73999L13.4289 11.7882C13.5982 12.0616 13.4299 12.5 12.9822 12.5H3.0178C2.57013 12.5 2.40185 12.0616 2.5711 11.7882L7.55329 3.73999Z'
                              fill='#DC4067'
                            />
                          </svg>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'hooked_to',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='HOOKED TO' field='[id]' nested_field='hook_to' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='hooked-to-column-wrapper fade-in-row'>
              {rowData?.hook_to?.equipment_id ? (
                <div
                  style={navLinkInnerClickableElement}
                  onClick={($event) => {
                    UnHookedModalShow(
                      rowData?.id,
                      rowData?.hook_to?.equipment_id,
                      rowData?.hook_to?.length?.length,
                      rowData?.hook_to?.length?.unit,
                      rowData?.hook_to?.equipment_type?.title
                    );
                    $event.stopPropagation();
                  }}
                >
                  <span style={{ color: palette.dark800 }}>{rowData?.hook_to?.equipment_id}</span>
                  <span className='hooked-numbers-sub ms-1' style={{ color: palette.dark800 }}>
                    {rowData?.hook_to?.length?.length}
                    <span
                      // className="ms-1"
                      style={{ color: palette.dark800 }}
                    >
                      {rowData?.hook_to?.length?.unit}
                    </span>
                    <span className='ms-1' style={{ color: palette.dark800 }}>
                      {rowData?.hook_to?.equipment_type?.title}
                    </span>
                  </span>
                </div>
              ) : // rowData.equipment_type.id === 1 means Truck
              rowData.equipment_type?.vehicle_type_id !== 1 || +rowData.equipment_type.id === 1 ? (
                <div className='row-style'>
                  <div className='add-driver'>
                    <div
                      style={navLinkInnerClickableElement}
                      className=' add-driver-wrapper '
                      onClick={($event) => {
                        $event.stopPropagation();
                        hookModalShow(rowData?.id);
                      }}
                    >
                      <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                          fill='#4f5aed'
                        />
                      </svg>
                      <span className='add-driver-content'>Hook Trailer</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>-</>
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'group',
        title: (
          <EquipmentTableSort
            sortingQuery={sortingQuery}
            title='TAGS'
            field='[short_name]'
            nested_field='equipment_group'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <div className='equipment-group fade-in-row'>
                <div className='group-wrapper'>
                  {rowData?.equipment_group
                    ? rowData?.equipment_group.map((g) => {
                        const colorCode = hexRgb(g.color);
                        const TextColor =
                          colorCode?.red < 70 || colorCode?.green < 70 || colorCode?.blue < 70
                            ? 'white'
                            : 'rgb(23, 28, 38)';
                        return (
                          <span key={g.id}>
                            <span
                              className='equipment-groups'
                              style={{
                                backgroundColor: g.color,
                                color: TextColor,
                                ...navLinkInnerClickableElement,
                              }}
                              onClick={($event) => {
                                RemoveGroupModal(
                                  g.id,
                                  rowData.equipment_id,
                                  rowData?.id,
                                  rowData?.equipment_type?.title,
                                  g?.group_name
                                );
                                $event.stopPropagation();
                              }}
                            >
                              {g.short_name}
                            </span>
                          </span>
                        );
                      })
                    : ''}
                </div>

                {rowData?.equipment_group && rowData?.equipment_group.length >= 1 ? (
                  ''
                ) : (
                  <div className='row-style'>
                    <div className='add-group'>
                      <div
                        style={navLinkInnerClickableElement}
                        className='d-flex add-group-wrapper'
                        onClick={($event) => {
                          $event.stopPropagation();
                          addGroupModal(rowData.id);
                        }}
                      >
                        <div>
                          <svg
                            width='12'
                            height='12'
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            style={{ cursor: 'pointer' }}
                          >
                            <path
                              d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                              fill='#4f5aed'
                            />
                          </svg>
                        </div>
                        <span className='add-group-content '>Tags</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'status',
        title: (
          <EquipmentTableSort
            title='STATUS'
            sortingQuery={sortingQuery}
            field='[equipment_status]'
            nested_field='equipment_status'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          const flag =
            rowData?.drivers?.length === 2
              ? rowData?.drivers?.filter((ele) => ele.status_data?.driver_status === 'Not Available').length >= 2
              : !!(
                  '' || rowData?.drivers?.filter((ele) => ele.status_data?.driver_status === 'Not Available').length > 0
                );
          return (
            <>
              <div className='equipment-status-wrapper fade-in-row'>
                <div className='d-flex justify-content-start'>
                  {/* <div className={"tablePlaner_status-wrapper"}> */}
                  {!flag ? (
                    <span
                      style={{
                        backgroundColor: rowData?.equipment_status?.background_color,
                        color: rowData?.equipment_status.color,
                      }}
                      className='equipment_status'
                    >
                      {rowData?.equipment_status?.equipment_status}
                    </span>
                  ) : (
                    <span style={{ backgroundColor: '#F0F2F7', color: '#5A6376' }} className='equipment_status'>
                      <span style={navLinkInnerClickableElement}>
                        <EquipmentStatusTooltip
                          title={`Driver is Not available Until ${formatDate(
                            rowData?.drivers?.map((v) => v.driver_not_available_until_date)[0]
                          )} ${rowData?.drivers?.map((v) => v.driver_not_available_until_time)[0]}. Note: ${
                            rowData?.drivers?.map((v) => v.note_not_available)[0]
                          }`}
                          placement='right'
                        >
                          <span> Not Available</span>
                        </EquipmentStatusTooltip>
                      </span>
                    </span>
                  )}
                </div>
                {/* </div> */}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'owned_by',
        title: (
          <EquipmentTableSort
            sortingQuery={sortingQuery}
            title='OWNED BY'
            field='[owner]'
            nested_field='equipment_ownership'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div
              style={{
                color: palette.dark800,
                fontSize: '13px',
                marginTop: '-12px',
              }}
            >
              {rowData?.equipment_ownership?.owner_operator === '1' && userData?.user?.customer?.company_name}
              {rowData?.equipment_ownership?.owner_operator === '2' &&
                rowData?.equipment_ownership?.leasing_company_name}
              {rowData?.equipment_ownership?.owner_operator === '3' && rowData?.equipment_ownership?.owner}
              {rowData?.equipment_ownership?.owner_operator === '4' && '3rd Party'}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'planned_count',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='PLANNED' field='planned_count' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <Typography
              onClick={(e) => onClickPlanned(e, rowData, rowData.planned_count)}
              variant='s3'
              style={{
                color: rowData.planned_count ? palette.indigo500 : palette.gray700,
                textDecoration: rowData.planned_count ? 'underline' : 'none',
                ...navLinkInnerClickableElement,
              }}
            >
              {rowData.planned_count}
            </Typography>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'current_location',
        title: (
          <EquipmentTableSort
            title='CURRENT LOCATION'
            sortingQuery={sortingQuery}
            field='[location]'
            nested_field='gps'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-list-current-location fade-in-row'>
              <div className='d-flex'>
                {rowData?.location_target === 'Driver' ? (
                  <>
                    <span className='d-flex align-items-center me-2' style={{ width: '60px' }}>
                      <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M13.0001 3C13 1.89544 12.1046 1.00001 11.0001 1.00001L5.00004 1C3.89547 1 3.00004 1.89543 3.00003 3L3 13C3 14.1046 3.89543 15 5 15H11.0001C12.1047 15 13.0001 14.1046 13.0001 13L13.0001 3ZM11.5 11.5C11.5 11.7761 11.2761 12 11 12H4.99999C4.72385 12 4.49999 11.7761 4.49999 11.5L4.5 3C4.5 2.72386 4.72386 2.5 5 2.5L6 2.5L6.15532 3.12127C6.21096 3.34385 6.41095 3.5 6.64039 3.5H9.35961C9.58905 3.5 9.78904 3.34385 9.84468 3.12127L10 2.5L11 2.5C11.2761 2.5 11.5 2.72386 11.5 3L11.5 11.5ZM7.24462 13.5C7.24462 13.9074 7.58698 14.25 7.99462 14.25C8.402 14.25 8.74435 13.9238 8.74462 13.5C8.74462 13.0924 8.402 12.75 7.99462 12.75C7.58698 12.75 7.24462 13.0924 7.24462 13.5Z'
                          fill='#38A06C'
                        />
                      </svg>
                      <div className='d-flex flex-column'>
                        {rowData?.drivers && rowData?.drivers[0].driver_gps?.signal ? (
                          rowData?.drivers[0]?.driver_gps?.signal === 'poor' ? (
                            <div
                              className='equipment-signalstrenth  me-1'
                              style={{
                                backgroundColor: palette.white,
                              }}
                            >
                              <img src={Icons.PoorSignal} className='mt-0' alt='' />
                            </div>
                          ) : rowData?.drivers[0]?.driver_gps?.signal === 'good' ? (
                            <div
                              className='equipment-signalstrenth  me-1'
                              style={{
                                backgroundColor: palette.white,
                              }}
                            >
                              <img src={Icons.GoodSignal} className='mt-0 me-1' alt='' />
                            </div>
                          ) : rowData?.drivers[0]?.driver_gps?.signal === 'great' ? (
                            <div
                              className='equipment-signalstrenth  me-1'
                              style={{
                                backgroundColor: palette.white,
                              }}
                            >
                              <img src={Icons.StrongSignal} className='mt-0 me-1' alt='' />
                            </div>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </span>

                    {rowData?.location_target === 'Driver' && rowData?.drivers ? (
                      <div>
                        {' '}
                        <p
                          className='current-location'
                          style={{
                            color: palette.dark800,
                          }}
                        >
                          {rowData?.drivers[0]?.driver_gps?.location
                            ? rowData?.drivers[0]?.driver_gps?.location
                            : rowData?.last_known_location}
                          {/* {d?.driver_gps?.location} */}
                        </p>
                      </div>
                    ) : (
                      <p
                        className='current-location'
                        style={{
                          color: palette.dark800,
                        }}
                      >
                        {rowData?.last_known_location}
                        {/* {d?.driver_gps?.location} */}
                      </p>
                    )}
                  </>
                ) : (
                  ''
                )}
                {rowData?.location_target === 'Equipment' ? (
                  <>
                    <div className='d-flex me-2' style={{ width: '60px' }}>
                      {!!rowData.equipment_connected_services_device && <span className='me-2'> {satelight}</span>}
                      <div className='ms-1'>
                        {rowData?.equipment_connected_services_device?.engine_state === 'Idle' ? (
                          <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                              fill='#81D6AB'
                            />
                          </svg>
                        ) : rowData?.equipment_connected_services_device?.engine_state === 'On' ? (
                          <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                              fill='#38A06C'
                            />
                          </svg>
                        ) : rowData?.equipment_connected_services_device?.engine_state === 'Off' ? (
                          <svg width='14' height='12' viewBox='0 0 14 12' xmlns='http://www.w3.org/2000/svg'>
                            <path
                              fillRule='evenodd'
                              clipRule='evenodd'
                              d='M6.03518 2.5H6.75L6.75 1.5L5.25 1.5C4.83579 1.5 4.5 1.16421 4.5 0.75C4.5 0.335787 4.83579 0 5.25 0H9.75C10.1642 0 10.5 0.335787 10.5 0.75C10.5 1.16421 10.1642 1.5 9.75 1.5L8.25 1.5V2.5H8.96482C9.29917 2.5 9.6114 2.6671 9.79687 2.9453L10.2031 3.5547C10.3886 3.8329 10.7008 4 11.0352 4H13C13.5523 4 14 4.44772 14 5V8C14 8.31476 13.8518 8.61115 13.6 8.8L12.284 9.78698C12.1003 9.92478 11.9696 10.1216 11.9139 10.3444L11.6894 11.2425C11.5781 11.6877 11.1781 12 10.7192 12H5.91421C5.649 12 5.39464 11.8946 5.20711 11.7071L4.79289 11.2929C4.60536 11.1054 4.351 11 4.08579 11H3.5C2.94772 11 2.5 10.5523 2.5 10V8.25L1.5 8.25L1.5 9.75C1.5 10.1642 1.16421 10.5 0.75 10.5C0.335787 10.5 0 10.1642 0 9.75V5.25C0 4.83579 0.335786 4.5 0.75 4.5C1.16421 4.5 1.5 4.83579 1.5 5.25L1.5 6.75L2.5 6.75V5C2.5 4.44772 2.94772 4 3.5 4H3.96482C4.29917 4 4.6114 3.8329 4.79687 3.5547L5.20313 2.9453C5.3886 2.6671 5.70083 2.5 6.03518 2.5ZM6.30278 4L6.04494 4.38675C5.58779 5.07248 4.82256 5.48821 4 5.49975V9.5H4.08579C4.74883 9.5 5.38471 9.76339 5.85355 10.2322L6.12132 10.5H10.3288L10.4587 9.98064C10.5979 9.4236 10.9247 8.93148 11.384 8.58698L12.5 7.75V5.5H11.0352C10.1993 5.5 9.41872 5.08225 8.95506 4.38675L8.69722 4H6.30278Z'
                              fill='#5A6376'
                            />
                          </svg>
                        ) : (
                          ''
                        )}
                      </div>

                      <div className='d-flex flex-column'>
                        {rowData?.equipment_gps?.signal !== null ? (
                          rowData?.equipment_gps?.signal === 'poor' ? (
                            <div className='equipment-signalstrenth  me-1'>
                              <img src={Icons.PoorSignal} className='mt-0' alt='' />
                            </div>
                          ) : rowData?.equipment_gps?.signal === 'good' ? (
                            <div className='equipment-signalstrenth  me-1'>
                              {' '}
                              <img src={Icons.GoodSignal} className='mt-0 me-1' alt='signal' />
                            </div>
                          ) : rowData?.equipment_gps?.signal === 'great' ? (
                            <div className='equipment-signalstrenth  me-1'>
                              <img src={Icons.StrongSignal} className='mt-0 me-1' alt='' />
                            </div>
                          ) : (
                            ''
                          )
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                    <p className='current-location' style={{ color: palette.dark800 }}>
                      {rowData?.equipment_gps?.location
                        ? rowData?.equipment_gps?.location
                        : rowData?.last_known_location}
                      {/* {rowData?.equipment_gps?.location} */}
                    </p>
                  </>
                ) : rowData?.location_target === null ? (
                  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M4.79114 3.72156C4.49577 3.42619 4.01689 3.42619 3.72152 3.72156C3.42616 4.01693 3.42616 4.49581 3.72152 4.79117L6.93037 8.00002L3.72155 11.2088C3.42619 11.5042 3.42619 11.9831 3.72155 12.2784C4.01692 12.5738 4.4958 12.5738 4.79117 12.2784L7.99998 9.06963L11.2088 12.2785C11.5042 12.5738 11.9831 12.5738 12.2784 12.2785C12.5738 11.9831 12.5738 11.5042 12.2784 11.2089L9.06959 8.00002L12.2785 4.79114C12.5738 4.49577 12.5738 4.01689 12.2785 3.72152C11.9831 3.42616 11.5042 3.42616 11.2089 3.72152L7.99998 6.9304L4.79114 3.72156Z'
                      fill='#DC4067'
                    />
                  </svg>
                ) : (
                  ''
                )}
              </div>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'last_update',
        title: (
          <EquipmentTableSort sortingQuery={sortingQuery} title='LAST UPDATE' field='[updated_at]' nested_field='gps' />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <div style={{ fontSize: '13px' }}>
                {rowData?.location_target === 'Driver' ? (
                  rowData?.drivers ? (
                    rowData?.drivers[0]?.driver_gps?.updated_at ? (
                      <div
                        className='flex-row d-flex fade-id-row'
                        key={id}
                        style={{
                          color: palette.dark800,
                        }}
                      >
                        {rowData?.drivers[0]?.driver_gps?.updated_at
                          ? convertToCustomerTime(rowData?.drivers[0]?.driver_gps?.updated_at, timeFormat)
                          : ''}
                        <br />
                        {rowData?.drivers[0]?.driver_gps?.updated_at
                          ? convertToCustomerTime(rowData?.drivers[0]?.driver_gps?.updated_at, dateFormat)
                          : ''}
                      </div>
                    ) : (
                      <div
                        className='flex-row d-flex fade-id-row'
                        key={id}
                        style={{
                          color: palette.dark800,
                        }}
                      >
                        {rowData?.last_known_update
                          ? convertToCustomerTime(rowData?.last_known_update, timeFormat)
                          : ''}
                        <br />
                        {rowData?.last_known_update
                          ? convertToCustomerTime(rowData?.last_known_update, dateFormat)
                          : ''}
                      </div>
                    )
                  ) : (
                    rowData?.last_known_update && (
                      <div
                        className='flex-row d-flex fade-id-row'
                        key={id}
                        style={{
                          color: palette.dark800,
                        }}
                      >
                        {rowData?.last_known_update
                          ? convertToCustomerTime(rowData?.last_known_update, timeFormat)
                          : ''}
                        <br />
                        {convertToCustomerTime(rowData?.last_known_update, dateFormat)}
                      </div>
                    )
                  )
                ) : rowData?.location_target === 'Equipment' && rowData?.equipment_gps?.updated_at ? (
                  <div className='flex-row d-flex fade-in-row' key={id} style={{ color: palette.dark800 }}>
                    {rowData?.equipment_gps?.updated_at
                      ? convertToCustomerTime(rowData?.equipment_gps?.updated_at, timeFormat)
                      : ''}
                    <br />
                    {convertToCustomerTime(rowData?.equipment_gps?.updated_at, dateFormat)}
                  </div>
                ) : (
                  rowData?.last_known_update && (
                    <div className='flex-row d-flex fade-in-row' key={id} style={{ color: palette.dark800 }}>
                      {rowData?.last_known_update ? convertToCustomerTime(rowData?.last_known_update, timeFormat) : ''}
                      <br />
                      {convertToCustomerTime(rowData?.last_known_update, dateFormat)}
                    </div>
                  )
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'hours_remaining',
        title: (
          <>
            <Typography variant='c3'>HOS </Typography>
            <Garlax
              title='Drive remaining/Shift remaining/Cycle remaining'
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: 13,
                    fontFamily: 'Inter',
                    whiteSpace: 'nowrap',
                    maxWidth: 'unset',
                  },
                },
              }}
            >
              <InfoIcon />
            </Garlax>
          </>
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <Typography variant='s3' style={{ color: palette.dark800, ...navLinkInnerClickableElement }}>
                {rowData.drivers?.map((d) => (
                  <Hos driver={d} />
                ))}
              </Typography>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'drivers',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='DRIVER(S)' field='[id]' nested_field='drivers' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            {rowData?.drivers &&
              rowData?.drivers.map((d) => {
                return (
                  <div style={navLinkInnerClickableElement}>
                    <UserInfo
                      key={d.id}
                      size='26px'
                      statusSize='6px'
                      onRemove={() =>
                        deleteDriverModal(
                          d?.id,
                          rowData?.id,
                          d?.fname,
                          d?.lname,
                          rowData?.equipment_id,
                          rowData?.equipment_type?.title,
                          rowData.equipment_status.equipment_status,
                          rowData?.equipment_id
                        )
                      }
                      data={{
                        id: d.id,
                        image: d.image,
                        name: `${d.fname} ${d.lname}`,
                        email: d.email,
                        phone_number: d.phone_number,
                        status: d.status_data?.driver_status,
                        user_type: 'driver',
                      }}
                    />
                  </div>
                );
              })}
            <div className='row-style' style={navLinkInnerClickableElement}>
              {!rowData?.drivers ? (
                <div className='add-driver mt-1'>
                  <div
                    className='d-flex align-items-center add-driver-wrapper'
                    onClick={($event) => {
                      addDriverModalShow(rowData);
                      $event.stopPropagation();
                    }}
                  >
                    <div className='add-driver-profile' style={{ border: '1px dashed #4F5AED' }}>
                      <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <path
                          d='M6.74999 0.750031C6.74999 0.335817 6.4142 3.05176e-05 5.99999 3.05176e-05C5.58578 3.05176e-05 5.24999 0.335817 5.24999 0.750031V5.25H0.75C0.335786 5.25 0 5.58578 0 6C0 6.41421 0.335786 6.75 0.75 6.75H5.24999L5.25 11.25C5.25 11.6642 5.58579 12 6 12C6.41421 12 6.75 11.6642 6.75 11.25L6.74999 6.75H11.25C11.6642 6.75 12 6.41421 12 6C12 5.58578 11.6642 5.25 11.25 5.25H6.74999V0.750031Z'
                          fill='#4f5aed'
                        />
                      </svg>
                    </div>
                    <span className='add-driver-content'>Add Driver</span>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'vin',
        title: <EquipmentTableSort title='VIN' sortingQuery={sortingQuery} field='vin' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {rowData?.vin}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },

      {
        field: 'make',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='MAKE' field='make' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {rowData?.make}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'year',
        title: <EquipmentTableSort sortingQuery={sortingQuery} title='YEAR' field='year' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {rowData?.year}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'axles',
        title: <EquipmentTableSort title='AXLES' sortingQuery={sortingQuery} field='axles' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {rowData?.axles || '-'}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'fuel',
        title: (
          <EquipmentTableSort
            sortingQuery={sortingQuery}
            title='FUEL'
            // field={"fuel_percent"}
            field='[fuel_percent]'
            nested_field='equipment_connected_services_device'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            {rowData?.equipment_connected_services_device?.fuel_percent ? (
              <div className='equipment-vehicle-columns'>
                <div className='d-flex align-item-flex-start mb-3'>
                  <div className=' me-2'>{PetrolIcon}</div>
                  <div className='petrolPersent'>
                    <div
                      style={{
                        width: `${rowData?.equipment_connected_services_device?.fuel_percent}%`,
                        backgroundColor: '#81D6AB',
                        borderRadius: '4px',
                      }}
                    >
                      <span style={{ textAlign: 'center' }} className='ms-2'>
                        {rowData?.equipment_connected_services_device?.fuel_percent}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : rowData?.location_target === 'Driver' ? (
              <div className='mb-2' style={navLinkInnerClickableElement}>
                <span>
                  <LightTooltip title='No telematics equipped.' placement='right'>
                    <span> {Speed}</span>
                  </LightTooltip>
                </span>
              </div>
            ) : null}
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },

      {
        field: 'model',
        title: <EquipmentTableSort title='MODEL' sortingQuery={sortingQuery} fuel_percent field='model_id' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {rowData?.model_id || '-'}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'plate',
        title: (
          <EquipmentTableSort
            sortingQuery={sortingQuery}
            title='PLATE'
            field='[license_plate_number]'
            nested_field='equipment_ownership'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {rowData?.equipment_ownership?.license_plate_number || '-'}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'speed',
        title: (
          <EquipmentTableSort
            title='SPEED'
            sortingQuery={sortingQuery}
            // field={"speed_mph"}
            field='[speed_mph]'
            nested_field='equipment_connected_services_device'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            {rowData?.equipment_connected_services_device?.speed_mph ? (
              <div className='equipment-vehicle-columns'>
                <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                  <div
                    className={
                      rowData?.equipment_connected_services_device?.speed_mph.toString().split('.')[0] !== '0'
                        ? 'speedMPH'
                        : 'speedMPHgray'
                    }
                  >
                    {Number(rowData?.equipment_connected_services_device?.speed_mph).toFixed(1) <= 0
                      ? 0
                      : Number(rowData?.equipment_connected_services_device?.speed_mph).toFixed()}
                    <span className='ms-1'>MPH </span>
                  </div>
                </p>
              </div>
            ) : rowData?.location_target === 'Driver' ? (
              rowData?.drivers && rowData?.drivers[0]?.driver_gps && rowData?.drivers[0]?.driver_gps?.mph !== null ? (
                <div
                  className={
                    rowData?.drivers[0]?.driver_gps?.mph.toString().split('.')[0] > 0 ? 'speedMPH' : 'speedMPHgray'
                  }
                >
                  {rowData?.drivers[0]?.driver_gps?.mph.toString().split('.')[0] <= 0
                    ? 0
                    : rowData?.drivers[0]?.driver_gps?.mph.toString().split('.')[0]}
                  <span className='ms-1'>MPH </span>
                </div>
              ) : (
                <div className='mb-2' style={navLinkInnerClickableElement}>
                  <span>
                    <LightTooltip title='No telematics equipped' placement='right'>
                      <span> {Speed} </span>
                    </LightTooltip>
                  </span>
                </div>
              )
            ) : null}
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'dimensions',
        title: <Typography variant='c3'>DIMENSIONS/PAYLOAD</Typography>,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              {rowData?.length ? (
                <div className='d-flex flex-column gap-1'>
                  <Typography variant='s3' style={{ color: palette.dark800, ...navLinkInnerClickableElement }}>
                    {rowData.length.length * 12} x {rowData.width || '-'} x {rowData.height || '-'} in
                  </Typography>
                  {rowData.cargo_maximum_capacity_weight && (
                    <Typography variant='s3' style={{ color: palette.dark800, ...navLinkInnerClickableElement }}>
                      Payload {formatNumber(rowData.cargo_maximum_capacity_weight, 0)}{' '}
                      {rowData.cargo_capacity_measuring_unit}
                    </Typography>
                  )}
                </div>
              ) : (
                <Typography variant='s3' style={{ color: palette.dark800, ...navLinkInnerClickableElement }}>
                  -
                </Typography>
              )}
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'odometer',
        title: (
          <EquipmentTableSort
            title='ODOMETER'
            sortingQuery={sortingQuery}
            field='[obd_odometer_miles]'
            nested_field='equipment_connected_services_device'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              {rowData?.odometer_unit === 'miles' &&
              rowData?.equipment_connected_services_device?.obd_odometer_miles ? (
                <div className='equipment-vehicle-columns'>
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    {format(rowData?.equipment_connected_services_device?.obd_odometer_miles)}
                    <span className='ms-1' style={{ color: palette.dark800 }}>
                      {rowData?.odometer_unit}
                    </span>
                  </p>
                </div>
              ) : rowData?.odometer_unit === 'meter' &&
                rowData?.equipment_connected_services_device?.obd_odometer_meter ? (
                <div className='equipment-vehicle-columns'>
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    {rowData?.equipment_connected_services_device?.obd_odometer_meter}
                    <span className='ms-1' style={{ color: palette.dark800 }}>
                      {rowData?.odometer_unit}
                    </span>
                  </p>
                </div>
              ) : rowData?.location_target === 'Driver' ? (
                <div className='mb-2' style={navLinkInnerClickableElement}>
                  <span>
                    <LightTooltip title='No telematics equipped.' placement='right'>
                      <span>{Speed}</span>
                    </LightTooltip>
                  </span>
                </div>
              ) : null}
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'next_pm',
        title: <EquipmentTableSort title='NEXT PM' sortingQuery={sortingQuery} field='next_pm' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />;
        },
      },

      {
        field: 'join_date',
        title: <EquipmentTableSort title='JOIN DATE' sortingQuery={sortingQuery} field='created_at' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              {rowData?.created_at ? (
                <div>
                  <span className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    {rowData?.created_at ? convertToCustomerTime(rowData?.created_at, timeFormat) : ''}
                  </span>
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    {rowData?.created_at ? moment.utc(rowData?.created_at).format(dateFormat) : ''}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },

      {
        field: 'can_travel_to',
        title: (
          <EquipmentTableSort
            sortingQuery={sortingQuery}
            field='[id]'
            nested_field='can_travel_to'
            title='CAN TRAVEL TO'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='d-flex justify-content-center'>
              {rowData?.drivers &&
                rowData?.drivers.map((v) => (
                  <div key={v.id}>
                    <span className='me-1'>
                      {v?.certifications?.can_travel_usa === 1 ? <img src={Icons.US} alt='' /> : ''}
                    </span>
                    <span className='me-1'>
                      {v?.certifications?.can_travel_mexico === 1 ? <img src={Icons.Mexico} alt='' /> : ''}
                    </span>
                    <span>{v?.certifications?.can_travel_canada === 1 ? <img src={Icons.Canada} alt='' /> : ''}</span>
                  </div>
                ))}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'next_available',
        title: (
          <EquipmentTableSort title='NEXT AVAILABLE' field='next_available_location' sortingQuery={sortingQuery} />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <span className='equipment-vehicle-columns-text' style={{ width: '68px', whiteSpace: 'normal' }}>
                {rowData?.next_available_location || '-'}
              </span>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'next_available_date',
        title: (
          <EquipmentTableSort title='NEXT AVAILABLE DATE' field='next_available_date' sortingQuery={sortingQuery} />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <div className='equipment-vehicle-columns'>
              {rowData?.next_available_date ? (
                <div>
                  <span className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    {rowData?.next_available_date
                      ? convertToCustomerTime(rowData?.next_available_date, timeFormat)
                      : ''}
                  </span>
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    {convertToCustomerTime(rowData?.next_available_date, dateFormat)}{' '}
                    <Garlax title='Next available based on last destination with consideration of avg wait time.'>
                      <InfoIcon fill={palette.indigo500} width={13} height={13} className='align-middle' />
                    </Garlax>
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        },
      },

      {
        field: 'last_inspection',
        title: (
          <EquipmentTableSort
            title='LAST INSPECTION'
            sortingQuery={sortingQuery}
            field='[service_date_time]'
            nested_field='equipment_maintenance_record'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <div className='equipment-vehicle-columns'>
                {rowData?.equipment_maintenance_record?.service_date_time ? (
                  <div>
                    <span className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                      {rowData?.equipment_maintenance_record
                        ? convertToCustomerTime(rowData?.equipment_maintenance_record?.service_date_time, timeFormat)
                        : ''}
                    </span>
                    <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                      {moment.utc(rowData?.equipment_maintenance_record?.service_date_time).format(dateFormat)}
                    </p>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'next_inspection',
        title: (
          <EquipmentTableSort
            title='NEXT INSPECTION'
            sortingQuery={sortingQuery}
            field='[dot_inspection_date]'
            nested_field='equipment_insurance'
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              {rowData?.equipment_insurance?.dot_inspection_date ? (
                <div>
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    {moment.utc(rowData?.equipment_insurance?.dot_inspection_date).format(dateFormat)}
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'plate_expiration',
        title: (
          <EquipmentTableSort
            title='PLATE EXPIRATION'
            field='[license_plate_expire_date]'
            nested_field='equipment_ownership'
            sortingQuery={sortingQuery}
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {rowData?.equipment_ownership?.license_plate_expire_date
                  ? moment.utc(rowData?.equipment_ownership?.license_plate_expire_date).format(dateFormat)
                  : ''}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'gross_vehicle_weight',
        title: (
          <EquipmentTableSort title='GROSS VEHICLE WEIGHT' field='gross_vehicle_weight' sortingQuery={sortingQuery} />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {rowData?.gross_vehicle_weight}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'drive_remaining',
        title: <EquipmentTableSort title='DRIVE REMAINING' field='drive_remaining' sortingQuery={sortingQuery} />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          const dr = rowData?.drivers?.map((v) => v?.driver_connected_services_hos?.drive_remaining);
          let days;
          let hours;
          let mins;
          if (dr && dr?.length !== 0) {
            const a = dr[0];
            if (a) {
              const arrayOftime = a?.trim().split(':');
              let secs = +arrayOftime[0] * 3600; // get total amount of seconds from your input
              secs += +arrayOftime[1] * 60;
              secs += +arrayOftime[2];
              days = Math.floor(secs / (3600 * 24));
              secs -= days * 3600 * 24;
              hours = Math.floor(secs / 3600);
              secs -= hours * 3600;
              mins = Math.floor(secs / 60);
              secs -= mins * 60;
            }
          }

          return (
            <>
              <div className='equipment-vehicle-columns'>
                {rowData?.drivers ? (
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    <span className='ms-1'>{days && days !== 0 ? <span>{days} Days</span> : ''}</span>
                    <span className='ms-1'>{hours && hours !== 0 ? <span>{hours} hours</span> : ''}</span>
                    <span className='ms-1'>{mins && mins !== 0 ? <span>{mins} mins</span> : ''}</span>
                  </p>
                ) : (
                  <div className='mb-2' style={navLinkInnerClickableElement}>
                    <span>
                      <LightTooltip title='No HOS service connected' placement='right'>
                        <span> {Speed}</span>
                      </LightTooltip>
                    </span>
                  </div>
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'shift_remaining',
        title: <EquipmentTableSort title='SHIFT REMAINING' field='shift_remaining ' sortingQuery={sortingQuery} />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          const a = rowData?.drivers?.map((v) => v?.driver_connected_services_hos?.shift_remaining)[0];
          let days;
          let hours;
          let mins;
          if (a) {
            const arrayOftime = a?.trim().split(':');

            let secs = +arrayOftime[0] * 3600; // get total amount of seconds from your input
            secs += +arrayOftime[1] * 60;
            secs += +arrayOftime[2];
            days = Math.floor(secs / (3600 * 24));
            secs -= days * 3600 * 24;
            hours = Math.floor(secs / 3600);
            secs -= hours * 3600;
            mins = Math.floor(secs / 60);
            secs -= mins * 60;
          }

          return (
            <>
              <div className='equipment-vehicle-columns'>
                {rowData?.drivers ? (
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    <span className='ms-1'>{days && days !== 0 ? <span>{days} Days</span> : ''}</span>
                    <span className='ms-1'>{hours && hours !== 0 ? <span>{hours} hours</span> : ''}</span>
                    <span className='ms-1'>{mins && mins !== 0 ? <span>{mins} mins</span> : ''}</span>
                  </p>
                ) : (
                  <div className='mb-2' style={navLinkInnerClickableElement}>
                    <span>
                      <LightTooltip title='No HOS service connected' placement='right'>
                        <span> {Speed}</span>
                      </LightTooltip>
                    </span>
                  </div>
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'cycle_remaining',
        title: <EquipmentTableSort title='CYCLE REMAINING' field='cycle_remaining ' sortingQuery={sortingQuery} />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          const a = rowData?.drivers?.map((v) => v?.driver_connected_services_hos?.cycle_remaining_duration)[0];
          let days;
          let hours;
          let mins;
          if (a) {
            const arrayOftime = a?.trim().split(':');

            let secs = +arrayOftime[0] * 3600; // get total amount of seconds from your input
            secs += +arrayOftime[1] * 60;
            secs += +arrayOftime[2];
            days = Math.floor(secs / (3600 * 24));
            secs -= days * 3600 * 24;
            hours = Math.floor(secs / 3600);
            secs -= hours * 3600;
            mins = Math.floor(secs / 60);
            secs -= mins * 60;
          }
          return (
            <>
              <div className='equipment-vehicle-columns'>
                {rowData?.drivers ? (
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    <span className='ms-1'>{days && days !== 0 ? <span>{days} Days</span> : ''}</span>
                    <span className='ms-1'>{hours && hours !== 0 ? <span>{hours} hours</span> : ''}</span>
                    <span className='ms-1'>{mins && mins !== 0 ? <span>{mins} mins</span> : ''}</span>
                  </p>
                ) : (
                  <div className='mb-2' style={navLinkInnerClickableElement}>
                    <span>
                      <LightTooltip title='No HOS service connected' placement='right'>
                        <span> {Speed}</span>
                      </LightTooltip>
                    </span>
                  </div>
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'time_until_break',
        title: <EquipmentTableSort title='TIME UNTIL BREAK' field='time_until_break' sortingQuery={sortingQuery} />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          const a = rowData?.drivers?.map((v) => v?.driver_connected_services_hos?.time_until_break)[0];
          let days;
          let hours;
          let mins;
          if (a) {
            const arrayOftime = a?.trim().split(':');

            let secs = +arrayOftime[0] * 3600; // get total amount of seconds from your input
            secs += +arrayOftime[1] * 60;
            secs += +arrayOftime[2];
            days = Math.floor(secs / (3600 * 24));
            secs -= days * 3600 * 24;
            hours = Math.floor(secs / 3600);
            secs -= hours * 3600;
            mins = Math.floor(secs / 60);
            secs -= mins * 60;
          }

          return (
            <>
              <div className='equipment-vehicle-columns'>
                {rowData?.drivers ? (
                  <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                    <span className='ms-1'>{days && days !== 0 ? <span>{days} Days</span> : ''}</span>
                    <span className='ms-1'>{hours && hours !== 0 ? <span>{hours} hours</span> : ''}</span>
                    <span className='ms-1'>{mins && mins !== 0 ? <span>{mins} mins</span> : ''}</span>
                  </p>
                ) : (
                  <div className='mb-2' style={navLinkInnerClickableElement}>
                    <span>
                      <LightTooltip title='No HOS service connected' placement='right'>
                        <span> {Speed}</span>
                      </LightTooltip>
                    </span>
                  </div>
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'motion',
        title: <EquipmentTableSort title='MOTION' sortingQuery={sortingQuery} nested_field='gps' field='[motion]' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => {
          return (
            <>
              <div className='equipment-vehicle-columns'>
                {rowData?.location_target === 'Equipment' && rowData?.equipment_gps?.motion?.motion ? (
                  <>
                    <div>
                      <Chip
                        fontWeight={500}
                        fontSize='11px'
                        bgColor={motionColors[rowData?.equipment_gps.motion.motion].bgColor}
                        labelColor={motionColors[rowData?.equipment_gps.motion.motion].color}
                        style={{ textTransform: 'uppercase' }}
                        label={`${rowData?.equipment_gps?.motion?.motion} ${formatSeconds(
                          rowData?.equipment_gps?.motion.duration
                        )}`}
                      />
                    </div>
                    <Typography variant='c3'>
                      Updated {getUTCTimeDifference(rowData?.equipment_gps?.motion?.updated_at)} ago
                    </Typography>
                  </>
                ) : rowData?.location_target === 'Driver' &&
                  rowData?.drivers?.length &&
                  rowData?.drivers?.[0]?.driver_gps?.motion?.motion ? (
                  <>
                    <div>
                      <Chip
                        fontWeight={500}
                        fontSize='11px'
                        bgColor={motionColors[rowData?.drivers?.[0]?.driver_gps?.motion?.motion].bgColor}
                        labelColor={motionColors[rowData?.drivers?.[0]?.driver_gps?.motion?.motion].color}
                        style={{ textTransform: 'uppercase' }}
                        label={`${rowData?.drivers?.[0]?.driver_gps?.motion?.motion} ${formatSeconds(
                          rowData?.drivers?.[0]?.driver_gps?.motion?.duration
                        )}`}
                      />
                    </div>
                    <Typography variant='c3'>
                      Updated {getUTCTimeDifference(rowData?.drivers?.[0]?.driver_gps?.motion?.updated_at)} ago
                    </Typography>
                  </>
                ) : (
                  <span>-</span>
                )}
              </div>
              <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
            </>
          );
        },
      },
      {
        field: 'duty_status',
        title: <EquipmentTableSort title='DUTY STATUS' field='duty_status ' sortingQuery={sortingQuery} />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              {rowData?.drivers ? (
                <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                  {rowData?.drivers?.map((v) => v?.driver_connected_services_hos?.duty_status)[0]}
                </p>
              ) : (
                <div className='mb-2' style={navLinkInnerClickableElement}>
                  <span>
                    <LightTooltip title='No HOS service connected' placement='right'>
                      <span> {Speed}</span>
                    </LightTooltip>
                  </span>
                </div>
              )}
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'insurance_expiration',
        title: (
          <EquipmentTableSort
            title='INSURANCE EXPIRATION'
            field='[physical_insurance_expire_date]'
            nested_field='equipment_insurance'
            sortingQuery={sortingQuery}
          />
        ),
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <div className='equipment-vehicle-columns'>
              <p className='equipment-vehicle-columns-text' style={{ color: palette.dark800 }}>
                {moment.utc(rowData?.equipment_insurance?.physical_insurance_expire_date).format(dateFormat)}
              </p>
            </div>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'connected_service',
        title: <ColumnHeader title='CONNECTED SERVICE' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <Typography variant='b2'>{rowData.equipment_connected_services_device ? 'Yes' : 'No'}</Typography>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
      {
        field: 'connected_service_serial_number',
        title: <ColumnHeader title='CONNECTED SERVICE - SERIAL NUMBER' />,
        cellStyle: { position: 'relative' },
        render: (rowData) => (
          <>
            <Typography variant='b2'>{rowData.equipment_connected_services_device?.serial_id || '-'}</Typography>
            <NavLinkWrapper state={state} to={getDefaultTo(rowData)} />
          </>
        ),
      },
    ];
  }, [vehicleData, tableSettingSwitch]);

  const [dragItem, setDragItem] = useState([]);

  const OnTableSettingChange = (value) => {
    if (value) {
      TableSetting();
    }
  };

  const onPageChange = (page) => {
    const lastPage = vehicleData.last_page;
    if (lastPage && lastPage !== 1) {
      setLoading(true);
      GetTableEquipment({ data: filteredValues, page });
    }
  };

  const onChangeRowPerPage = (amount) => {
    setRowPerPage(amount);
    setPage(1);
  };

  const updateFilter = (updatedFilter) => {
    setFilter(updatedFilter);
  };

  const PaginationComponent = () => {
    return (
      <TableFooter
        rowPerPage={rowPerPage}
        totalCount={vehicleData.total}
        totalLength={vehicleData?.data.length}
        lastPage={vehicleData.last_page}
        currentPage={vehicleData.current_page}
        onPageChange={onPageChange}
        onChangeRowPerPage={onChangeRowPerPage}
      />
    );
  };

  async function Tabs() {
    await axios.get(`${API_URL}/tabs?type=vehicle`, { headers: authHeader() }).then((res) => {
      if (res) {
        setVehicleTabData(res?.data?.data);
      } else {
        setVehicleTabData([]);
      }
    });
  }

  const filterTableColumn = (columns) => {
    if (filter?.tableColumn) {
      const values = [];
      Object.keys(filter?.tableColumn).map((item) => filter?.tableColumn[item] === true && values.push(item));
      const cols = [];
      dragItem.forEach((value) => {
        const col = columns.find((c) => c?.field === value);
        cols.push({ ...col, hidden: !values.includes(col?.field) });
        cols.sort(
          (a, b) => Number(filter?.ColumnOrder.indexOf(a.field)) - Number(filter?.ColumnOrder.indexOf(b.field))
        );
      });
      return cols;
    }
  };

  async function init(query = '', preventLoading = false) {
    if (!preventLoading) {
      setLoading(true);
    }
    if (query) {
      setQueryParameterData(query);
    } else {
      setQueryParameterData('');
    }
    if (query?.allFilterData) {
      Equipments({
        page,
        itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
        allFilterData: { ...query?.allFilterData, equipment_id_query: query?.allFilterData?.equipment_id || undefined },
      }).then((res) => {
        setCurrentPageIds(res?.data?.map((i) => i.id) || []);
        if (res && res.data) {
          setVehicleData(res);
        } else {
          setVehicleData({ data: [] });
        }
        setLoading(false);
      });
    } else if (query?.QueryAllData) {
      Equipments({
        page,
        itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
        QueryAllData: { ...query?.QueryAllData, equipment_id_query: query?.QueryAllData?.equipment_id || undefined },
      }).then((res) => {
        setCurrentPageIds(res?.data?.map((i) => i.id) || []);
        if (res && res.data) {
          setVehicleData(res);
        } else {
          setVehicleData({ data: [] });
        }
        setLoading(false);
      });
    } else {
      Equipments({
        page,
        itemsPerPage: query?.rowPage ? query?.rowPage : rowPerPage,
        query,
      }).then((res) => {
        setCurrentPageIds(res?.data?.map((i) => i.id) || []);
        if (res && res.data) {
          setVehicleData(res);
        } else {
          setVehicleData({ data: [] });
        }
        setLoading(false);
      });
    }
  }

  async function TableSetting() {
    await axios
      .get(`${API_URL}/table-settings?type=vehicle`, { headers: authHeader() })
      .then((data) => {
        if (data) {
          SET_TYPES(data.data?.data?.data.columns);
          setColumnOrder(data.data?.data?.data?.columns_order);
          setTableSettingSwitch({
            date: data.data?.data?.data?.date,
            amount: data.data?.data?.data?.amount,
            time: data.data?.data?.data?.time,
          });
        }
        setFilter({
          ...filter,
          tableColumn: data.data?.data?.data.columns,
          ColumnOrder: data.data?.data?.data?.columns_order,
          TableSetting: data.data,
          switchRadioButtons: {
            date: data.data?.data?.data?.date,
            amount: data.data?.data?.data?.amount,
            time: data.data?.data?.data?.time,
          },
        });
        setDragItem(Object.keys(data.data?.data?.data?.columns).map((t) => t));
      })
      .catch(() => {
        // Do nothing
      });
  }

  useEffect(() => {
    setTabName('vehicles');
    init('sort[][equipment_id]=asc').catch(null);
    TableSetting();
    Tabs();
  }, []);

  // useEffect(() => {
  //   const channelName = `private-equipmentList-Update.${userData?.user?.customer?.dot}`;
  //   const channel = PusherJs.subscribe(channelName);
  //   channel.bind('equipment-event', (data) => {
  //     if (filter.searchBy.value.search('location') > -1 || queryParameterData?.allFilterData?.radius) {
  //       return;
  //     }
  //     const updatedData = data?.message?.data;
  //     const currentVehicles = vehicles?.current || [];
  //
  //     if (!updatedData) {
  //       return;
  //     }
  //
  //     const vehicleIndex = currentVehicles.findIndex((item) => item.id === updatedData.id);
  //
  //     if (vehicleIndex !== -1) {
  //       const newVehicles = currentVehicles.map((item, index) =>
  //         index !== vehicleIndex
  //           ? item
  //           : {
  //               ...item,
  //               ...updatedData,
  //               drivers: updatedData.drivers ? updatedData.drivers : null,
  //               equipment_group: updatedData.equipment_group ? updatedData.equipment_group : null,
  //               equipment_reserve: updatedData.equipment_reserve ? updatedData.equipment_reserve : null,
  //             }
  //       );
  //       setVehicleData((prevState) => ({ ...prevState, data: newVehicles }));
  //     }
  //   });
  //
  //   return () => {
  //     PusherJs.unsubscribe(channelName);
  //   };
  // }, []);

  useEffect(() => {
    if (vehicleData.data) {
      vehicles.current = vehicleData.data;
    }
  }, [vehicleData]);

  useEffect(() => {
    if (vehicleData && tab === 1) {
      getMapTabs()
        .then((res) => {
          if (res) {
            const data = filter.filterTableTop.map((i) => {
              if (i.key === 'vehicle') {
                i.count = vehicleData.total;
              } else {
                i.count = res?.data?.[1]?.count || 0;
              }
              return i;
            });
            setSwitcherData(data);
          }
        })
        .catch(() => {
          // Do nothing
        });
    }
  }, [vehicleData]);

  const onSubmitModal = () => {};

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    backgroundColor: palette.white,
    outline: 0,
  };

  const OnTabChange = (value, key, item) => {
    if (key === 'vehicle') {
      filterTab(value, item);
    }
  };

  const format = (num) => {
    if (!num) return;
    const numArray = num.toString().split('').reverse();
    for (let i = 3; i < numArray.length; i += 4) {
      numArray.splice(i, 0, ',');
    }
    return numArray.reverse().join('');
  };

  function sortingQuery(field, sortBy, nested_field) {
    let urlQuery;
    if (nested_field) {
      urlQuery = `sort[${nested_field}]${field}=${sortBy}`;
    } else {
      urlQuery = `sort[][${field}]=${sortBy}`;
    }

    setSort(urlQuery);
  }

  const filterTab = (tab, item) => {
    // const v = TabData.find((c) => c.id === tab && c.type === 'vehicle');
    if (tab !== 1) {
      let query = '';
      if (item?.data?.filters?.status) {
        if (!query) {
          query += `status_id=${item?.data?.filters?.status}`;
        } else {
          query += `&status_id=${item?.data?.filters?.status}`;
        }
      }
      if (item?.data?.filters?.equipment_type_id) {
        if (!query) {
          query += `equipment_type_id=${item?.data?.filters?.equipment_type_id}`;
        } else {
          query += `&equipment_type_id=${item?.data?.filters?.equipment_type_id}`;
        }
      }
      if (item?.data?.filters?.group_id) {
        if (!query) {
          query += `group_id=${item?.data?.filters?.group_id}`;
        } else {
          query += `&group_id=${item?.data?.filters?.group_id}`;
        }
      }

      init(query);
    }
  };

  const EditModalData = (item) => {
    filterTab(item?.data?.id, item?.data);
  };

  useEffect(() => {
    if (createdEquipmentId) {
      setFilter((prevState) => ({ ...prevState, searchValue: createdEquipmentId }));
      init({ QueryAllData: { equipment_id_exact: createdEquipmentId } });
      setShowHeaderFilters([
        {
          searchValue: createdEquipmentId,
          title: 'Equipment',
          valuePrefix: 'equipment_id',
        },
      ]);
    }
  }, [createdEquipmentId]);

  const getFilterByGroups = useCallback(() => {
    Groups()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              title: el.group_name,
              id: el.id,
              value: 'groups',
              type: 'checkbox',
              key: uuid(),
              typeData: 'groups',
            };
          });
          setFilterByGroupsData(filterByData);
        }
      })
      .catch(() => {
        // Do nothing
      });
  }, []);
  const getFilterDrivers = useCallback(() => {
    setLoadingDrivers(true);
    getDrivers()
      .then((res) => {
        if (res && res?.data) {
          const filterByData = res.data.map((el) => {
            return {
              title: `${el.fname} ${el.lname}`,
              id: el.id,
              value: 'drivers',
              type: 'checkbox',
              typeData: 'drivers',
              key: uuid(),

              // key: el.id
            };
          });
          setFilterByDriversData(filterByData);
        }
      })
      .finally(() => {
        setLoadingDrivers(false);
      });
  }, []);
  const getFilterByEquipmentType = useCallback(async () => {
    const filterByEquipmentData = [];
    await Promise.all([getEquipmentRequiredVehicles()])
      .then(([vehicle]) => {
        if (vehicle && vehicle?.data) {
          vehicle.data.forEach((el) => {
            if (+el.id !== 1) {
              const filterByData = {
                title: el.title,
                id: el.id,
                value: 'equipment_types',
                type: 'checkbox',
                key: uuid(),
                typeData: 'vehicle',
              };
              filterByEquipmentData.push(filterByData);
            }
          });
        }
      })
      .catch(() => {
        // Do nothing
      });
    setFilterByEquipmentTypeData(filterByEquipmentData);
  }, []);
  useEffect(() => {
    getFilterByGroups();
    getFilterByEquipmentType().then();
    getFilterDrivers();
  }, []);

  /** PAGINATION ROW_PER_PAGE ADVANCED FILTER AND FILTER WORKED HERE* */
  const controller = useRef(new AbortController());
  const [searchBy, setSearchBy] = useState(FILTER_SEARCH_BY_VEHICLE[0]);
  const [filteredValues, setFilteredValues] = useState({});

  const onSearch = (data) => {
    const filterObject = data || filteredValues;
    GetTableEquipment({ data: filterObject }).catch();
  };

  const convertFilterData = (data) => {
    const requestData = {};
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (Array.isArray(value)) {
        if (key === 'ownership') {
          requestData[key] = value.map((el) => el.title).toString();
          requestData.ownership_type = value.map((el) => el.ownership_type).toString();
        } else {
          requestData[key] = value.map((el) => el.id).toString();
        }
      } else if (typeof value === 'string') {
        requestData[key] = value;
      }
    });

    return requestData;
  };

  const GetTableEquipment = async ({ data = {}, page = 1 }) => {
    setLoading(true);
    try {
      if (controller?.current) {
        controller?.current?.abort();
        controller.current = new AbortController();
      }

      const requestData = convertFilterData(data);
      const options = {
        signal: controller?.current?.signal,
        page,
        query: sort,
        itemsPerPage: rowPerPage,
        allFilterData: requestData,
      };
      const res = await Equipments(options);
      res?.data ? setVehicleData(res) : setVehicleData({ data: [] });
      setPage(page);
    } catch (e) {
      // Do nothing
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (didMountPageRef?.current) {
      GetTableEquipment({ data: filteredValues }).catch();
    } else {
      didMountPageRef.current = true;
    }
  }, [sort, tableIndex, rowPerPage, filter]);

  /** PAGINATION ROW_PER_PAGE ADVANCED FILTER AND FILTER WORKED HERE* */

  return (
    <>
      <VehicleHeader
        onSearch={onSearch}
        searchBy={searchBy}
        setSearchBy={setSearchBy}
        filter={filteredValues}
        setFilter={setFilteredValues}
        driverSelectData={filterByDriversData}
        loadingDrivers={loadingDrivers}
        TYPES={TYPES}
        dragItem={dragItem}
        tablePopoverFilter={filter}
        updateTablePopoverFilter={updateFilter}
        OnTableSettingChange={OnTableSettingChange}
        onChangeOrder={(items) => setDragItem(items)}
      />

      <SwitchRadio
        name='tableTopTabMenu'
        items={switcherData || []}
        title='vehicle'
        value={tab}
        type='tab'
        TabData={vehicleTabData}
        OnTabChange={OnTabChange}
        multiSelectOptions={multiSelectOptions}
        onChange={(n, v) => {
          setMainTab(+v);
        }}
      />
      <div className='ms-4 mb-2 mt-0' style={{ height: '50px' }}>
        <SubTabs
          name='tableTopTabMenu'
          value={tab}
          type='tab'
          TabData={vehicleTabData}
          Tabs={Tabs}
          EditModalData={EditModalData}
          OnTabChange={OnTabChange}
          multiSelectOptions={multiSelectOptions}
          onChange={(n, v) => setTab(+v)}
          plus
          onClickPlus={() => setShowModal(true)}
        />
      </div>
      {loading ? (
        <Preloader />
      ) : (
        <div className='tableFixHead table-fixed-header-400'>
          <MaterialTableWrapper
            data={vehicleData?.data}
            rowPerPage={rowPerPage}
            components={{
              Pagination: PaginationComponent,
            }}
            columns={filterTableColumn(columns)}
            onRowClick={(e, rowData) => {
              navigate(`/equipment-profile/vehicle/${rowData?.id}`, { state: { tab: 'vehicles' } });
            }}
            style={{ backgroundColor: palette.white }}
          />
        </div>
      )}
      <CustomModal
        showModal={showModal}
        styleBody={{ width: 446 }}
        onHide={() => setShowModal(false)}
        headerTitle='Add Tab'
        buttons={[
          { key: 'close', type: 'secondary', title: 'Cancel' },
          {
            key: 'submit',
            type: 'primary',
            title: 'Add Tab',
            onClick: onSubmitModal,
          },
        ]}
      >
        <EquipmentTableModal
          onHide={() => setShowModal(false)}
          multiSelectOptions={multiSelectOptions}
          Tabs={Tabs}
          // getTabValue={getTabValue}
          onChange={(n, v) => setTab(+v)}
          OnTabChange={OnTabChange}
          currentType='Vehicle Type'
        />
      </CustomModal>
      <Modal
        show={reserveEquipment}
        open={reserveEquipment}
        onClose={ReserveEquipmentModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={reserveEquipment}>
          <Box sx={style}>
            <ReserveEquipment
              id={id}
              ReserveEquipmentModalClose={ReserveEquipmentModalClose}
              toggleShowA={toggleShowA}
              toggleCloseA={toggleCloseA}
              GetreserveMsgData={GetreserveMsgData}
              onSuccess={() => setTableIndex(Date.now())}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={hook}
        onClose={hookModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={hook}>
          <Box sx={style}>
            <AddHook
              id={id}
              handleClose={hookModalClose}
              SuccessShow={SuccessShow}
              SuccessClose={SuccessClose}
              HookedErrClose={HookedErrClose}
              HookedErrShow={HookedErrShow}
              GetHookedError={GetHookedError}
              onSuccess={() => setTableIndex(Date.now())}
            />
          </Box>
        </Fade>
      </Modal>
      <BootstrapModal enforceFocus={false} show={addDriver} onHide={addDriverModalClose} centered>
        <AddDriver
          vehicle={selectedEquipment}
          handleClose={addDriverModalClose}
          DriversAddedmsgModal={(id) => DriversAddedmsgModal(id)}
          onSuccess={() => setTableIndex(Date.now())}
        />
      </BootstrapModal>
      <Modal
        open={delDriver}
        onClose={deleteDriverModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={delDriver}>
          <Box sx={style}>
            <RemoveDriver
              RemoveDriverData={removeDriverData}
              deleteDriverModalClose={deleteDriverModalClose}
              RemoveDriverModal={(id) => RemoveDriverModal(id)}
              RemoveDriverModalClose={RemoveDriverModalClose}
              onSuccess={() => setTableIndex(Date.now())}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={addGroup}
        onClose={addGroupModalClose}
        style={{ outline: 0, zIndex: 999 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={addGroup}>
          <Box sx={style}>
            <AddGroup
              handleClose={addGroupModalClose}
              id={id}
              AddGroupSuccessModal={(id) => AddGroupSuccessModal(id)}
              onSuccess={() => setTableIndex(Date.now())}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={removeGroup}
        onClose={RemoveGroupModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={removeGroup}>
          <Box sx={style}>
            <RemoveGroup
              handleClose={RemoveGroupModalClose}
              RemoveGroupData={RemoveGroupData}
              RemoveGroupSuccessModal={(id) => RemoveGroupSuccessModal(id)}
              onSuccess={() => setTableIndex(Date.now())}
            />
          </Box>
        </Fade>
      </Modal>
      {addActionOpen && (
        <PlannedLoads
          open={addActionOpen}
          shipmentData={{ equipment: selectedEquipment }}
          onClose={() => {
            setAddActionOpen(false);
            setSelectedEquipment(null);
          }}
          hideView
        />
      )}

      <Modal
        open={unReseveredEq}
        onClose={UnReservedModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={unReseveredEq}>
          <Box sx={style}>
            <UnresevedEquipment
              handleClose={UnReservedModalClose}
              unReseverdEqId={unReseveredEqId}
              alreadyReserveid={alreadyReserveId}
              UnReserveSuccessShow={(id) => UnReserveSuccessShow(id)}
              UnReserveSuccessClose={UnReserveSuccessClose}
              onSuccess={() => setTableIndex(Date.now())}
            />
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={unHookedTrailer}
        onClose={UnHookedModalClose}
        style={{ outline: 0 }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Fade in={unHookedTrailer}>
          <Box sx={style}>
            <UnHooked
              handleClose={UnHookedModalClose}
              unHookedData={unHookedData}
              UnhookedMsgClose={UnhookedMsgClose}
              UnHoookedMsgShow={(id) => UnHoookedMsgShow(id)}
              HookedErrClose={HookedErrClose}
              HookedErrShow={HookedErrShow}
              GetHookedError={GetHookedError}
              onSuccess={() => setTableIndex(Date.now())}
            />
          </Box>
        </Fade>
      </Modal>

      <Popover
        id={ProfileAlertContent}
        // anchorPosition={{ top: 299, left: 669 }}
        open={ProfileAlertOpen}
        anchorEl={profileAlert}
        onClose={ProfileAlerthandleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '20px' }}
      >
        <AlertMsg alertData={alertData} alertID={alertID} equipmentAlert />
      </Popover>
      <Popover
        id={Popup}
        anchorPosition={{ top: 200, left: 400 }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DriverInfo driverData={driverPopupData} PopupHandleClose={handleClose} deleteDriverModal={deleteDriverModal} />
      </Popover>
      <Popover
        id={FaultCodeContent}
        anchorPosition={{ top: 299, left: 669 }}
        open={faultcodeOpen}
        anchorEl={faultCode}
        onClose={FaultCodehandleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        style={{ marginLeft: '20px' }}
      >
        <div style={{ width: '360px' }}>
          <FaultCode faultCodeData={faultCodeData} />
        </div>
      </Popover>
      <Toast show={showA} onClose={toggleCloseA} className='reserve-equipment-toast'>
        <ReserveEquipmentMsg
          handleClose={toggleCloseA}
          reserveMsgData={reserveMsgData}
          userFname={user.first_name}
          userLname={user.last_name}
        />
      </Toast>
      <Toast show={success} onClose={SuccessClose} className='equipment-success-msg'>
        <AddHookedSuccess handleClose={SuccessClose} />
      </Toast>

      <Toast show={Hookederr} onClose={HookedErrClose} className='equipment-err-msg'>
        {/* <div className="errText">{getHookederr}</div> */}.
        <AddHookedError getHookederr={getHookederr} handleClose={HookedErrClose} />
      </Toast>
      <Toast show={unreserveSuccess} onClose={UnReserveSuccessClose} className='equipment-success-msg'>
        <div>
          <UnreservedEquipmentmsg handleClose={UnReserveSuccessClose} />
        </div>
      </Toast>
      <Toast show={unHookedMsg} onClose={UnhookedMsgClose} className='equipment-success-msg'>
        <div>
          <UnhookedMsg
            handleClose={UnReserveSuccessClose}
            UnhookedMsgClose={UnhookedMsgClose}
            UnHoookedMsgShow={UnHoookedMsgShow}
          />
        </div>
      </Toast>
      <Toast show={unHookedMsg} onClose={UnhookedMsgClose} className='equipment-success-msg'>
        <div>
          <UnhookedMsg
            handleClose={UnReserveSuccessClose}
            UnhookedMsgClose={UnhookedMsgClose}
            UnHoookedMsgShow={UnHoookedMsgShow}
          />
        </div>
      </Toast>
      <Toast show={removeDrivermsg} onClose={RemoveDriverModalClose} className='equipment-success-msg'>
        <div>
          <RemovedDriverMsg RemoveDriverModalClose={RemoveDriverModalClose} />
        </div>
      </Toast>
      <Toast show={driverAddedmsg} onClose={DriversAddedmsgModalClose} className='equipment-success-msg'>
        <div>
          <AddDriverSuccess DriversAddedmsgModalClose={DriversAddedmsgModalClose} />
        </div>
      </Toast>
      <Toast show={alreadyReserveMsg} onClose={AlreadyReserveMsgModalClose} className='already-reserve-equipment-toast'>
        <div>
          <AlreadyReserved
            alreadyReserveid={alreadyReserveId}
            AlreadyReserveMsgModalClose={AlreadyReserveMsgModalClose}
          />
        </div>
      </Toast>
      <Toast show={addGroupSuccess} onClose={AddGroupSuccessClose} className='equipment-success-msg'>
        <div>
          <AddGroupSuccess AddGroupSuccessClose={AddGroupSuccessClose} />
        </div>
      </Toast>
      <Toast show={removeGroupSuccess} onClose={RemoveGroupSuccessModalClose} className='equipment-success-msg'>
        <div>
          <RemoveGroupSuccess RemoveGroupSuccessModalClose={RemoveGroupSuccessModalClose} />
        </div>
      </Toast>
    </>
  );
};

export default VehicleTable;
