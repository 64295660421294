import jsPDF from 'jspdf';
import JsBarcode from 'jsbarcode';
import locationIcon from 'assets/icons/location.png';
import globeIcon from 'assets/icons/globe.png';
import mailIcon from 'assets/icons/mail.png';
import phoneIcon from 'assets/icons/phone.png';
import { formatNumber } from 'utils/constants';
import { generatePaymentMethods } from './paymentMethods';

const getBarcode = (id, displayValue = true) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, id, { displayValue });

  return canvas.toDataURL('image/jpeg');
};

const createFooter = ({
  doc,
  accountingSettings,
  companyProfile,
  instructions,
  page = 1,
  totalPages = 1,
  payment_method,
  stripePaymentLink,
  total_amount,
  batch_due_date,
  currency,
  formatDate,
}) => {
  if (!accountingSettings || !companyProfile) {
    return null;
  }

  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const { website, show_website, show_billing, receivable_email } = accountingSettings || {};
  const { address1, address2, city_id, state_id, zip, phone_number } = companyProfile || {};

  const phone = `${phone_number.slice(0, 2)} (${phone_number.slice(2, 5)}) ${phone_number.slice(
    5,
    8
  )}-${phone_number.slice(8)}`;

  // background
  if (page === totalPages) {
    doc.setDrawColor(161, 169, 184);
    doc.setFillColor(247, 249, 252);
    doc.rect(0, pageHeight - 22, pageWidth, 26, 'F');
  }

  // Pagination
  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#687182');
  doc.text(`Page ${page} of ${totalPages}`, pageWidth / 2, pageHeight - 5.5, { align: 'center' });

  if (page === totalPages) {
    doc.setDrawColor(233, 237, 245);
    doc.line(pageWidth / 4, pageHeight - 20, pageWidth / 4, pageHeight - 10);
    doc.line(pageWidth / 2 + 1, pageHeight - 20, pageWidth / 2 + 1, pageHeight - 10);
    doc.line(pageWidth - pageWidth / 4, pageHeight - 20, pageWidth - pageWidth / 4, pageHeight - 10);

    doc.setFontSize(10);
    doc.addImage(locationIcon, 'png', 13, pageHeight - 20, 3, 3, undefined, 'FAST');
    doc.addImage(globeIcon, 'png', pageWidth / 4 + 5, pageHeight - 20, 3, 3, undefined, 'FAST');
    doc.addImage(mailIcon, 'png', pageWidth / 2 + 6, pageHeight - 20, 4, 3, undefined, 'FAST');
    doc.addImage(phoneIcon, 'png', pageWidth - pageWidth / 4 + 6, pageHeight - 20, 3, 3, undefined, 'FAST');
    doc.text('Address', 18, pageHeight - 17.5);
    doc.text('Online', pageWidth / 4 + 10, pageHeight - 17.5);
    doc.text('Email', pageWidth / 2 + 12, pageHeight - 17.5);
    doc.text('Phone', pageWidth - pageWidth / 4 + 11, pageHeight - 17.5);
    doc.setFontSize(9);
    doc.setTextColor('#171C26');
    doc.text(`${address1}`, 13, pageHeight - 13);
    if (address2) {
      doc.text(`${address2}`, 13, pageHeight - 9);
    }
    doc.text(`${city_id?.name}, ${state_id?.name} ${zip}`, 13, address2 ? pageHeight - 5 : pageHeight - 9, {
      maxWidth: 40,
    });
    if (show_website === 1) {
      doc.text(`${website}`, pageWidth / 4 + 5, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 4 + 5, pageHeight - 13);
    }
    if (show_billing) {
      doc.text(`${receivable_email}`, pageWidth / 2 + 6, pageHeight - 13);
    } else {
      doc.text('-', pageWidth / 2 + 6, pageHeight - 13);
    }
    doc.text(`${phone}`, pageWidth - pageWidth / 4 + 6, pageHeight - 13);

    // summary
    doc.setFillColor(170, 91, 0);
    doc.rect(135, pageHeight - 50, pageWidth / 3, 6, 'F');

    doc.setFont('Inter', 'normal', 700);
    doc.setFontSize(10);
    doc.setTextColor('#FFFFFF');
    doc.text('Payment Due Date', pageWidth / 3 + 67, pageHeight - 46);
    doc.text('Total Due', pageWidth / 3 + 110, pageHeight - 46);

    doc.setTextColor('#171C26');
    doc.text(`${formatDate(batch_due_date)}`, pageWidth / 3 + 67, pageHeight - 40);
    doc.setFont('Inter', 'normal', 700);
    doc.text(`${currency}${formatNumber(total_amount)}`, pageWidth / 3 + 110, pageHeight - 40);

    doc.setFontSize(8);
    generatePaymentMethods({
      doc,
      page,
      pageHeight,
      instructions,
      payment_method,
      stripePaymentLink,
    });
  }
};

export const generateBatchPDF = ({
  accountingSettings,
  companyProfile,
  instructions,
  tableData,
  batchId,
  billDate = null,
  payment_method,
  stripePaymentLink,
  total_amount,
  batch_due_date,
  payable_to,
  send_to_company,
  currency,
  formatDate,
}) => {
  if (!accountingSettings || !companyProfile) {
    return null;
  }

  const {
    address_line1,
    address_line2,
    city: cityA,
    state: stateA,
    zip: zipA,
    factoring_company,
  } = accountingSettings || {};

  const { address1, address2, city_id, state_id, country, zip, company_logo, company_name } = companyProfile || {};

  const doc = new jsPDF({ format: 'letter' });
  const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#171C26');
  doc.text(`${company_name}`, 16, 25);
  doc.text(`${address1}${address2 ? `, ${address2}` : ''}`, 16, 30);
  doc.text(`${city_id?.name}, ${state_id?.name} ${zip}`, 16, 35);

  doc.addImage(`${company_logo}?cache=${Math.random()}`, 'JPEG', 16, 8, 60, 12, 'Logo', 'FAST');

  if (payable_to === 'factoring_company') {
    doc.text(`${factoring_company}`, 16, 52);
    doc.text(`${address_line1}${address_line2 ? `, ${address_line2}` : ''}`, 16, 57);
    doc.text(`${cityA?.name}, ${stateA?.name} ${zipA}`, 16, 62);
    doc.text(`${country}`, 16, 67);
  } else {
    doc.text(`${send_to_company.company_name}`, 16, 52);
    doc.text(`${send_to_company.address1}${send_to_company.address2 ? `, ${send_to_company.address2}` : ''}`, 16, 57);
    doc.text(`${send_to_company.city?.name}, ${send_to_company.state?.name || ''} ${send_to_company.zipcode}`, 16, 62);
    doc.text(`${send_to_company.country?.name || ''}`, 16, 67);
  }

  // Invoice ID
  doc.setFillColor(70, 79, 96);
  doc.rect(pageWidth - 110, 13, pageWidth - (pageWidth - 94), 7, 'F');
  doc.setFontSize(11);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#FFFFFF');
  doc.text('Invoice Batch ID', pageWidth - 108, 18);
  doc.setFont('Inter', 'normal', 400);
  doc.text(`${batchId}`, pageWidth - 69, 18);
  doc.setFontSize(12);
  doc.addImage(getBarcode(`${batchId}`, true), 'JPEG', pageWidth - 50, 21, 35, 15, undefined, 'FAST');

  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#000000');
  doc.setFontSize(10);
  doc.text(`Bill Date:`, pageWidth - 108, 26);

  doc.setFont('Inter', 'normal', 400);
  doc.text(`${billDate ? formatDate(billDate) : '-'}`, pageWidth - 82, 26);

  doc.setFillColor(70, 79, 96);
  doc.rect(pageWidth - 110, 39, pageWidth - (pageWidth - 94), 7, 'F');
  doc.setFontSize(11);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#FFFFFF');
  doc.text('Make Payable To', pageWidth - 108, 44);

  doc.setFont('Inter', 'normal', 400);
  doc.setFontSize(10);
  doc.setTextColor('#171C26');

  doc.text(`${company_name}`, pageWidth - 107, 52);
  doc.text(`${address1}`, pageWidth - 107, 56);
  if (address2) {
    doc.text(`${address2}`, pageWidth - 107, 60);
  }
  doc.text(`${city_id.name}, ${state_id.name} ${zip}`, pageWidth - 107, address2 ? 64 : 60);
  doc.text(`${country}`, pageWidth - 107, address2 ? 68 : 64);

  doc.setDrawColor(188, 194, 206);
  doc.line(16, 75, pageWidth - 16, 75);
  doc.line(16, 75.2, pageWidth - 16, 75.2);

  doc.setFont('Inter', 'normal', 700);
  doc.setFontSize(20);
  doc.setTextColor('#000000');
  doc.text(`Invoice Batch Statement`, 16, 84);

  doc.setFontSize(8);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#171C26');
  doc.text(
    `This statement contains multiple invoices following this cover page. It includes in the following order: invoice and Supporting Documents.`,
    16,
    90
  );

  doc.line(16, 94, pageWidth - 16, 94);
  doc.line(16, 94.1, pageWidth - 16, 94.1);

  doc.setFontSize(10);
  doc.setFont('Inter', 'normal', 700);
  doc.setTextColor('#000000');
  doc.text(`Bill To`, 16, 100);
  doc.text(`Due Date`, 70, 100);
  doc.text(`Invoice ID`, 105, 100);
  doc.text(`Reference ID`, pageWidth - 85, 100);
  doc.text(`Total Charges`, pageWidth - 50, 100);

  doc.setDrawColor(233, 237, 245);
  doc.setFont('Inter', 'normal', 400);
  doc.setTextColor('#464F60');

  tableData.forEach((data, index) => {
    doc.text(`${data.billing_customer.company_name}`, 16, 108 + index * 7);
    doc.text(`${data.due_date ? formatDate(data.due_date) : '-'}`, 70, 108 + index * 7);
    doc.text(`${data.invoiceId}`, 105, 108 + index * 7);
    doc.text(`${data.reference_id}`, pageWidth - 85, 108 + index * 7);
    doc.text(`${currency}${formatNumber(data.total_amount)}`, pageWidth - 50, 108 + index * 7);
    doc.line(16, 110 + index * 7, pageWidth - 16, 109 + index * 7);
  });

  const totalPages = doc.internal.getNumberOfPages(); // Total Page Number
  for (let i = 0; i < totalPages; i++) {
    doc.setPage(i);
    const page = doc.internal.getCurrentPageInfo().pageNumber; // Current Page
    doc.setFontSize(12);
    createFooter({
      doc,
      accountingSettings,
      companyProfile,
      instructions,
      page,
      totalPages,
      payment_method,
      stripePaymentLink,
      total_amount,
      batch_due_date,
      currency,
      formatDate,
    });
  }

  const url = doc.output('datauristring');
  const blob = doc.output('blob');

  return { blob, url };
};
