import React, { useMemo } from 'react';
import Modal from 'common/Modal';
import ChangeLog, { CHANGE_LOG_TYPE } from 'componentsV2/ChangeLog';
import { palette } from 'utils/constants';

const EquipmentLogs = ({ open, onClose, profileData }) => {
  const { equipment_id, equipment_type, id } = profileData;
  const additionalParams = useMemo(() => ({ equipment_id: id }), [id]);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={
        <>
          Equipment Logs for {equipment_id} {equipment_type?.title}
        </>
      }
      $bgColor={palette.gray0}
      $minWidth='1200px'
      $maxWidth='1400px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', fontFamily: 'Inter' }}
      styleModal={{ zIndex: '1050' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Close',
          onClick: onClose,
        },
      ]}
    >
      <ChangeLog type={CHANGE_LOG_TYPE.EQUIPMENT} additionalParams={additionalParams} hideFilters />
    </Modal>
  );
};

export default EquipmentLogs;
