import React from 'react';
import { palette } from 'utils/constants';
import Modal from 'common/Modal';
import DelayReasonCodes from 'componentsV2/Customer/EdiApi/DelayReasonCodes/index';

const DelayReasonCodesModal = ({ open, onClose, customerId }) => {
  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle='Delay Reason Codes'
      $bgColor={palette.white}
      $maxWidth='1350px'
      $minWidth='1350px'
      backdropClassName='double-modal-backdrop'
      backdrop='static'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px', margin: '10px 5px' }}
      buttons={[{ key: 'close', type: 'secondary', title: 'Done' }]}
    >
      <DelayReasonCodes customerId={customerId} onlyEdit />
    </Modal>
  );
};

export default DelayReasonCodesModal;
