import React, { useEffect, useState } from 'react';
import Modal from 'common/Modal';
import Input from 'common/Input';
import Uploader from 'common/Uploader';
import Autocomplete from 'common/Autocomplete';
import useForm from 'hooks/useForm';
import { palette } from 'utils/constants';
import { getErrorMessage } from 'utils/error';
import useShowToaster from 'hooks/useShowToaster';
import { documentType } from 'Api/Document';
import { getSlaveShipments, postShipmentDocument, putShipmentDocument } from 'Api/Shipment';
import { validationSchema } from './validationSchema';
import { getInitialValues } from './AddDocument.data';

const AddDocument = ({ open, onClose, document, shipment, onSuccess }) => {
  const showToaster = useShowToaster();
  const [loading, setLoading] = useState(false);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [slaveShipments, setSlaveShipments] = useState([]);

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append(
        'shipment_id',
        shipment.shipment_type === 'ltl' ? values.shipment.shipment_id : shipment.shipment_id
      );
      formData.append('reference_id', values.reference_id);
      formData.append('type', values.type.id);
      formData.append('doc', values.doc);

      if (values.shipment_stop) {
        formData.append('shipment_stop_id', values.shipment_stop.id);
      }

      if (document) {
        await putShipmentDocument({ formData, id: document.id });
      } else {
        await postShipmentDocument({ formData });
      }
      showToaster({ type: 'success', message: `Document ${document ? 'updated' : 'created'} successfully` });
      onSuccess();
      onClose();
    } catch (e) {
      showToaster({ type: 'error', message: getErrorMessage(e) });
    } finally {
      setLoading(false);
    }
  };

  const { values, handleChange, handleSubmit, touchedErrors } = useForm({
    initialValues: getInitialValues(document, shipment),
    onSubmit,
    validationSchema,
  });

  const getDocumentTypes = async () => {
    try {
      const { data } = await documentType({ shipment_id_required: 1 });
      if (data?.length) {
        setDocumentTypes(data.filter((el) => !el.hidden));
      }
    } catch (e) {
      // Do nothing
    }
  };

  const getSlaveShipmentsList = async () => {
    try {
      const { data } = await getSlaveShipments({ shipment_id: shipment.shipment_id });
      if (data?.length) {
        setSlaveShipments(data);
      }
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getDocumentTypes();

    if (shipment?.shipment_type === 'ltl') {
      getSlaveShipmentsList();
    }
  }, []);

  return (
    <Modal
      showModal={open}
      onHide={onClose}
      headerTitle={document ? 'Update Document' : 'Add Document'}
      $bgColor={palette.gray0}
      $maxWidth='500px'
      $minWidth='500px'
      styleButtons={{ padding: '6px 12px', fontSize: '14px', fontWeight: 500, lineHeight: '20px' }}
      buttons={[
        {
          key: 'close',
          type: 'secondary',
          title: 'Cancel',
        },
        {
          key: 'submit',
          type: 'primary',
          title: document ? 'Update Document' : 'Add Document',
          disabled: loading,
          onClick: handleSubmit,
        },
      ]}
    >
      <div>
        <div className='mb-4'>
          <Input
            required
            label='Reference'
            name='reference_id'
            value={values.reference_id}
            onChange={handleChange}
            error={touchedErrors.reference_id}
          />
        </div>
        {shipment.shipment_type === 'ltl' && (
          <div className='mb-4'>
            <Autocomplete
              required
              name='shipment'
              label='Shipment ID/Customer'
              options={slaveShipments}
              value={values.shipment}
              onChange={(e, val) => handleChange('shipment', val)}
              isOptionEqualToValue={(option, value) => option.shipment_id === value.shipment_id}
              getOptionLabel={(option) =>
                `${option.shipment_id} ${option.shipment_billing?.[0]?.billing_customer?.company_name}`
              }
              error={touchedErrors.shipment}
            />
          </div>
        )}
        <div className='mb-4'>
          <Autocomplete
            required
            name='type'
            label='Document Type'
            options={documentTypes}
            value={values.type}
            onChange={(e, val) => handleChange('type', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            error={touchedErrors.type}
          />
        </div>
        <div className='mb-4'>
          <Autocomplete
            name='shipment_stop'
            label='Shipment Stop'
            options={shipment.shipment_stops}
            value={values.shipment_stop}
            onChange={(e, val) => handleChange('shipment_stop', val)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => `${option.stop_point.location_name}`}
            error={touchedErrors.shipment_stop}
          />
        </div>
        <div className='mb-3'>
          <Uploader
            type={1}
            required
            label='Upload Document'
            document={values.doc}
            maxSize={5242880}
            showMaxSizeLabel
            accept={['application/pdf']}
            onDrop={(files, fileRejections) => {
              if (!fileRejections?.length) {
                handleChange('doc', files[0]);
              }
            }}
            onRemove={() => handleChange('doc', null)}
            error={touchedErrors.doc}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddDocument;
