import axios from 'services/axios';

export async function saveFinishLater(id, body) {
  const { data } = await axios.post(`driver/draft-step-number/${id}`, body);
  return data;
}

export async function createDriverPolicies(body) {
  const { data } = await axios.post(`driver/save-policies`, body);
  return data;
}

export async function getDriverPolicies(id, params) {
  const { data } = await axios.get(`driver/get-policies/${id}`, { params });
  return data;
}

export async function getConnectedServices() {
  const { data } = await axios.get('customer/all-connected-services');
  return data;
}

export async function getConnectedServiceSettings(id) {
  const { data } = await axios.get(`/customer/connected-services-settings/${id}`);
  return data;
}

export async function disconnectQuickbooksService(body) {
  const { data } = await axios.post('/quickbooks/remove-connected-service', body);
  return data;
}

export async function getDriverGroups() {
  const { data } = await axios.get('group');
  return data;
}

export async function assignVehicleToDriver(body) {
  const { data } = await axios.post('/equipment-driver', body);
  return data;
}

// Medical Examiners Results
export async function getMedicalExaminers(id) {
  const { data } = await axios.get(`driver/${id}/medical-examiner-results`);
  return data;
}

export async function createMedicalExaminers(id, body) {
  const { data } = await axios.post(`driver/${id}/medical-examiner-result`, body);
  return data;
}

export async function updateMedicalExaminers(body) {
  const { data } = await axios.put('driver/medical-examiner-result', body);
  return data;
}

export async function deleteMedicalExaminers(id) {
  const { data } = await axios.delete(`driver/medical-examiner-result/${id}`);
  return data;
}

export async function uploadMedicalExaminersFile(body) {
  const { data } = await axios.post('driver/medical-examiner-result/upload', body);
  return data;
}

// MVR Records
export async function getDriverMvrRecords(id) {
  const { data } = await axios.get(`driver/${id}/mvr-records`);
  return data;
}

export async function createDriverMvrRecords(id, body) {
  const { data } = await axios.post(`driver/${id}/mvr-record`, body);
  return data;
}

export async function updateDriverMvrRecords(body) {
  const { data } = await axios.put('driver/mvr-record', body);
  return data;
}

export async function deleteMvrRecord(id) {
  const { data } = await axios.delete(`driver/mvr-record/${id}`);
  return data;
}

export async function uploadMvrFile(body) {
  const { data } = await axios.post('driver/mvr-record-result/upload', body);
  return data;
}

// Drug Test Records
export async function getDriverDrugTestRecords(id) {
  const { data } = await axios.get(`driver/${id}/drug-test-record`);
  return data;
}

export async function createDriverDrugTestRecords(id, body) {
  const { data } = await axios.post(`driver/${id}/drug-test-record`, body);
  return data;
}

export async function updateDriverDrugTestRecords(body) {
  const { data } = await axios.put('driver/drug-test-record', body);
  return data;
}

export async function deleteDrugTestRecord(id) {
  const { data } = await axios.delete(`driver/drug-test-record/${id}`);
  return data;
}

export async function uploadDrugTestFile(body) {
  const { data } = await axios.post('driver/drug-test-record-result/upload', body);
  return data;
}

// FMCSA Results
export async function getFmcsaResults(id) {
  const { data } = await axios.get(`driver/${id}/fmcsa-result`);
  return data;
}

export async function createDriverFmcsa(id, body) {
  const { data } = await axios.post(`driver/${id}/fmcsa-result`, body);
  return data;
}

export async function updateDriverFmcsa(body) {
  const { data } = await axios.put('driver/fmcsa-result', body);
  return data;
}

export async function deleteFmcsaRecord(id) {
  const { data } = await axios.delete(`driver/fmcsa-result/${id}`);
  return data;
}

export async function uploadFmcsaRecordFile(body) {
  const { data } = await axios.post('driver/fmcsa-result/upload', body);
  return data;
}

// Criminal Records
export async function getDriverCriminalRecords(id) {
  const { data } = await axios.get(`driver/${id}/criminal-record`);
  return data;
}

export async function createDriverCriminalRecords(id, body) {
  const { data } = await axios.post(`driver/${id}/criminal-check-record`, body);
  return data;
}

export async function updateDriverCriminalRecords(body) {
  const { data } = await axios.put('driver/criminal-check-record', body);
  return data;
}

export async function deleteCriminalRecord(id) {
  const { data } = await axios.delete(`driver/criminal-check-record/${id}`);
  return data;
}

export async function uploadCriminalRecordFile(body) {
  const { data } = await axios.post('driver/criminal-check-record/upload', body);
  return data;
}

// PSP Results
export async function getDriverPsp(id) {
  const { data } = await axios.get(`driver/${id}/psp-result`);
  return data;
}

export async function createDriverPsp(id, body) {
  const { data } = await axios.post(`driver/${id}/psp-result`, body);
  return data;
}

export async function updateDriverPsp(body) {
  const { data } = await axios.put('driver/psp-result', body);
  return data;
}

export async function deletePspRecord(id) {
  const { data } = await axios.delete(`driver/psp-result/${id}`);
  return data;
}

export async function uploadPspRecordFile(body) {
  const { data } = await axios.post('driver/psp-result/upload', body);
  return data;
}

export async function getDriverChangeLog(params, signal) {
  const { data } = await axios.get('/driver/change-log', { params, signal });
  return data;
}
