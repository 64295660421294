import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { QueryClientProvider } from 'react-query';
import { Provider as ProviderRedux } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { getAppUrl } from 'utils/helpers';
import App from './App';
import { AuthProvider } from './context/auth.context';
import { queryClient } from './reactQuery/reactQuery';
import { ThemeProvider } from './context/themeContext';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import store from './store/store';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [
    'https://app-dev.truckindigital.com/',
    'https://app-staging.truckindigital.com/',
    'https://app.truckindigital.com/',
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: window.location.hostname !== 'localhost',
});

ReactDOM.render(
  <ProviderRedux store={store}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </LocalizationProvider>
  </ProviderRedux>,
  document.getElementById('root')
);
