import React, { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { Form, Formik, setNestedObjectValues } from 'formik';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactComponent as CheckIcon } from 'assets/icons/check-filled.svg';
import { ReactComponent as GearIcon } from 'assets/icons/drivers/setting.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/equipment/warning.svg';
import { ReactComponent as ArrowIcon } from 'assets/icons/createShipment/subRight.svg';
import Divider from 'common/Divider';
import Tooltip from 'common/Tooltip';
import InputLabel from 'common/InputLabel';
import { SimpleLoader } from 'common/Loader';
import Autocomplete from 'common/Autocomplete';
import { DateTimePicker } from 'common/Pickers';
import { Typography } from 'components/Typography';
import CustomButton from 'components/CustomButton/CustomButton';
import ModalWrapper from 'components/ModalWrapper/ModalWrapper';
import { SCHEDULED_DATE_TYPE } from 'components/CreateShipment/ShipmentStops/helpers/constants';
import {
  requireETAValidationSchema,
  validationSchema,
} from 'components/TableRowEditModalTypes/CheckInOutModal/validationSchema';
import useDateFormat from 'hooks/useDateFormat';
import useShowToaster from 'hooks/useShowToaster';
import { getAlphabet, palette } from 'utils/constants';
import { revertLastAction } from 'Api/Planner';
import { getCustomerDelayCode } from 'Api/Customers';
import { GetSettingsShipments } from 'Api/CompanySettings';
import DelayReasonCodesModal from 'componentsV2/Customer/EdiApi/DelayReasonCodes/DelayReasonCodesModal';
import classes from './checkInOut.module.scss';
import PickUpInModal from './types/PickUpInModal';
import DeliveryInModal from './types/DeliveryInModal';
import WayPointInModal from './types/WayPointInModal';
import { getInitialValues } from './CheckInOutModal.data';
import { SInfoSection } from './CheckInOutModal.styles';

const CheckInOutModal = ({ isOpen, setIsOpen, onAddBtn, data, shipment, onRevertSuccess }) => {
  const { convertToCustomerTime } = useDateFormat();
  const showToaster = useShowToaster();
  const [imageData, setImageData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingRevert, setLoadingRevert] = useState(false);
  const [shipmentSettings, setShipmentSettings] = useState(null);
  const [delayReasonCodes, setDelayReasonCodes] = useState([]);
  const [openDelayReason, setOpenDelayReason] = useState(false);
  const getStopName = useCallback((type) => {
    const TYPE_STOPS = {
      1: { type: ' : PICK UP', color: palette.green500 },
      2: { type: ' : DELIVERY', color: palette.red500 },
      3: { type: ' : WAYPOINT', color: palette.blueText },
    };
    return TYPE_STOPS[type];
  }, []);

  const nextStop = shipment?.shipment_stops?.[data.index + 1];
  const customer = shipment?.shipment_billing?.[0]?.billing_customer;
  const showNextStopETA =
    !!data.arrival_date && !!nextStop && !!customer?.customer_payment_term?.require_staff_provide_reason;

  const isETADelayed = nextStop
    ? moment(convertToCustomerTime(nextStop.eta)).isAfter(
        [1, 3].includes(Number(nextStop.scheduled_type))
          ? `${nextStop.scheduled_date_to || nextStop.scheduled_date} ${nextStop.to || nextStop.from}`
          : `${nextStop.scheduled_date} ${nextStop.from}`
      )
    : false;

  const submit = (validateForm, resetForm, submitForm, values) => {
    if (
      !!shipment?.shipment_billing?.[0]?.billing_customer?.customer_payment_term?.require_signature_on_stop &&
      !values.signature &&
      !values.check_in &&
      Number(data?.stop_point_type) !== 3
    ) {
      showToaster({ type: 'warning', message: 'Save signature to continue' });
    } else {
      const arrival_date = moment(values.arrival_date);
      const departure_date = data.arrival_date ? moment(values.departure_date) : null;
      if (departure_date && departure_date.isBefore(arrival_date)) {
        showToaster({ type: 'error', message: 'Departure date cannot be before Arrival date.' });
      } else if ((departure_date && !departure_date.isValid()) || (arrival_date && !arrival_date.isValid())) {
        showToaster({ type: 'error', message: 'Arrival or departure date is invalid!' });
      } else {
        validateForm().then((errors) => {
          const isValid = Object.keys(errors).length === 0;
          if (isValid) {
            setLoading(true);
            onAddBtn(validateForm, resetForm, submitForm, values, setLoading).finally(() => setLoading(false));
          }
        });
      }
    }
  };

  const subTitle = useMemo(
    () => `Shipment ${data?.shipment_id} ${data?.shipment_billing?.[0]?.billing_customer?.company_name}`,
    []
  );

  const initialValue = useMemo(() => {
    switch (Number(data?.stop_point_type)) {
      case 1:
        return getInitialValues(data, shipment, convertToCustomerTime);
      case 2:
        return getInitialValues(data, shipment, convertToCustomerTime);
      case 3:
        return getInitialValues(data, shipment, convertToCustomerTime);
      default:
    }
  }, [data, shipment]);

  const StopType = useMemo(() => {
    switch (Number(data?.stop_point_type)) {
      case 1:
        return PickUpInModal;
      case 2:
        return DeliveryInModal;
      case 3:
        return WayPointInModal;
      default:
    }
  }, [data]);

  const getSettingsShipments = async () => {
    try {
      const { data } = await GetSettingsShipments();
      setShipmentSettings(data);
    } catch (e) {
      // Do nothing
    }
  };

  const getDelayReasonCodes = async () => {
    try {
      const { data } = await getCustomerDelayCode({ id: customer.id });
      setDelayReasonCodes(data);
    } catch (e) {
      // Do nothing
    }
  };

  useEffect(() => {
    getSettingsShipments();
    getDelayReasonCodes();
  }, []);

  return (
    <div>
      <Formik
        initialValues={{ ...initialValue }}
        enableReinitialize
        onSubmit={() => {}}
        validationSchema={
          Number(data?.stop_point_type) === 1 && !!data?.arrival_date && shipmentSettings?.require_actual_data
            ? validationSchema
            : showNextStopETA
            ? requireETAValidationSchema
            : undefined
        }
      >
        {({ values, resetForm, submitForm, validateForm, setFieldValue, touched, setTouched, errors }) => {
          const typeData = getStopName(Number(data?.stop_point_type));
          const nextStopTypeData = nextStop ? getStopName(Number(nextStop?.stop_point_type)) : null;

          return (
            <Form>
              <ModalWrapper
                autoFocus
                width={!data.arrival_date || !showNextStopETA ? 900 : 1200}
                minHeight={600}
                backdrop='static'
                isOpen={isOpen}
                title={!data.arrival_date ? 'Check In' : 'Check Out'}
                subTitle={subTitle}
                styleTitle={{ fontSize: 16 }}
                onHide={() => setIsOpen(false)}
                dialogClassName='dialogClassName'
                styleBody={{ height: 600, overflowY: 'auto', padding: '20px' }}
                footer={
                  <div className={classes.modal_footer_lane} style={{ backgroundColor: palette.white }}>
                    <CustomButton
                      type='secondary'
                      title='Cancel'
                      onClick={() => {
                        setIsOpen(false);
                        resetForm();
                      }}
                      styleButton={{ padding: '2px 8px', marginTop: 0 }}
                    />
                    {shipment?.shipment_stops?.[0].arrival_date && (
                      <CustomButton
                        type='yellow'
                        title='Revert Last Action'
                        onClick={() => {
                          setLoadingRevert(true);
                          revertLastAction([shipment.shipment_id])
                            .then(() => {
                              showToaster({ type: 'success', message: 'Last action has been reverted' });
                              onRevertSuccess();
                              setIsOpen(false);
                            })
                            .finally(() => {
                              setLoadingRevert(false);
                            });
                        }}
                        styleButton={{ padding: '2px 8px', marginTop: 0, background: 'rgb(180, 137, 9)' }}
                        disabled={loadingRevert}
                      />
                    )}
                    <CustomButton
                      type='primary'
                      leftIcon={
                        loading ? (
                          <CircularProgress
                            style={{ height: '14px', width: '15px', marginRight: 10, color: '#FFFFFF' }}
                          />
                        ) : (
                          <div />
                        )
                      }
                      title={!data.arrival_date ? 'Check In' : 'Check Out'}
                      onClick={() => {
                        setTouched(setNestedObjectValues(values, true));
                        submit(validateForm, resetForm, submitForm, values);
                      }}
                      styleButton={{ padding: '2px 8px', marginRight: 0, marginTop: 0 }}
                      disabled={loading}
                    />
                  </div>
                }
              >
                <div className='d-flex gap-5'>
                  <div>
                    <div className={classes.header}>
                      <Typography variant='c1' style={{ color: typeData?.color }}>
                        STOP {getAlphabet(data?.index)}
                        {typeData?.type}
                      </Typography>
                      <div className={classes.headerSubTitle}>
                        <div className='d-flex gap-2'>
                          <Typography variant='s2'>{data?.stop_point?.location_name}</Typography>
                          <Typography variant='s3'>
                            {Number(data?.index) + 1} of {data?.shipment_stops?.length}
                          </Typography>
                        </div>
                        <div className={classes.subTitle}>
                          <Typography className={classes.address1} variant='s3'>
                            {data?.stop_point?.address1},&nbsp;
                          </Typography>
                          <Typography variant='s3'>{data?.stop_point?.city?.name},&nbsp;</Typography>
                          <Typography variant='s3'>{data?.stop_point?.state?.name}&nbsp;</Typography>
                          <Typography variant='s3'>{data?.stop_point?.zipcode}</Typography>
                        </div>
                      </div>
                    </div>

                    <StopType
                      values={values}
                      stop={data}
                      setFieldValue={setFieldValue}
                      shipment={shipment}
                      imageData={imageData}
                      setImageData={setImageData}
                      touched={touched}
                      errors={errors}
                      shipmentSettings={shipmentSettings}
                    />
                  </div>
                  {!!showNextStopETA && (
                    <SInfoSection>
                      <Typography variant='h5' style={{ color: palette.gray900 }}>
                        ETA to Next Stop
                      </Typography>
                      <Divider margin='24px 0' />
                      <div className={classes.header}>
                        <Typography variant='c1' style={{ color: nextStopTypeData?.color }}>
                          STOP {getAlphabet(data.index + 1)}
                          {nextStopTypeData?.type}
                        </Typography>
                        <div className={classes.headerSubTitle}>
                          <div className='d-flex gap-2'>
                            <Typography variant='s2'>{nextStop?.stop_point?.location_name}</Typography>
                          </div>
                          <div className={classes.subTitle}>
                            <Typography className={classes.address1} variant='s3' style={{ color: palette.gray500 }}>
                              {nextStop?.stop_point?.address1},&nbsp;
                            </Typography>
                            <Typography variant='s3' style={{ color: palette.gray500 }}>
                              {nextStop?.stop_point?.city?.name},&nbsp;
                            </Typography>
                            <Typography variant='s3' style={{ color: palette.gray500 }}>
                              {nextStop?.stop_point?.state?.name}&nbsp;
                            </Typography>
                            <Typography variant='s3' style={{ color: palette.gray500 }}>
                              {nextStop?.stop_point?.zipcode}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <Divider margin='24px 0' />
                      <div>
                        <div className='mb-4'>
                          <Autocomplete
                            width='230px'
                            label='Scheduled Date Type'
                            name='scheduled_type'
                            labelKey='label'
                            options={SCHEDULED_DATE_TYPE}
                            value={SCHEDULED_DATE_TYPE.find((i) => i.key === Number(nextStop.scheduled_type))}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            disabled
                          />
                        </div>
                        <div className='mb-4'>
                          <InputLabel>Scheduled Date/Time</InputLabel>
                          <div className='d-flex align-items-center gap-2'>
                            <DateTimePicker
                              name='scheduled_date'
                              value={new Date(`${nextStop.scheduled_date} ${nextStop.from}`)}
                              disabled
                            />
                            {[1, 3].includes(Number(nextStop.scheduled_type)) && (
                              <>
                                <Typography variant='s2'>to</Typography>
                                <DateTimePicker
                                  name='scheduled_date_to'
                                  value={
                                    new Date(
                                      `${nextStop.scheduled_date_to || nextStop.scheduled_date} ${
                                        nextStop.to || nextStop.from
                                      }`
                                    )
                                  }
                                  disabled
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <div>
                          {!!nextStop.eta && (
                            <div className='mb-4'>
                              <InputLabel>
                                <ArrowIcon className='me-2' />
                                Estimated Time of Arrival
                              </InputLabel>
                              <span className='d-flex align-items-center gap-2'>
                                {!nextStop.custom_eta && !nextStop.arrival_date && <SimpleLoader loading size={14} />}
                                {(!!nextStop.arrival_date || !!nextStop.delay_reason) && (
                                  <Tooltip
                                    title={
                                      nextStop.delay_reason && !nextStop.arrival_date
                                        ? `${nextStop.delay_reason.reason?.title || ''}`
                                        : ''
                                    }
                                  >
                                    <CheckIcon width={14} height={14} fill={palette.indigo500} />
                                  </Tooltip>
                                )}
                                {!nextStop.arrival_date && !!isETADelayed && !nextStop.delay_reason && (
                                  <Tooltip
                                    title={nextStop.delay_reason ? `${nextStop.delay_reason.reason?.title || ''}` : ''}
                                  >
                                    <WarningIcon fill={palette.red500} />
                                  </Tooltip>
                                )}
                                <DateTimePicker
                                  name='eta'
                                  value={values.eta}
                                  onChange={(date) => setFieldValue('eta', date)}
                                  disablePast
                                  error={touched?.eta && errors?.eta}
                                />
                              </span>
                            </div>
                          )}
                          {!!isETADelayed && (
                            <div>
                              <div className='d-flex align-items-center gap-2 mb-2'>
                                <InputLabel className='mb-0'>Select Delay Reason from Delay Reasons List</InputLabel>
                                <GearIcon onClick={() => setOpenDelayReason(true)} className='pointer' />
                              </div>
                              <Autocomplete
                                width='230px'
                                name='delay_reason'
                                options={[
                                  { id: 'add-reason', reason: { title: '+ Add Delay Reason' }, edi_api_code: '' },
                                  ...delayReasonCodes,
                                ]}
                                value={values.delay_reason}
                                onChange={(e, val) => {
                                  if (val.id === 'add-reason') {
                                    setOpenDelayReason(true);
                                    return;
                                  }
                                  setFieldValue('delay_reason', val);
                                }}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                getOptionLabel={(option) => `${option.reason.title} ${option.edi_api_code}`}
                                error={touched?.delay_reason && errors?.delay_reason}
                                renderOption={(props, option) => (
                                  <li
                                    {...props}
                                    key={option.id}
                                    className={`${props.className} ${
                                      option.id === 'add-reason' ? 'add-new-option' : ''
                                    }`}
                                  >
                                    {option.reason.title} {option.edi_api_code}
                                  </li>
                                )}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </SInfoSection>
                  )}
                </div>
              </ModalWrapper>
            </Form>
          );
        }}
      </Formik>
      {openDelayReason && (
        <DelayReasonCodesModal
          open={openDelayReason}
          onClose={() => {
            setOpenDelayReason(false);
            getDelayReasonCodes();
          }}
          customerId={customer.id}
        />
      )}
    </div>
  );
};

export default CheckInOutModal;
