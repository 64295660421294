export const CHANGE_LOG_TYPE = {
  COMPANY_SETTINGS: 'company-settings',
  OWNER_OPERATOR: 'owner-operator',
  DRIVER: 'driver',
  CUSTOMER: 'customer',
  CARRIER: 'carrier',
  STAFF: 'staff',
  STOP_POINT: 'stop-point',
  EQUIPMENT: 'equipment',
};
