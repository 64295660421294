import React from 'react';
import ChangeLogTable, { CHANGE_LOG_TYPE } from 'componentsV2/ChangeLog';
import { Typography } from 'components/Typography';
import { palette } from 'utils/constants';
import { SWrapper } from './ChangeLog.styles';

const ChangeLog = () => {
  return (
    <SWrapper>
      <div className='mb-4'>
        <Typography variant='h2' style={{ color: palette.gray900 }}>
          Change Logs
        </Typography>
      </div>
      <ChangeLogTable type={CHANGE_LOG_TYPE.COMPANY_SETTINGS} />
    </SWrapper>
  );
};

export default ChangeLog;
