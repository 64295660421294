import moment from 'moment';

export const getInitialValues = (settings) => {
  const {
    physical_insurance_policy_active,
    physical_insurance_policy_id,
    physical_insurance_expire_date,
    physical_insurance_policy_attach,
    liability_insurance_policy_active,
    liability_insurance_policy_id,
    liability_insurance_expire_date,
    liability_insurance_policy_attach,
    motor_cargo_insurance_policy_active,
    motor_cargo_insurance_policy_id,
    motor_cargo_insurance_expire_date,
    motor_cargo_insurance_policy_attach,
    general_liability_insurance_policy_active,
    general_liability_insurance_policy_id,
    general_liability_insurance_expire_date,
    general_liability_insurance_policy_attach,
  } = settings || {};

  return {
    physical_insurance_policy_active: !!physical_insurance_policy_active,
    physical_insurance_policy_id: physical_insurance_policy_id || '',
    physical_insurance_expire_date: physical_insurance_expire_date
      ? moment(physical_insurance_expire_date).toDate()
      : null,
    physical_insurance_no_expire: !physical_insurance_expire_date,
    physical_insurance_policy_attach: physical_insurance_policy_attach || null,
    liability_insurance_policy_active: !!liability_insurance_policy_active,
    liability_insurance_policy_id: liability_insurance_policy_id || '',
    liability_insurance_expire_date: liability_insurance_expire_date
      ? moment(liability_insurance_expire_date).toDate()
      : null,
    liability_insurance_no_expire: !liability_insurance_expire_date,
    liability_insurance_policy_attach: liability_insurance_policy_attach || null,
    motor_cargo_insurance_policy_active: !!motor_cargo_insurance_policy_active,
    motor_cargo_insurance_policy_id: motor_cargo_insurance_policy_id || '',
    motor_cargo_insurance_expire_date: motor_cargo_insurance_expire_date
      ? moment(motor_cargo_insurance_expire_date).toDate()
      : null,
    motor_cargo_insurance_no_expire: !motor_cargo_insurance_expire_date,
    motor_cargo_insurance_policy_attach: motor_cargo_insurance_policy_attach || null,
    general_liability_insurance_policy_active: !!general_liability_insurance_policy_active,
    general_liability_insurance_policy_id: general_liability_insurance_policy_id || '',
    general_liability_insurance_expire_date: general_liability_insurance_expire_date
      ? moment(general_liability_insurance_expire_date).toDate()
      : null,
    general_liability_insurance_no_expire: !general_liability_insurance_expire_date,
    general_liability_insurance_policy_attach: general_liability_insurance_policy_attach || null,
  };
};

export const insuranceFields = [
  {
    id: 1,
    title: 'Physical Insurance',
    policy_type: 'physical_insurance',
    active: 'physical_insurance_policy_active',
    policy_id: 'physical_insurance_policy_id',
    expire_date: 'physical_insurance_expire_date',
    no_expire: 'physical_insurance_no_expire',
    attachment: 'physical_insurance_policy_attach',
  },
  {
    id: 2,
    title: 'Auto Liability Insurance',
    policy_type: 'liability_insurance',
    active: 'liability_insurance_policy_active',
    policy_id: 'liability_insurance_policy_id',
    expire_date: 'liability_insurance_expire_date',
    no_expire: 'liability_insurance_no_expire',
    attachment: 'liability_insurance_policy_attach',
  },
  {
    id: 3,
    title: 'Motor Cargo Insurance',
    policy_type: 'motor_cargo_insurance',
    active: 'motor_cargo_insurance_policy_active',
    policy_id: 'motor_cargo_insurance_policy_id',
    expire_date: 'motor_cargo_insurance_expire_date',
    no_expire: 'motor_cargo_insurance_no_expire',
    attachment: 'motor_cargo_insurance_policy_attach',
  },
  {
    id: 4,
    title: 'General Liability Insurance',
    policy_type: 'general_liability_insurance',
    active: 'general_liability_insurance_policy_active',
    policy_id: 'general_liability_insurance_policy_id',
    expire_date: 'general_liability_insurance_expire_date',
    no_expire: 'general_liability_insurance_no_expire',
    attachment: 'general_liability_insurance_policy_attach',
  },
];

export const updateInsuranceConverter = (values) => {
  const formData = new FormData();
  const {
    physical_insurance_policy_id,
    physical_insurance_policy_active,
    physical_insurance_expire_date,
    physical_insurance_no_expire,
    physical_insurance_policy_attach,

    liability_insurance_policy_active,
    liability_insurance_policy_id,
    liability_insurance_expire_date,
    liability_insurance_no_expire,
    liability_insurance_policy_attach,

    motor_cargo_insurance_policy_active,
    motor_cargo_insurance_policy_id,
    motor_cargo_insurance_expire_date,
    motor_cargo_insurance_no_expire,
    motor_cargo_insurance_policy_attach,

    general_liability_insurance_policy_active,
    general_liability_insurance_policy_id,
    general_liability_insurance_expire_date,
    general_liability_insurance_no_expire,
    general_liability_insurance_policy_attach,
  } = values || {};

  formData.append('physical_insurance_policy_active', Number(physical_insurance_policy_active));
  if (physical_insurance_policy_active) {
    formData.append('physical_insurance_policy_id', physical_insurance_policy_id);
    formData.append(
      'physical_insurance_expire_date',
      physical_insurance_no_expire ? '' : moment(physical_insurance_expire_date).format('YYYY-MM-DD')
    );
    if (physical_insurance_policy_attach && typeof physical_insurance_policy_attach !== 'string') {
      formData.append('physical_insurance_policy_attach', physical_insurance_policy_attach);
    }
  }

  formData.append('liability_insurance_policy_active', Number(liability_insurance_policy_active));
  if (liability_insurance_policy_active) {
    formData.append('liability_insurance_policy_id', liability_insurance_policy_id);
    formData.append(
      'liability_insurance_expire_date',
      liability_insurance_no_expire ? '' : moment(liability_insurance_expire_date).format('YYYY-MM-DD')
    );
    if (liability_insurance_policy_attach && typeof liability_insurance_policy_attach !== 'string') {
      formData.append('liability_insurance_policy_attach', liability_insurance_policy_attach);
    }
  }

  formData.append('motor_cargo_insurance_policy_active', Number(motor_cargo_insurance_policy_active));
  if (motor_cargo_insurance_policy_active) {
    formData.append('motor_cargo_insurance_policy_id', motor_cargo_insurance_policy_id);
    formData.append(
      'motor_cargo_insurance_expire_date',
      motor_cargo_insurance_no_expire ? '' : moment(motor_cargo_insurance_expire_date).format('YYYY-MM-DD')
    );
    if (motor_cargo_insurance_policy_attach && typeof motor_cargo_insurance_policy_attach !== 'string') {
      formData.append('motor_cargo_insurance_policy_attach', motor_cargo_insurance_policy_attach);
    }
  }

  formData.append('general_liability_insurance_policy_active', Number(general_liability_insurance_policy_active));
  if (general_liability_insurance_policy_active) {
    formData.append('general_liability_insurance_policy_id', general_liability_insurance_policy_id);
    formData.append(
      'general_liability_insurance_expire_date',
      general_liability_insurance_no_expire ? '' : moment(general_liability_insurance_expire_date).format('YYYY-MM-DD')
    );
    if (general_liability_insurance_policy_attach && typeof general_liability_insurance_policy_attach !== 'string') {
      formData.append('general_liability_insurance_policy_attach', general_liability_insurance_policy_attach);
    }
  }

  return formData;
};
