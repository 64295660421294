import React from 'react';
import CustomButton from 'components/CustomButton/CustomButton';
import { ReactComponent as ExportIcon } from 'assets/icons/exportIcon.svg';
import { ReactComponent as RevertIcon } from 'assets/icons/reload.svg';
import { palette } from 'utils/constants';
import { onExportClick } from 'pages/Accounting/Receivables/TableSection/TableSection.data';

const BulkActions = ({ data, onRevert, tab }) => {
  return (
    <div className='d-flex align-items-center gap-3 mb-4'>
      {tab === 'shipment' && (
        <CustomButton
          type='secondary'
          title='Export CSV'
          leftIcon={<ExportIcon fill={palette.gray700} style={{ marginRight: 10 }} />}
          styleButton={{ padding: '5px 12px', marginTop: 0 }}
          onClick={() => onExportClick(data, 'paid')}
        />
      )}
      <CustomButton
        type='secondary'
        title='Revert'
        leftIcon={<RevertIcon fill={palette.gray700} style={{ marginRight: 10, transform: 'rotate(90deg)' }} />}
        styleButton={{ padding: '5px 12px', marginTop: 0 }}
        onClick={() => onRevert()}
      />
    </div>
  );
};

export default BulkActions;
